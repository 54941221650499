import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import {
  CreateNPPPayload,
  GetRespondentCompanyDataPayload,
  GetRespondentsCompaniesPayload,
  GetSignedContractsPayload,
  RemoveFilePayload,
  RemoveNPPsPayload,
  UpdateNPPPayload,
  UploadFilesPayload,
} from './api';

/* ACTION TYPES */
export enum NonProjectHandoverProtocolActionTypes {
  GetNonProjectHandoverProtocols = '@@NonProjectHandoverProtocol/GET_HANDOVER_PROTOCOLS',
  SetNonProjectHandoverProtocolsPage = '@@NonProjectHandoverProtocol/SET_HANDOVER_PROTOCOLS_PAGE',
  IncreasePage = '@@NonProjectHandoverProtocol/INCREASE_HANDOVER_PROTOCOLS',
  SetOrderColumn = '@@NonProjectHandoverProtocol/SET_ORDER_COLUMN',
  SetFilterColumn = '@@NonProjectHandoverProtocol/SET_FILTER_COLUMNS',
  DownloadZip = '@@NonProjectHandoverProtocol/DOWNLOAD_ZIP',

  GetRespondentsCompanies = '@@NonProjectHandoverProtocol/GET_RESPONDENTS_COMPANIES',
  GetRespondentCompanyData = '@@NonProjectHandoverProtocol/GET_RESPONDENT_COMPANY_DATA',
  SetRespondentsCompaniesPage = '@@NonProjectHandoverProtocol/SET_RESPONDENTS_COMPANIES_PAGE',
  IncreaseRespondentsCompaniesPage = '@@NonProjectHandoverProtocol/INCREASE_RESPONDENTS_COMPANIES_PAGE',
  SetRespondentsCompaniesFilterColumn = '@@NonProjectHandoverProtocol/SET_RESPONDENTS_COMPANIES_FILTER_COLUMN',

  CreateNonProjectHandoverProtocol = '@@NonProjectHandoverProtocol/CREATE_HANDOVER_PROTOCOL',
  GetNonProjectHandoverProtocol = '@@NonProjectHandoverProtocol/GET_HANDOVER_PROTOCOL',
  UpdateNonProjectHandoverProtocol = '@@NonProjectHandoverProtocol/UPDATE_HANDOVER_PROTOCOL',
  RemoveNonProjectHandoverProtocols = '@@NonProjectHandoverProtocol/REMOVE_HANDOVER_PROTOCOLS',

  GetSignedContracts = '@@NonProjectHandoverProtocol/GET_SIGNED_CONTRACTS',
  GetSignedContractsStatus = '@@NonProjectHandoverProtocol/GET_SIGNED_CONTRACTS_STATUS',

  UploadFiles = '@NonProjectHandoverProtocol/UPLOAD_FILES',
  RemoveFile = '@NonProjectHandoverProtocol/REMOVE_FILE',
}

/* ACTIONS */
export const nonProjectHandoverProtocolGetNonProjectHandoverProtocolsActions =
  createApiActionCreators(NonProjectHandoverProtocolActionTypes.GetNonProjectHandoverProtocols);
export const nonProjectHandoverProtocolSetNonProjectHandoverProtocolsPageActions =
  createActionCreator(NonProjectHandoverProtocolActionTypes.SetNonProjectHandoverProtocolsPage);
export const nonProjectHandoverProtocolIncreasePageActions = createActionCreator(
  NonProjectHandoverProtocolActionTypes.IncreasePage
);
export const nonProjectHandoverProtocolSetOrderColumnActions = createActionCreator(
  NonProjectHandoverProtocolActionTypes.SetOrderColumn
);
export const nonProjectHandoverProtocolSetFilterColumnActions = createActionCreator(
  NonProjectHandoverProtocolActionTypes.SetFilterColumn
);

export const nonProjectHandoverProtocolDownloadZipActions = createApiActionCreators(
  NonProjectHandoverProtocolActionTypes.DownloadZip
);

export const nonProjectHandoverProtocolGetRespondentsCompaniesActions =
  createApiActionCreators<GetRespondentsCompaniesPayload>(
    NonProjectHandoverProtocolActionTypes.GetRespondentsCompanies
  );

export const nonProjectHandoverProtocolSetRespondentsCompaniesPageActions = createActionCreator(
  NonProjectHandoverProtocolActionTypes.SetRespondentsCompaniesPage
);

export const nonProjectHandoverProtocolIncreaseRespondentsCompaniesPageActions =
  createActionCreator(NonProjectHandoverProtocolActionTypes.IncreaseRespondentsCompaniesPage);

export const nonProjectHandoverProtocolSetRespondentsCompaniesFilterColumnActions =
  createActionCreator(NonProjectHandoverProtocolActionTypes.SetRespondentsCompaniesFilterColumn);

export const nonProjectHandoverProtocolCreateHandoverProtocolActions =
  createApiActionCreators<CreateNPPPayload>(
    NonProjectHandoverProtocolActionTypes.CreateNonProjectHandoverProtocol
  );

export const nonProjectHandoverProtocolGetNonProjectHandoverProtocolActions =
  createApiActionCreators(NonProjectHandoverProtocolActionTypes.GetNonProjectHandoverProtocol);

export const nonProjectHandoverProtocolUpdateHandoverProtocolActions =
  createApiActionCreators<UpdateNPPPayload>(
    NonProjectHandoverProtocolActionTypes.UpdateNonProjectHandoverProtocol
  );

export const nonProjectHandoverProtocolRemoveHandoverProtocolsActions =
  createApiActionCreators<RemoveNPPsPayload>(
    NonProjectHandoverProtocolActionTypes.RemoveNonProjectHandoverProtocols
  );

export const nonProjectHandoverProtocolGetRespondentCompanyDataActions =
  createApiActionCreators<GetRespondentCompanyDataPayload>(
    NonProjectHandoverProtocolActionTypes.GetRespondentCompanyData
  );

export const nonProjectHandoverProtocolGetSignedContractsActions =
  createApiActionCreators<GetSignedContractsPayload>(
    NonProjectHandoverProtocolActionTypes.GetSignedContracts
  );

export const nonProjectHandoverProtocolUploadFiles = createApiActionCreators<UploadFilesPayload>(
  NonProjectHandoverProtocolActionTypes.UploadFiles
);

export const nonProjectHandoverProtocolRemoveFile = createApiActionCreators<RemoveFilePayload>(
  NonProjectHandoverProtocolActionTypes.RemoveFile
);
