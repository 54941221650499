import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

interface BankAccountPayload {
  id?: number;
  userId?: number;
  bank?: string;
  iban?: string;
  companyId?: number;
}

export interface RemoveBankAccountPayload {
  id: number;
  userId?: number;
  companyId?: number;
}

export const api = {
  getBankAccounts: ({ pagination }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/bank-accounts', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
      }),
    });
  },

  setPrimaryBankAccount: (payload: {
    bankAccountId: number;
    typeName: string;
    bank: string;
    iban: string;
    companyId?: number;
  }): Promise<ExtendedAxiosResponse> => {
    return apiClient.put(`/bank-accounts/${payload.bankAccountId}`, toSnakeCase(payload));
  },

  createBankAccount: (payload: BankAccountPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post(
      '/bank-accounts',
      toSnakeCase({
        ...payload,
        typeName: 'primárny',
      })
    ),

  getBankAccount: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/bank-accounts/${id}`),

  removeBankAccount: ({
    id,
    userId,
    companyId,
  }: RemoveBankAccountPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/bank-accounts/${id}`, {
      params: toSnakeCase({
        companyId,
        userId,
      }),
    }),

  updateBankAccount: (payload: BankAccountPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(
      `/bank-accounts/${payload.id}`,
      toSnakeCase({
        ...payload,
        typeName: 'primárny',
      })
    ),
};
