import React, { Suspense, useCallback } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Loading from '../../../../views/components/Loading/Loading';
import { IRoute } from '../../route';
import { buildRoute } from '../../route-builder';
import { AppRoutes } from '../../routes/app-routes';
import ExtendedRoute from '../ExtendedRoute/ExtendedRoute';

interface Props {
  routes: IRoute[];
}

const RouteMapper = ({ routes }: Props) => {
  const renderRoutes = useCallback(
    (routes: IRoute[]) => {
      return routes.map((route) => (
        <Route key={route.path} path={route.path} element={<ExtendedRoute route={route} />}>
          {route.children && renderRoutes(route.children)}
        </Route>
      ));
    },
    [routes]
  );

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {renderRoutes(routes)}
        <Route path="*" element={<Navigate replace to={buildRoute(AppRoutes.Home)} />} />
      </Routes>
    </Suspense>
  );
};

export default RouteMapper;
