import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { SettingsRoutes } from '../../helpers/route/routes/settings-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';

import { Country } from '../../models/Country';
import { Pages, PaginatedResp } from '../../models/Pages';
import { Region } from '../../models/Region';
import { ISearch, IOrderStatus, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  RegionActionTypes,
  regionCreateRegionActions,
  regionGetRegionsActions,
  regionGetRegionActions,
  regionGetCountriesActions,
  regionRemoveRegionActions,
  regionUpdateRegionActions,
} from './actions';
import { api, RegionPayload } from './api';
import {
  selectRegionRegionsFilterColumn,
  selectRegionRegionsOrderColumn,
  selectRegionRegionsPagination,
} from './selectors';

/* STATE */
export interface RegionState {
  regions: Region[];
  regionsPagination: Pages;
  regionsStatus: LoadingStatus;
  regionsOrder: ITableOrder;
  regionsFilter: Record<string, ISearch>;
  region: Region | null;
  countries: Country[];
  regionActionStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: RegionState = {
  regions: [],
  regionsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  regionsStatus: LoadingStatus.initial,
  regionsOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  regionsFilter: {},
  region: null,
  countries: [],
  regionActionStatus: LoadingStatus.initial,
};

const regions = createReducer(initialState.regions, {
  [RegionActionTypes.GetRegions]: {
    [RequestActionTypes.SUCCESS]: (state: Region[], payload: PaginatedResp<Region>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.regions,
  },
  [RegionActionTypes.RemoveRegion]: {
    [RequestActionTypes.SUCCESS]: (state: Region[], payload: { id: number }) => {
      return state.filter((region) => region.id !== payload.id);
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.regions,
  },
});

const regionsPagination = createReducer(initialState.regionsPagination, {
  [RegionActionTypes.GetRegions]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<Region>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.regionsPagination,
  },
  [RegionActionTypes.SetRegionsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [RegionActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [RegionActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [RegionActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.regionsPagination,
  },
});

const regionsStatus = createLoadingStateReducer(
  initialState.regionsStatus,
  {
    [RegionActionTypes.GetRegions]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [RegionActionTypes.SetRegionsPage]: () => initialState.regionsStatus,
    [RegionActionTypes.IncreasePage]: () => initialState.regionsStatus,
    [RegionActionTypes.SetFilterColumn]: () => initialState.regionsStatus,
    [RegionActionTypes.SetOrderColumn]: () => initialState.regionsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.regionsStatus,
    },
  }
);

const regionsOrder = createReducer(initialState.regionsOrder, {
  [RegionActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.regionsOrder,
  },
});

const regionsFilter = createReducer(initialState.regionsFilter, {
  [RegionActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.regionsFilter,
  },
});

const region = createReducer(initialState.region, {
  [RegionActionTypes.CreateRegion]: {
    [RequestActionTypes.SUCCESS]: (state: Region | null, payload: Region) => payload,
  },
  [RegionActionTypes.GetRegion]: {
    [RequestActionTypes.REQUEST]: () => initialState.region,
    [RequestActionTypes.SUCCESS]: (state: Region | null, payload: Region) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.region,
  },
  [RegionActionTypes.UpdateRegion]: {
    [RequestActionTypes.SUCCESS]: (state: Region | null, payload: Region) => payload,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.region,
  },
});

const countries = createReducer(initialState.countries, {
  [RegionActionTypes.GetCountries]: {
    [RequestActionTypes.SUCCESS]: (state: Country[], payload: Country[]) => payload,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.countries,
  },
});

const regionActionStatus = createLoadingStateReducer(
  initialState.regionActionStatus,
  {
    [RegionActionTypes.CreateRegion]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [RegionActionTypes.UpdateRegion]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.regionActionStatus,
    },
  }
);

export default combineReducers<RegionState>({
  regions,
  regionsPagination,
  regionsStatus,
  regionsOrder,
  regionsFilter,
  region,
  countries,
  regionActionStatus,
});

/* SAGAS */
function* getRegions() {
  const pagination: Pages = yield select(selectRegionRegionsPagination);
  const orderColumn: ITableOrder = yield select(selectRegionRegionsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(selectRegionRegionsFilterColumn);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getRegions, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(regionGetRegionsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(regionGetRegionsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeRegion({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeRegion, payload);

  if (resp.ok) {
    yield put(regionRemoveRegionActions.success({ id: payload.id }));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(regionRemoveRegionActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createRegion({ payload }: AppAction<RegionPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.createRegion, payload);

  if (resp.ok) {
    yield put(regionCreateRegionActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageRegions]));
  } else {
    yield put(regionCreateRegionActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getRegion({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getRegion, payload);

  if (resp.ok) {
    yield put(regionGetRegionActions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(regionGetRegionActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateRegion({ payload }: AppAction<RegionPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateRegion, payload);

  if (resp.ok) {
    yield put(regionUpdateRegionActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageRegions]));
  } else {
    yield put(regionUpdateRegionActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getCountries() {
  const resp: ExtendedAxiosResponse = yield call(api.getCountries);

  if (resp.ok) {
    yield put(regionGetCountriesActions.success(toCamelCase(resp.data)?.data || []));
  } else {
    yield put(regionGetCountriesActions.failure());
  }
}

/* EXPORT */
export function* regionSaga() {
  yield takeLatest(
    [
      createActionType(RegionActionTypes.GetRegions, RequestActionTypes.REQUEST),
      RegionActionTypes.SetRegionsPage,
      RegionActionTypes.IncreasePage,
      RegionActionTypes.SetOrderColumn,
      RegionActionTypes.SetFilterColumn,
    ],
    getRegions
  );
  yield takeLatest(
    [createActionType(RegionActionTypes.RemoveRegion, RequestActionTypes.REQUEST)],
    removeRegion
  );
  yield takeLatest(
    createActionType(RegionActionTypes.CreateRegion, RequestActionTypes.REQUEST),
    createRegion
  );
  yield takeLatest(
    createActionType(RegionActionTypes.GetRegion, RequestActionTypes.REQUEST),
    getRegion
  );
  yield takeLatest(
    createActionType(RegionActionTypes.UpdateRegion, RequestActionTypes.REQUEST),
    updateRegion
  );
  yield takeLatest(
    createActionType(RegionActionTypes.GetCountries, RequestActionTypes.REQUEST),
    getCountries
  );
}
