import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { Profession } from '../../models/User';
import { ProfessionState } from './ducks';

/* SELECTORS */
const selectProfession = (state: AppState): ProfessionState => state.profession;

export const selectProfessionGetProfessions = (state: AppState): Profession[] =>
  selectProfession(state).professions;

export const selectProfessionProfessionsPagination = (state: AppState): Pages =>
  selectProfession(state).professionsPagination;

export const selectProfessionProfessionsStatus = (state: AppState): LoadingStatus =>
  selectProfession(state).professionsStatus;

export const selectProfessionProfessionsOrderColumn = (state: AppState): ITableOrder =>
  selectProfession(state).professionsOrder;

export const selectProfessionProfessionsFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectProfession(state).professionsFilter;
