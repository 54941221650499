import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export interface InvoiceCompanyPayload {
  id?: number;
  city: string;
  dic: string;
  icdph: string;
  ico: string;
  name: string;
  state: boolean;
  street: string;
  zip: string;
  register: string;
}

export const api = {
  getInvoiceCompaniesAll: (): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/invoicing-entities', {
      params: toSnakeCase({
        page: 1,
        resultsPerPage: 1000,
      }),
    }),
  getInvoiceCompanies: ({
    pagination,
    order,
    filter,
  }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/invoicing-entities', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  removeInvoiceCompany: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/invoicing-entities/${id}`),

  getInvoiceCompany: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/invoicing-entities/${id}`),

  createInvoiceCompany: (payload: InvoiceCompanyPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/invoicing-entities', toSnakeCase(payload)),

  updateInvoiceCompany: (payload: InvoiceCompanyPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(`/invoicing-entities/${payload?.id}`, toSnakeCase(payload)),
};
