import { omit } from 'lodash';
import { combineReducers } from 'redux';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IProject } from '../../models/Project';
import { ProjectProtocolProtocol } from '../../models/ProjectProtocol';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { selectProjectsDataOverviewProject } from '../projectsDataOverview/selectors';
import { toastCreateErrorActions } from '../toast/actions';
import { ProjectProtocolActionTypes, projectProtocolGetProtocolsActions } from './actions';
import { api } from './api';
import {
  selectProjectProtocolProtocolsFilterColumn,
  selectProjectProtocolProtocolsOrderColumn,
  selectProjectProtocolProtocolsPagination,
} from './selectors';

/* STATE */
export interface ProjectProtocolState {
  protocols: ProjectProtocolProtocol[];
  protocolsPagination: Pages;
  protocolsStatus: LoadingStatus;
  protocolsOrder: ITableOrder;
  protocolsFilter: Record<string, ISearch>;
  summary: number;
}

/* REDUCERS */
const initialState: ProjectProtocolState = {
  protocols: [],
  protocolsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  protocolsStatus: LoadingStatus.initial,
  protocolsOrder: {
    sort: '',
    order: IOrderStatus.DESC,
  },
  protocolsFilter: {},
  summary: 0,
};

const protocols = createReducer(initialState.protocols, {
  [ProjectProtocolActionTypes.GetProtocols]: {
    [RequestActionTypes.SUCCESS]: (
      state: ProjectProtocolProtocol[],
      payload: PaginatedResp<ProjectProtocolProtocol>
    ) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.protocols,
  },
  [ProjectProtocolActionTypes.CleanData]: () => initialState.protocols,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.protocols,
  },
});

const protocolsPagination = createReducer(initialState.protocolsPagination, {
  [ProjectProtocolActionTypes.GetProtocols]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<ProjectProtocolProtocol>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.protocolsPagination,
  },
  [ProjectProtocolActionTypes.SetProtocolsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [ProjectProtocolActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [ProjectProtocolActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ProjectProtocolActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ProjectProtocolActionTypes.CleanData]: () => initialState.protocolsPagination,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.protocolsPagination,
  },
});

const protocolsStatus = createLoadingStateReducer(
  initialState.protocolsStatus,
  {
    [ProjectProtocolActionTypes.GetProtocols]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ProjectProtocolActionTypes.SetProtocolsPage]: () => initialState.protocolsStatus,
    [ProjectProtocolActionTypes.IncreasePage]: () => initialState.protocolsStatus,
    [ProjectProtocolActionTypes.SetFilterColumn]: () => initialState.protocolsStatus,
    [ProjectProtocolActionTypes.SetOrderColumn]: () => initialState.protocolsStatus,
    [ProjectProtocolActionTypes.CleanData]: () => initialState.protocolsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.protocolsStatus,
    },
  }
);

const protocolsOrder = createReducer(initialState.protocolsOrder, {
  [ProjectProtocolActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [ProjectProtocolActionTypes.CleanData]: () => initialState.protocolsOrder,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.protocolsOrder,
  },
});

const protocolsFilter = createReducer(initialState.protocolsFilter, {
  [ProjectProtocolActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [ProjectProtocolActionTypes.CleanData]: () => initialState.protocolsFilter,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.protocolsFilter,
  },
});

const summary = createReducer(initialState.summary, {
  [ProjectProtocolActionTypes.GetProtocols]: {
    [RequestActionTypes.SUCCESS]: (
      state: number,
      payload: {
        summary: {
          surveysCount: number;
        };
      } & PaginatedResp<ProjectProtocolProtocol>
    ) => {
      if (isNaN(parseInt(`${payload?.summary?.surveysCount}`))) {
        return state;
      }
      if (payload?.currentPage == 1) {
        return parseInt(`${payload?.summary?.surveysCount}`);
      } else {
        return state + parseInt(`${payload?.summary?.surveysCount}`);
      }
    },
  },
  [ProjectProtocolActionTypes.CleanData]: () => {
    return initialState.summary;
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.summary,
  },
});

export default combineReducers<ProjectProtocolState>({
  protocols,
  protocolsPagination,
  protocolsStatus,
  protocolsOrder,
  protocolsFilter,
  summary,
});

/* SAGAS */
function* getProjectProtocolProtocols() {
  const pagination: Pages = yield select(selectProjectProtocolProtocolsPagination);
  const orderColumn: ITableOrder = yield select(selectProjectProtocolProtocolsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(
    selectProjectProtocolProtocolsFilterColumn
  );

  const project: IProject = yield select(selectProjectsDataOverviewProject);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getProtocols, {
    id: `${project?.id}` ?? '',
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(projectProtocolGetProtocolsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(projectProtocolGetProtocolsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* projectProtocolSaga() {
  yield takeLatest(
    [
      createActionType(ProjectProtocolActionTypes.GetProtocols, RequestActionTypes.REQUEST),
      ProjectProtocolActionTypes.SetProtocolsPage,
      ProjectProtocolActionTypes.IncreasePage,
      ProjectProtocolActionTypes.SetOrderColumn,
      ProjectProtocolActionTypes.SetFilterColumn,
    ],
    getProjectProtocolProtocols
  );
}
