import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { BankAccount } from '../../models/BankAccount';
import { Client } from '../../models/Client';

import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  ClientActionTypes,
  clientCreateClientActions,
  clientGetBankAccountsActions,
  clientGetClientActions,
  clientGetClientsActions,
  clientRemoveClientActions,
  clientUpdateClientActions,
} from './actions';
import { api, ClientPayload } from './api';
import {
  selectClientClientsFilterColumn,
  selectClientClientsOrderColumn,
  selectClientClientsPagination,
} from './selectors';

/* STATE */
export interface ClientState {
  clients: Client[];
  clientsPagination: Pages;
  clientsStatus: LoadingStatus;
  clientsOrder: ITableOrder;
  clientsFilter: Record<string, ISearch>;
  bankAccounts: Record<string, BankAccount[]>;
  client: Client | null;
  clientActionStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: ClientState = {
  clients: [],
  clientsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  clientsStatus: LoadingStatus.initial,
  clientsOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  clientsFilter: {},
  bankAccounts: {},
  client: null,
  clientActionStatus: LoadingStatus.initial,
};

const clients = createReducer(initialState.clients, {
  [ClientActionTypes.GetClients]: {
    [RequestActionTypes.SUCCESS]: (state: Client[], payload: PaginatedResp<Client>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.clients,
  },
  [ClientActionTypes.RemoveClient]: {
    [RequestActionTypes.SUCCESS]: (state: Client[], payload: { id: number }) => {
      return state.filter((client) => client.id !== payload.id);
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clients,
  },
});

const clientsPagination = createReducer(initialState.clientsPagination, {
  [ClientActionTypes.GetClients]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<Client>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.clientsPagination,
  },
  [ClientActionTypes.SetClientsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [ClientActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [ClientActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ClientActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clientsPagination,
  },
});

const clientsStatus = createLoadingStateReducer(
  initialState.clientsStatus,
  {
    [ClientActionTypes.GetClients]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ClientActionTypes.SetClientsPage]: () => initialState.clientsStatus,
    [ClientActionTypes.IncreasePage]: () => initialState.clientsStatus,
    [ClientActionTypes.SetFilterColumn]: () => initialState.clientsStatus,
    [ClientActionTypes.SetOrderColumn]: () => initialState.clientsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.clientsStatus,
    },
  }
);

const clientsOrder = createReducer(initialState.clientsOrder, {
  [ClientActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clientsOrder,
  },
});

const clientsFilter = createReducer(initialState.clientsFilter, {
  [ClientActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clientsFilter,
  },
});

const bankAccounts = createReducer(initialState.bankAccounts, {
  [ClientActionTypes.GetBankAccounts]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, BankAccount[]>,
      payload: { data: PaginatedResp<BankAccount>; clientId: number }
    ) => {
      return {
        ...state,
        [payload.clientId]: [...payload?.data?.data],
      };
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.bankAccounts,
  },
});

const client = createReducer(initialState.client, {
  [ClientActionTypes.CreateClient]: {
    [RequestActionTypes.SUCCESS]: (state: Client | null, payload: Client) => payload,
  },
  [ClientActionTypes.GetClient]: {
    [RequestActionTypes.REQUEST]: () => initialState.client,
    [RequestActionTypes.SUCCESS]: (state: Client | null, payload: Client) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.client,
  },
  [ClientActionTypes.UpdateClient]: {
    [RequestActionTypes.SUCCESS]: (state: Client | null, payload: Client) => payload,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.client,
  },
});

const clientActionStatus = createLoadingStateReducer(
  initialState.clientActionStatus,
  {
    [ClientActionTypes.CreateClient]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [ClientActionTypes.UpdateClient]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.clientActionStatus,
    },
  }
);

export default combineReducers<ClientState>({
  clients,
  clientsPagination,
  clientsStatus,
  clientsOrder,
  clientsFilter,
  bankAccounts,
  client,
  clientActionStatus,
});

/* SAGAS */
function* getClients() {
  const pagination: Pages = yield select(selectClientClientsPagination);
  const orderColumn: ITableOrder = yield select(selectClientClientsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(selectClientClientsFilterColumn);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getClients, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(clientGetClientsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(clientGetClientsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeClient({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeClient, payload);

  if (resp.ok) {
    yield put(clientRemoveClientActions.success({ id: payload.id }));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(clientRemoveClientActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getBankAccounts({ payload }: AppAction<{ clientId: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getBankAccounts, payload);

  if (resp.ok) {
    yield put(
      clientGetBankAccountsActions.success({
        data: toCamelCase(resp.data),
        clientId: payload.clientId,
      })
    );
  } else {
    yield put(clientGetBankAccountsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createClient({ payload }: AppAction<ClientPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.createClient, payload);

  if (resp.ok) {
    yield put(clientCreateClientActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));

    history.push(buildRoute([AppRoutes.Admin, AdminRoutes.Clients]));
  } else {
    yield put(clientCreateClientActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getClient({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getClient, payload);

  if (resp.ok) {
    yield put(clientGetClientActions.success(toCamelCase(resp.data)?.data || initialState.client));
  } else {
    yield put(clientGetClientActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateClient({ payload }: AppAction<ClientPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateClient, payload);

  if (resp.ok) {
    yield put(clientUpdateClientActions.success(toCamelCase(resp?.data)));
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(buildRoute([AppRoutes.Admin, AdminRoutes.Clients]));
  } else {
    yield put(clientUpdateClientActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* clientSaga() {
  yield takeLatest(
    [
      createActionType(ClientActionTypes.GetClients, RequestActionTypes.REQUEST),
      ClientActionTypes.SetClientsPage,
      ClientActionTypes.IncreasePage,
      ClientActionTypes.SetOrderColumn,
      ClientActionTypes.SetFilterColumn,
    ],
    getClients
  );
  yield takeLatest(
    [createActionType(ClientActionTypes.RemoveClient, RequestActionTypes.REQUEST)],
    removeClient
  );
  yield takeLatest(
    createActionType(ClientActionTypes.CreateClient, RequestActionTypes.REQUEST),
    createClient
  );
  yield takeLatest(
    createActionType(ClientActionTypes.GetClient, RequestActionTypes.REQUEST),
    getClient
  );
  yield takeLatest(
    createActionType(ClientActionTypes.UpdateClient, RequestActionTypes.REQUEST),
    updateClient
  );
  yield takeLatest(
    [createActionType(ClientActionTypes.GetBankAccounts, RequestActionTypes.REQUEST)],
    getBankAccounts
  );
}
