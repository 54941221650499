import { lazy } from 'react';

import {
  BreadcrumbAddress,
  BreadcrumbAddressCreate,
  BreadcrumbAddressDetail,
  BreadcrumbArchivedProjectDetail,
  BreadcrumbAssignedProjectDetail,
  BreadcrumbBankAccountDetail,
  BreadcrumbCompanyCreate,
  BreadcrumbCompanyDetail,
  BreadcrumbMyProfile,
  BreadcrumbSurvey,
} from '../components/SubBreadcrumbs/SubBreadcrumbs';
import Translate from '../components/Translate/Translate';
import { IRoute, Permission } from '../route';
import adminRoutes from './admin-routes';
import authRoutes from './auth-routes';

export enum AppRoutes {
  Home = '/',
  Profile = 'profile',
  Faq = 'faq',
  Cookies = 'cookies',
  Gdpr = 'gdpr',
  DataSecurity = 'data-security',
  AssignedProjects = 'assigned-projects',
  AssignedProjectsDetail = 'assigned-projects/:projectId',
  ArchivedProjects = 'archived-projects',
  ArchivedProjectsDetail = 'archived-projects/:projectId',
  Contracts = 'contracts',
  ContractCreate = 'contracts/create',
  BankAccounts = 'bankAccounts',
  BankAccountCreate = 'bankAccounts/create',
  BankAccountDetail = 'bankAccounts/:bankAccountId',
  Addresses = 'addresses',
  AddressesCreate = 'addresses/create',
  AddressesDetail = 'addresses/:addressId',
  Contacts = 'contacts',
  ContactsCreate = 'contacts/create',
  ContactsDetail = 'contacts/:contactId',
  Admin = 'admin',
  Auth = 'auth',
  Survey = 'survey',
  Companies = 'companies',
  CompanyCreate = 'companies/create',
  CompanyDetail = 'companies/:companyId',
  CompanyAddresses = 'companies/:companyId/addresses',
  CompanyAddressCreate = 'companies/:companyId/addresses/create',
  CompanyAddressDetail = 'companies/:companyId/addresses/:addressId',
  CompanyBankAccounts = 'companies/:companyId/bankAccounts',
  CompanyBankAccountCreate = 'companies/:companyId/bankAccounts/create',
  CompanyBankAccountDetail = 'companies/:companyId/bankAccounts/:bankAccountId',
  HandoverProtocol = 'handover-protocols',
  HandoverProtocolDownload = 'handover-protocols/:handoverProtocolId/pdf',
  BankTransfers = 'bank-transfers',
  BankTransfersDownload = 'bank-transfers/:bankTransferId/pdf',
  ChangeEmail = 'auth/change-email',
  VerifyEmail = 'auth/change-email-approve',
  ResetPassword = 'auth/reset-password',
}

const Home = lazy(() => import('../../../views/pages/Home/Home'));
const Profile = lazy(() => import('../../../views/pages/Profile/Profile'));
const Faq = lazy(() => import('../../../views/pages/Faq/Faq'));
const Cookies = lazy(() => import('../../../views/pages/Cookies/Cookies'));
const Gdpr = lazy(() => import('../../../views/pages/Gdpr/Gdpr'));
const DataSecurity = lazy(() => import('../../../views/pages/DataSecurity/DataSecurity'));
const AssignedProjects = lazy(
  () => import('../../../views/pages/AssignedProjects/AssignedProjects')
);

const AssignedProjectDetail = lazy(
  () => import('../../../views/pages/AssignedProjects/AssignedProjectDetail/AssignedProjectDetail')
);
const ArchivedProjects = lazy(
  () => import('../../../views/pages/ArchivedProjects/ArchivedProjects')
);
const ArchivedProjectDetail = lazy(
  () => import('../../../views/pages/ArchivedProjects/ArchivedProjectDetail/ArchivedProjectDetail')
);
const Contracts = lazy(() => import('../../../views/pages/Contracts/Contracts'));
const ContractCreate = lazy(
  () => import('../../../views/pages/Contracts/ContractCreate/ContractCreate')
);
const BankAccount = lazy(() => import('../../../views/pages/BankAccount/BankAccount'));
const BankAccountCreate = lazy(
  () => import('../../../views/pages/BankAccount/BankAccountCreate/BankAccountCreate')
);
const BankAccountDetail = lazy(
  () => import('../../../views/pages/BankAccount/BankAccountDetail/BankAccountDetail')
);
const Address = lazy(() => import('../../../views/pages/Address/Address'));
const AddressCreate = lazy(
  () => import('../../../views/pages/Address/AddressCreate/AddressCreate')
);
const AddressDetail = lazy(
  () => import('../../../views/pages/Address/AddressDetail/AddressDetail')
);
const CompaniesCreate = lazy(
  () => import('../../../views/pages/Companies/CompaniesCreate/CompaniesCreate')
);
const CompaniesDetailRespondent = lazy(
  () => import('../../../views/pages/Companies/CompaniesDetail/CompaniesDetailRespondent')
);

const Survey = lazy(() => import('../../../views/pages/Survey/Survey'));

const AcceptanceProtocol = lazy(
  () => import('../../../views/pages/AdminContracts/AcceptanceProtocol/AcceptanceProtocol')
);

const AcceptanceProtocolDownload = lazy(
  () => import('../../../views/pages/AdminContracts/AcceptanceProtocol/AcceptanceProtocolDownload')
);

const BankTransferDownload = lazy(
  () => import('../../../views/pages/BankTransfers/BankTransferDownload')
);
const ChangeEmail = lazy(() => import('../../../views/pages/Auth/ChangeEmail/ChangeEmail'));
const VerifyEmail = lazy(() => import('../../../views/pages/Auth/VerifyEmail/VerifyEmail'));
const ResetPassword = lazy(() => import('../../../views/pages/Auth/ResetPassword/ResetPassword'));

const ContactCreate = lazy(
  () => import('../../../views/pages/Contact/ContactCreate/ContactCreate')
);
const ContactDetail = lazy(
  () => import('../../../views/pages/Contact/ContactDetail/ContactDetail')
);

const routes: IRoute[] = [
  {
    path: AppRoutes.Home,
    element: <Home />,
    permissions: [Permission.loggedIn],
    breadcrumb: null,
  },
  {
    path: AppRoutes.Profile,
    element: <Profile />,
    permissions: [Permission.loggedIn],
    breadcrumb: BreadcrumbMyProfile,
  },
  {
    path: AppRoutes.Cookies,
    element: <Cookies />,
    breadcrumb: Translate,
    props: { translate: 'pages.cookies.header' },
  },
  {
    path: AppRoutes.Gdpr,
    element: <Gdpr />,
    breadcrumb: Translate,
    props: { translate: 'pages.gdpr.header' },
  },
  {
    path: AppRoutes.Faq,
    element: <Faq />,
    breadcrumb: Translate,
    props: { translate: 'pages.faq.header' },
  },
  {
    path: AppRoutes.DataSecurity,
    element: <DataSecurity />,
    breadcrumb: Translate,
    props: { translate: 'pages.dataSecurity.header' },
  },
  {
    path: AppRoutes.AssignedProjects,
    element: <AssignedProjects />,
    permissions: [Permission.loggedIn],
    breadcrumb: Translate,
    props: { translate: 'pages.assignedProjects.header' },
  },
  {
    path: AppRoutes.AssignedProjectsDetail,
    element: <AssignedProjectDetail />,
    permissions: [Permission.loggedIn],
    breadcrumb: BreadcrumbAssignedProjectDetail,
  },
  {
    path: AppRoutes.ArchivedProjects,
    element: <ArchivedProjects />,
    permissions: [Permission.loggedIn],
    breadcrumb: Translate,
    props: { translate: 'pages.archivedProjects.header' },
  },
  {
    path: AppRoutes.ArchivedProjectsDetail,
    element: <ArchivedProjectDetail />,
    permissions: [Permission.loggedIn],
    breadcrumb: BreadcrumbArchivedProjectDetail,
  },
  {
    path: AppRoutes.Contracts,
    element: <Contracts />,
    permissions: [Permission.loggedIn],
    breadcrumb: Translate,
    props: { translate: 'pages.contracts.header' },
  },
  {
    path: AppRoutes.ContractCreate,
    element: <ContractCreate />,
    permissions: [Permission.loggedIn],
    breadcrumb: Translate,
    props: { translate: 'pages.createContract.header' },
  },
  {
    path: AppRoutes.BankAccounts,
    element: <BankAccount />,
    permissions: [Permission.loggedIn],
    breadcrumb: Translate,
    props: { translate: 'pages.bankAccounts.header' },
  },
  {
    path: AppRoutes.BankAccountDetail,
    element: <BankAccountDetail />,
    permissions: [Permission.loggedIn],
    breadcrumb: BreadcrumbBankAccountDetail,
  },
  {
    path: AppRoutes.BankAccountCreate,
    element: <BankAccountCreate />,
    permissions: [Permission.loggedIn],
    breadcrumb: Translate,
    props: { translate: 'pages.bankAccountsCreate.header' },
  },
  {
    path: AppRoutes.Addresses,
    element: <Address />,
    permissions: [Permission.loggedIn],
    breadcrumb: BreadcrumbAddress,
  },
  {
    path: AppRoutes.AddressesCreate,
    element: <AddressCreate />,
    permissions: [Permission.loggedIn],
    breadcrumb: BreadcrumbAddressCreate,
  },
  {
    path: AppRoutes.AddressesDetail,
    element: <AddressDetail />,
    permissions: [Permission.loggedIn],
    breadcrumb: BreadcrumbAddressDetail,
  },
  {
    path: AppRoutes.Admin,
    children: adminRoutes,
    breadcrumb: null,
    permissions: [Permission.loggedIn],
  },
  {
    path: AppRoutes.Auth,
    children: authRoutes,
    breadcrumb: null,
    permissions: [Permission.notLoggedInOnly],
  },
  {
    path: AppRoutes.Survey,
    element: <Survey />,
    breadcrumb: BreadcrumbSurvey,
    permissions: [Permission.loggedIn],
  },
  {
    path: AppRoutes.Companies,
    breadcrumb: null,
    permissions: [Permission.loggedIn],
  },
  {
    path: AppRoutes.CompanyCreate,
    element: <CompaniesCreate variant="respondent" />,
    breadcrumb: BreadcrumbCompanyCreate,
    permissions: [Permission.loggedIn],
  },
  {
    path: AppRoutes.CompanyDetail,
    element: <CompaniesDetailRespondent />,
    breadcrumb: BreadcrumbCompanyDetail,
    permissions: [Permission.loggedIn],
  },
  {
    path: AppRoutes.CompanyAddresses,
    element: <Address />,
    breadcrumb: null,
    permissions: [Permission.loggedIn],
  },
  {
    path: AppRoutes.CompanyAddressCreate,
    element: <AddressCreate />,
    breadcrumb: BreadcrumbAddressCreate,
    permissions: [Permission.loggedIn],
  },
  {
    path: AppRoutes.CompanyAddressDetail,
    element: <AddressDetail />,
    breadcrumb: BreadcrumbAddressDetail,
    permissions: [Permission.loggedIn],
  },
  {
    path: AppRoutes.CompanyBankAccounts,
    element: <BankAccountCreate />,
    breadcrumb: null,
    permissions: [Permission.loggedIn],
  },
  {
    path: AppRoutes.CompanyBankAccountCreate,
    element: <BankAccountCreate />,
    breadcrumb: Translate,
    props: { translate: 'pages.bankAccountsCreate.header' },
    permissions: [Permission.loggedIn],
  },
  {
    path: AppRoutes.CompanyBankAccountDetail,
    element: <BankAccountDetail />,
    breadcrumb: BreadcrumbBankAccountDetail,
    permissions: [Permission.loggedIn],
  },
  {
    path: AppRoutes.HandoverProtocol,
    element: <AcceptanceProtocol variant="respondent" />,
    permissions: [Permission.loggedIn],
    breadcrumb: Translate,
    props: { translate: 'pages.adminContracts.acceptanceProtocol.header' },
  },
  {
    path: AppRoutes.HandoverProtocolDownload,
    element: <AcceptanceProtocolDownload />,
    permissions: [Permission.loggedIn],
    breadcrumb: null,
  },
  {
    path: AppRoutes.BankTransfers,
    permissions: [Permission.loggedIn],
    breadcrumb: null,
  },
  {
    path: AppRoutes.BankTransfersDownload,
    element: <BankTransferDownload />,
    permissions: [Permission.loggedIn],
    breadcrumb: null,
  },
  {
    path: AppRoutes.ChangeEmail,
    element: <ChangeEmail />,
    breadcrumb: null,
  },
  {
    path: AppRoutes.VerifyEmail,
    element: <VerifyEmail />,
    breadcrumb: null,
  },
  {
    path: AppRoutes.ResetPassword,
    element: <ResetPassword />,
    breadcrumb: null,
  },
  {
    path: AppRoutes.Contacts,
    breadcrumb: null,
  },
  {
    path: AppRoutes.ContactsCreate,
    element: <ContactCreate />,
    permissions: [Permission.loggedIn],
    breadcrumb: Translate,
    props: { translate: 'pages.contactCreate.header' },
  },
  {
    path: AppRoutes.ContactsDetail,
    element: <ContactDetail />,
    permissions: [Permission.loggedIn],
    breadcrumb: Translate,
    props: { translate: 'pages.contactDetail.header' },
  },
];

export default routes;
