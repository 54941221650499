import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Pages } from '../../models/Pages';
import { IRespondent } from '../../models/Respondent';
import { ISearch, ITableOrder } from '../../models/Table';
import { RespondentState } from './ducks';

/* SELECTORS */
const selectRespondent = (state: AppState): RespondentState => state.respondent;

export const selectRespondentRespondents = (state: AppState): IRespondent[] =>
  selectRespondent(state).respondents;

export const selectRespondentRespondentsPagination = (state: AppState): Pages =>
  selectRespondent(state).respondentsPagination;

export const selectRespondentRespondentsStatus = (state: AppState): LoadingStatus =>
  selectRespondent(state).respondentsStatus;

export const selectRespondentRespondentsOrderColumn = (state: AppState): ITableOrder =>
  selectRespondent(state).respondentsOrder;

export const selectRespondentRespondentsFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectRespondent(state).respondentsFilter;

export const selectRespondentRespondent = (state: AppState): IRespondent | null =>
  selectRespondent(state).respondent;

export const selectRespondentRespondentStatus = (state: AppState): LoadingStatus =>
  selectRespondent(state).respondentStatus;
