import { combineReducers } from 'redux';

import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { buildRoute } from '../../helpers/route/route-builder';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { history } from '../../helpers/store/root-reducer';
import { toCamelCase, toSnakeCase } from '../../helpers/transformObject';
import { IProject } from '../../models/Project';
import {
  Block,
  GroupedNumericBlock,
  GroupedNumericBlockSurveyQuestion,
  ISurveySidebar,
  SidebarBlock,
  SurveyBlockQuestion,
  SurveyStatus,
} from '../../models/Survey';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateActions, toastCreateErrorActions } from '../toast/actions';
import {
  SurveyActionTypes,
  surveyFinishSurvey,
  surveyGetBlocks,
  surveyGetChildOptions,
  surveySendAnswer,
  surveySetBlockId,
  surveySetSessionId,
} from './actions';
import { api } from './api';
import { selectSurveyBlocks, selectSurveyCurrentBlockId } from './selectors';

export type GroupedNumericBlockState = {
  answers: {
    [questionId: number]: {
      answer: any[];
      completed: boolean;
    };
  };
  blockTotal: number;
  status: 'in_progress' | 'complete';
  validationError?: string;
};

/* STATE */
export interface SurveyState {
  project: IProject | null;
  currentSessionId: string;
  blocks: ISurveySidebar;
  questions: Record<string, any>;
  currentBlockId: string;
  status: LoadingStatus;
  questionChildOptions: Record<string, any>;
  surveyStatuses: Record<string, SurveyStatus>;
  groupedNumericBlocksState: Record<number, GroupedNumericBlockState>;
}

/* REDUCERS */
const initialState: SurveyState = {
  project: null,
  currentSessionId: '',
  blocks: {
    blocks: {},
    blocksOrder: [],
    lockedBlocks: [],
  },
  questions: {},
  currentBlockId: '',
  status: LoadingStatus.initial,
  questionChildOptions: {},
  surveyStatuses: {},
  groupedNumericBlocksState: {},
};

const project = createReducer(initialState.project, {
  [SurveyActionTypes.GetBlocks]: {
    [RequestActionTypes.SUCCESS]: (state: IProject, payload: { project: IProject }) => ({
      ...payload?.project,
    }),
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.project,
  },
});

const status = createLoadingStateReducer(
  initialState.status,
  {
    [SurveyActionTypes.GetBlocks]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.status,
    },
  }
);

const currentSessionId = createReducer(initialState.currentSessionId, {
  [SurveyActionTypes.SetSessionId]: (state: string, payload?: string) => {
    return payload ? payload : state;
  },
  [SurveyActionTypes.CleanSurveyData]: () => initialState.currentSessionId,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.currentSessionId,
  },
});

const questions = createReducer(initialState.questions, {
  [SurveyActionTypes.GetBlocks]: {
    [RequestActionTypes.SUCCESS]: (state: any | null, payload: any) => {
      const blocks = payload?.questionBlocks;

      // map question id to GroupedNumericBlock
      const questionsToGroupedNumericBlocksMapping = payload?.groupedNumericQuestions?.reduce(
        (acc: Record<number, GroupedNumericBlock>, current: GroupedNumericBlock) => {
          current?.surveyQuestions.forEach((question) => {
            const { id } = question;

            acc[id] = current;
          });

          return acc;
        },
        {}
      );

      return blocks?.reduce((acc: Record<string, any>, item: Block) => {
        const questions = item?.blockQuestions;

        questions?.map((question) => {
          acc[question.id] = {
            ...question,
            groupedNumericBlockId: questionsToGroupedNumericBlocksMapping[question.id]?.id,
          };
        });

        return acc;
      }, {});
    },
    [RequestActionTypes.FAILURE]: () => initialState.questions,
  },
  [SurveyActionTypes.SendAnswer]: {
    [RequestActionTypes.REQUEST]: (state: any, payload: any) => {
      if (payload?.hasOwnProperty('optionId')) {
        const optionIds = Array.isArray(payload?.optionId)
          ? payload?.optionId
          : [{ id: payload?.optionId }];
        return {
          ...state,
          [payload.questionId]: {
            ...state[payload.questionId],
            answers: optionIds.map((option: { id: string; answer?: string }) => ({
              answer: option.answer,
              answerableId: option.id,
            })),
          },
        };
      } else {
        return {
          ...state,
          [payload.questionId]: {
            ...state[payload.questionId],
            answers: [
              {
                answer: payload.answer,
              },
            ],
          },
        };
      }
    },
    [RequestActionTypes.SUCCESS]: (state: any, payload: any) => ({
      ...state,
      [payload.data.id]: {
        ...state[payload.data.id],
        /**
         * We save only completed because when we also saved the answers,
         * there was a bug where when the user quickly clicked on the checkboxes in a multi-choice question,
         * the answer was automatically checked when the answer was unchecked
         */
        completed: payload.data.completed,
      },
    }),
  },
  [SurveyActionTypes.CleanSurveyData]: () => initialState.questions,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.questions,
  },
});

const blocks = createReducer(initialState.blocks, {
  [SurveyActionTypes.GetBlocks]: {
    [RequestActionTypes.SUCCESS]: (state: ISurveySidebar | null, payload: any) => {
      const blocks = payload?.questionBlocks?.sort(
        (a: { position: number }, b: { position: number }) => (a.position > b.position ? 1 : -1)
      );

      const result = {
        blocks: blocks?.reduce((acc: Record<string, SidebarBlock>, item: Block) => {
          const questions = item?.blockQuestions?.sort(
            (a: { position: number }, b: { position: number }) => (a.position > b.position ? 1 : -1)
          );

          acc[item.id] = {
            id: item.id,
            position: item.position,
            name: item.name,
            explanation: item.explanation,
            questionsOrder: questions?.reduce((acc: string[], i: SurveyBlockQuestion) => {
              acc.push(i.id);
              return acc;
            }, []),
            status: item.status,
            locked: item.locked,
            hasHiddenName: item.hasHiddenName,
          };
          return acc;
        }, {}),
        blocksOrder: blocks?.reduce((acc: string[], i: Block) => {
          acc.push(i.id);
          return acc;
        }, []),
        lockedBlocks: blocks.reduce((acc: string[], i: Block) => {
          if (i.locked === 1) {
            acc.push(i.id);
          }
          return acc;
        }, []),
      };

      return result;
    },
    [RequestActionTypes.FAILURE]: () => initialState.blocks,
  },
  [SurveyActionTypes.SendAnswer]: {
    [RequestActionTypes.SUCCESS]: (state: ISurveySidebar, payload: any) => {
      const lockedBlocks = [...state.lockedBlocks]
        .concat(payload.lockedBlocks)
        .filter((blockId) => !payload.unlockedBlocks.includes(blockId));

      const blockId = payload.data.questionBlockId;

      return {
        ...state,
        blocks: {
          ...state.blocks,
          [blockId]: {
            ...state.blocks[blockId],
            locked: lockedBlocks.includes(blockId),
            status: payload.currentBlockStatus,
          },
          ...[...payload.lockedBlocks, ...payload.unlockedBlocks].reduce(
            (acc: Record<string, SidebarBlock>, i: string) => {
              acc[i] = {
                ...state.blocks?.[i],
                locked: lockedBlocks.includes(i) ? 1 : 0,
              };

              return acc;
            },
            {}
          ),
        },
        lockedBlocks: lockedBlocks,
      };
    },
    [RequestActionTypes.FAILURE]: (state: ISurveySidebar) => state,
  },
  [SurveyActionTypes.CleanSurveyData]: () => initialState.blocks,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.blocks,
  },
});

const currentBlockId = createReducer(initialState.currentBlockId, {
  [SurveyActionTypes.SetBlockId]: (state: string, payload: string) => payload,
  [SurveyActionTypes.CleanSurveyData]: () => initialState.currentBlockId,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.currentBlockId,
  },
});

const questionChildOptions = createReducer(initialState.questionChildOptions, {
  [SurveyActionTypes.GetChildOptions]: {
    [RequestActionTypes.SUCCESS]: (
      state: any,
      payload: {
        parentId: string;
        childOptions: {
          data: any[];
          end: boolean;
        };
        questionId: number;
      }
    ) => {
      if (!payload.childOptions.data.length) return state;

      const options = state[payload.parentId];

      const questionEnds: number[] = options != null && options.end != null ? options.end : [];

      if (payload.childOptions.end && !questionEnds.includes(payload.questionId)) {
        questionEnds.push(payload.questionId);
      }

      return {
        ...state,
        [payload.parentId]: {
          data: payload.childOptions.data,
          end: questionEnds,
        },
      };
    },
  },
  [SurveyActionTypes.CleanSurveyData]: () => initialState.questionChildOptions,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.questionChildOptions,
  },
});

const surveyStatuses = createReducer(initialState.surveyStatuses, {
  [SurveyActionTypes.SetSurveyStatuses]: (
    state: Record<string, SurveyStatus>,
    payload: { id: string; status: SurveyStatus }[]
  ) => {
    const newState = { ...state };

    payload.forEach((survey) => {
      newState[survey.id] = survey.status;
    });

    return newState;
  },
  [SurveyActionTypes.SendAnswer]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, SurveyStatus>,
      payload: { surveySessionId: number; currentBlockStatus: SurveyStatus; data: any }
    ) => {
      const surveySessionId = payload.surveySessionId;
      const status = payload.currentBlockStatus === 'done' ? 'in_progress' : 'in_progress';

      const newState = {
        ...state,
        [surveySessionId]: status,
      };

      return newState;
    },
  },
  [SurveyActionTypes.FinishSurvey]: {
    [RequestActionTypes.SUCCESS]: (state: Record<string, SurveyStatus>, payload: string) => {
      return {
        ...state,
        [payload]: 'done',
      };
    },
  },
  [SurveyActionTypes.CleanSurveysStatuses]: () => initialState.surveyStatuses,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.surveyStatuses,
  },
});

const groupedNumericBlocksState = createReducer(initialState.groupedNumericBlocksState, {
  [SurveyActionTypes.GetBlocks]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, GroupedNumericBlockState>,
      payload: any
    ) => {
      type ParsedQuestions = {
        answer: any[];
        completed: boolean;
        validationError?: string;
      };

      const questionAnswers = payload?.questionBlocks?.reduce(
        (acc: Record<number, ParsedQuestions>, current: Block) => {
          current.blockQuestions.forEach((question) => {
            acc[parseInt(question.id)] = {
              answer: question.answers,
              completed: question.completed,
              validationError: question.groupedBlockError,
            };
          });

          return acc;
        },
        {}
      );

      const groupedNumericBlocks = payload?.groupedNumericQuestions?.reduce(
        (acc: Record<number, GroupedNumericBlockState>, current: GroupedNumericBlock) => {
          const groupedNumericBlockAnswers = current.surveyQuestions.reduce(
            (
              acc: Record<number, { answer: any; completed: boolean }>,
              current: GroupedNumericBlockSurveyQuestion
            ) => {
              acc[current.id] = {
                answer: questionAnswers[current.id].answer,
                completed: questionAnswers[current.id].completed,
              };

              return acc;
            },
            {}
          );

          const questionId = current.surveyQuestions[0].id;
          const status = Object.values(groupedNumericBlockAnswers).every(
            (questionStatus) => questionStatus.completed === true
          );

          acc[current.id] = {
            answers: groupedNumericBlockAnswers,
            blockTotal: current.blockTotal,
            status: !status ? 'in_progress' : 'complete',
            validationError: questionAnswers[questionId].validationError,
          };

          return acc;
        },
        {}
      );

      return groupedNumericBlocks;
    },
    [RequestActionTypes.FAILURE]: () => initialState.groupedNumericBlocksState,
  },
  [SurveyActionTypes.SendAnswer]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, GroupedNumericBlockState>,
      payload: any
    ) => {
      if (payload.data.groupedNumericBlockId == null) return state;

      const groupedNumericBlockState = state[payload.data.groupedNumericBlockId as number];
      let stateAnswers = groupedNumericBlockState.answers;

      stateAnswers[payload.data.id as number] = {
        answer: payload.data.answers,
        completed: payload.data.completed,
      };

      // check if the sum equals the target sum, if so, set all questions to completed
      const currentSum = Object.values(stateAnswers).reduce((acc: number, current) => {
        if (!current.answer.length) return acc;

        return acc + parseInt(current.answer[0].answer);
      }, 0);

      if (currentSum === groupedNumericBlockState.blockTotal) {
        stateAnswers = Object.fromEntries(
          Object.entries(stateAnswers).map(([id, questionStatus]) => [
            id,
            {
              ...questionStatus,
              completed: true,
            },
          ])
        );
      } else {
        // if all questions of the grouped numeric block has set the answers and the sum is incorrect
        // set all questions to incomplete
        const areAllQuestionsAnswered = Object.values(stateAnswers).every(
          (questionStatus) => questionStatus.answer.length > 0
        );

        if (areAllQuestionsAnswered) {
          stateAnswers = Object.fromEntries(
            Object.entries(stateAnswers).map(([id, questionStatus]) => [
              id,
              {
                ...questionStatus,
                completed: false,
              },
            ])
          );
        }
      }

      const status = Object.values(stateAnswers).every(
        (questionState) => questionState.completed === true
      );

      state[payload.data.groupedNumericBlockId as number] = {
        ...state[payload.data.groupedNumericBlockId],
        answers: stateAnswers,
        validationError: payload.data.groupedBlockError,
        status: !status ? 'in_progress' : 'complete',
      };

      return state;
    },
  },
  [SurveyActionTypes.CleanSurveyData]: () => initialState.groupedNumericBlocksState,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.groupedNumericBlocksState,
  },
});

export default combineReducers<SurveyState>({
  project,
  currentSessionId,
  questions,
  blocks,
  currentBlockId,
  status,
  questionChildOptions,
  surveyStatuses,
  groupedNumericBlocksState,
});

/* SAGAS */
function* getSurveyBlocks({ payload }: AppAction<{ surveySessionId: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getSidebar, payload);

  if (resp.ok) {
    yield put(surveyGetBlocks.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(surveyGetBlocks.failure());
  }
}

function* getSurveyNextBlock() {
  const currentBlockId: string = yield select(selectSurveyCurrentBlockId);
  const blocks: ISurveySidebar = yield select(selectSurveyBlocks);

  const currIdx = blocks.blocksOrder.findIndex((idx: string) => currentBlockId === idx);

  if (currIdx >= 0) {
    for (let i = currIdx + 1; i < blocks.blocksOrder?.length; i++) {
      if (!blocks?.lockedBlocks?.includes(blocks.blocksOrder[i])) {
        yield put(surveySetBlockId(blocks.blocksOrder[i]));
        break;
      }
    }
  }
}

function* getSurveyPrevBlock() {
  const currentBlockId: string = yield select(selectSurveyCurrentBlockId);
  const blocks: ISurveySidebar = yield select(selectSurveyBlocks);

  const currIdx = blocks.blocksOrder.findIndex((idx: string) => currentBlockId === idx);

  if (currIdx >= 0) {
    for (let i = currIdx - 1; i >= 0; i--) {
      if (!blocks?.lockedBlocks?.includes(blocks.blocksOrder[i])) {
        yield put(surveySetBlockId(blocks.blocksOrder[i]));
        break;
      }
    }
  }
}

function* sendAnswer({
  payload,
}: AppAction<{
  answer: string | string[];
  questionId: number;
  surveySessionId: string;
}>) {
  const resp: ExtendedAxiosResponse = yield call(api.sendAnswer, payload);

  if (resp.ok) {
    yield put(
      surveySendAnswer.success(
        toCamelCase({
          ...resp.data,
          surveySessionId: payload.surveySessionId,
        })
      )
    );
  } else if (resp.status === 400) {
    yield put(surveySendAnswer.failure());
  } else {
    yield put(surveySendAnswer.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* finishSurvey({ payload }: AppAction<{ sessionId: string; nextSessionId?: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.finishSurvey, {
    sessionId: payload.sessionId,
  });

  if (resp.ok) {
    const sessionId = toCamelCase(resp?.data)?.data?.id;
    if (sessionId) yield put(surveyFinishSurvey.success(sessionId));
    yield put(
      toastCreateActions({
        message: resp.data?.message,
        type: 'success',
        closeOnClick: true,
        rawHTML: true,
        progress: 0,
      })
    );
    if (payload.nextSessionId) yield put(surveySetSessionId(payload.nextSessionId));
    else history.push(buildRoute([AppRoutes.AssignedProjects]));
  } else {
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getChildOptions({ payload }: AppAction<{ id: string; optionId: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getChildOptions, payload);

  if (resp.ok) {
    yield put(
      surveyGetChildOptions.success({
        parentId: payload.optionId || payload.id,
        childOptions: toSnakeCase(resp.data),
        questionId: payload.id,
      })
    );
  } else {
    yield put(surveyGetChildOptions.failure());
  }
}

/* EXPORT */
export function* surveySaga() {
  yield takeLatest(
    createActionType(SurveyActionTypes.GetBlocks, RequestActionTypes.REQUEST),
    getSurveyBlocks
  );
  yield takeLatest([SurveyActionTypes.GetNextBlock], getSurveyNextBlock);
  yield takeLatest([SurveyActionTypes.GetPrevBlock], getSurveyPrevBlock);
  yield takeEvery(
    createActionType(SurveyActionTypes.SendAnswer, RequestActionTypes.REQUEST),
    sendAnswer
  );
  yield takeLatest(
    createActionType(SurveyActionTypes.FinishSurvey, RequestActionTypes.REQUEST),
    finishSurvey
  );
  yield takeEvery(
    createActionType(SurveyActionTypes.GetChildOptions, RequestActionTypes.REQUEST),
    getChildOptions
  );
}
