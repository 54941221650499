import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { District } from '../../models/District';
import { Pages } from '../../models/Pages';
import { Region } from '../../models/Region';
import { ISearch, ITableOrder } from '../../models/Table';
import { DistrictState } from './ducks';

/* SELECTORS */
const selectDistrict = (state: AppState): DistrictState => state.district;

export const selectDistrictDistricts = (state: AppState): District[] =>
  selectDistrict(state).districts;

export const selectDistrictDistrictsPagination = (state: AppState): Pages =>
  selectDistrict(state).districtsPagination;

export const selectDistrictDistrictsStatus = (state: AppState): LoadingStatus =>
  selectDistrict(state).districtsStatus;

export const selectDistrictDistrictsOrderColumn = (state: AppState): ITableOrder =>
  selectDistrict(state).districtsOrder;

export const selectDistrictDistrictsFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectDistrict(state).districtsFilter;

export const selectDistrictDistrict = (state: AppState): District | null =>
  selectDistrict(state).district;

export const selectDistrictRegions = (state: AppState): Region[] => selectDistrict(state).regions;

export const selectDistrictDistrictActionStatus = (state: AppState): LoadingStatus =>
  selectDistrict(state).districtActionStatus;
