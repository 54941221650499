import { omit } from 'lodash';
import { combineReducers } from 'redux';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase, toSnakeCase } from '../../helpers/transformObject';
import { Company } from '../../models/Company';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IProject } from '../../models/Project';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';

import {
  ImportRespondentActionTypes,
  importRespondentGenerateRespondentsActions,
  importRespondentGetCompaniesActions,
  importRespondentGetErrorsActions,
  importRespondentGetImportStatusActions,
  importRespondentGetProjectsActions,
  importRespondentGetRespondentsActions,
  importRespondentImportRespondentsActions,
  importRespondentRemoveRespondentActions,
} from './actions';
import { api } from './api';
import {
  selectImportRespondentImportId,
  selectImportRespondentRespondentsFilterColumn,
  selectImportRespondentRespondentsOrderColumn,
  selectImportRespondentRespondentsPagination,
} from './selectors';

interface Error {
  row: string;
  message: string;
}

export interface ImportStatus {
  alreadyImportedCount: number;
  createdAt: Date;
  deletedAt?: Date;
  id: number;
  importStartedAt: Date;
  rowsTotal: number;
  rowsWithErrorCount: number;
  updatedAt: Date;
  importedToProjects: string[];
}

export interface Respondent {
  firstName: string;
  lastName: string;
  projectName: string;
  pharmaCompanyName: string;
  invoiceNumber: string;
  numberOfAssignedProjects: number;
  importedAt: Date;
  id: string;
}

/* STATE */
export interface ImportRespondentState {
  generateStatus: LoadingStatus;
  errors: Error[];
  projects: { name: string; id: string }[];
  projectsStatus: LoadingStatus;
  companies: { name: string; id: string }[];
  companiesStatus: LoadingStatus;
  importStatus: ImportStatus | null;
  respondents: Respondent[];
  respondentsPagination: Pages;
  respondentsStatus: LoadingStatus;
  respondentsOrder: ITableOrder;
  respondentsFilter: Record<string, ISearch>;
  importId: string;
  importedProjectList: string[];
}

/* REDUCERS */
const initialState: ImportRespondentState = {
  generateStatus: LoadingStatus.initial,
  errors: [],
  projects: [],
  projectsStatus: LoadingStatus.initial,
  companies: [],
  companiesStatus: LoadingStatus.initial,
  importStatus: null,
  respondents: [],
  respondentsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  respondentsStatus: LoadingStatus.loading,
  respondentsOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  respondentsFilter: {},
  importId: '',
  importedProjectList: [],
};

const generateStatus = createLoadingStateReducer(
  initialState.generateStatus,
  {
    [ImportRespondentActionTypes.GenerateRespondents]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.generateStatus,
    },
  }
);

const importStatus = createReducer(initialState.importStatus, {
  [ImportRespondentActionTypes.GetImportStatus]: {
    [RequestActionTypes.SUCCESS]: (state: ImportStatus | null, payload: ImportStatus) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.importStatus,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.importStatus,
  },
});

const errors = createReducer(initialState.errors, {
  [ImportRespondentActionTypes.GetErrors]: {
    [RequestActionTypes.SUCCESS]: (state: Error, payload: any) =>
      Object.keys(payload).reduce((acc: Error[], i) => {
        payload[i].forEach((item: string) => {
          acc.push({
            row: i,
            message: item,
          });
        });

        return acc;
      }, []),
    [RequestActionTypes.FAILURE]: () => initialState.errors,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
});

const respondents = createReducer(initialState.respondents, {
  [ImportRespondentActionTypes.GetRespondents]: {
    [RequestActionTypes.SUCCESS]: (state: Respondent[], payload: PaginatedResp<Respondent>) => {
      if (payload.currentPage == 1 || payload?.removeOldData) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.respondents,
  },
  [ImportRespondentActionTypes.RemoveRespondents]: {
    [RequestActionTypes.SUCCESS]: (state: Respondent[], payload: string[]) => {
      return state.filter((respondent) => !payload?.includes(respondent.id));
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.respondents,
  },
});

const respondentsPagination = createReducer(initialState.respondentsPagination, {
  [ImportRespondentActionTypes.GetRespondents]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<Respondent>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.respondentsPagination,
  },
  [ImportRespondentActionTypes.SetRespondentsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [ImportRespondentActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [ImportRespondentActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ImportRespondentActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.respondentsPagination,
  },
});

const respondentsStatus = createLoadingStateReducer(
  initialState.respondentsStatus,
  {
    [ImportRespondentActionTypes.GetRespondents]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ImportRespondentActionTypes.SetRespondentsPage]: () => initialState.respondentsStatus,
    [ImportRespondentActionTypes.IncreasePage]: () => initialState.respondentsStatus,
    [ImportRespondentActionTypes.SetFilterColumn]: () => initialState.respondentsStatus,
    [ImportRespondentActionTypes.SetOrderColumn]: () => initialState.respondentsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.respondentsStatus,
    },
  }
);

const respondentsOrder = createReducer(initialState.respondentsOrder, {
  [ImportRespondentActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.respondentsOrder,
  },
});

const respondentsFilter = createReducer(initialState.respondentsFilter, {
  [ImportRespondentActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.respondentsFilter,
  },
});

const importId = createReducer(initialState.importId, {
  [ImportRespondentActionTypes.GenerateRespondents]: {
    [RequestActionTypes.SUCCESS]: (state: string, payload: string) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.importId,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.importId,
  },
});

const projects = createReducer(initialState.projects, {
  [ImportRespondentActionTypes.GetProjects]: {
    [RequestActionTypes.SUCCESS]: (state: { name: string; id: string }[], payload: IProject[]) =>
      payload.map((item) => ({
        name: item.name,
        id: item.id,
      })),
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projects,
  },
});

const projectsStatus = createLoadingStateReducer(
  initialState.projectsStatus,
  {
    [ImportRespondentActionTypes.GetProjects]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.projectsStatus,
    },
  }
);

const companies = createReducer(initialState.companies, {
  [ImportRespondentActionTypes.GetCompanies]: {
    [RequestActionTypes.SUCCESS]: (state: { id: string; name: string }[], payload: Company[]) =>
      payload.map((item) => ({
        name: item.name,
        id: item.id,
      })),
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.companies,
  },
});

const companiesStatus = createLoadingStateReducer(
  initialState.companiesStatus,
  {
    [ImportRespondentActionTypes.GetCompanies]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.companiesStatus,
    },
  }
);

const importedProjectList = createReducer(initialState.importedProjectList, {
  [ImportRespondentActionTypes.GetImportStatus]: {
    [RequestActionTypes.SUCCESS]: (state: string[], payload: ImportStatus) =>
      payload?.importedToProjects || [],
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.importedProjectList,
  },
});

export default combineReducers<ImportRespondentState>({
  generateStatus,
  errors,
  projects,
  projectsStatus,
  companies,
  companiesStatus,
  respondents,
  respondentsPagination,
  respondentsStatus,
  respondentsOrder,
  respondentsFilter,
  importStatus,
  importId,
  importedProjectList,
});

/* SAGAS */
function* generateRespondents({ payload }: AppAction<any>) {
  const resp: ExtendedAxiosResponse = yield call(api.generateRespondents, payload);

  if (resp.ok) {
    const importId = toCamelCase(resp.data)?.importId;
    yield put(importRespondentGenerateRespondentsActions.success(importId || ''));
    history.push(`${buildRoute([AppRoutes.Admin, AdminRoutes.RespondentImport])}?step=2`);
  } else {
    yield put(importRespondentGenerateRespondentsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* importRespondents() {
  const importId: string = yield select(selectImportRespondentImportId);
  const resp: ExtendedAxiosResponse = yield call(api.importRespondents, {
    id: importId,
  });

  history.push(`${buildRoute([AppRoutes.Admin, AdminRoutes.RespondentImport])}?step=3`);
  if (resp.ok) {
    yield put(
      importRespondentImportRespondentsActions.success(
        resp.data?.length ? toCamelCase(resp?.data) : []
      )
    );
  } else {
    yield put(
      importRespondentImportRespondentsActions.failure(
        resp.data?.length ? toCamelCase(resp.data) : []
      )
    );
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getRespondents() {
  const pagination: Pages = yield select(selectImportRespondentRespondentsPagination);
  const orderColumn: ITableOrder = yield select(selectImportRespondentRespondentsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(
    selectImportRespondentRespondentsFilterColumn
  );

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const importId: string = yield select(selectImportRespondentImportId);

  const resp: ExtendedAxiosResponse = yield call(api.getImportedRespondents, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
    id: importId,
  });

  if (resp.ok) {
    yield put(
      importRespondentGetRespondentsActions.success(
        toCamelCase({
          ...resp.data,
          removeOldData: !!pagination?.removeOldData,
        })
      )
    );
  } else {
    yield put(importRespondentGetRespondentsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getProjects({ payload }: AppAction<{ search: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getProjects, payload);

  if (resp.ok) {
    yield put(importRespondentGetProjectsActions.success(toSnakeCase(resp.data)?.data || []));
  } else {
    yield put(importRespondentGetProjectsActions.failure());
  }
}

function* getCompanies({ payload }: AppAction<{ search: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getCompanies, payload);

  if (resp.ok) {
    yield put(importRespondentGetCompaniesActions.success(toSnakeCase(resp.data)?.data || []));
  } else {
    yield put(importRespondentGetCompaniesActions.failure());
  }
}

function* removeImportRespondents({
  payload,
}: AppAction<{ assignedProjectImportsArray: string[] }>) {
  const importId: string = yield select(selectImportRespondentImportId);
  const resp: ExtendedAxiosResponse = yield call(api.removeImportRespondents, {
    id: importId,
    ...payload,
  });

  if (resp.ok) {
    yield put(importRespondentRemoveRespondentActions.success(payload.assignedProjectImportsArray));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(importRespondentRemoveRespondentActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getImportStatus({ payload }: AppAction<{ id: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getImportStatus, payload);

  if (resp.ok) {
    yield put(importRespondentGetImportStatusActions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(importRespondentGetImportStatusActions.failure());
    yield put(toastCreateErrorActions(resp?.data?.message));
  }
}

function* getErrors({ payload }: AppAction<{ id: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getErrors, payload);

  if (resp.ok) {
    yield put(importRespondentGetErrorsActions.success(resp.data?.errors || {}));
  } else {
    yield put(importRespondentGetErrorsActions.failure());
  }
}

/* EXPORT */
export function* importRespondentSaga() {
  yield takeLatest(
    createActionType(ImportRespondentActionTypes.GenerateRespondents, RequestActionTypes.REQUEST),
    generateRespondents
  );
  yield takeLatest(
    createActionType(ImportRespondentActionTypes.ImportRespondents, RequestActionTypes.REQUEST),
    importRespondents
  );
  yield takeLatest(
    [
      createActionType(ImportRespondentActionTypes.GetRespondents, RequestActionTypes.REQUEST),
      ImportRespondentActionTypes.SetRespondentsPage,
      ImportRespondentActionTypes.IncreasePage,
      ImportRespondentActionTypes.SetOrderColumn,
      ImportRespondentActionTypes.SetFilterColumn,
    ],
    getRespondents
  );
  yield takeLatest(
    createActionType(ImportRespondentActionTypes.GetProjects, RequestActionTypes.REQUEST),
    getProjects
  );
  yield takeLatest(
    createActionType(ImportRespondentActionTypes.GetCompanies, RequestActionTypes.REQUEST),
    getCompanies
  );
  yield takeLatest(
    createActionType(ImportRespondentActionTypes.RemoveRespondents, RequestActionTypes.REQUEST),
    removeImportRespondents
  );
  yield takeLatest(
    createActionType(ImportRespondentActionTypes.GetImportStatus, RequestActionTypes.REQUEST),
    getImportStatus
  );
  yield takeLatest(
    createActionType(ImportRespondentActionTypes.GetErrors, RequestActionTypes.REQUEST),
    getErrors
  );
}
