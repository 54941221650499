export const reorder = (list: Record<string, any>, startIndex: number, endIndex: number) => {
  const result = Object.keys(list).reduce((acc: Record<string, any>, key) => {
    acc[key] = {
      ...list[key],
      position: calculatePosition(list[key].position, startIndex, endIndex),
    };
    return acc;
  }, {});
  return sortByPosition(result);
};

export const sortByPosition = (ob: Record<string, any>) => {
  const entries = Object.entries(ob).sort(([, a], [, b]) => {
    return a.position - b.position;
  });
  return Object.fromEntries(entries);
};

const calculatePosition = (currentPosition: number, startIndex: number, endIndex: number) => {
  if (startIndex == endIndex) {
    return currentPosition;
  }

  if (currentPosition == startIndex) {
    return endIndex;
  }

  if (startIndex < endIndex) {
    if (currentPosition < startIndex || currentPosition > endIndex) {
      return currentPosition;
    } else {
      return currentPosition - 1;
    }
  }

  if (startIndex > endIndex) {
    if (currentPosition > startIndex || currentPosition < endIndex) {
      return currentPosition;
    } else {
      return currentPosition + 1;
    }
  }

  return currentPosition;
};
