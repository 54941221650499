import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { SettingsRoutes } from '../../helpers/route/routes/settings-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { District } from '../../models/District';

import { Pages, PaginatedResp } from '../../models/Pages';
import { Region } from '../../models/Region';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  DistrictActionTypes,
  districtCreateDistrictActions,
  districtGetDistrictActions,
  districtGetDistrictsActions,
  districtGetRegionsActions,
  districtRemoveDistrictActions,
  districtUpdateDistrictActions,
} from './actions';
import { api, DistrictPayload } from './api';
import {
  selectDistrictDistrictsFilterColumn,
  selectDistrictDistrictsOrderColumn,
  selectDistrictDistrictsPagination,
} from './selectors';

/* STATE */
export interface DistrictState {
  districts: District[];
  districtsPagination: Pages;
  districtsStatus: LoadingStatus;
  districtsOrder: ITableOrder;
  districtsFilter: Record<string, ISearch>;
  district: District | null;
  regions: Region[];
  districtActionStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: DistrictState = {
  districts: [],
  districtsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  districtsStatus: LoadingStatus.initial,
  districtsOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  districtsFilter: {},
  district: null,
  regions: [],
  districtActionStatus: LoadingStatus.initial,
};

const districts = createReducer(initialState.districts, {
  [DistrictActionTypes.GetDistricts]: {
    [RequestActionTypes.SUCCESS]: (state: District[], payload: PaginatedResp<District>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.districts,
  },
  [DistrictActionTypes.RemoveDistrict]: {
    [RequestActionTypes.SUCCESS]: (state: District[], payload: { id: number }) => {
      return state.filter((district) => district.id !== payload.id);
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.districts,
  },
});

const districtsPagination = createReducer(initialState.districtsPagination, {
  [DistrictActionTypes.GetDistricts]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<District>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.districtsPagination,
  },
  [DistrictActionTypes.SetDistrictsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [DistrictActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [DistrictActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [DistrictActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.districtsPagination,
  },
});

const districtsStatus = createLoadingStateReducer(
  initialState.districtsStatus,
  {
    [DistrictActionTypes.GetDistricts]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [DistrictActionTypes.SetDistrictsPage]: () => initialState.districtsStatus,
    [DistrictActionTypes.IncreasePage]: () => initialState.districtsStatus,
    [DistrictActionTypes.SetFilterColumn]: () => initialState.districtsStatus,
    [DistrictActionTypes.SetOrderColumn]: () => initialState.districtsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.districtsStatus,
    },
  }
);

const districtsOrder = createReducer(initialState.districtsOrder, {
  [DistrictActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.districtsOrder,
  },
});

const districtsFilter = createReducer(initialState.districtsFilter, {
  [DistrictActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.districtsFilter,
  },
});

const district = createReducer(initialState.district, {
  [DistrictActionTypes.CreateDistrict]: {
    [RequestActionTypes.SUCCESS]: (state: District | null, payload: District) => payload,
  },
  [DistrictActionTypes.GetDistrict]: {
    [RequestActionTypes.REQUEST]: () => initialState.district,
    [RequestActionTypes.SUCCESS]: (state: District | null, payload: District) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.district,
  },
  [DistrictActionTypes.UpdateDistrict]: {
    [RequestActionTypes.SUCCESS]: (state: District | null, payload: District) => payload,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.district,
  },
});

const regions = createReducer(initialState.regions, {
  [DistrictActionTypes.GetRegions]: {
    [RequestActionTypes.SUCCESS]: (state: Region[], payload: Region[]) => payload,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.regions,
  },
});

const districtActionStatus = createLoadingStateReducer(
  initialState.districtActionStatus,
  {
    [DistrictActionTypes.CreateDistrict]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [DistrictActionTypes.UpdateDistrict]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.districtActionStatus,
    },
  }
);

export default combineReducers<DistrictState>({
  districts,
  districtsPagination,
  districtsStatus,
  districtsOrder,
  districtsFilter,
  district,
  regions,
  districtActionStatus,
});

/* SAGAS */
function* getDistricts() {
  const pagination: Pages = yield select(selectDistrictDistrictsPagination);
  const orderColumn: ITableOrder = yield select(selectDistrictDistrictsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(selectDistrictDistrictsFilterColumn);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getDistricts, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(districtGetDistrictsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(districtGetDistrictsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeDistrict({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeDistrict, payload);

  if (resp.ok) {
    yield put(districtRemoveDistrictActions.success({ id: payload.id }));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(districtRemoveDistrictActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createDistrict({ payload }: AppAction<DistrictPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.createDistrict, payload);

  if (resp.ok) {
    yield put(districtCreateDistrictActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(
      buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageDistricts])
    );
  } else {
    yield put(districtCreateDistrictActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getDistrict({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getDistrict, payload);

  if (resp.ok) {
    yield put(districtGetDistrictActions.success(toCamelCase(resp?.data)?.data));
  } else {
    yield put(districtGetDistrictActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateDistrict({ payload }: AppAction<DistrictPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateDistrict, payload);

  if (resp.ok) {
    yield put(districtUpdateDistrictActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(
      buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageDistricts])
    );
  } else {
    yield put(districtUpdateDistrictActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getRegions() {
  const resp: ExtendedAxiosResponse = yield call(api.getRegions);

  if (resp.ok) {
    yield put(districtGetRegionsActions.success(toCamelCase(resp.data)?.data || []));
  } else {
    yield put(districtGetRegionsActions.failure());
  }
}

/* EXPORT */
export function* districtSaga() {
  yield takeLatest(
    [
      createActionType(DistrictActionTypes.GetDistricts, RequestActionTypes.REQUEST),
      DistrictActionTypes.SetDistrictsPage,
      DistrictActionTypes.IncreasePage,
      DistrictActionTypes.SetOrderColumn,
      DistrictActionTypes.SetFilterColumn,
    ],
    getDistricts
  );
  yield takeLatest(
    [createActionType(DistrictActionTypes.RemoveDistrict, RequestActionTypes.REQUEST)],
    removeDistrict
  );
  yield takeLatest(
    createActionType(DistrictActionTypes.CreateDistrict, RequestActionTypes.REQUEST),
    createDistrict
  );
  yield takeLatest(
    createActionType(DistrictActionTypes.GetDistrict, RequestActionTypes.REQUEST),
    getDistrict
  );
  yield takeLatest(
    createActionType(DistrictActionTypes.UpdateDistrict, RequestActionTypes.REQUEST),
    updateDistrict
  );
  yield takeLatest(
    createActionType(DistrictActionTypes.GetRegions, RequestActionTypes.REQUEST),
    getRegions
  );
}
