/* SELECTORS */

import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Pages } from '../../models/Pages';
import { ProjectProtocolProtocol } from '../../models/ProjectProtocol';
import { ISearch, ITableOrder } from '../../models/Table';

const selectProjectProtocol = (state: AppState) => state.projectProtocol;

export const selectProjectProtocolProtocols = (state: AppState): ProjectProtocolProtocol[] =>
  selectProjectProtocol(state).protocols;

export const selectProjectProtocolProtocolsPagination = (state: AppState): Pages =>
  selectProjectProtocol(state).protocolsPagination;

export const selectProjectProtocolProtocolsStatus = (state: AppState): LoadingStatus =>
  selectProjectProtocol(state).protocolsStatus;

export const selectProjectProtocolProtocolsOrderColumn = (state: AppState): ITableOrder =>
  selectProjectProtocol(state).protocolsOrder;

export const selectProjectProtocolProtocolsFilterColumn = (
  state: AppState
): Record<string, ISearch> => selectProjectProtocol(state).protocolsFilter;

export const selectProjectProtocolSummary = (state: AppState): number =>
  selectProjectProtocol(state).summary;
