import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

import i18n from 'i18next';
import { Location } from 'react-router';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { AuthRoutes } from '../../helpers/route/routes/auth-routes';
import { SettingsRoutes } from '../../helpers/route/routes/settings-routes';
import { history } from '../../helpers/store/root-reducer';
import { toCamelCase, toSnakeCase } from '../../helpers/transformObject';
import {
  ForgotPasswordRequest,
  LoginAsRespondentRequest,
  LoginRequest,
  RegisterRequest,
  ResetPasswordAuthenticated,
  ResetPasswordRequest,
  ResetPasswordSetByAdminRequest,
} from '../../models/Auth';
import { Contract } from '../../models/Contract';
import { Address, BankAccount, Chamber, Company, Permission, User } from '../../models/User';

import { AddressActionTypes } from '../address/actions';
import { BankAccountActionTypes } from '../bankAccount/actions';
import { CompanyActionTypes } from '../company/actions';
import { ContactActionTypes } from '../contact/actions';
import { SpecActionTypes } from '../specs/actions';
import {
  toastCreateErrorActions,
  toastCreateSuccessActions,
  toastCreateActions,
} from '../toast/actions';
import { UserActionTypes } from '../user/actions';
import {
  AuthActionTypes,
  authCacheAuthUser,
  authGetChambersActions,
  authGetPermissionsActions,
  authGetProfileActions,
  authLoginActions,
  authLoginAsRespondent,
  authLogoutActions,
  authRegisterActions,
  authRequestPasswordResetActions,
  authResetPasswordActions,
  authResetPasswordAuthenticatedActions,
  authResetPasswordSetByAdmin,
  authSetHasSignedConctractActions,
  authVerifyActions,
} from './actions';
import { api } from './api';
import { selectAuthCachedUser, selectAuthUser } from './selectors';

/* STATE */
export interface AuthState {
  loggedIn: boolean;
  user: User | null;
  cookieBarClosed: boolean;
  loginStatus: LoadingStatus;
  registerStatus: LoadingStatus;
  forgotPasswordStatus: LoadingStatus;
  resetPasswordStatus: LoadingStatus;
  resetPasswordSetByAdminStatus: LoadingStatus;
  chambers: Chamber[] | null;
  permissions: Permission[] | null;
  hasSignedContract: boolean;
  cachedUser: User | null;
}

/* REDUCERS */
const initialState: AuthState = {
  loggedIn: false,
  user: null,
  cookieBarClosed: false,
  loginStatus: LoadingStatus.initial,
  registerStatus: LoadingStatus.initial,
  forgotPasswordStatus: LoadingStatus.initial,
  resetPasswordStatus: LoadingStatus.initial,
  resetPasswordSetByAdminStatus: LoadingStatus.initial,
  chambers: null,
  permissions: null,
  hasSignedContract: false,
  cachedUser: null,
};

const loggedIn = createReducer(initialState.loggedIn, {
  [AuthActionTypes.Login]: {
    [RequestActionTypes.SUCCESS]: () => true,
    [RequestActionTypes.FAILURE]: () => false,
  },
  [AuthActionTypes.LoginAsRespondent]: {
    [RequestActionTypes.SUCCESS]: () => true,
    [RequestActionTypes.FAILURE]: () => false,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => false,
  },
  [AuthActionTypes.Verify]: {
    [RequestActionTypes.FAILURE]: () => false,
  },
});

const user = createReducer(initialState.user, {
  [AuthActionTypes.Login]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.user,
  },
  [AuthActionTypes.LoginAsRespondent]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => payload,
  },
  [AuthActionTypes.GetProfile]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => payload,
  },
  [UserActionTypes.UpdateUserProfile]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload?: User) =>
      payload?.id && payload.id === state?.id
        ? {
            ...state,
            ...payload,
          }
        : state,
  },
  [SpecActionTypes.CreateSpec]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => {
      if (state?.id === payload.id) {
        // updated your specs
        return {
          ...state,
          specs: payload.specs,
        };
      }
      return state;
    },
  },
  [SpecActionTypes.RemoveSpec]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => {
      if (state?.id === payload.id) {
        // updated your specs
        return {
          ...state,
          specs: payload.specs,
        };
      }
      return state;
    },
  },
  [BankAccountActionTypes.CreateBankAccount]: {
    [RequestActionTypes.SUCCESS]: (
      state: User | null,
      payload: { userId?: number; companyId?: number; data: BankAccount }
    ) => {
      if (payload.userId !== undefined && state?.id !== payload.userId) return state;
      if (payload.companyId != null) return state;

      return {
        ...state,
        bankAccounts: [...(state?.bankAccounts || []), payload.data],
      };
    },
  },
  [BankAccountActionTypes.UpdateBankAccount]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: BankAccount) => {
      const index = state?.bankAccounts?.findIndex((account) => account.id === payload.id);

      if (index === undefined || index < 0) return state;

      const bankAccounts = state?.bankAccounts;

      if (bankAccounts) {
        bankAccounts[index] = payload;
      }

      return {
        ...state,
        bankAccounts,
      };
    },
  },
  [BankAccountActionTypes.RemoveBankAccount]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: { id: number }) => ({
      ...state,
      bankAccounts: state?.bankAccounts?.filter((account) => account.id !== payload.id),
    }),
  },
  [AddressActionTypes.CreateAddress]: {
    [RequestActionTypes.SUCCESS]: (
      state: User | null,
      payload: { userId?: number; companyId?: number; data: Address }
    ) => {
      if (payload.userId !== undefined && state?.id !== payload.userId) return state;
      if (payload.companyId == null)
        return {
          ...state,
          addresses: [...(state?.addresses || []), payload.data],
        };

      const company = state?.companies.find((company) => company.id == payload.companyId);

      if (!company) return state;

      (company.addresses = company.addresses || []).push(payload.data);

      return { ...state };
    },
  },
  [AddressActionTypes.UpdateAddress]: {
    [RequestActionTypes.SUCCESS]: (
      state: User | null,
      payload: { userId?: number; companyId?: number; data: Address }
    ) => {
      if (payload.userId !== undefined && state?.id !== payload.userId) return state;
      if (payload.companyId == null) {
        const index = state?.addresses?.findIndex((address) => address.id === payload.data.id);

        if (index === undefined || index < 0) return state;

        const addresses = state?.addresses;

        if (addresses) {
          addresses[index] = payload.data;
        }

        return {
          ...state,
          addresses,
        };
      }

      const company = state?.companies.find((company) => company.id == payload.companyId);

      if (!company || company.addresses == null) return state;

      const index = company.addresses.findIndex((address) => address.id === payload.data.id);
      if (index < 0) return state;

      company.addresses[index] = payload.data;

      return { ...state };
    },
  },
  [AddressActionTypes.RemoveAddress]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: { id: number }) => ({
      ...state,
      addresses: state?.addresses?.filter((address) => address.id !== payload.id),
    }),
  },
  [ContactActionTypes.UpsertContact]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => {
      if (state?.id === payload.id) {
        // updated your contacts
        return {
          ...state,
          contacts: payload.contacts,
        };
      }
      return state;
    },
  },
  [ContactActionTypes.RemoveContact]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => {
      if (state?.id === payload.id) {
        // updated your contacts
        return {
          ...state,
          contacts: payload.contacts,
        };
      }
      return state;
    },
  },
  [CompanyActionTypes.CreateCompany]: {
    [RequestActionTypes.SUCCESS]: (
      state: User | null,
      payload: { userId?: number; data: Company }
    ) => {
      if (payload.userId !== undefined && state?.id !== payload.userId) return state;

      return {
        ...state,
        companies: [...(state?.companies || []), payload.data],
      };
    },
  },
  [CompanyActionTypes.UpdateCompany]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: Company) => {
      const index = state?.companies?.findIndex((company) => company.id === payload.id);

      if (index === undefined || index < 0) return state;

      const companies = state?.companies;

      if (companies) {
        companies[index] = payload;
      }

      return {
        ...state,
        companies,
      };
    },
  },
  [CompanyActionTypes.RemoveCompany]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: { id: number }) => ({
      ...state,
      companies: state?.companies?.filter((company) => company.id !== payload.id),
    }),
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.user,
  },
  [AuthActionTypes.Verify]: {
    [RequestActionTypes.FAILURE]: () => initialState.user,
  },
});

const cookieBarClosed = createReducer(initialState.cookieBarClosed, {
  [AuthActionTypes.SetCookieConsent]: () => true,
});

const loginStatus = createLoadingStateReducer(
  initialState.loginStatus,
  {
    [AuthActionTypes.Login]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.loginStatus,
    },
  }
);

const registerStatus = createLoadingStateReducer(
  initialState.registerStatus,
  {
    [AuthActionTypes.Register]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.registerStatus,
    },
  }
);

const forgotPasswordStatus = createLoadingStateReducer(
  initialState.forgotPasswordStatus,
  {
    [AuthActionTypes.RequestPasswordReset]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.forgotPasswordStatus,
    },
  }
);

const resetPasswordStatus = createLoadingStateReducer(
  initialState.resetPasswordStatus,
  {
    [AuthActionTypes.ResetPassword]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.resetPasswordStatus,
    },
  }
);

const resetPasswordSetByAdminStatus = createLoadingStateReducer(
  initialState.resetPasswordSetByAdminStatus,
  {
    [AuthActionTypes.ResetPasswordSetByAdmin]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.resetPasswordSetByAdminStatus,
    },
  }
);

const chambers = createReducer(initialState.chambers, {
  [AuthActionTypes.GetChambers]: {
    [RequestActionTypes.SUCCESS]: (state: Chamber[] | null, payload: Chamber[]) => payload,
  },
});

const permissions = createReducer(initialState.permissions, {
  [AuthActionTypes.GetPermissions]: {
    [RequestActionTypes.SUCCESS]: (state: Permission[] | null, payload: Permission[]) => payload,
  },
});

const hasSignedContract = createReducer(initialState.hasSignedContract, {
  [AuthActionTypes.SetHasSignedContract]: (state: boolean, payload: boolean) => payload,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.hasSignedContract,
  },
});

const cachedUser = createReducer(initialState.cachedUser, {
  [AuthActionTypes.CacheAuthUser]: (state: User | null, payload: User) => payload,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.cachedUser,
  },
});

export default combineReducers<AuthState>({
  loggedIn,
  user,
  cookieBarClosed,
  loginStatus,
  registerStatus,
  forgotPasswordStatus,
  resetPasswordStatus,
  resetPasswordSetByAdminStatus,
  chambers,
  permissions,
  hasSignedContract,
  cachedUser,
});

/* SAGAS */
function* login({ payload }: AppAction<LoginRequest>) {
  const { redirectTo, ...data } = payload;

  const resp: ExtendedAxiosResponse = yield call(api.login, toSnakeCase(data));
  if (resp.ok) {
    // remove cachedAuthToken if exists
    yield call([localStorage, 'removeItem'], 'cachedAuthToken');

    localStorage.setItem('authToken', toCamelCase(resp.data).accessToken);

    const hasSignedContract: boolean | undefined = yield getUserContracts();

    yield put(toastCreateSuccessActions(i18n.t('pages.login.toast.success')));
    yield put(authLoginActions.success(toCamelCase(resp.data)?.user));

    setTimeout(() => {
      if (redirectTo) {
        history.push(redirectTo);
      } else {
        if (!toCamelCase(resp.data)?.user?.tanzanitId) {
          history.push(
            buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.Projects])
          );
        } else {
          if (hasSignedContract) history.push(buildRoute(AppRoutes.AssignedProjects));
          else history.push(buildRoute(AppRoutes.Home));
        }
      }
    }, 0);
  } else {
    yield put(toastCreateErrorActions(resp.data?.message));
    yield put(authLoginActions.failure(toCamelCase(resp.data)?.errors));
  }
}

function* loginAsRespondent({ payload }: AppAction<LoginAsRespondentRequest>) {
  const resp: ExtendedAxiosResponse = yield call(api.loginAsRespondent, payload);

  if (resp.ok) {
    const respData = toCamelCase(resp.data);

    // get all data to cache
    const authUser: User = yield select(selectAuthUser);
    const authToken: string = yield call([localStorage, 'getItem'], 'authToken');

    // logout the current user - clear all redux data
    yield put(authLogoutActions.success());

    // cache current auth user
    yield put(authCacheAuthUser(authUser));

    // cache current authToken
    yield call([localStorage, 'setItem'], 'cachedAuthToken', authToken);

    // set a new authToken
    yield call([localStorage, 'setItem'], 'authToken', respData.accessToken);

    // replace authUser data
    yield put(authLoginAsRespondent.success(respData.user));
    yield put(toastCreateSuccessActions(resp.data?.message || i18n.t('pages.login.toast.success')));

    /**
     * Redirect to assigned projects page.
     * It must be wrapped in setTimeout to redirect successfully, I could not find any other solution.
     */
    yield call(() => {
      setTimeout(() => {
        history.push(buildRoute(AppRoutes.AssignedProjects));
      }, 0);
    });
  } else {
    yield put(authLoginAsRespondent.failure(toCamelCase(resp.data)?.errors));
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* logout() {
  const resp: ExtendedAxiosResponse = yield call(api.logout);

  if (resp.ok) {
    const cachedUser: User | null = yield select(selectAuthCachedUser);

    if (cachedUser) {
      // logout the admin who is logged in as a respondent

      // get all data to restore
      const cachedAuthToken: string = yield call([localStorage, 'getItem'], 'cachedAuthToken');

      // logout the respondent - clear all redux data
      yield put(authLogoutActions.success());

      // restore cached authToken
      yield call([localStorage, 'setItem'], 'authToken', cachedAuthToken);

      // reset logout action and log in cached user
      yield put(authLoginActions.success(cachedUser));

      // remove cached data
      yield call([localStorage, 'removeItem'], 'cachedAuthToken');

      yield put(
        toastCreateSuccessActions(resp.data?.message || i18n.t('pages.login.toast.success'))
      );

      /**
       * Redirect to respondent's page
       * It must be wrapped in setTimeout to redirect successfully, I could not find any other solution.
       */
      yield call(() => {
        setTimeout(() => {
          history.push(buildRoute([AppRoutes.Admin, AdminRoutes.Respondents]));
        }, 0);
      });
    } else {
      // classic logout
      yield call([localStorage, 'removeItem'], 'authToken');
      yield call([localStorage, 'removeItem'], 'cachedAuthToken');

      history.push(buildRoute([AppRoutes.Auth, AuthRoutes.Login]));
      yield put(toastCreateSuccessActions(resp.data?.message));
      yield put(authLogoutActions.success());
    }
  } else {
    yield put(toastCreateErrorActions(resp.data?.message));
    yield put(authLogoutActions.failure());
  }
}

function* register({ payload }: AppAction<RegisterRequest>) {
  const resp: ExtendedAxiosResponse = yield call(api.register, toSnakeCase(payload));

  if (resp.ok) {
    yield put(authRegisterActions.success());
    yield put(
      toastCreateActions({
        message: i18n.t('pages.register.toast.success'),
        type: 'success',
        closeOnClick: false,
        progress: 1,
      })
    );
    history.push(buildRoute([AppRoutes.Auth, AuthRoutes.Login]));
  } else {
    if (resp.status === 404) {
      yield put(
        toastCreateActions({
          message: toCamelCase(resp.data)?.message || i18n.t('components.errorToast.requestFailed'),
          type: 'error',
          closeOnClick: false,
          progress: 1,
        })
      );
    } else {
      yield put(toastCreateErrorActions(resp.data?.message));
    }
    yield put(authRegisterActions.failure(toCamelCase(resp.data)?.errors));
  }
}

function* requestPasswordReset({ payload }: AppAction<ForgotPasswordRequest>) {
  const resp: ExtendedAxiosResponse = yield call(api.requestPasswordReset, toSnakeCase(payload));

  if (resp.ok) {
    yield put(authRequestPasswordResetActions.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(buildRoute([AppRoutes.Auth, AuthRoutes.Login]));
  } else {
    yield put(toastCreateErrorActions(resp.data?.message));
    yield put(authRequestPasswordResetActions.failure(toCamelCase(resp.data)?.errors));
  }
}

function* resetPassword({ payload }: AppAction<ResetPasswordRequest>) {
  const resp: ExtendedAxiosResponse = yield call(api.resetPassword, toSnakeCase(payload));

  if (resp.ok) {
    yield put(authResetPasswordActions.success());
    yield put(toastCreateSuccessActions(resp.data?.message));

    yield call([localStorage, 'removeItem'], 'authToken');
    yield put(authLogoutActions.success());

    history.push(buildRoute([AppRoutes.Auth, AuthRoutes.Login]));
  } else {
    yield put(toastCreateErrorActions(resp.data?.message));
    yield put(authResetPasswordActions.failure(toCamelCase(resp.data)?.errors));
  }
}

function* resetPasswordAuthenticated({ payload }: AppAction<ResetPasswordAuthenticated>) {
  const resp: ExtendedAxiosResponse = yield call(
    api.resetPasswordAuthenticated,
    toSnakeCase(payload)
  );

  if (resp.ok) {
    yield put(authResetPasswordAuthenticatedActions.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(toastCreateErrorActions(resp.data?.message));
    yield put(authResetPasswordAuthenticatedActions.failure(toCamelCase(resp.data)?.errors));
  }
}

function* getProfile() {
  const resp: ExtendedAxiosResponse = yield call(api.getProfile);

  if (resp.ok) {
    yield put(authGetProfileActions.success(toCamelCase(resp.data)));
  } else {
    yield put(authGetProfileActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getChambers() {
  const resp: ExtendedAxiosResponse = yield call(api.getChambers);

  if (resp.ok) {
    yield put(authGetChambersActions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(authGetChambersActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getPermissions() {
  const resp: ExtendedAxiosResponse = yield call(api.getPermissions);

  if (resp.ok) {
    yield put(authGetPermissionsActions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(authGetPermissionsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* verify({ payload }: AppAction<{ location: Location }>) {
  const resp: ExtendedAxiosResponse = yield call(api.verify);

  if (resp.ok) {
    yield put(authVerifyActions.success());
  } else {
    // remove cachedAuthToken if exists
    yield call([localStorage, 'removeItem'], 'cachedAuthToken');

    localStorage.removeItem('authToken');
    history.push(buildRoute([AppRoutes.Auth, AuthRoutes.Login]), {
      from: payload.location.pathname,
    });
    yield put(authLogoutActions.success());
    yield put(authVerifyActions.failure());
  }
}

function* getUserContracts() {
  const resp: ExtendedAxiosResponse = yield call(api.getUserContracts);

  if (resp.ok) {
    const contracts: Contract[] = toCamelCase(resp.data)?.data;
    const defaultContract = contracts[0];
    const hasSignedContract =
      !!defaultContract?.default && dayjs(defaultContract?.validity).isSameOrAfter(dayjs());

    yield put(authSetHasSignedConctractActions(hasSignedContract));

    return hasSignedContract;
  }
}

function* resetPasswordSetByAdmin({ payload }: AppAction<ResetPasswordSetByAdminRequest>) {
  const resp: ExtendedAxiosResponse = yield call(api.resetPasswordSetByAdmin, payload);

  if (resp.ok) {
    yield put(authResetPasswordSetByAdmin.success());
    yield put(toastCreateSuccessActions(resp.data?.message));

    history.push(buildRoute([AppRoutes.Auth, AuthRoutes.Login]));
  } else {
    yield put(toastCreateErrorActions(resp.data?.message));
    yield put(authResetPasswordSetByAdmin.failure(toCamelCase(resp.data)?.errors));
  }
}

/* EXPORT */
export function* authSaga() {
  yield takeLatest(createActionType(AuthActionTypes.Login, RequestActionTypes.REQUEST), login);

  yield takeLatest(
    createActionType(AuthActionTypes.LoginAsRespondent, RequestActionTypes.REQUEST),
    loginAsRespondent
  );

  yield takeLatest(createActionType(AuthActionTypes.Logout, RequestActionTypes.REQUEST), logout);

  yield takeLatest(
    createActionType(AuthActionTypes.Register, RequestActionTypes.REQUEST),
    register
  );

  yield takeLatest(
    createActionType(AuthActionTypes.RequestPasswordReset, RequestActionTypes.REQUEST),
    requestPasswordReset
  );

  yield takeLatest(
    createActionType(AuthActionTypes.ResetPassword, RequestActionTypes.REQUEST),
    resetPassword
  );

  yield takeLatest(
    createActionType(AuthActionTypes.GetProfile, RequestActionTypes.REQUEST),
    getProfile
  );

  yield takeLatest(
    createActionType(AuthActionTypes.GetChambers, RequestActionTypes.REQUEST),
    getChambers
  );

  yield takeLatest(
    createActionType(AuthActionTypes.GetPermissions, RequestActionTypes.REQUEST),
    getPermissions
  );

  yield takeLatest(
    createActionType(AuthActionTypes.ResetPasswordAuthenticated, RequestActionTypes.REQUEST),
    resetPasswordAuthenticated
  );

  yield takeLatest(
    createActionType(AuthActionTypes.ResetPasswordSetByAdmin, RequestActionTypes.REQUEST),
    resetPasswordSetByAdmin
  );

  yield takeLatest(createActionType(AuthActionTypes.Verify, RequestActionTypes.REQUEST), verify);
}
