import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export interface GetContactsPayload extends ITableReq {}

export interface UpsertContactsPayload {
  id?: number;
  item: {
    contacts: {
      contactId?: number;
      typeName: string;
      name: string;
    }[];
  };
}

export interface RemoveContactsPayload {
  id?: number;
  item: {
    contacts: {
      contactId: number;
      typeName: string;
      name: string;
    }[];
  };
}

export const api = {
  getContacts: ({
    pagination,
    order,
    filter,
  }: GetContactsPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/types/contacts/all', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    }),

  upsertContacts: (payload: UpsertContactsPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put('/user/update', toSnakeCase({ item: payload.item })),

  upsertContactsAdmin: ({
    id,
    ...payload
  }: UpsertContactsPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(`/admin/users/${id}`, toSnakeCase({ item: payload.item })),

  removeContacts: ({ id, ...payload }: RemoveContactsPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.delete('/user/delete', {
      data: toSnakeCase(payload),
    }),

  removeContactsAdmin: ({
    id,
    ...payload
  }: RemoveContactsPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/admin/users/${id}/delete-child-entities`, {
      data: toSnakeCase(payload),
    }),
};
