import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum InvoiceCompanyActionTypes {
  GetAllInvoiceCompanies = '@@InvoiceCompany/GET_ALL_INVOICING_COMPANIES',
  GetInvoiceCompanies = '@@InvoiceCompany/GET_INVOICING_COMPANIES',
  SetInvoiceCompaniesPage = '@@InvoiceCompany/SET_INVOICING_COMPANIES_PAGE',
  IncreasePage = '@@InvoiceCompany/INCREASE_COMPANIES',
  SetOrderColumn = '@@InvoiceCompany/SET_ORDER_COLUMN',
  SetFilterColumn = '@@InvoiceCompany/SET_FILTER_COLUMNS',
  RemoveInvoiceCompany = '@@InvoiceCompany/REMOVE_INVOICING_COMPANY',
  CreateInvoiceCompany = '@@InvoiceCompany/CREATE_COMPANY',
  GetInvoiceCompany = '@@InvoiceCompany/GET_COMPANY',
  UpdateInvoiceCompany = '@@InvoiceCompany/UPDATE_COMPANY',
}

/* ACTIONS */
export const invoiceCompanyGetAllCompaniesActions = createApiActionCreators(
  InvoiceCompanyActionTypes.GetAllInvoiceCompanies
);
export const invoiceCompanyGetCompaniesActions = createApiActionCreators(
  InvoiceCompanyActionTypes.GetInvoiceCompanies
);
export const invoiceCompanySetCompaniesPageActions = createActionCreator(
  InvoiceCompanyActionTypes.SetInvoiceCompaniesPage
);
export const invoiceCompanyIncreasePageActions = createActionCreator(
  InvoiceCompanyActionTypes.IncreasePage
);
export const invoiceCompanySetOrderColumnActions = createActionCreator(
  InvoiceCompanyActionTypes.SetOrderColumn
);
export const invoiceCompanySetFilterColumnActions = createActionCreator(
  InvoiceCompanyActionTypes.SetFilterColumn
);

export const invoiceCompanyRemoveCompanyActions = createApiActionCreators(
  InvoiceCompanyActionTypes.RemoveInvoiceCompany
);

export const invoiceCompanyCreateCompanyActions = createApiActionCreators(
  InvoiceCompanyActionTypes.CreateInvoiceCompany
);
export const invoiceCompanyGetCompanyActions = createApiActionCreators(
  InvoiceCompanyActionTypes.GetInvoiceCompany
);
export const invoiceCompanyUpdateCompanyActions = createApiActionCreators(
  InvoiceCompanyActionTypes.UpdateInvoiceCompany
);
