/* SELECTORS */

import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { ClientProtocolProtocol } from '../../models/ClientProtocol';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';

const selectClientProtocol = (state: AppState) => state.clientProtocol;

export const selectClientProtocolProtocols = (state: AppState): ClientProtocolProtocol[] =>
  selectClientProtocol(state).protocols;

export const selectClientProtocolProtocolsPagination = (state: AppState): Pages =>
  selectClientProtocol(state).protocolsPagination;

export const selectClientProtocolProtocolsStatus = (state: AppState): LoadingStatus =>
  selectClientProtocol(state).protocolsStatus;

export const selectClientProtocolProtocolsOrderColumn = (state: AppState): ITableOrder =>
  selectClientProtocol(state).protocolsOrder;

export const selectClientProtocolProtocolsFilterColumn = (
  state: AppState
): Record<string, ISearch> => selectClientProtocol(state).protocolsFilter;

export const selectClientProtocolSummary = (state: AppState): number =>
  selectClientProtocol(state).summary;
