import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { selectAuthLoggedIn, selectAuthUser } from '../../../../saga/auth/selectors';
import { IRoute, Permission } from '../../route';
import { buildRoute } from '../../route-builder';
import { AppRoutes } from '../../routes/app-routes';
import { AuthRoutes } from '../../routes/auth-routes';

interface IExtendedRoute {
  route: IRoute;
}

const ExtendedRoute = ({ route }: IExtendedRoute) => {
  const isLoggedIn = useSelector(selectAuthLoggedIn);
  const user = useSelector(selectAuthUser);

  if (route.permissions) {
    if (route.permissions.includes(Permission.loggedIn) && !isLoggedIn) {
      return <Navigate to={buildRoute([AppRoutes.Auth, AuthRoutes.Login])} replace />;
    }

    if (route.permissions.includes(Permission.notLoggedInOnly) && isLoggedIn) {
      return <Navigate to={buildRoute([AppRoutes.Home])} replace />;
    }

    if (user?.permissions?.findIndex((userPerm) => userPerm.name === Permission.grant) === -1) {
      let hasPermissions = true;

      route.permissions.forEach((perm: string) => {
        if (
          user?.permissions?.findIndex((v) => v.name == perm) == -1 &&
          perm !== Permission.loggedIn &&
          perm !== Permission.notLoggedInOnly
        ) {
          hasPermissions = false;
        }
      });

      if (!hasPermissions) {
        return <Navigate to={buildRoute([AppRoutes.Home])} replace />;
      }
    }
  }

  if (route.element) {
    return route.element;
  }

  if (route.children) {
    return <Outlet />;
  }

  return <></>;
};

export default ExtendedRoute;
