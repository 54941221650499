import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router';

import useMediaQuery from '../../../hooks/useMediaQuery';
import { authVerifyActions } from '../../../saga/auth/actions';
import AppContent from '../AppContent/AppContent';
import AppFooter from '../AppFooter/AppFooter';
import AppHeader from '../AppHeader/AppHeader';

interface Props {
  children: React.ReactNode;
}

export const AppLayout = ({ children }: Props) => {
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const matches = useMediaQuery('(min-width: 768px)');

  useEffect(() => {
    if (!currentLocation.pathname.split('/').includes('auth')) {
      dispatch(authVerifyActions.request({ location: currentLocation }));
    }
  }, []);

  const showFooter = () => {
    if (currentLocation) {
      const pathname = currentLocation.pathname.split('/');
      if (!matches && (pathname[1] === 'survey' || pathname[1] === 'assigned-projects')) {
        return false;
      } else if (!matches && pathname[1] === 'projects') {
        if (pathname?.[2] && !isNaN(+pathname[2])) return false;
      }

      return true;
    }
  };

  return (
    <div className="AppLayout flex flex-col min-h-screen bg-gray-25">
      <AppHeader />
      <AppContent>{children}</AppContent>
      {showFooter() && <AppFooter />}
    </div>
  );
};
