import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';

import address from '../../saga/address/ducks';
import archivedProject from '../../saga/archivedProject/ducks';
import assignedProject from '../../saga/assignedProject/ducks';
import auth from '../../saga/auth/ducks';
import bank from '../../saga/bank/ducks';
import bankAccount from '../../saga/bankAccount/ducks';
import bankTransfer from '../../saga/bankTransfer/ducks';
import city from '../../saga/city/ducks';
import client from '../../saga/client/ducks';
import clientInvoice from '../../saga/clientInvoice/ducks';
import clientProtocol from '../../saga/clientProtocol/ducks';
import clientsDataOverview from '../../saga/clientsDataOverview/ducks';
import company from '../../saga/company/ducks';
import contact from '../../saga/contact/ducks';
import contract from '../../saga/contract/ducks';
import district from '../../saga/district/ducks';
import error from '../../saga/error/ducks';
import handoverProtocol from '../../saga/handoverProtocol/ducks';
import importRespondent from '../../saga/importRespondent/ducks';
import invoiceCompany from '../../saga/invoiceCompany/ducks';
import nonProjectHandoverProtocol from '../../saga/nonProjectHandoverProtocol/ducks';
import profession from '../../saga/professions/ducks';
import project from '../../saga/project/ducks';
import projectProtocol from '../../saga/projectProtocol/ducks';
import projectRespondent from '../../saga/projectRespondent/ducks';
import projectsDataOverview from '../../saga/projectsDataOverview/ducks';
import region from '../../saga/region/ducks';
import respondent from '../../saga/respondent/ducks';
import spec from '../../saga/specs/ducks';
import survey from '../../saga/survey/ducks';
import toast from '../../saga/toast/ducks';
import user from '../../saga/user/ducks';
import winner from '../../saga/winner/ducks';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  auth,
  user,
  error,
  toast,
  client,
  company,
  invoiceCompany,
  project,
  region,
  district,
  city,
  contract,
  bankAccount,
  respondent,
  survey,
  address,
  assignedProject,
  archivedProject,
  clientInvoice,
  projectRespondent,
  projectsDataOverview,
  clientsDataOverview,
  projectProtocol,
  clientProtocol,
  bank,
  bankTransfer,
  importRespondent,
  handoverProtocol,
  nonProjectHandoverProtocol,
  spec,
  profession,
  contact,
  winner,
});

export default rootReducer;
