import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export const api = {
  getAssignedProjects: ({
    pagination,
    order,
    filter,
  }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/assigned-projects', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  getAssignedProject: ({ id }: { id: number }) => apiClient.get(`/assigned-projects/${id}`),
};
