import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { SettingsRoutes } from '../../helpers/route/routes/settings-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { Bank } from '../../models/Bank';

import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  BankActionTypes,
  bankCreateBankActions,
  bankGetBankActions,
  bankGetBanksActions,
  bankRemoveBankActions,
  bankUpdateBankActions,
} from './actions';
import { api, BankPayload } from './api';
import {
  selectBankBanksFilterColumn,
  selectBankBanksOrderColumn,
  selectBankBanksPagination,
} from './selectors';

/* STATE */
export interface BankState {
  banks: Bank[];
  banksPagination: Pages;
  banksStatus: LoadingStatus;
  banksOrder: ITableOrder;
  banksFilter: Record<string, ISearch>;
  bank: Bank | null;
  bankActionStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: BankState = {
  banks: [],
  banksPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  banksStatus: LoadingStatus.initial,
  banksOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  banksFilter: {},
  bank: null,
  bankActionStatus: LoadingStatus.initial,
};

const banks = createReducer(initialState.banks, {
  [BankActionTypes.GetBanks]: {
    [RequestActionTypes.SUCCESS]: (state: Bank[], payload: PaginatedResp<Bank>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.banks,
  },
  [BankActionTypes.RemoveBank]: {
    [RequestActionTypes.SUCCESS]: (state: Bank[], payload: { id: number }) => {
      return state.filter((bank) => bank.id !== payload.id);
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.banks,
  },
});

const banksPagination = createReducer(initialState.banksPagination, {
  [BankActionTypes.GetBanks]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<Bank>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.banksPagination,
  },
  [BankActionTypes.SetBanksPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [BankActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [BankActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [BankActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.banksPagination,
  },
});

const banksStatus = createLoadingStateReducer(
  initialState.banksStatus,
  {
    [BankActionTypes.GetBanks]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [BankActionTypes.SetBanksPage]: () => initialState.banksStatus,
    [BankActionTypes.IncreasePage]: () => initialState.banksStatus,
    [BankActionTypes.SetFilterColumn]: () => initialState.banksStatus,
    [BankActionTypes.SetOrderColumn]: () => initialState.banksStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.banksStatus,
    },
  }
);

const banksOrder = createReducer(initialState.banksOrder, {
  [BankActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.banksOrder,
  },
});

const banksFilter = createReducer(initialState.banksFilter, {
  [BankActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.banksFilter,
  },
});

const bank = createReducer(initialState.bank, {
  [BankActionTypes.CreateBank]: {
    [RequestActionTypes.SUCCESS]: (state: Bank | null, payload: Bank) => payload,
  },
  [BankActionTypes.GetBank]: {
    [RequestActionTypes.REQUEST]: () => initialState.bank,
    [RequestActionTypes.SUCCESS]: (state: Bank | null, payload: Bank) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.bank,
  },
  [BankActionTypes.UpdateBank]: {
    [RequestActionTypes.SUCCESS]: (state: Bank | null, payload: Bank) => payload,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.bank,
  },
});

const bankActionStatus = createLoadingStateReducer(
  initialState.bankActionStatus,
  {
    [BankActionTypes.CreateBank]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [BankActionTypes.UpdateBank]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.bankActionStatus,
    },
  }
);

export default combineReducers<BankState>({
  banks,
  banksPagination,
  banksStatus,
  banksOrder,
  banksFilter,
  bank,
  bankActionStatus,
});

/* SAGAS */
function* getBanks() {
  const pagination: Pages = yield select(selectBankBanksPagination);
  const orderColumn: ITableOrder = yield select(selectBankBanksOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(selectBankBanksFilterColumn);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getBanks, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(bankGetBanksActions.success(toCamelCase(resp.data)));
  } else {
    yield put(bankGetBanksActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeBank({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeBank, payload);

  if (resp.ok) {
    yield put(bankRemoveBankActions.success({ id: payload.id }));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(bankRemoveBankActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createBank({ payload }: AppAction<BankPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.createBank, payload);

  if (resp.ok) {
    yield put(bankCreateBankActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageBanks]));
  } else {
    yield put(bankCreateBankActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getBank({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getBank, payload);

  if (resp.ok) {
    yield put(bankGetBankActions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(bankGetBankActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateBank({ payload }: AppAction<BankPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateBank, payload);

  if (resp.ok) {
    yield put(bankUpdateBankActions.success(toCamelCase(resp?.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageBanks]));
  } else {
    yield put(bankUpdateBankActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* bankSaga() {
  yield takeLatest(
    [
      createActionType(BankActionTypes.GetBanks, RequestActionTypes.REQUEST),
      BankActionTypes.SetBanksPage,
      BankActionTypes.IncreasePage,
      BankActionTypes.SetOrderColumn,
      BankActionTypes.SetFilterColumn,
    ],
    getBanks
  );
  yield takeLatest(
    [createActionType(BankActionTypes.RemoveBank, RequestActionTypes.REQUEST)],
    removeBank
  );
  yield takeLatest(
    createActionType(BankActionTypes.CreateBank, RequestActionTypes.REQUEST),
    createBank
  );
  yield takeLatest(createActionType(BankActionTypes.GetBank, RequestActionTypes.REQUEST), getBank);
  yield takeLatest(
    createActionType(BankActionTypes.UpdateBank, RequestActionTypes.REQUEST),
    updateBank
  );
}
