import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Chamber, Permission, User } from '../../models/User';
import { AuthState } from './ducks';

/* SELECTORS */
const selectAuth = (state: AppState): AuthState => state.auth;

export const selectAuthLoggedIn = (state: AppState): boolean => selectAuth(state).loggedIn;

export const selectAuthUser = (state: AppState): User | null => selectAuth(state).user;

export const selectChambers = (state: AppState): Chamber[] | null => selectAuth(state).chambers;

export const selectPermissions = (state: AppState): Permission[] | null =>
  selectAuth(state)?.permissions;

export const selectAuthRegisterStatus = (state: AppState): LoadingStatus =>
  selectAuth(state).registerStatus;

export const selectAuthLoginStatus = (state: AppState): LoadingStatus =>
  selectAuth(state).loginStatus;

export const selectAuthForgotPasswordStatus = (state: AppState): LoadingStatus =>
  selectAuth(state).forgotPasswordStatus;

export const selectAuthResetPasswordStatus = (state: AppState): LoadingStatus =>
  selectAuth(state).resetPasswordStatus;

export const selectAuthHasSignedContract = (state: AppState): boolean =>
  selectAuth(state).hasSignedContract;

export const selectAuthCachedUser = (state: AppState): User | null => selectAuth(state).cachedUser;

export const selectAuthResetPasswordSetByAdminStatus = (state: AppState): LoadingStatus =>
  selectAuth(state).resetPasswordSetByAdminStatus;
