import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

export enum ClientProtocolActionTypes {
  GetProtocols = '@@ClientProtocol/GET_PROTOCOLS',
  SetProtocolsPage = '@@ClientProtocol/SET_PROTOCOLS_PAGE',
  IncreasePage = '@@ClientProtocol/INCREASE_PAGE',
  SetOrderColumn = '@@ClientProtocol/SET_ORDER_COLUMN',
  SetFilterColumn = '@@ClientProtocol/SET_FILTER_COLUMNS',
  CleanData = '@@ClientProtocol/CLEAN_DATA',
}

/* ACTIONS */
export const clientProtocolGetProtocolsActions = createApiActionCreators(
  ClientProtocolActionTypes.GetProtocols
);
export const clientProtocolSetProtocolsPageActions = createActionCreator(
  ClientProtocolActionTypes.SetProtocolsPage
);
export const clientProtocolIncreasePageActions = createActionCreator(
  ClientProtocolActionTypes.IncreasePage
);
export const clientProtocolSetOrderColumnActions = createActionCreator(
  ClientProtocolActionTypes.SetOrderColumn
);
export const clientProtocolSetFilterColumnActions = createActionCreator(
  ClientProtocolActionTypes.SetFilterColumn
);

export const clientProtocolCleanData = createActionCreator(ClientProtocolActionTypes.CleanData);
