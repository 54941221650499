import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { BankAccount } from '../../models/BankAccount';
import { Client } from '../../models/Client';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { ClientState } from './ducks';

/* SELECTORS */
const selectClient = (state: AppState): ClientState => state.client;

export const selectClientClients = (state: AppState): Client[] => selectClient(state).clients;

export const selectClientClientsPagination = (state: AppState): Pages =>
  selectClient(state).clientsPagination;

export const selectClientClientsStatus = (state: AppState): LoadingStatus =>
  selectClient(state).clientsStatus;

export const selectClientClientsOrderColumn = (state: AppState): ITableOrder =>
  selectClient(state).clientsOrder;

export const selectClientClientsFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectClient(state).clientsFilter;

export const selectClientBankAccounts = (state: AppState, id: number): BankAccount[] =>
  selectClient(state).bankAccounts?.[id] || [];

export const selectClientClient = (state: AppState): Client | null => selectClient(state).client;

export const selectClientClientActionStatus = (state: AppState): LoadingStatus =>
  selectClient(state).clientActionStatus;
