import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Bank } from '../../models/Bank';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { BankState } from './ducks';

/* SELECTORS */
const selectBank = (state: AppState): BankState => state.bank;

export const selectBankBanks = (state: AppState): Bank[] => selectBank(state).banks;

export const selectBankBanksPagination = (state: AppState): Pages =>
  selectBank(state).banksPagination;

export const selectBankBanksStatus = (state: AppState): LoadingStatus =>
  selectBank(state).banksStatus;

export const selectBankBanksOrderColumn = (state: AppState): ITableOrder =>
  selectBank(state).banksOrder;

export const selectBankBanksFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectBank(state).banksFilter;

export const selectBankBank = (state: AppState): Bank | null => selectBank(state).bank;

export const selectBankBankActionStatus = (state: AppState): LoadingStatus =>
  selectBank(state).bankActionStatus;
