import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { IProject } from '../../models/Project';
import { ISurveySidebar, SurveyBlockQuestion, SurveyStatus } from '../../models/Survey';
import { GroupedNumericBlockState, SurveyState } from './ducks';

/* SELECTORS */
const selectSurvey = (state: AppState): SurveyState => state.survey;

export const selectSurveyProject = (state: AppState): IProject | null =>
  selectSurvey(state).project;

export const selectSurveyTotalCountOfQuestions = (state: AppState): number => {
  const blocks = selectSurveyBlocks(state);
  return (
    blocks?.blocksOrder?.reduce((acc: number, blockId: string) => {
      return acc + (blocks?.blocks?.[blockId]?.questionsOrder?.length || 0);
    }, 0) || 0
  );
};

export const selectSurveyBlockOffset = (state: AppState): number => {
  const blocks = selectSurveyBlocks(state);
  const selectCurrentBlockId = selectSurveyCurrentBlockId(state);

  return (
    blocks?.blocksOrder?.reduce((acc: number, blockId: string) => {
      const block = blocks?.blocks?.[blockId];
      if (
        selectCurrentBlockId &&
        blocks.lockedBlocks &&
        !blocks.lockedBlocks.includes(selectCurrentBlockId) &&
        block?.position < blocks.blocks?.[selectCurrentBlockId]?.position
      ) {
        return acc + (block?.questionsOrder?.length || 0);
      }

      return acc;
    }, 0) || 0
  );
};

export const selectSurveyStatus = (state: AppState): LoadingStatus => selectSurvey(state).status;

export const selectSurveyCurrentSessionId = (state: AppState): string =>
  selectSurvey(state).currentSessionId;

export const selectSurveyBlocks = (state: AppState): ISurveySidebar => selectSurvey(state).blocks;

export const selectSurveyQuestions = (state: AppState): Record<string, SurveyBlockQuestion> =>
  selectSurvey(state).questions;

export const selectSurveyCurrentBlockId = (state: AppState): string =>
  selectSurvey(state).currentBlockId;

export const selectQuestionChildOptions = (state: AppState) =>
  selectSurvey(state).questionChildOptions;

export const selectSurveyStatuses = (state: AppState): Record<string, SurveyStatus> =>
  selectSurvey(state).surveyStatuses;

export const selectSurveyGroupedNumericBlocksState = (
  state: AppState
): Record<number, GroupedNumericBlockState> => selectSurvey(state).groupedNumericBlocksState;
