import { Permission } from '../helpers/route/route';
import { buildRoute } from '../helpers/route/route-builder';
import { AdminRoutes } from '../helpers/route/routes/admin-routes';
import { AppRoutes } from '../helpers/route/routes/app-routes';
import { OverviewRoutes } from '../helpers/route/routes/overview-routes';
import { SettingsRoutes } from '../helpers/route/routes/settings-routes';

export const adminMenu: any = {
  clients: {
    label: 'layout.appHeader.adminMenu.clients',
    to: buildRoute([AppRoutes.Admin, AdminRoutes.Clients]),
    permission: Permission.clientRead,
  },
  respondents: {
    label: 'layout.appHeader.adminMenu.respondents.label',
    children: {
      respondents: {
        label: 'layout.appHeader.adminMenu.respondents.items.respondents',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.Respondents]),
        permission: Permission.respondentRead,
      },
      companies: {
        label: 'layout.appHeader.adminMenu.respondents.items.companies',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.Companies]),
        permission: Permission.companyRead,
      },
      imports: {
        label: 'layout.appHeader.adminMenu.respondents.items.import',
        to: `${buildRoute([AppRoutes.Admin, AdminRoutes.RespondentImport])}?step=1`,
        permission: Permission.assignedProjectImport,
      },
    },
  },
  contracts: {
    label: 'layout.appHeader.adminMenu.contracts.label',
    children: {
      nonProjectAcceptanceProtocol: {
        label: 'layout.appHeader.adminMenu.contracts.items.nonProjectAcceptanceProtocol',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.NonProjectAcceptanceProtocol]),
        permission: Permission.nonProjectAcceptanceProtocolRead,
      },
      acceptanceProtocol: {
        label: 'layout.appHeader.adminMenu.contracts.items.acceptanceProtocol',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.AcceptanceProtocol]),
        permission: Permission.acceptanceProtocolRead,
      },
      contract: {
        label: 'layout.appHeader.adminMenu.contracts.items.contract',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.Contract]),
        permission: Permission.contractRead,
      },
    },
  },
  overviews: {
    label: 'layout.appHeader.adminMenu.overviews.label',
    children: {
      project: {
        label: 'layout.appHeader.adminMenu.overviews.items.projects',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.Projects]),
        permission: Permission.projectRead,
      },
      projectData: {
        label: 'layout.appHeader.adminMenu.overviews.items.projectsData',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.Overviews, OverviewRoutes.ProjectsData]),
        permission: Permission.projectDataRead,
      },
      clientsData: {
        label: 'layout.appHeader.adminMenu.overviews.items.clientsData',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.Overviews, OverviewRoutes.ClientsData]),
        permission: Permission.clientsDataRead,
      },
      clientInvoice: {
        label: 'layout.appHeader.adminMenu.overviews.items.clientInvoice',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.Overviews, OverviewRoutes.ClientInvoice]),
        permission: Permission.clientInvoiceRead,
      },
    },
  },
  transfers: {
    label: 'layout.appHeader.adminMenu.bankTransfers',
    to: buildRoute([AppRoutes.Admin, AdminRoutes.BankTransfers]),
    permission: Permission.bankTransferRead,
  },
  settings: {
    label: 'layout.appHeader.adminMenu.settings.label',
    children: {
      cms: {
        label: 'layout.appHeader.adminMenu.settings.items.cms',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.Cms]),
        permission: Permission.cmsRead,
      },
      admin: {
        label: 'layout.appHeader.adminMenu.settings.items.admins',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageUsers]),
        permission: Permission.adminRead,
      },
      invoicingEntity: {
        label: 'layout.appHeader.adminMenu.settings.items.invoiceCompany',
        to: buildRoute([
          AppRoutes.Admin,
          AdminRoutes.Settings,
          SettingsRoutes.ManageInvoiceCompanies,
        ]),
        permission: Permission.invoicingEntityRead,
      },
      region: {
        label: 'layout.appHeader.adminMenu.settings.items.regions',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageRegions]),
        permission: Permission.territoryRead,
      },
      district: {
        label: 'layout.appHeader.adminMenu.settings.items.districts',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageDistricts]),
        permission: Permission.territoryRead,
      },
      city: {
        label: 'layout.appHeader.adminMenu.settings.items.cities',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageCities]),
        permission: Permission.territoryRead,
      },
      bank: {
        label: 'layout.appHeader.adminMenu.settings.items.banks',
        to: buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageBanks]),
        permission: Permission.banksRead,
      },
    },
  },
};

export const userMenu = {
  assignedProject: {
    label: 'layout.appHeader.userMenu.actualProject',
    to: buildRoute(AppRoutes.AssignedProjects),
    permission: '',
  },
  archivedProject: {
    label: 'layout.appHeader.userMenu.archives',
    to: buildRoute(AppRoutes.ArchivedProjects),
    permission: '',
  },
  contracts: {
    label: 'layout.appHeader.userMenu.contractsAndInvoice.label',
    children: {
      contracts: {
        label: 'layout.appHeader.userMenu.contractsAndInvoice.items.contracts',
        to: buildRoute(AppRoutes.Contracts),
        permission: '',
      },
      invoice: {
        label: 'layout.appHeader.userMenu.contractsAndInvoice.items.invoice',
        to: buildRoute([AppRoutes.HandoverProtocol]),
        permission: '',
      },
    },
  },
  helper: {
    label: 'layout.appHeader.userMenu.helpers.label',
    children: {
      faq: {
        label: 'layout.appHeader.userMenu.helpers.items.faq',
        to: buildRoute(AppRoutes.Faq),
        permission: '',
      },
      dataSecurity: {
        label: 'layout.appHeader.userMenu.helpers.items.dataSecurity',
        to: buildRoute(AppRoutes.DataSecurity),
        permission: '',
      },
    },
  },
};

export const menu = {
  user: userMenu,
  admin: adminMenu,
};

export const myProfile: any = {
  profile: {
    label: 'layout.appHeader.myProfile.items.profile',
    to: buildRoute(AppRoutes.Profile),
    permission: '',
  },
};
