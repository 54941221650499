import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

export enum ImportRespondentActionTypes {
  GenerateRespondents = '@@ImportRespondent/GENERATE_RESPONDENTS',
  ImportRespondents = '@@ImportRespondent/IMPORT_RESPONDENT',
  GetRespondents = '@@ImportRespondent/GET_RESPONDENTS',
  SetRespondentsPage = '@@ImportRespondent/SET_RESPONDENTS_PAGE',
  IncreasePage = '@@ImportRespondent/INCREASE_PAGE',
  SetOrderColumn = '@@ImportRespondent/SET_ORDER_COLUMN',
  SetFilterColumn = '@@ImportRespondent/SET_FILTER_COLUMNS',
  RemoveRespondents = '@@ImportRespondent/REMOVE_RESPONDENTS',
  GetProjects = '@@ImportRespondent/GET_PROJECTS',
  GetCompanies = '@@ImportRespondent/GET_COMPANIES',
  GetImportStatus = '@@ImportRespondent/GET_IMPORT_RESPONDENT',
  GetErrors = '@@ImportRespondent/GET_ERRORS',
}

export const importRespondentGenerateRespondentsActions = createApiActionCreators(
  ImportRespondentActionTypes.GenerateRespondents
);

export const importRespondentImportRespondentsActions = createApiActionCreators(
  ImportRespondentActionTypes.ImportRespondents
);

export const importRespondentGetRespondentsActions = createApiActionCreators(
  ImportRespondentActionTypes.GetRespondents
);

export const importRespondentSetRespondentsPageActions = createActionCreator(
  ImportRespondentActionTypes.SetRespondentsPage
);
export const importRespondentIncreasePageActions = createActionCreator(
  ImportRespondentActionTypes.IncreasePage
);
export const importRespondentSetOrderColumnActions = createActionCreator(
  ImportRespondentActionTypes.SetOrderColumn
);
export const importRespondentSetFilterColumnActions = createActionCreator(
  ImportRespondentActionTypes.SetFilterColumn
);

export const importRespondentRemoveRespondentActions = createApiActionCreators(
  ImportRespondentActionTypes.RemoveRespondents
);

export const importRespondentGetProjectsActions = createApiActionCreators(
  ImportRespondentActionTypes.GetProjects
);

export const importRespondentGetCompaniesActions = createApiActionCreators(
  ImportRespondentActionTypes.GetCompanies
);

export const importRespondentGetImportStatusActions = createApiActionCreators(
  ImportRespondentActionTypes.GetImportStatus
);

export const importRespondentGetErrorsActions = createApiActionCreators(
  ImportRespondentActionTypes.GetErrors
);
