import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { Permission, User } from '../../models/User';
import { UserState } from './ducks';

/* SELECTORS */
const selectUser = (state: AppState): UserState => state.user;

export const selectUserUsers = (state: AppState): User[] | null => selectUser(state).users;

export const selectUserUsersPagination = (state: AppState): Pages =>
  selectUser(state).usersPagination;

export const selectUserUsersStatus = (state: AppState): LoadingStatus =>
  selectUser(state).usersStatus;

export const selectUserUsersOrderColumn = (state: AppState): ITableOrder =>
  selectUser(state).usersOrder;

export const selectUserUsersFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectUser(state).usersFilter;

export const selectUserProfile = (state: AppState): User | null => selectUser(state).userProfile;

export const selectUserPermissions = (state: AppState): Permission[] =>
  selectUser(state).userPermissions;

export const selectIsEmailVerifiedStatus = (state: AppState): LoadingStatus =>
  selectUser(state).isEmailVerifiedStatus;

export const selectUserChangeEmailStatus = (state: AppState): LoadingStatus =>
  selectUser(state).changeEmailStatus;

export const selectUserVerifyEmailStatus = (state: AppState): LoadingStatus =>
  selectUser(state).verifyEmailStatus;

export const selectUserUserActionStatus = (state: AppState): LoadingStatus =>
  selectUser(state).userActionStatus;
