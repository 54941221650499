import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { BankAccount } from '../../models/BankAccount';
import { Company } from '../../models/Company';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { CompanyState } from './ducks';

/* SELECTORS */
const selectCompany = (state: AppState): CompanyState => state.company;

export const selectCompanyCompanies = (state: AppState): Company[] =>
  selectCompany(state).companies;

export const selectCompanyCompaniesPagination = (state: AppState): Pages =>
  selectCompany(state).companiesPagination;

export const selectCompanyCompaniesStatus = (state: AppState): LoadingStatus =>
  selectCompany(state).companiesStatus;

export const selectCompanyCompaniesOrderColumn = (state: AppState): ITableOrder =>
  selectCompany(state).companiesOrder;

export const selectCompanyCompaniesFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectCompany(state).companiesFilter;

export const selectCompanyBankAccounts = (state: AppState, id: number): BankAccount[] =>
  selectCompany(state).bankAccounts?.[id] || [];

export const selectCompanyCompany = (state: AppState): Company | null =>
  selectCompany(state).company;

export const selectCompanyCompanyStatus = (state: AppState): LoadingStatus =>
  selectCompany(state).companyStatus;

export const selectCompanyRespondents = (state: AppState): { id: string; name: string }[] =>
  selectCompany(state).respondents;

export const selectCompanyRespondentsStatus = (state: AppState): LoadingStatus =>
  selectCompany(state).respondentsStatus;
