import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum CityActionTypes {
  GetCities = '@@City/GET_COMPANIES',
  SetCitiesPage = '@@City/SET_COMPANIES_PAGE',
  IncreasePage = '@@City/INCREASE_COMPANIES',
  SetOrderColumn = '@@City/SET_ORDER_COLUMN',
  SetFilterColumn = '@@City/SET_FILTER_COLUMNS',
  RemoveCity = '@@City/REMOVE_CITY',
  CreateCity = '@@City/CREATE_CITY',
  GetCity = '@@City/GET_CITY',
  UpdateCity = '@@City/UPDATE_CITY',
  GetDistricts = '@@City/GET_DISTRICTS',
  GetCitiesZip = '@@City/GET_CITIES_ZIP',
  SetCitiesZipPage = '@@City/SET_CITIES_ZIP_PAGE',
  IncreasePageZip = '@@City/INCREASE_CITIES_ZIP',
  SetOrderColumnZip = '@@City/SET_ORDER_COLUMN_ZIP',
  SetFilterColumnZip = '@@City/SET_FILTER_COLUMNS_ZIP',
}

/* ACTIONS */
export const cityGetCitiesActions = createApiActionCreators(CityActionTypes.GetCities);
export const citySetCitiesPageActions = createActionCreator(CityActionTypes.SetCitiesPage);
export const cityIncreasePageActions = createActionCreator(CityActionTypes.IncreasePage);
export const citySetOrderColumnActions = createActionCreator(CityActionTypes.SetOrderColumn);
export const citySetFilterColumnActions = createActionCreator(CityActionTypes.SetFilterColumn);
export const cityRemoveCityActions = createApiActionCreators(CityActionTypes.RemoveCity);

export const cityCreateCityActions = createApiActionCreators(CityActionTypes.CreateCity);
export const cityUpdateCityActions = createApiActionCreators(CityActionTypes.UpdateCity);
export const cityGetCityActions = createApiActionCreators(CityActionTypes.GetCity);
export const cityGetDistrictsActions = createApiActionCreators(CityActionTypes.GetDistricts);

export const cityGetCitiesZipActions = createApiActionCreators(CityActionTypes.GetCitiesZip);
export const citySetCitiesZipPageActions = createActionCreator(CityActionTypes.SetCitiesZipPage);
export const cityIncreasePageZipActions = createActionCreator(CityActionTypes.IncreasePageZip);
export const citySetOrderColumnZipActions = createActionCreator(CityActionTypes.SetOrderColumnZip);
export const citySetFilterColumnZipActions = createActionCreator(
  CityActionTypes.SetFilterColumnZip
);
