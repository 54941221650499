import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export const api = {
  getProjects: (payload: { name: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/project', {
      params: toSnakeCase({
        page: 1,
        resultsPerPage: 10000,
        order: 'ASC',
        sort: 'name',
        search: `name:like:${payload.name}`,
      }),
    }),

  getProjectRespondents: ({
    id,
    pagination,
    order,
    filter,
  }: ITableReq & { id?: string }): Promise<ExtendedAxiosResponse> => {
    return apiClient.get(`/admin/project/${id}/assigned-projects`, {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },
};
