import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Disclosure, Transition } from '@headlessui/react';

import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as LockedIcon } from '../../../assets/icons/lock.svg';
import { Permission } from '../../../helpers/route/route';
import { selectAuthUser } from '../../../saga/auth/selectors';

const MenuDisclosure = ({ items, label }: any) => {
  const user = useSelector(selectAuthUser);

  const { t } = useTranslation();

  return (
    <Disclosure as={'div'} className={'block lg:hidden font-sans font-normal text-sm'}>
      <Disclosure.Button
        className={
          'inline-flex items-center w-full justify-between px-3 py-2 font-normal font-sans text-sm text-secondary'
        }
      >
        <>
          {t(label)}
          <ArrowDown width={12} height={6} className={'stroke-secondary ml-1'} />
        </>
      </Disclosure.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Disclosure.Panel>
          <div className={'block space-y-1 px-2'}>
            {Object.keys(items).map((item) => {
              const hasPermission =
                user?.permissions?.findIndex(
                  (perm) => perm.name == items[item].permission || perm.name === Permission.grant
                ) !== -1 || !items[item]?.permission;

              return (
                <Disclosure.Button
                  key={item}
                  as={'a'}
                  href={items[item].to}
                  className={`block py-2 px-3 flex justify-between items-center ${
                    hasPermission
                      ? 'text-secondary cursor-pointer'
                      : 'pointer-events-none text-gray-75'
                  }`}
                >
                  <>
                    {t(items[item].label)}
                    {!hasPermission && (
                      <LockedIcon width={14} height={14} className={'stroke-gray-75'} />
                    )}
                  </>
                </Disclosure.Button>
              );
            })}
          </div>
        </Disclosure.Panel>
      </Transition>
    </Disclosure>
  );
};

export default MenuDisclosure;
