/* SELECTORS */

import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { BankTransfer, ExportDate } from '../../models/BankTransfers';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';

const selectBankTransfer = (state: AppState) => state.bankTransfer;

export const selectBankTransferExports = (state: AppState): ExportDate[] =>
  selectBankTransfer(state).exports;

export const selectBankTransferExportsStatus = (state: AppState): LoadingStatus =>
  selectBankTransfer(state).exportsStatus;

export const selectBankTransferExport = (state: AppState) => selectBankTransfer(state).exportDate;

export const selectBankTransferBankTransfers = (state: AppState): BankTransfer[] =>
  selectBankTransfer(state).bankTransfers;

export const selectBankTransferBankTransfersPagination = (state: AppState): Pages =>
  selectBankTransfer(state).bankTransfersPagination;

export const selectBankTransferBankTransfersStatus = (state: AppState): LoadingStatus =>
  selectBankTransfer(state).bankTransfersStatus;

export const selectBankTransferBankTransfersOrderColumn = (state: AppState): ITableOrder =>
  selectBankTransfer(state).bankTransfersOrder;

export const selectBankTransferBankTransfersFilterColumn = (
  state: AppState
): Record<string, ISearch> => selectBankTransfer(state).bankTransfersFilter;

export const selectBankTransferGenerationTime = (state: AppState) =>
  selectBankTransfer(state).generationTime;
