import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export interface ClientPayload {
  id?: number;
  respondentId?: number;
  dic: string;
  icdph: string;
  ico: string;
  name: string;
  contactPerson: string;
  withholdingTax: boolean;
  vatPayer: boolean;
  ownInvoice: boolean;
  state: boolean;
}

export const api = {
  getClients: ({ pagination, order, filter }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/pharma-company', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  removeClient: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/pharma-company/${id}`),

  getBankAccounts: (payload: { clientId?: number }): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/bank-accounts', {
      params: toSnakeCase({
        ...payload,
        resultsPerPage: 30,
      }),
    });
  },

  getClient: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/pharma-company/${id}`),

  updateClient: (payload: ClientPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(`/pharma-company/${payload?.id}`, toSnakeCase(payload)),

  createClient: (payload: ClientPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/pharma-company', toSnakeCase(payload)),
};
