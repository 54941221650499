import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { formatFilterToString } from '../../helpers/table';
import { lowercaseObjectKeys, toCamelCase } from '../../helpers/transformObject';

import { HandoverProtocol } from '../../models/HandoverProtocol';
import { Pages, PaginatedResp } from '../../models/Pages';
import { ISearch, IOrderStatus, ITableOrder } from '../../models/Table';
import { User } from '../../models/User';
import { AuthActionTypes } from '../auth/actions';
import { selectAuthUser } from '../auth/selectors';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  HandoverProtocolActionTypes,
  handoverProtocolDownloadPdfActions,
  handoverProtocolGetHandoverProtocolsActions,
} from './actions';
import { api } from './api';
import {
  selectHandoverProtocolProtocolsFilterColumn,
  selectHandoverProtocolProtocolsOrderColumn,
  selectHandoverProtocolProtocolsPagination,
} from './selectors';

/* STATE */
export interface HandoverProtocolState {
  handoverProtocols: HandoverProtocol[];
  handoverProtocolsPagination: Pages;
  handoverProtocolsStatus: LoadingStatus;
  handoverProtocolsOrder: ITableOrder;
  handoverProtocolsFilter: Record<string, ISearch>;
}

/* REDUCERS */
const initialState: HandoverProtocolState = {
  handoverProtocols: [],
  handoverProtocolsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  handoverProtocolsStatus: LoadingStatus.initial,
  handoverProtocolsOrder: {
    sort: 'exportedAt',
    order: IOrderStatus.ASC,
  },
  handoverProtocolsFilter: {},
};

const handoverProtocols = createReducer(initialState.handoverProtocols, {
  [HandoverProtocolActionTypes.GetHandoverProtocols]: {
    [RequestActionTypes.SUCCESS]: (
      state: HandoverProtocol[],
      payload: PaginatedResp<HandoverProtocol>
    ) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.handoverProtocols,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.handoverProtocols,
  },
});

const handoverProtocolsPagination = createReducer(initialState.handoverProtocolsPagination, {
  [HandoverProtocolActionTypes.GetHandoverProtocols]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<HandoverProtocol>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.handoverProtocolsPagination,
  },
  [HandoverProtocolActionTypes.SetHandoverProtocolsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [HandoverProtocolActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [HandoverProtocolActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [HandoverProtocolActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.handoverProtocolsPagination,
  },
});

const handoverProtocolsStatus = createLoadingStateReducer(
  initialState.handoverProtocolsStatus,
  {
    [HandoverProtocolActionTypes.GetHandoverProtocols]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [HandoverProtocolActionTypes.SetHandoverProtocolsPage]: () =>
      initialState.handoverProtocolsStatus,
    [HandoverProtocolActionTypes.IncreasePage]: () => initialState.handoverProtocolsStatus,
    [HandoverProtocolActionTypes.SetFilterColumn]: () => initialState.handoverProtocolsStatus,
    [HandoverProtocolActionTypes.SetOrderColumn]: () => initialState.handoverProtocolsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.handoverProtocolsStatus,
    },
  }
);

const handoverProtocolsOrder = createReducer(initialState.handoverProtocolsOrder, {
  [HandoverProtocolActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.handoverProtocolsOrder,
  },
});

const handoverProtocolsFilter = createReducer(initialState.handoverProtocolsFilter, {
  [HandoverProtocolActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.handoverProtocolsFilter,
  },
});

export default combineReducers<HandoverProtocolState>({
  handoverProtocols,
  handoverProtocolsPagination,
  handoverProtocolsStatus,
  handoverProtocolsOrder,
  handoverProtocolsFilter,
});

/* SAGAS */
function* getHandoverProtocols() {
  const user: User | null = yield select(selectAuthUser);

  const pagination: Pages = yield select(selectHandoverProtocolProtocolsPagination);
  const orderColumn: ITableOrder = yield select(selectHandoverProtocolProtocolsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(
    selectHandoverProtocolProtocolsFilterColumn
  );

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(
    user?.tanzanitId ? api.getHandoverProtocols : api.adminGetHandoverProtocols,
    {
      pagination: pagination,
      order: orderColumn,
      filter: stringFromFilter,
    }
  );

  if (resp.ok) {
    yield put(handoverProtocolGetHandoverProtocolsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(handoverProtocolGetHandoverProtocolsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* downloadPdf({ payload }: AppAction<{ id: number }>) {
  const user: User | null = yield select(selectAuthUser);

  const resp: ExtendedAxiosResponse = yield call(
    user?.tanzanitId ? api.downloadPdf : api.adminDownloadPdf,
    payload
  );

  if (resp.ok) {
    const href = URL.createObjectURL(resp.data);

    const filename =
      lowercaseObjectKeys(resp.headers)
        ['content-disposition']?.split('filename="')?.[1]
        ?.split('"')?.[0] || '';

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${filename}.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    yield put(handoverProtocolDownloadPdfActions.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(handoverProtocolDownloadPdfActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* handoverProtocolSaga() {
  yield takeLatest(
    [
      createActionType(
        HandoverProtocolActionTypes.GetHandoverProtocols,
        RequestActionTypes.REQUEST
      ),
      HandoverProtocolActionTypes.SetHandoverProtocolsPage,
      HandoverProtocolActionTypes.IncreasePage,
      HandoverProtocolActionTypes.SetOrderColumn,
      HandoverProtocolActionTypes.SetFilterColumn,
    ],
    getHandoverProtocols
  );
  yield takeLatest(
    createActionType(HandoverProtocolActionTypes.DownloadPdf, RequestActionTypes.REQUEST),
    downloadPdf
  );
}
