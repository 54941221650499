import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum RespondentActionTypes {
  GetRespondents = '@@Respondent/GET_RESPONDENTS',
  SetRespondentsPage = '@@Respondent/SET_RESPONDENTS_PAGE',
  IncreasePage = '@@Respondent/INCREASE_PAGE',
  SetOrderColumn = '@@Respondent/SET_ORDER_COLUMN',
  SetFilterColumn = '@@Respondent/SET_FILTER_COLUMNS',
  CreateRespondent = '@@Respondent/CREATE_RESPONDENT',
  GetRespondent = '@@Respondent/GET_RESPONDENT',
  RemoveRespondent = '@@Respondent/REMOVE_RESPONDENT',
  UpdateRespondent = '@@Respondent/UPDATE_RESPONDENT',
  Clean = '@@Respondent/CLEAN',
  CleanRespondent = '@@Respondent/CLEAN_RESPONDENT',
}

/* ACTIONS */
export const respondentGetRespondentsActions = createApiActionCreators(
  RespondentActionTypes.GetRespondents
);
export const respondentSetRespondentsPageActions = createActionCreator(
  RespondentActionTypes.SetRespondentsPage
);
export const respondentIncreasePageActions = createActionCreator(
  RespondentActionTypes.IncreasePage
);
export const respondentSetOrderColumnActions = createActionCreator(
  RespondentActionTypes.SetOrderColumn
);
export const respondentSetFilterColumnActions = createActionCreator(
  RespondentActionTypes.SetFilterColumn
);

export const respondentCreateRespondentActions = createApiActionCreators(
  RespondentActionTypes.CreateRespondent
);

export const respondentGetRespondentActions = createApiActionCreators(
  RespondentActionTypes.GetRespondent
);

export const respondentRemoveRespondentActions = createApiActionCreators(
  RespondentActionTypes.RemoveRespondent
);

export const respondentUpdateRespondentActions = createApiActionCreators(
  RespondentActionTypes.UpdateRespondent
);

export const respondentCleanActions = createActionCreator(RespondentActionTypes.Clean);

export const respondentCleanRespondentActions = createActionCreator(
  RespondentActionTypes.CleanRespondent
);
