import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum ClientInvoiceActionTypes {
  GetClientInvoices = '@@ClientInvoice/GET_CLIENT_INVOICES',
  SetClientInvoicesPage = '@@ClientInvoice/SET_CLIENT_INVOICES_PAGE',
  IncreasePage = '@@ClientInvoice/INCREASE_CLIENT_INVOICES',
  SetOrderColumn = '@@ClientInvoice/SET_ORDER_COLUMN',
  SetFilterColumn = '@@ClientInvoice/SET_FILTER_COLUMNS',
  RemoveClientInvoice = '@@ClientInvoice/REMOVE_CLIENT_INVOICE',
  CreateClientInvoice = '@@ClientInvoice/CREATE_CLIENT_INVOICE',
  GetClientInvoice = '@@ClientInvoice/GET_CLIENT_INVOICE',
  UpdateClientInvoice = '@@ClientInvoice/UPDATE_CLIENT_INVOICE',
  GetClients = '@@ClientInvoice/GET_CLIENTS',
  RemoveMultipleClientInvoices = '@@ClientInvoice/REMOVE_MULTIPLE_CLIENT_INVOICES',
}

/* ACTIONS */
export const clientInvoiceGetClientInvoicesActions = createApiActionCreators(
  ClientInvoiceActionTypes.GetClientInvoices
);
export const clientInvoiceSetClientInvoicesPageActions = createActionCreator(
  ClientInvoiceActionTypes.SetClientInvoicesPage
);
export const clientInvoiceIncreasePageActions = createActionCreator(
  ClientInvoiceActionTypes.IncreasePage
);
export const clientInvoiceSetOrderColumnActions = createActionCreator(
  ClientInvoiceActionTypes.SetOrderColumn
);
export const clientInvoiceSetFilterColumnActions = createActionCreator(
  ClientInvoiceActionTypes.SetFilterColumn
);
export const clientInvoiceRemoveClientInvoiceActions = createApiActionCreators(
  ClientInvoiceActionTypes.RemoveClientInvoice
);

export const clientInvoiceCreateClientInvoiceActions = createApiActionCreators(
  ClientInvoiceActionTypes.CreateClientInvoice
);
export const clientInvoiceUpdateClientInvoiceActions = createApiActionCreators(
  ClientInvoiceActionTypes.UpdateClientInvoice
);
export const clientInvoiceGetClientInvoiceActions = createApiActionCreators(
  ClientInvoiceActionTypes.GetClientInvoice
);

export const clientInvoiceGetClientsActions = createApiActionCreators(
  ClientInvoiceActionTypes.GetClients
);

export const clientInvoiceRemoveMultipleClientInvoicesActions = createApiActionCreators(
  ClientInvoiceActionTypes.RemoveMultipleClientInvoices
);
