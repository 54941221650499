import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { AdminContract } from '../../models/Contract';
import { ITableReq } from '../../models/Table';

export const api = {
  getContracts: ({ pagination, order, filter }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/signed-contracts', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  getAdminContracts: ({ pagination, order, filter }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/admin/signed-contracts', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  setPrimaryContract: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> => {
    return apiClient.put(`/signed-contracts/${id}`, { default: true });
  },

  setPrimaryBankAccount: (payload: {
    id: number;
    bankAccountId: number;
  }): Promise<ExtendedAxiosResponse> => {
    return apiClient.put(`/signed-contracts/${payload.id}`, toSnakeCase(payload));
  },

  getBankAccounts: (payload: { companyId?: number }): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/bank-accounts', {
      params: toSnakeCase({
        ...payload,
        resultsPerPage: 30,
      }),
    });
  },

  createContract: (payload: {
    contractType: string;
    addressId: number;
    bankAccountId: number;
    companyId?: number;
  }): Promise<ExtendedAxiosResponse> => {
    return apiClient.post('/sign-contract', toSnakeCase(payload));
  },

  generateContractDoc: (payload: {
    contractType: string;
    addressId: number;
    bankAccountId: number;
    companyId?: number;
  }): Promise<ExtendedAxiosResponse> => {
    return apiClient.post(
      '/generate-document',
      toSnakeCase({
        ...payload,
        type: 'html',
      })
    );
  },

  exportContract: ({ id }: Pick<AdminContract, 'id'>) =>
    apiClient.get(`/signed-contracts/${id}`, {
      params: toSnakeCase({ type: 'pdf' }),
      responseType: 'arraybuffer',
    }),

  exportAdminContract: ({ id }: Pick<AdminContract, 'id'>) =>
    apiClient.get(`/admin/signed-contracts/${id}/preview`, {
      params: toSnakeCase({ type: 'pdf' }),
      responseType: 'arraybuffer',
    }),
};
