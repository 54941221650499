import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

export enum ProjectsDataOverviewActionTypes {
  GetProjects = '@@ProjectsDataOverview/GET_PROJECTS',
  SetProject = '@@ProjectsDataOverview/SET_PROJECT',
  GetProjectRespondents = '@@ProjectsDataOverview/GET_PROJECT_RESPONDENTS',
  SetProjectRespondentsPage = '@@ProjectsDataOverview/SET_PROJECT_RESPONDENTS_PAGE',
  IncreasePage = '@@ProjectsDataOverview/INCREASE_PROJECT_RESPONDENTS',
  SetOrderColumn = '@@ProjectsDataOverview/SET_ORDER_COLUMN',
  SetFilterColumn = '@@ProjectsDataOverview/SET_FILTER_COLUMNS',
  CleanData = '@@ProjectsDataOverview/CLEAN_DATA',
}

/* ACTIONS */
export const projectsDataOverviewGetProjectRespondentsActions = createApiActionCreators(
  ProjectsDataOverviewActionTypes.GetProjectRespondents
);
export const projectsDataOverviewSetProjectRespondentsPageActions = createActionCreator(
  ProjectsDataOverviewActionTypes.SetProjectRespondentsPage
);
export const projectsDataOverviewIncreasePageActions = createActionCreator(
  ProjectsDataOverviewActionTypes.IncreasePage
);
export const projectsDataOverviewSetOrderColumnActions = createActionCreator(
  ProjectsDataOverviewActionTypes.SetOrderColumn
);
export const projectsDataOverviewSetFilterColumnActions = createActionCreator(
  ProjectsDataOverviewActionTypes.SetFilterColumn
);

export const projectsDataOverviewGetProjects = createApiActionCreators(
  ProjectsDataOverviewActionTypes.GetProjects
);

export const projectsDataOverviewSetProject = createActionCreator(
  ProjectsDataOverviewActionTypes.SetProject
);

export const projectsDataOverviewCleanData = createActionCreator(
  ProjectsDataOverviewActionTypes.CleanData
);
