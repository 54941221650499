import React from 'react';
import { Link } from 'react-router-dom';

import useBreadcrumbs from 'use-react-router-breadcrumbs';

import routes from '../../../helpers/route/routes/app-routes';

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <nav className={'hidden md:block bg-gray-25 font-sans'}>
      <div className={'container xl:max-w-screen-xl mx-auto text-xs p-4 content'}>
        <ol className={'flex'}>
          {breadcrumbs.map(({ match, breadcrumb }, index) => {
            return (
              <li
                className={
                  'after:content-["/"] last:after:content-none font-bold last:font-normal last:cursor-default last:pointer-events-none pr-1'
                }
                key={index}
              >
                <Link className={'pr-1'} to={match.pathname}>
                  {breadcrumb}
                </Link>
              </li>
            );
          })}
        </ol>
      </div>
    </nav>
  );
};

export default Breadcrumbs;
