import { omit } from 'lodash';
import { combineReducers } from 'redux';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { ClientInvoice } from '../../models/ClientInvoice';
import { ClientProtocolProtocol } from '../../models/ClientProtocol';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { selectClientsDataOverviewClientInvoice } from '../clientsDataOverview/selectors';
import { toastCreateErrorActions } from '../toast/actions';
import { ClientProtocolActionTypes, clientProtocolGetProtocolsActions } from './actions';
import { api } from './api';
import {
  selectClientProtocolProtocolsFilterColumn,
  selectClientProtocolProtocolsOrderColumn,
  selectClientProtocolProtocolsPagination,
} from './selectors';

/* STATE */
export interface ClientProtocolState {
  protocols: ClientProtocolProtocol[];
  protocolsPagination: Pages;
  protocolsStatus: LoadingStatus;
  protocolsOrder: ITableOrder;
  protocolsFilter: Record<string, ISearch>;
  summary: number;
}

/* REDUCERS */
const initialState: ClientProtocolState = {
  protocols: [],
  protocolsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  protocolsStatus: LoadingStatus.initial,
  protocolsOrder: {
    sort: '',
    order: IOrderStatus.DESC,
  },
  protocolsFilter: {},
  summary: 0,
};

const protocols = createReducer(initialState.protocols, {
  [ClientProtocolActionTypes.GetProtocols]: {
    [RequestActionTypes.SUCCESS]: (
      state: ClientProtocolProtocol[],
      payload: PaginatedResp<ClientProtocolProtocol>
    ) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.protocols,
  },
  [ClientProtocolActionTypes.CleanData]: () => initialState.protocols,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.protocols,
  },
});

const protocolsPagination = createReducer(initialState.protocolsPagination, {
  [ClientProtocolActionTypes.GetProtocols]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<ClientProtocolProtocol>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.protocolsPagination,
  },
  [ClientProtocolActionTypes.SetProtocolsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [ClientProtocolActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [ClientProtocolActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ClientProtocolActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ClientProtocolActionTypes.CleanData]: () => initialState.protocolsPagination,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.protocolsPagination,
  },
});

const protocolsStatus = createLoadingStateReducer(
  initialState.protocolsStatus,
  {
    [ClientProtocolActionTypes.GetProtocols]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ClientProtocolActionTypes.SetProtocolsPage]: () => initialState.protocolsStatus,
    [ClientProtocolActionTypes.IncreasePage]: () => initialState.protocolsStatus,
    [ClientProtocolActionTypes.SetFilterColumn]: () => initialState.protocolsStatus,
    [ClientProtocolActionTypes.SetOrderColumn]: () => initialState.protocolsStatus,
    [ClientProtocolActionTypes.CleanData]: () => initialState.protocolsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.protocolsStatus,
    },
  }
);

const protocolsOrder = createReducer(initialState.protocolsOrder, {
  [ClientProtocolActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [ClientProtocolActionTypes.CleanData]: () => initialState.protocolsOrder,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.protocolsOrder,
  },
});

const protocolsFilter = createReducer(initialState.protocolsFilter, {
  [ClientProtocolActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [ClientProtocolActionTypes.CleanData]: () => initialState.protocolsFilter,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.protocolsFilter,
  },
});

const summary = createReducer(initialState.summary, {
  [ClientProtocolActionTypes.GetProtocols]: {
    [RequestActionTypes.SUCCESS]: (
      state: number,
      payload: {
        summary: {
          surveysCount: number;
        };
      } & PaginatedResp<ClientProtocolProtocol>
    ) => {
      if (isNaN(parseInt(`${payload?.summary?.surveysCount}`))) {
        return state;
      }
      if (payload?.currentPage == 1) {
        return parseInt(`${payload?.summary?.surveysCount}`);
      } else {
        return state + parseInt(`${payload?.summary?.surveysCount}`);
      }
    },
  },
  [ClientProtocolActionTypes.CleanData]: () => {
    return initialState.summary;
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.summary,
  },
});

export default combineReducers<ClientProtocolState>({
  protocols,
  protocolsPagination,
  protocolsStatus,
  protocolsOrder,
  protocolsFilter,
  summary,
});

/* SAGAS */
function* getClientProtocolProtocols() {
  const pagination: Pages = yield select(selectClientProtocolProtocolsPagination);
  const orderColumn: ITableOrder = yield select(selectClientProtocolProtocolsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(
    selectClientProtocolProtocolsFilterColumn
  );

  const invoice: ClientInvoice = yield select(selectClientsDataOverviewClientInvoice);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getProtocols, {
    id: `${invoice?.id}` ?? '',
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(clientProtocolGetProtocolsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(clientProtocolGetProtocolsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* clientProtocolSaga() {
  yield takeLatest(
    [
      createActionType(ClientProtocolActionTypes.GetProtocols, RequestActionTypes.REQUEST),
      ClientProtocolActionTypes.SetProtocolsPage,
      ClientProtocolActionTypes.IncreasePage,
      ClientProtocolActionTypes.SetOrderColumn,
      ClientProtocolActionTypes.SetFilterColumn,
    ],
    getClientProtocolProtocols
  );
}
