import { lazy } from 'react';

import {
  BreadcrumbCityDetail,
  BreadcrumbCreateProject,
  BreadcrumbDistrictDetail,
  BreadcrumbInvoiceCompanyDetail,
  BreadcrumbProjectDetail,
  BreadcrumbRegionDetail,
  BreadcrumbUserDetail,
} from '../components/SubBreadcrumbs/SubBreadcrumbs';
import Translate from '../components/Translate/Translate';
import { IRoute, Permission } from '../route';

export enum SettingsRoutes {
  ManageUsers = 'manage-users',
  ManageUserDetail = 'manage-users/:userId',
  ManageUserCreate = 'manage-users/create',
  ManageInvoiceCompanies = 'manage-invoice-companies',
  InvoiceCompanyDetail = 'manage-invoice-companies/:companyId',
  InvoiceCompanyCreate = 'manage-invoice-companies/create',
  ManageRegions = 'manage-regions',
  RegionsDetail = 'manage-regions/:regionId',
  RegionsCreate = 'manage-regions/create',
  ManageDistricts = 'manage-districts',
  DistrictDetail = 'manage-districts/:districtId',
  DistrictCreate = 'manage-districts/create',
  ManageCities = 'manage-cities',
  CityDetail = 'manage-cities/:cityId',
  CityCreate = 'manage-cities/create',
  Projects = 'projects',
  ProjectCreate = 'projects/create/:step',
  ProjectDetailRespondents = 'projects/:projectId/respondent',
  ProjectDetail = 'projects/:projectId/:step',
  ProjectDetailWinners = 'projects/:projectId/winning-users',
  ManageBanks = 'manage-banks',
  BankDetail = 'manage-banks/:bankId',
  BankCreate = 'manage-banks/create',
}

const ManageUsers = lazy(() => import('../../../views/pages/ManageUsers/ManageUsers'));
const ManageUserCreate = lazy(
  () => import('../../../views/pages/ManageUsers/ManageUserCreate/ManageUserCreate')
);
const ManageUserDetail = lazy(
  () => import('../../../views/pages/ManageUsers/ManageUserDetail/ManageUserDetail')
);
const CreateProject = lazy(
  () => import('../../../views/pages/Projects/CreateProject/CreateProject')
);
const ProjectDetail = lazy(
  () => import('../../../views/pages/Projects/ProjectDetail/ProjectDetail')
);
const InvoiceCompanies = lazy(
  () => import('../../../views/pages/InvoiceCompanies/InvoiceCompanies')
);
const InvoiceCompanyDetail = lazy(
  () => import('../../../views/pages/InvoiceCompanies/InvoiceCompanyDetail/InvoiceCompanyDetail')
);
const InvoiceCompanyCreate = lazy(
  () => import('../../../views/pages/InvoiceCompanies/InvoiceCompanyCreate/InvoiceCompanyCreate')
);
const Region = lazy(() => import('../../../views/pages/Region/Region'));
const RegionDetail = lazy(() => import('../../../views/pages/Region/RegionDetail/RegionDetail'));
const RegionCreate = lazy(() => import('../../../views/pages/Region/RegionCreate/RegionCreate'));
const District = lazy(() => import('../../../views/pages/District/District'));
const DistrictDetail = lazy(
  () => import('../../../views/pages/District/DistrictDetail/DistrictDetail')
);
const DistrictCreate = lazy(
  () => import('../../../views/pages/District/DistrictCreate/DistrictCreate')
);
const City = lazy(() => import('../../../views/pages/City/City'));
const CityDetail = lazy(() => import('../../../views/pages/City/CityDetail/CityDetail'));
const CityCreate = lazy(() => import('../../../views/pages/City/CityCreate/CityCreate'));
const Projects = lazy(() => import('../../../views/pages/Projects/Projects'));
const ProjectRespondents = lazy(
  () => import('../../../views/pages/ProjectRespondents/ProjectRespondents')
);
const Bank = lazy(() => import('../../../views/pages/Bank/Bank'));
const BankDetail = lazy(() => import('../../../views/pages/Bank/BankDetail/BankDetail'));
const BankCreate = lazy(() => import('../../../views/pages/Bank/BankCreate/BankCreate'));
const Winners = lazy(() => import('../../../views/pages/Winners/Winners'));

const settingsRoutes: IRoute[] = [
  {
    path: SettingsRoutes.ManageUsers,
    element: <ManageUsers />,
    permissions: [Permission.adminRead],
    breadcrumb: Translate,
    props: { translate: 'pages.users.header' },
  },
  {
    path: SettingsRoutes.ManageUserDetail,
    element: <ManageUserDetail />,
    permissions: [Permission.adminRead],
    breadcrumb: BreadcrumbUserDetail,
  },
  {
    path: SettingsRoutes.ManageUserCreate,
    element: <ManageUserCreate />,
    permissions: [Permission.adminCreate],
    breadcrumb: Translate,
    props: { translate: 'pages.usersCreate.header' },
  },
  {
    path: SettingsRoutes.Projects,
    element: <Projects />,
    permissions: [Permission.projectRead],
    breadcrumb: Translate,
    props: { translate: 'pages.projects.header' },
  },
  {
    path: 'projects/create',
    breadcrumb: null,
  },
  {
    path: SettingsRoutes.ProjectCreate,
    element: <CreateProject />,
    permissions: [Permission.projectCreate],
    breadcrumb: BreadcrumbCreateProject,
  },
  {
    path: 'projects/:projectId',
    breadcrumb: null,
  },
  {
    path: SettingsRoutes.ProjectDetail,
    element: <ProjectDetail />,
    permissions: [Permission.projectRead],
    breadcrumb: BreadcrumbProjectDetail,
  },
  {
    path: SettingsRoutes.ProjectDetailWinners,
    element: <Winners />,
    permissions: [Permission.projectRead],
    breadcrumb: Translate,
    props: { translate: 'pages.winners.header' },
  },
  {
    path: SettingsRoutes.ManageInvoiceCompanies,
    element: <InvoiceCompanies />,
    breadcrumb: Translate,
    permissions: [Permission.invoicingEntityRead],
    props: { translate: 'pages.invoiceCompanies.header' },
  },
  {
    path: SettingsRoutes.InvoiceCompanyDetail,
    element: <InvoiceCompanyDetail />,
    permissions: [Permission.invoicingEntityRead],
    breadcrumb: BreadcrumbInvoiceCompanyDetail,
  },
  {
    path: SettingsRoutes.InvoiceCompanyCreate,
    element: <InvoiceCompanyCreate />,
    permissions: [Permission.invoicingEntityCreate],
    breadcrumb: Translate,
    props: { translate: 'pages.invoiceCompanyCreate.header' },
  },
  {
    path: SettingsRoutes.ManageRegions,
    element: <Region />,
    permissions: [Permission.territoryRead],
    breadcrumb: Translate,
    props: { translate: 'pages.regions.header' },
  },
  {
    path: SettingsRoutes.RegionsDetail,
    element: <RegionDetail />,
    permissions: [Permission.territoryRead],
    breadcrumb: BreadcrumbRegionDetail,
  },
  {
    path: SettingsRoutes.RegionsCreate,
    element: <RegionCreate />,
    permissions: [Permission.territoryCreate],
    breadcrumb: Translate,
    props: { translate: 'pages.regionsCreate.header' },
  },
  {
    path: SettingsRoutes.ManageDistricts,
    element: <District />,
    permissions: [Permission.territoryRead],
    breadcrumb: Translate,
    props: { translate: 'pages.districts.header' },
  },
  {
    path: SettingsRoutes.DistrictDetail,
    element: <DistrictDetail />,
    permissions: [Permission.territoryRead],
    breadcrumb: BreadcrumbDistrictDetail,
  },
  {
    path: SettingsRoutes.DistrictCreate,
    element: <DistrictCreate />,
    permissions: [Permission.territoryCreate],
    breadcrumb: Translate,
    props: { translate: 'pages.districtsCreate.header' },
  },
  {
    path: SettingsRoutes.ManageCities,
    element: <City />,
    permissions: [Permission.territoryRead],
    breadcrumb: Translate,
    props: { translate: 'pages.cities.header' },
  },
  {
    path: SettingsRoutes.CityDetail,
    element: <CityDetail />,
    permissions: [Permission.territoryRead],
    breadcrumb: BreadcrumbCityDetail,
  },
  {
    path: SettingsRoutes.CityCreate,
    element: <CityCreate />,
    permissions: [Permission.territoryCreate],
    breadcrumb: Translate,
    props: { translate: 'pages.citiesCreate.header' },
  },
  {
    path: SettingsRoutes.ProjectDetailRespondents,
    element: <ProjectRespondents />,
    permissions: [Permission.projectRead],
    breadcrumb: Translate,
    props: { translate: 'pages.projectRespondents.header' },
  },
  {
    path: SettingsRoutes.ManageBanks,
    element: <Bank />,
    permissions: [Permission.banksRead],
    breadcrumb: Translate,
    props: { translate: 'pages.banks.header' },
  },
  {
    path: SettingsRoutes.BankDetail,
    element: <BankDetail />,
    permissions: [Permission.banksRead],
    breadcrumb: Translate,
    props: { translate: 'pages.banksDetail.header' },
  },
  {
    path: SettingsRoutes.BankCreate,
    element: <BankCreate />,
    permissions: [Permission.banksCreate],
    breadcrumb: Translate,
    props: { translate: 'pages.banksCreate.header' },
  },
];

export default settingsRoutes;
