import { lazy } from 'react';

import { IRoute } from '../route';

export enum AuthRoutes {
  Login = 'login',
  Register = 'register',
  ForgotPassword = 'forgot-password',
  ResetPasswordSetByAdmin = 'set-password',
}

const Login = lazy(() => import('../../../views/pages/Auth/Login/Login'));
const Register = lazy(() => import('../../../views/pages/Auth/Register/Register'));
const ForgotPassword = lazy(
  () => import('../../../views/pages/Auth/ForgotPassword/ForgotPassword')
);
const ResetPasswordSetByAdmin = lazy(
  () => import('../../../views/pages/Auth/ResetPasswordSetByAdmin/ResetPasswordSetByAdmin')
);

const authRoutes: IRoute[] = [
  {
    path: AuthRoutes.Login,
    element: <Login />,
  },
  {
    path: AuthRoutes.Register,
    element: <Register />,
  },
  {
    path: AuthRoutes.ForgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: AuthRoutes.ResetPasswordSetByAdmin,
    element: <ResetPasswordSetByAdmin />,
  },
];

export default authRoutes;
