import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { Profession } from '../../models/User';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions } from '../toast/actions';
import { ProfessionActionTypes, professionGetProfessionsActions } from './actions';
import { api } from './api';
import {
  selectProfessionProfessionsFilterColumn,
  selectProfessionProfessionsOrderColumn,
  selectProfessionProfessionsPagination,
} from './selectors';

export interface ProfessionState {
  professions: Profession[];
  professionsPagination: Pages;
  professionsStatus: LoadingStatus;
  professionsOrder: ITableOrder;
  professionsFilter: Record<string, ISearch>;
}

const initialState: ProfessionState = {
  professions: [],
  professionsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  professionsStatus: LoadingStatus.initial,
  professionsOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  professionsFilter: {},
};

const professions = createReducer(initialState.professions, {
  [ProfessionActionTypes.GetProfessions]: {
    [RequestActionTypes.SUCCESS]: (state: Profession[], payload: PaginatedResp<Profession>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.professions,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.professions,
  },
});

const professionsPagination = createReducer(initialState.professionsPagination, {
  [ProfessionActionTypes.GetProfessions]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<Profession>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.professionsPagination,
  },
  [ProfessionActionTypes.SetProfessionsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [ProfessionActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [ProfessionActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ProfessionActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.professionsPagination,
  },
});

const professionsStatus = createLoadingStateReducer(
  initialState.professionsStatus,
  {
    [ProfessionActionTypes.GetProfessions]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ProfessionActionTypes.SetProfessionsPage]: () => initialState.professionsStatus,
    [ProfessionActionTypes.IncreasePage]: () => initialState.professionsStatus,
    [ProfessionActionTypes.SetFilterColumn]: () => initialState.professionsStatus,
    [ProfessionActionTypes.SetOrderColumn]: () => initialState.professionsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.professionsStatus,
    },
  }
);

const professionsOrder = createReducer(initialState.professionsOrder, {
  [ProfessionActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.professionsOrder,
  },
});

const professionsFilter = createReducer(initialState.professionsFilter, {
  [ProfessionActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.professionsFilter,
  },
});

export default combineReducers<ProfessionState>({
  professions,
  professionsPagination,
  professionsStatus,
  professionsOrder,
  professionsFilter,
});

function* GetProfessions() {
  const pagination: Pages = yield select(selectProfessionProfessionsPagination);
  const orderColumn: ITableOrder = yield select(selectProfessionProfessionsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(
    selectProfessionProfessionsFilterColumn
  );

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getProfessions, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(professionGetProfessionsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(professionGetProfessionsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

export function* professionSaga() {
  yield takeLatest(
    [
      createActionType(ProfessionActionTypes.GetProfessions, RequestActionTypes.REQUEST),
      ProfessionActionTypes.SetProfessionsPage,
      ProfessionActionTypes.IncreasePage,
      ProfessionActionTypes.SetOrderColumn,
      ProfessionActionTypes.SetFilterColumn,
    ],
    GetProfessions
  );
}
