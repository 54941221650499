import { Location } from 'history';
import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export interface CompanyPayload {
  id?: number;
  respondentId?: number;
  dic: string;
  icdph: string;
  ico: string;
  name: string;
  holdingTax: boolean;
  ownInvoice: boolean;
  userId: number;
  location?: Location;
}

export interface RemoveCompanyPayload {
  id: number;
  userId?: number;
}

export const api = {
  getCompanies: ({ pagination, order, filter }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/company', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  removeCompany: ({ id, userId }: RemoveCompanyPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/company/${id}`, {
      params: toSnakeCase({
        userId,
      }),
    }),

  getBankAccounts: (payload: { companyId?: number }): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/bank-accounts', {
      params: toSnakeCase({
        ...payload,
        resultsPerPage: 30,
      }),
    });
  },

  getRespondents: (payload: { search: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/admin/respondents-dropdown', {
      params: toSnakeCase({
        search: `respondent_name:like:${payload.search}`,
        resultsPerPage: 200,
      }),
    }),

  getCompany: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/company/${id}`),

  updateCompany: (payload: CompanyPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(
      `/company/${payload?.id}`,
      toSnakeCase({
        ...payload,
        typeName: 'Firma',
      })
    ),

  createCompany: (payload: CompanyPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post(
      '/company',
      toSnakeCase({
        ...payload,
        typeName: 'Firma',
      })
    ),
};
