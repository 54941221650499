import { combineReducers } from 'redux';

import { createReducer, RequestActionTypes } from '../../helpers/redux/redux-helpers';
import { AddressActionTypes } from '../address/actions';
import { AuthActionTypes } from '../auth/actions';
import { BankAccountActionTypes } from '../bankAccount/actions';
import { CityActionTypes } from '../city/actions';
import { ClientActionTypes } from '../client/actions';
import { CompanyActionTypes } from '../company/actions';
import { DistrictActionTypes } from '../district/actions';
import { ProjectActionTypes } from '../project/actions';
import { RespondentActionTypes } from '../respondent/actions';
import { UserActionTypes } from '../user/actions';
import { ErrorActionType } from './actions';

/* STATE */
export interface ErrorState {
  errors: any | null;
}

/* REDUCERS */
const initialState: ErrorState = {
  errors: null,
};

const errors = createReducer(initialState.errors, {
  [ErrorActionType.ClearErrors]: {
    [RequestActionTypes.REQUEST]: () => initialState.errors,
  },
  [AuthActionTypes.Login]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [AuthActionTypes.Register]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [AuthActionTypes.RequestPasswordReset]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [AuthActionTypes.ResetPassword]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [AuthActionTypes.ResetPasswordAuthenticated]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [UserActionTypes.CreateUser]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [UserActionTypes.UpdateUserProfile]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [ProjectActionTypes.CreateProject]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [AddressActionTypes.CreateAddress]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [AddressActionTypes.UpdateAddress]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [BankAccountActionTypes.CreateBankAccount]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [BankAccountActionTypes.UpdateBankAccount]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [CityActionTypes.CreateCity]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [CityActionTypes.UpdateCity]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [ClientActionTypes.CreateClient]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [ClientActionTypes.UpdateClient]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [CompanyActionTypes.CreateCompany]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [CompanyActionTypes.UpdateCompany]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [DistrictActionTypes.CreateDistrict]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [DistrictActionTypes.UpdateDistrict]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [RespondentActionTypes.CreateRespondent]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [RespondentActionTypes.UpdateRespondent]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [ProjectActionTypes.SaveQuestionWithBlock]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [ProjectActionTypes.SaveQuestionWithExistingBlock]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
  [ProjectActionTypes.UpdateBlock]: {
    [RequestActionTypes.FAILURE]: (state: any | null, payload: any) => payload || null,
    [RequestActionTypes.SUCCESS]: () => initialState.errors,
  },
});

export default combineReducers<ErrorState>({
  errors,
});

/* SAGAS */

/* EXPORT */
export function* errorSaga() {}
