import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  SpecActionTypes,
  specCreateSpecActions,
  specGetSpecsActions,
  specRemoveSpecActions,
} from './actions';
import { api, CreateSpecPayload, RemoveSpecPayload } from './api';
import {
  selectSpecSpecsFilterColumn,
  selectSpecSpecsOrderColumn,
  selectSpecSpecsPagination,
} from './selectors';

export interface Spec {
  id: number;
  name: string;
  code: string;
}

export interface SpecState {
  specs: Spec[];
  specsPagination: Pages;
  specsStatus: LoadingStatus;
  specsOrder: ITableOrder;
  specsFilter: Record<string, ISearch>;
  specActionStatus: LoadingStatus;
}

const initialState: SpecState = {
  specs: [],
  specsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  specsStatus: LoadingStatus.initial,
  specsOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  specsFilter: {},
  specActionStatus: LoadingStatus.initial,
};

const specs = createReducer(initialState.specs, {
  [SpecActionTypes.GetSpecs]: {
    [RequestActionTypes.SUCCESS]: (state: Spec[], payload: PaginatedResp<Spec>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.specs,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.specs,
  },
});

const specsPagination = createReducer(initialState.specsPagination, {
  [SpecActionTypes.GetSpecs]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<Spec>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.specsPagination,
  },
  [SpecActionTypes.SetSpecsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [SpecActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [SpecActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [SpecActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.specsPagination,
  },
});

const specsStatus = createLoadingStateReducer(
  initialState.specsStatus,
  {
    [SpecActionTypes.GetSpecs]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [SpecActionTypes.SetSpecsPage]: () => initialState.specsStatus,
    [SpecActionTypes.IncreasePage]: () => initialState.specsStatus,
    [SpecActionTypes.SetFilterColumn]: () => initialState.specsStatus,
    [SpecActionTypes.SetOrderColumn]: () => initialState.specsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.specsStatus,
    },
  }
);

const specsOrder = createReducer(initialState.specsOrder, {
  [SpecActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.specsOrder,
  },
});

const specsFilter = createReducer(initialState.specsFilter, {
  [SpecActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.specsFilter,
  },
});

const specActionStatus = createLoadingStateReducer(
  initialState.specActionStatus,
  {
    [SpecActionTypes.CreateSpec]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [SpecActionTypes.RemoveSpec]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.specActionStatus,
    },
  }
);

export default combineReducers<SpecState>({
  specs,
  specsPagination,
  specsStatus,
  specsOrder,
  specsFilter,
  specActionStatus,
});

function* getSpecs() {
  const pagination: Pages = yield select(selectSpecSpecsPagination);
  const orderColumn: ITableOrder = yield select(selectSpecSpecsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(selectSpecSpecsFilterColumn);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getSpecs, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(specGetSpecsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(specGetSpecsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createSpec({ payload }: AppAction<CreateSpecPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.createSpec, payload);

  if (resp.ok) {
    yield put(specCreateSpecActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(specCreateSpecActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeSpec({ payload }: AppAction<RemoveSpecPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeSpec, payload);

  if (resp.ok) {
    yield put(specRemoveSpecActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(specRemoveSpecActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

export function* specSaga() {
  yield takeLatest(
    [
      createActionType(SpecActionTypes.GetSpecs, RequestActionTypes.REQUEST),
      SpecActionTypes.SetSpecsPage,
      SpecActionTypes.IncreasePage,
      SpecActionTypes.SetOrderColumn,
      SpecActionTypes.SetFilterColumn,
    ],
    getSpecs
  );
  yield takeLatest(
    createActionType(SpecActionTypes.CreateSpec, RequestActionTypes.REQUEST),
    createSpec
  );
  yield takeLatest(
    createActionType(SpecActionTypes.RemoveSpec, RequestActionTypes.REQUEST),
    removeSpec
  );
}
