import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export interface GetSpecsPayload extends ITableReq {}

export interface CreateSpecPayload {
  specId: number;
  userId?: number;
}

export interface RemoveSpecPayload {
  id: number;
  userId?: number;
}

export const api = {
  getSpecs: ({ pagination, order, filter }: GetSpecsPayload): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/specs', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },
  createSpec: (payload: CreateSpecPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/specs', toSnakeCase(payload)),
  removeSpec: ({ id, ...other }: RemoveSpecPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/specs/${id}`, {
      data: toSnakeCase(other),
    }),
};
