import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import {
  ForgotPasswordRequest,
  LoginAsRespondentRequest,
  LoginRequest,
  RegisterRequest,
  ResetPasswordAuthenticated,
  ResetPasswordRequest,
  ResetPasswordSetByAdminRequest,
} from '../../models/Auth';

export const api = {
  login: (data: LoginRequest): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/login', {
      ...data,
    }),

  loginAsRespondent: (data: LoginAsRespondentRequest): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/admin/respondents/${data.respondentId}/login`),

  logout: (): Promise<ExtendedAxiosResponse> => apiClient.post('/logout', {}),

  register: (data: RegisterRequest): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/register', {
      ...data,
    }),

  requestPasswordReset: (data: ForgotPasswordRequest): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/send-reset-email', data),

  resetPassword: (data: ResetPasswordRequest): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/reset-password-with-email-token', data),

  resetPasswordAuthenticated: (data: ResetPasswordAuthenticated): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/reset-password-authenticated', data),

  resetPasswordSetByAdmin: (
    data: ResetPasswordSetByAdminRequest
  ): Promise<ExtendedAxiosResponse> => {
    const { userId, ...otherParams } = data.params;
    return apiClient.post(`/user/${userId}/set-password`, toSnakeCase(data.body), {
      params: toSnakeCase(otherParams),
    });
  },

  getProfile: (): Promise<ExtendedAxiosResponse> => apiClient.get('/profile'),

  getChambers: (): Promise<ExtendedAxiosResponse> => apiClient.get('/chambers'),

  getPermissions: (): Promise<ExtendedAxiosResponse> => apiClient.get('/admin/permissions'),

  verify: (): Promise<ExtendedAxiosResponse> => apiClient.get('/test-token'),

  getUserContracts: (): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/signed-contracts', {
      params: toSnakeCase({
        resultsPerPage: 1,
      }),
    }),
};
