import i18n from 'i18next';
import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { SettingsRoutes } from '../../helpers/route/routes/settings-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase, toSnakeCase } from '../../helpers/transformObject';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { Permission, User } from '../../models/User';

import { AuthActionTypes, authLogoutActions } from '../auth/actions';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  UserActionTypes,
  userChangeEmailActions,
  userCreateUserActions,
  userGetUserProfileActions,
  userGetUsersActions,
  userManagePermissionsActions,
  userRemoveUserActions,
  userSendChangeEmailRequestActions,
  userUpdateUserProfileActions,
  userVerifyEmailActions,
} from './actions';
import { api } from './api';
import {
  selectUserUsers,
  selectUserUsersFilterColumn,
  selectUserUsersOrderColumn,
  selectUserUsersPagination,
} from './selectors';

/* STATE */
export interface UserState {
  users: User[] | null;
  usersPagination: Pages;
  usersStatus: LoadingStatus;
  usersOrder: ITableOrder;
  usersFilter: Record<string, ISearch>;
  userProfile: User | null;
  userPermissions: Permission[];
  isEmailVerifiedStatus: LoadingStatus;
  changeEmailStatus: LoadingStatus;
  verifyEmailStatus: LoadingStatus;
  userActionStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: UserState = {
  users: null,
  usersPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  usersStatus: LoadingStatus.initial,
  usersOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  usersFilter: {},
  userProfile: null,
  userPermissions: [],
  isEmailVerifiedStatus: LoadingStatus.initial,
  changeEmailStatus: LoadingStatus.initial,
  verifyEmailStatus: LoadingStatus.initial,
  userActionStatus: LoadingStatus.initial,
};

const users = createReducer(initialState.users, {
  [UserActionTypes.GetUsers]: {
    [RequestActionTypes.SUCCESS]: (state: User[], payload: PaginatedResp<User>) => payload.data,
    [RequestActionTypes.FAILURE]: () => initialState.users,
  },
  [UserActionTypes.RemoveUser]: {
    [RequestActionTypes.SUCCESS]: (state: User[], payload: { id: number }) => {
      return state.filter((user) => user.id !== payload.id);
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.users,
  },
});

const usersPagination = createReducer(initialState.usersPagination, {
  [UserActionTypes.GetUsers]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<User>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.usersPagination,
  },
  [UserActionTypes.SetUsersPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [UserActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [UserActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [UserActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.usersPagination,
  },
});

const usersStatus = createLoadingStateReducer(
  initialState.usersStatus,
  {
    [UserActionTypes.GetUsers]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [UserActionTypes.SetUsersPage]: () => initialState.usersStatus,
    [UserActionTypes.IncreasePage]: () => initialState.usersStatus,
    [UserActionTypes.SetFilterColumn]: () => initialState.usersStatus,
    [UserActionTypes.SetOrderColumn]: () => initialState.usersStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.usersStatus,
    },
  }
);

const usersOrder = createReducer(initialState.usersOrder, {
  [UserActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.usersOrder,
  },
});

const usersFilter = createReducer(initialState.usersFilter, {
  [UserActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.usersFilter,
  },
});

const userProfile = createReducer(initialState.userProfile, {
  [UserActionTypes.GetUserProfile]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => payload,
  },
  [UserActionTypes.UpdateUserProfile]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) =>
      payload?.id && payload.id === state?.id
        ? {
            ...state,
            ...payload,
          }
        : state,
  },
  [UserActionTypes.ClearUser]: () => initialState.userProfile,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.userProfile,
  },
});

const userPermissions = createReducer(initialState.userPermissions, {
  [UserActionTypes.GetUserProfile]: {
    [RequestActionTypes.SUCCESS]: (state: User | null, payload: User) => payload.permissions,
  },
  [UserActionTypes.ClearUser]: () => initialState.userProfile,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.userProfile,
  },
});

const isEmailVerifiedStatus = createLoadingStateReducer(
  initialState.isEmailVerifiedStatus,
  {
    [UserActionTypes.VerifyEmail]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.isEmailVerifiedStatus,
    },
  }
);

const changeEmailStatus = createLoadingStateReducer(
  initialState.changeEmailStatus,
  {
    [UserActionTypes.ChangeEmail]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.changeEmailStatus,
    },
  }
);

const verifyEmailStatus = createLoadingStateReducer(
  initialState.verifyEmailStatus,
  {
    [UserActionTypes.VerifyEmail]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.verifyEmailStatus,
    },
  }
);

const userActionStatus = createLoadingStateReducer(
  initialState.userActionStatus,
  {
    [UserActionTypes.CreateUser]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [UserActionTypes.UpdateUserProfile]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.userActionStatus,
    },
  }
);

export default combineReducers<UserState>({
  users,
  usersPagination,
  usersStatus,
  usersOrder,
  usersFilter,
  userProfile,
  userPermissions,
  isEmailVerifiedStatus,
  changeEmailStatus,
  verifyEmailStatus,
  userActionStatus,
});

/* SAGAS */
function* getUsers() {
  const pagination: Pages = yield select(selectUserUsersPagination);
  const orderColumn: ITableOrder = yield select(selectUserUsersOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(selectUserUsersFilterColumn);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getUsers, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    const data = toCamelCase(resp.data);

    if (data.currentPage <= 1) {
      yield put(userGetUsersActions.success(data));
    } else {
      const prevUsers: User[] = yield select(selectUserUsers);
      yield put(
        userGetUsersActions.success({
          ...data,
          data: [...prevUsers, ...data.data],
        })
      );
    }
  } else {
    yield put(userGetUsersActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeUser({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeUser, payload);

  if (resp.ok) {
    yield put(userRemoveUserActions.success({ id: payload.id }));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(userRemoveUserActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getUserProfile({ payload }: AppAction<{ userId: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getUserProfile, payload);
  if (resp.ok) {
    yield put(userGetUserProfileActions.success(toCamelCase(resp.data)));
  } else {
    yield put(userGetUserProfileActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* managePermissions({ payload }: AppAction<{ userId: string; permissionId: string[] }>) {
  const resp: ExtendedAxiosResponse = yield call(api.managePermission, payload);

  if (resp.ok) {
    yield put(userManagePermissionsActions.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(userManagePermissionsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateUserProfile({
  payload,
}: AppAction<{
  item: {
    firstName: string;
    lastName: string;
    middleName: string;
    titleAfter: string;
    titleBefore: string;
  };
}>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateUserProfile, toSnakeCase(payload));

  if (resp.ok) {
    const data = toCamelCase(resp.data)?.data;

    yield put(userUpdateUserProfileActions.success(data));

    yield put(
      toastCreateSuccessActions(
        resp.data?.message || i18n.t('pages.profile.basicInfoForm.toasts.success')
      )
    );
  } else {
    yield put(userUpdateUserProfileActions.failure(toCamelCase(resp.data)?.errors));
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* sendChangeEmailRequest({ payload }: AppAction<{ userId: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.sendChangeEmailRequest, payload);

  if (resp.ok) {
    yield put(userSendChangeEmailRequestActions.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(userSendChangeEmailRequestActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* changeEmail({
  payload,
}: AppAction<{
  email: string;
  userId: string;
  expires: string;
  signature: string;
}>) {
  const resp: ExtendedAxiosResponse = yield call(api.changeEmail, payload);

  if (resp.ok) {
    yield put(userChangeEmailActions.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(userChangeEmailActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* verifyEmail({
  payload,
}: AppAction<{
  email: string;
  userId: string;
  expires: string;
  signature: string;
}>) {
  const resp: ExtendedAxiosResponse = yield call(api.verifyEmail, payload);

  if (resp.ok) {
    localStorage.removeItem('authToken');
    yield put(authLogoutActions.success());

    yield put(userVerifyEmailActions.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(userVerifyEmailActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createUser({
  payload,
}: AppAction<{
  titleBefore: string;
  firstName: string;
  middleName: string;
  lastName: string;
  titleAfter: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}>) {
  const resp: ExtendedAxiosResponse = yield call(api.createUser, payload);

  if (resp.ok) {
    yield put(userCreateUserActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageUsers]));
  } else {
    yield put(userCreateUserActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* userSaga() {
  yield takeLatest(
    [
      createActionType(UserActionTypes.GetUsers, RequestActionTypes.REQUEST),
      UserActionTypes.SetUsersPage,
      UserActionTypes.IncreasePage,
      UserActionTypes.SetOrderColumn,
      UserActionTypes.SetFilterColumn,
    ],
    getUsers
  );
  yield takeLatest(
    [createActionType(UserActionTypes.RemoveUser, RequestActionTypes.REQUEST)],
    removeUser
  );
  yield takeLatest(
    createActionType(UserActionTypes.GetUserProfile, RequestActionTypes.REQUEST),
    getUserProfile
  );
  yield takeLatest(
    createActionType(UserActionTypes.ManagePermissions, RequestActionTypes.REQUEST),
    managePermissions
  );
  yield takeLatest(
    createActionType(UserActionTypes.UpdateUserProfile, RequestActionTypes.REQUEST),
    updateUserProfile
  );
  yield takeLatest(
    createActionType(UserActionTypes.SendChangeEmailRequest, RequestActionTypes.REQUEST),
    sendChangeEmailRequest
  );
  yield takeLatest(
    createActionType(UserActionTypes.ChangeEmail, RequestActionTypes.REQUEST),
    changeEmail
  );
  yield takeLatest(
    createActionType(UserActionTypes.VerifyEmail, RequestActionTypes.REQUEST),
    verifyEmail
  );
  yield takeLatest(
    createActionType(UserActionTypes.CreateUser, RequestActionTypes.REQUEST),
    createUser
  );
}
