import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export interface HandoverProtocolPayload {
  id?: number;
}

export const api = {
  adminGetHandoverProtocols: ({
    pagination,
    order,
    filter,
  }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/admin/handover-protocols', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  getHandoverProtocols: ({
    pagination,
    order,
    filter,
  }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/handover-protocols', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  adminDownloadPdf: (payload: HandoverProtocolPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/admin/handover-protocols/${payload.id}/download-pdf`, {
      responseType: 'blob',
    }),

  downloadPdf: (payload: HandoverProtocolPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/handover-protocols/${payload.id}/download-pdf`, {
      responseType: 'blob',
    }),
};
