import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum ClientActionTypes {
  GetClients = '@@Client/GET_CLIENTS',
  SetClientsPage = '@@Client/SET_CLIENTS_PAGE',
  IncreasePage = '@@Client/INCREASE_CLIENTS',
  SetOrderColumn = '@@Client/SET_ORDER_COLUMN',
  SetFilterColumn = '@@Client/SET_FILTER_COLUMNS',
  RemoveClient = '@@Client/REMOVE_CLIENT',
  GetBankAccounts = '@@Client/GET_BANK_ACCOUNTS',
  CreateClient = '@@Client/CREATE_CLIENT',
  GetClient = '@@Client/GET_CLIENT',
  UpdateClient = '@@Client/UPDATE_CLIENT',
}

/* ACTIONS */
export const clientGetClientsActions = createApiActionCreators(ClientActionTypes.GetClients);
export const clientSetClientsPageActions = createActionCreator(ClientActionTypes.SetClientsPage);
export const clientIncreasePageActions = createActionCreator(ClientActionTypes.IncreasePage);
export const clientSetOrderColumnActions = createActionCreator(ClientActionTypes.SetOrderColumn);
export const clientSetFilterColumnActions = createActionCreator(ClientActionTypes.SetFilterColumn);

export const clientRemoveClientActions = createApiActionCreators(ClientActionTypes.RemoveClient);
export const clientCreateClientActions = createApiActionCreators(ClientActionTypes.CreateClient);
export const clientGetClientActions = createApiActionCreators(ClientActionTypes.GetClient);
export const clientUpdateClientActions = createApiActionCreators(ClientActionTypes.UpdateClient);

export const clientGetBankAccountsActions = createApiActionCreators(
  ClientActionTypes.GetBankAccounts
);
