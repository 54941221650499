import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { NonProjectHandoverProtocol } from '../../models/NonProjectHandoverProtocol';
import { ITableReq } from '../../models/Table';

export interface GetRespondentsCompaniesPayload {
  search: string;
}

export interface GetRespondentCompanyDataPayload {
  id: string;
  isCompany: boolean;
}

export interface DownloadZIPNPPPayload {
  id?: number;
}

export type CreateNPPPayload = Pick<
  NonProjectHandoverProtocol,
  | 'userId'
  | 'companyId'
  | 'signedContractId'
  | 'price'
  | 'delivered'
  | 'withholdingTax'
  | 'start'
  | 'end'
  | 'workDescription'
> & { [file: string]: File } & FormData;

export type UpdateNPPPayload = Pick<
  NonProjectHandoverProtocol,
  'id' | 'userId' | 'companyId' | 'price' | 'delivered' | 'withholdingTax' | 'workDescription'
> & {
  signedContractId: string;
  start: Date | string;
  end: Date | string;
};

export interface RemoveNPPsPayload {
  nonProjectHandoverProtocolsArray: number[];
}

export interface GetSignedContractsPayload {
  search: string;
  entityId: string;
  isCompany: boolean;
}

export interface UploadFilesPayload {
  id: number;
  files: FormData;
}

export interface RemoveFilePayload {
  id: number;
}

export const api = {
  getNonProjectHandoverProtocols: ({
    pagination,
    order,
    filter,
  }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/admin/non-project-handover-protocols', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  downloadZip: (payload: DownloadZIPNPPPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/admin/non-project-handover-protocols/${payload.id}/files`, {
      responseType: 'blob',
    }),

  getRespondentsCompanies: (
    payload: GetRespondentsCompaniesPayload
  ): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/admin/respondents-companies', {
      params: toSnakeCase({
        search: `name:like:${payload.search?.trim()}`,
        resultsPerPage: 10,
      }),
    });
  },

  getRespondentData: (payload: GetRespondentCompanyDataPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/admin/users/${payload.id}`),

  getCompanydata: (payload: GetRespondentCompanyDataPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/company/${payload.id}`),

  getNonProjectHandoverProtocol: (
    payload: Pick<NonProjectHandoverProtocol, 'id'>
  ): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/admin/non-project-handover-protocols/${payload.id}`),

  createNonProjectHandoverProtocol: (payload: CreateNPPPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/admin/non-project-handover-protocols', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  updateNonProjectHandoverProtocol: (payload: UpdateNPPPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(`/admin/non-project-handover-protocols/${payload.id}`, toSnakeCase(payload)),

  removeNonProjectHandoverProtocols: (payload: RemoveNPPsPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.delete('/admin/non-project-handover-protocols', { data: toSnakeCase(payload) }),

  getSignedContracts: (payload: GetSignedContractsPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/admin/signed-contracts', {
      params: toSnakeCase({
        search: `id:like:${payload.search},${payload.isCompany ? 'company_id' : 'user_id'}:=:${
          payload.entityId
        },state:like:1`,
        resultsPerPage: 10,
      }),
    }),

  uploadFiles: (payload: UploadFilesPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/admin/non-project-handover-protocols/${payload.id}/files`, payload.files, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  removeFile: (payload: RemoveFilePayload): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/admin/non-project-handover-protocols/files/${payload.id}`),
};
