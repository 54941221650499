import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { ContactType } from '../../models/User';
import { ContactState } from './ducks';

/* SELECTORS */
const selectContact = (state: AppState): ContactState => state.contact;

export const selectContactGetContacts = (state: AppState): ContactType[] =>
  selectContact(state).contacts;

export const selectContactContactsPagination = (state: AppState): Pages =>
  selectContact(state).contactsPagination;

export const selectContactContactsStatus = (state: AppState): LoadingStatus =>
  selectContact(state).contactsStatus;

export const selectContactContactsOrderColumn = (state: AppState): ITableOrder =>
  selectContact(state).contactsOrder;

export const selectContactContactsFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectContact(state).contactsFilter;

export const selectContactContactActionStatus = (state: AppState): LoadingStatus =>
  selectContact(state).contactActionStatus;
