import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { WinningUser } from '../../models/User';
import { WinnerState } from './ducks';

/* SELECTORS */
const selectWinner = (state: AppState): WinnerState => state.winner;

export const selectWinnerWinners = (state: AppState): WinningUser[] => selectWinner(state).winners;

export const selectWinnerWinnersPagination = (state: AppState): Pages =>
  selectWinner(state).winnersPagination;

export const selectWinnerWinnersStatus = (state: AppState): LoadingStatus =>
  selectWinner(state).winnersStatus;

export const selectWinnerWinnersOrderColumn = (state: AppState): ITableOrder =>
  selectWinner(state).winnersOrder;

export const selectWinnerWinnersFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectWinner(state).winnersFilter;
