/* SELECTORS */

import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Pages } from '../../models/Pages';
import { IProject } from '../../models/Project';
import { ProjectsDataOverviewProjectRespondent } from '../../models/ProjectsDataOverview';
import { ISearch, ITableOrder } from '../../models/Table';
import { ProjectsDataOverviewSummary } from './ducks';

const selectProjectsDataOverview = (state: AppState) => state.projectsDataOverview;

export const selectProjectsDataOverviewProjects = (state: AppState): IProject[] =>
  selectProjectsDataOverview(state).projects;

export const selectProjectsDataOverviewProjectsStatus = (state: AppState): LoadingStatus =>
  selectProjectsDataOverview(state).projectsStatus;

export const selectProjectsDataOverviewProject = (state: AppState) =>
  selectProjectsDataOverview(state).project;

export const selectProjectsDataOverviewProjectRespondents = (
  state: AppState
): ProjectsDataOverviewProjectRespondent[] => selectProjectsDataOverview(state).projectRespondents;

export const selectProjectsDataOverviewProjectRespondentsPagination = (state: AppState): Pages =>
  selectProjectsDataOverview(state).projectRespondentsPagination;

export const selectProjectsDataOverviewProjectRespondentsStatus = (
  state: AppState
): LoadingStatus => selectProjectsDataOverview(state).projectRespondentsStatus;

export const selectProjectsDataOverviewProjectRespondentsOrderColumn = (
  state: AppState
): ITableOrder => selectProjectsDataOverview(state).projectRespondentsOrder;

export const selectProjectsDataOverviewProjectRespondentsFilterColumn = (
  state: AppState
): Record<string, ISearch> => selectProjectsDataOverview(state).projectRespondentsFilter;

export const selectProjectsDataOverviewSummary = (state: AppState): ProjectsDataOverviewSummary =>
  selectProjectsDataOverview(state).summary;
