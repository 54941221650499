import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { HandoverProtocol } from '../../models/HandoverProtocol';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { HandoverProtocolState } from './ducks';

/* SELECTORS */
const selectHandoverProtocol = (state: AppState): HandoverProtocolState => state.handoverProtocol;

export const selectHandoverProtocolProtocols = (state: AppState): HandoverProtocol[] =>
  selectHandoverProtocol(state).handoverProtocols;

export const selectHandoverProtocolProtocolsPagination = (state: AppState): Pages =>
  selectHandoverProtocol(state).handoverProtocolsPagination;

export const selectHandoverProtocolProtocolsStatus = (state: AppState): LoadingStatus =>
  selectHandoverProtocol(state).handoverProtocolsStatus;

export const selectHandoverProtocolProtocolsOrderColumn = (state: AppState): ITableOrder =>
  selectHandoverProtocol(state).handoverProtocolsOrder;

export const selectHandoverProtocolProtocolsFilterColumn = (
  state: AppState
): Record<string, ISearch> => selectHandoverProtocol(state).handoverProtocolsFilter;
