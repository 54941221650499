import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as LogoBlack } from '../../../assets/logo-black.svg';
import { ReactComponent as LogoWhite } from '../../../assets/logo-white.svg';
import { buildRoute } from '../../../helpers/route/route-builder';
import { AdminRoutes } from '../../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../../helpers/route/routes/app-routes';
import { SettingsRoutes } from '../../../helpers/route/routes/settings-routes';
import { selectAuthHasSignedContract, selectAuthUser } from '../../../saga/auth/selectors';

interface IAppLogo {
  color: 'white' | 'black';
}

const AppLogo = ({ color }: IAppLogo) => {
  const user = useSelector(selectAuthUser);
  const hasSignedContract = useSelector(selectAuthHasSignedContract);

  const homeRoute = useMemo(() => {
    if (!user) return buildRoute(AppRoutes.Home);
    if (!user?.tanzanitId)
      return buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.Projects]);
    if (hasSignedContract) return buildRoute(AppRoutes.AssignedProjects);

    return buildRoute(AppRoutes.Home);
  }, [user?.id, hasSignedContract]);

  return (
    <Link className={'flex items-center w-48'} to={homeRoute}>
      {color === 'black' ? <LogoBlack /> : <LogoWhite />}
    </Link>
  );
};

export default AppLogo;
