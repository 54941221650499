import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';

export const api = {
  getSidebar: (payload: { surveySessionId: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/survey-session/${payload.surveySessionId}/question-blocks`),

  getBlock: (payload: {
    surveySessionId: string;
    blockId: number;
  }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/survey-session/${payload.surveySessionId}/question-blocks/${payload.blockId}`),

  sendAnswer: (payload: {
    questionId: number;
    surveySessionId: string;
    answer: string | string[];
  }): Promise<ExtendedAxiosResponse> =>
    apiClient.post(
      `/survey-session/${payload.surveySessionId}/answer-question`,
      toSnakeCase(payload)
    ),
  finishSurvey: (payload: { sessionId: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/survey-session/${payload.sessionId}/complete-survey`),

  getChildOptions: (payload: { id: string; optionId: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/survey/questions/${payload.id}/get-child-options`, {
      params: toSnakeCase(payload),
    }),
};
