import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { SettingsRoutes } from '../../helpers/route/routes/settings-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { City, CityZip } from '../../models/City';

import { District } from '../../models/District';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  CityActionTypes,
  cityCreateCityActions,
  cityGetCitiesActions,
  cityGetCitiesZipActions,
  cityGetCityActions,
  cityGetDistrictsActions,
  cityRemoveCityActions,
  cityUpdateCityActions,
} from './actions';
import { api, CityPayload } from './api';
import {
  selectCityCitiesFilterColumn,
  selectCityCitiesOrderColumn,
  selectCityCitiesPagination,
  selectCityCitiesZipFilterColumn,
  selectCityCitiesZipOrderColumn,
  selectCityCitiesZipPagination,
} from './selectors';

/* STATE */
export interface CityState {
  cities: City[];
  citiesPagination: Pages;
  citiesStatus: LoadingStatus;
  citiesOrder: ITableOrder;
  citiesFilter: Record<string, ISearch>;
  city: City | null;
  districts: District[];
  cityActionStatus: LoadingStatus;

  citiesZip: CityZip[];
  citiesZipPagination: Pages;
  citiesZipStatus: LoadingStatus;
  citiesZipOrder: ITableOrder;
  citiesZipFilter: Record<string, ISearch>;
}

/* REDUCERS */
const initialState: CityState = {
  cities: [],
  citiesPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  citiesStatus: LoadingStatus.initial,
  citiesOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  citiesFilter: {},
  city: null,
  districts: [],
  cityActionStatus: LoadingStatus.initial,

  citiesZip: [],
  citiesZipPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  citiesZipStatus: LoadingStatus.initial,
  citiesZipOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  citiesZipFilter: {},
};

const cities = createReducer(initialState.cities, {
  [CityActionTypes.GetCities]: {
    [RequestActionTypes.SUCCESS]: (state: City[], payload: PaginatedResp<City>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.cities,
  },
  [CityActionTypes.RemoveCity]: {
    [RequestActionTypes.SUCCESS]: (state: City[], payload: { id: number }) => {
      return state.filter((city) => city.id !== payload.id);
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.cities,
  },
});

const citiesPagination = createReducer(initialState.citiesPagination, {
  [CityActionTypes.GetCities]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<City>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.citiesPagination,
  },
  [CityActionTypes.SetCitiesPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [CityActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [CityActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [CityActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.citiesPagination,
  },
});

const citiesStatus = createLoadingStateReducer(
  initialState.citiesStatus,
  {
    [CityActionTypes.GetCities]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [CityActionTypes.SetCitiesPage]: () => initialState.citiesStatus,
    [CityActionTypes.IncreasePage]: () => initialState.citiesStatus,
    [CityActionTypes.SetFilterColumn]: () => initialState.citiesStatus,
    [CityActionTypes.SetOrderColumn]: () => initialState.citiesStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.citiesStatus,
    },
  }
);

const citiesOrder = createReducer(initialState.citiesOrder, {
  [CityActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.citiesOrder,
  },
});

const citiesFilter = createReducer(initialState.citiesFilter, {
  [CityActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.citiesFilter,
  },
});

const city = createReducer(initialState.city, {
  [CityActionTypes.CreateCity]: {
    [RequestActionTypes.SUCCESS]: (state: City | null, payload: City) => payload,
  },
  [CityActionTypes.GetCity]: {
    [RequestActionTypes.REQUEST]: () => initialState.city,
    [RequestActionTypes.SUCCESS]: (state: City | null, payload: City) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.city,
  },
  [CityActionTypes.UpdateCity]: {
    [RequestActionTypes.SUCCESS]: (state: City | null, payload: City) => payload,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.city,
  },
});

const districts = createReducer(initialState.districts, {
  [CityActionTypes.GetDistricts]: {
    [RequestActionTypes.SUCCESS]: (state: District[], payload: District[]) => payload,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.districts,
  },
});

const cityActionStatus = createLoadingStateReducer(
  initialState.cityActionStatus,
  {
    [CityActionTypes.CreateCity]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [CityActionTypes.UpdateCity]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.cityActionStatus,
    },
  }
);

const citiesZip = createReducer(initialState.citiesZip, {
  [CityActionTypes.GetCitiesZip]: {
    [RequestActionTypes.SUCCESS]: (state: CityZip[], payload: PaginatedResp<CityZip>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.citiesZip,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.citiesZip,
  },
});

const citiesZipPagination = createReducer(initialState.citiesZipPagination, {
  [CityActionTypes.GetCitiesZip]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<CityZip>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.citiesZipPagination,
  },
  [CityActionTypes.SetCitiesZipPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [CityActionTypes.IncreasePageZip]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [CityActionTypes.SetOrderColumnZip]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [CityActionTypes.SetFilterColumnZip]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.citiesZipPagination,
  },
});

const citiesZipStatus = createLoadingStateReducer(
  initialState.citiesZipStatus,
  {
    [CityActionTypes.GetCitiesZip]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [CityActionTypes.SetCitiesZipPage]: () => initialState.citiesZipStatus,
    [CityActionTypes.IncreasePageZip]: () => initialState.citiesZipStatus,
    [CityActionTypes.SetFilterColumnZip]: () => initialState.citiesZipStatus,
    [CityActionTypes.SetOrderColumnZip]: () => initialState.citiesZipStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.citiesZipStatus,
    },
  }
);

const citiesZipOrder = createReducer(initialState.citiesZipOrder, {
  [CityActionTypes.SetOrderColumnZip]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.citiesZipOrder,
  },
});

const citiesZipFilter = createReducer(initialState.citiesZipFilter, {
  [CityActionTypes.SetFilterColumnZip]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.citiesZipFilter,
  },
});

export default combineReducers<CityState>({
  cities,
  citiesPagination,
  citiesStatus,
  citiesOrder,
  citiesFilter,
  city,
  districts,
  cityActionStatus,
  citiesZip,
  citiesZipPagination,
  citiesZipStatus,
  citiesZipOrder,
  citiesZipFilter,
});

/* SAGAS */
function* getCities() {
  const pagination: Pages = yield select(selectCityCitiesPagination);
  const orderColumn: ITableOrder = yield select(selectCityCitiesOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(selectCityCitiesFilterColumn);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getCities, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(cityGetCitiesActions.success(toCamelCase(resp.data)));
  } else {
    yield put(cityGetCitiesActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getCitiesZip() {
  const pagination: Pages = yield select(selectCityCitiesZipPagination);
  const orderColumn: ITableOrder = yield select(selectCityCitiesZipOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(selectCityCitiesZipFilterColumn);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getCitiesZip, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(cityGetCitiesZipActions.success(toCamelCase(resp.data)));
  } else {
    yield put(cityGetCitiesZipActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeCity({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeCity, payload);

  if (resp.ok) {
    yield put(cityRemoveCityActions.success({ id: payload.id }));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(cityRemoveCityActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createCity({ payload }: AppAction<CityPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.createCity, payload);

  if (resp.ok) {
    yield put(cityCreateCityActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageCities]));
  } else {
    yield put(cityCreateCityActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getCity({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getCity, payload);

  if (resp.ok) {
    yield put(cityGetCityActions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(cityGetCityActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateCity({ payload }: AppAction<CityPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateCity, payload);

  if (resp.ok) {
    yield put(cityUpdateCityActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageCities]));
  } else {
    yield put(cityUpdateCityActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getDistricts() {
  const resp: ExtendedAxiosResponse = yield call(api.getDistricts);

  if (resp.ok) {
    yield put(cityGetDistrictsActions.success(toCamelCase(resp.data)?.data || []));
  } else {
    yield put(cityGetDistrictsActions.failure());
  }
}

/* EXPORT */
export function* citySaga() {
  yield takeLatest(
    [
      createActionType(CityActionTypes.GetCities, RequestActionTypes.REQUEST),
      CityActionTypes.SetCitiesPage,
      CityActionTypes.IncreasePage,
      CityActionTypes.SetOrderColumn,
      CityActionTypes.SetFilterColumn,
    ],
    getCities
  );
  yield takeLatest(
    [
      createActionType(CityActionTypes.GetCitiesZip, RequestActionTypes.REQUEST),
      CityActionTypes.SetCitiesZipPage,
      CityActionTypes.IncreasePageZip,
      CityActionTypes.SetOrderColumnZip,
      CityActionTypes.SetFilterColumnZip,
    ],
    getCitiesZip
  );
  yield takeLatest(
    [createActionType(CityActionTypes.RemoveCity, RequestActionTypes.REQUEST)],
    removeCity
  );
  yield takeLatest(
    createActionType(CityActionTypes.CreateCity, RequestActionTypes.REQUEST),
    createCity
  );
  yield takeLatest(createActionType(CityActionTypes.GetCity, RequestActionTypes.REQUEST), getCity);
  yield takeLatest(
    createActionType(CityActionTypes.UpdateCity, RequestActionTypes.REQUEST),
    updateCity
  );
  yield takeLatest(
    createActionType(CityActionTypes.GetDistricts, RequestActionTypes.REQUEST),
    getDistricts
  );
}
