import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';
import { HandoverProtocolPayload } from '../handoverProtocol/api';

export const api = {
  getExports: (): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/admin/handover-protocol-generations', {
      params: toSnakeCase({
        page: 1,
        resultsPerPage: 10000,
        order: 'ASC',
        sort: 'generationTime',
      }),
    }),

  getGenerationTime: (): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/admin/handover-protocol-generations/next-generation'),

  updateGenerationTime: (payload: {
    id: string;
    generationTime: string;
  }): Promise<ExtendedAxiosResponse> =>
    apiClient.put(`/admin/handover-protocol-generations/${payload.id}`, toSnakeCase(payload)),

  getBankTransfers: ({
    id,
    pagination,
    order,
    filter,
  }: ITableReq & { id?: string }): Promise<ExtendedAxiosResponse> => {
    return apiClient.get(`/admin/handover-protocol-generations/${id}/bank-transfers`, {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  getUnpaidBankTransfers: ({
    pagination,
    order,
    filter,
  }: ITableReq & { id?: string }): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/admin/unpaid-bank-transfers', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  updateBankTransfer: (payload: any): Promise<ExtendedAxiosResponse> =>
    apiClient.put(`/admin/bank-transfers/${payload.id}`, toSnakeCase(payload)),

  changeSpr: (payload: { id: string; spr: boolean }[]) =>
    apiClient.post(
      '/admin/bank-transfers/update-psr',
      toSnakeCase({
        bankTransfers: payload,
      })
    ),

  getInvoiceDoc: (payload: HandoverProtocolPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/admin/bank-transfers/${payload.id}/invoice`, {
      responseType: 'blob',
    }),

  updateLink: (payload: { id: string; httpsLink: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/admin/bank-transfers/${payload.id}/link`, toSnakeCase(payload)),

  removeLink: (payload: { id: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/admin/bank-transfers/${payload.id}/link`),

  updateFile: (payload: { id: string; file: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/admin/bank-transfers/${payload.id}/file`, payload.file),

  removeFile: (payload: { id: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/admin/bank-transfers/${payload.id}/file`),

  adminDownloadFile: (payload: { id: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/admin/bank-transfers/${payload.id}/file`, {
      responseType: 'blob',
    }),

  downloadFile: (payload: { id: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/bank-transfers/${payload.id}/download-invoice`, {
      responseType: 'blob',
    }),
};
