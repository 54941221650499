import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { authLogoutActions } from '../../../saga/auth/actions';

const Logout = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogout = () => {
    dispatch(authLogoutActions.request());
  };

  return (
    <div className={'block py-2 px-3 lg:py-2 lg:px-2 w-full'} onClick={handleLogout}>
      {t('layout.appHeader.myProfile.items.logout')}
    </div>
  );
};

export default Logout;
