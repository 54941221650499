/* SELECTORS */
import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { ArchivedProject } from '../../models/ArchivedProject';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { ArchivedProjectState } from './ducks';

const selectArchivedProject = (state: AppState): ArchivedProjectState => state.archivedProject;

export const selectArchivedProjectProjects = (state: AppState): ArchivedProject[] | null =>
  selectArchivedProject(state).projects;

export const selectArchivedProjectProjectsPagination = (state: AppState): Pages =>
  selectArchivedProject(state).projectsPagination;

export const selectArchivedProjectProjectsStatus = (state: AppState): LoadingStatus =>
  selectArchivedProject(state).projectsStatus;

export const selectArchivedProjectProjectsOrderColumn = (state: AppState): ITableOrder =>
  selectArchivedProject(state).projectsOrder;

export const selectArchivedProjectProjectsFilterColumn = (
  state: AppState
): Record<string, ISearch> => selectArchivedProject(state).projectsFilter;

export const selectArchivedProjectProject = (state: AppState): ArchivedProject | null =>
  selectArchivedProject(state).project;

export const selectArchivedProjectProjectStatus = (state: AppState): LoadingStatus =>
  selectArchivedProject(state).projectStatus;
