import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum AssignedProjectActionTypes {
  GetAssignedProjects = '@@AssignedProject/GET_ASSIGNED_PROJECTS',
  SetAssignedProjectPage = '@@AssignedProject/SET_PROJECTS_PAGE',
  IncreasePage = '@@AssignedProject/INCREASE_PROJECTS_PAGE',
  SetOrderColumn = '@@AssignedProject/SET_ORDER_COLUMN',
  SetFilterColumn = '@@AssignedProject/SET_FILTER_COLUMNS',
  GetAssignedProject = '@@AssignedProject/GET_ASSIGNED_PROJECT',
}

/* ACTIONS */
export const assignedProjectGetAssignedProjects = createApiActionCreators(
  AssignedProjectActionTypes.GetAssignedProjects
);
export const assignedProjectSetProjectsPageActions = createActionCreator(
  AssignedProjectActionTypes.SetAssignedProjectPage
);
export const assignedProjectIncreasePageActions = createActionCreator(
  AssignedProjectActionTypes.IncreasePage
);
export const assignedProjectSetOrderColumnActions = createActionCreator(
  AssignedProjectActionTypes.SetOrderColumn
);
export const assignedProjectSetFilterColumnActions = createActionCreator(
  AssignedProjectActionTypes.SetFilterColumn
);
export const assignedProjectGetAssignedProject = createApiActionCreators(
  AssignedProjectActionTypes.GetAssignedProject
);
