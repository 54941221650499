import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export const api = {
  generateRespondents: (payload: any): Promise<ExtendedAxiosResponse> => {
    return apiClient.post('/admin/import/load-import-data', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getImportedRespondents: ({
    pagination,
    order,
    filter,
    id,
  }: ITableReq & { id: string }): Promise<ExtendedAxiosResponse> => {
    return apiClient.get(`/admin/import/import-data/${id}`, {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  importRespondents: (payload: { id: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/admin/import/start-import/${payload.id}`),

  getProjects: (payload: { search: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/project', {
      params: toSnakeCase({
        page: 1,
        resultsPerPage: 200,
        search: `name:like:${payload.search}`,
      }),
    }),
  getCompanies: (payload: { search: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/pharma-company', {
      params: toSnakeCase({
        page: 1,
        resultsPerPage: 200,
        search: `name:like:${payload.search}`,
      }),
    }),
  removeImportRespondents: (payload: {
    id: string;
    assignedProjectImportsArray: string[];
  }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/admin/import/import-data/${payload.id}`, {
      params: {
        ...toSnakeCase(payload),
      },
    }),

  getImportStatus: (payload: { id: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/admin/import/import-status/${payload.id}`),

  getErrors: (payload: { id: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/admin/import/import-errors/${payload.id}`),
};
