import { lazy } from 'react';

import {
  BreadcrumbAddressDetail,
  BreadcrumbBankAccountDetail,
  BreadcrumbClientDetail,
  BreadcrumbCompanyDetail,
  BreadcrumbRespondentDetail,
} from '../components/SubBreadcrumbs/SubBreadcrumbs';
import Translate from '../components/Translate/Translate';
import { IRoute, Permission } from '../route';
import overviewRoutes from './overview-routes';
import settingsRoutes from './settings-routes';

export enum AdminRoutes {
  Clients = 'clients',
  ClientsDetail = 'clients/:clientId',
  ClientsCreate = 'clients/create',
  Respondents = 'respondents',
  RespondentsDetail = 'respondents/:respondentId',
  RespondentsCreate = 'respondents/create',
  RespondentImport = 'import-respondents',

  Companies = 'companies',
  CompaniesDetail = 'companies/:companyId',
  CompaniesCreate = 'companies/create',
  Cms = 'cms',
  Settings = 'settings',
  Overviews = 'overviews',
  AcceptanceProtocol = 'acceptance-protocol',
  Contract = 'contract',
  NonProjectAcceptanceProtocol = 'non-project-acceptance-protocol',
  RespondentsBankAccountCreate = 'respondents/:respondentId/bankAccount/create',
  RespondentsBankAccountDetail = 'respondents/:respondentId/bankAccount/:bankAccountId',
  RespondentsAddressCreate = 'respondents/:respondentId/address/create',
  RespondentsAddressDetail = 'respondents/:respondentId/address/:addressId',
  RespondentsCompaniesBankAccountCreate = 'respondents/:respondentId/company/:companyId/bankAccount/create',
  RespondentsCompaniesBankAccountDetail = 'respondents/:respondentId/company/:companyId/bankAccount/:bankAccountId',
  RespondentsCompaniesAddressCreate = 'respondents/:respondentId/company/:companyId/address/create',
  RespondentsCompaniesAddressDetail = 'respondents/:respondentId/company/:companyId/address/:addressId',
  RespondentsCompanyCreate = 'respondents/:respondentId/company/create',
  RespondentsCompanyDetail = 'respondents/:respondentId/company/:companyId',
  RespondentsContactCreate = 'respondents/:respondentId/contact/create',
  RespondentsContactDetail = 'respondents/:respondentId/contact/:contactId',
  CompaniesBankAccountCreate = 'companies/:companyId/bankAccount/create',
  CompaniesBankAccountDetail = 'companies/:companyId/bankAccount/:bankAccountId',
  CompaniesAddressCreate = 'companies/:companyId/address/create',
  CompaniesAddressDetail = 'companies/:companyId/address/:addressId',
  ClientsBankAccountCreate = 'clients/:clientId/bankAccount/create',
  ClientsBankAccountDetail = 'clients/:clientId/bankAccount/:bankAccountId',
  ClientsAddressCreate = 'clients/:clientId/address/create',
  ClientsAddressDetail = 'clients/:clientId/address/:addressId',
  BankTransfers = 'bank-transfers',
}

const Clients = lazy(() => import('../../../views/pages/Clients/Clients'));
const ClientsDetail = lazy(
  () => import('../../../views/pages/Clients/ClientsDetail/ClientsDetail')
);
const ClientsCreate = lazy(
  () => import('../../../views/pages/Clients/ClientsCreate/ClientsCreate')
);
const Companies = lazy(() => import('../../../views/pages/Companies/Companies'));
const CompaniesDetail = lazy(
  () => import('../../../views/pages/Companies/CompaniesDetail/CompaniesDetail')
);
const CompaniesCreate = lazy(
  () => import('../../../views/pages/Companies/CompaniesCreate/CompaniesCreate')
);
const Cms = lazy(() => import('../../../views/pages/Cms/Cms'));
const Respondent = lazy(() => import('../../../views/pages/Respondent/Respondent'));
const RespondentDetail = lazy(
  () => import('../../../views/pages/Respondent/RespondentDetail/RespondentDetail')
);
const RespondentCreate = lazy(
  () => import('../../../views/pages/Respondent/RespondentCreate/RespondentCreate')
);

const BankAccountCreate = lazy(
  () => import('../../../views/pages/BankAccount/BankAccountCreate/BankAccountCreate')
);
const BankAccountDetail = lazy(
  () => import('../../../views/pages/BankAccount/BankAccountDetail/BankAccountDetail')
);

const AddressCreate = lazy(
  () => import('../../../views/pages/Address/AddressCreate/AddressCreate')
);
const AddressDetail = lazy(
  () => import('../../../views/pages/Address/AddressDetail/AddressDetail')
);

const ContactCreate = lazy(
  () => import('../../../views/pages/Contact/ContactCreate/ContactCreate')
);
const ContactDetail = lazy(
  () => import('../../../views/pages/Contact/ContactDetail/ContactDetail')
);

const Contracts = lazy(() => import('../../../views/pages/AdminContracts/Contracts/Contracts'));

const NonProjectAcceptanceProtocol = lazy(
  () =>
    import(
      '../../../views/pages/AdminContracts/NonProjectAcceptanceProtocol/NonProjectAcceptanceProtocol'
    )
);

const AcceptanceProtocol = lazy(
  () => import('../../../views/pages/AdminContracts/AcceptanceProtocol/AcceptanceProtocol')
);

const BankTransfers = lazy(() => import('../../../views/pages/BankTransfers/BankTransfers'));

const ImportRespondents = lazy(
  () => import('../../../views/pages/ImportRespondents/ImportRespondents')
);

const adminRoutes: IRoute[] = [
  {
    path: AdminRoutes.Clients,
    element: <Clients />,
    breadcrumb: Translate,
    permissions: [Permission.pharmaCompanyRead],
    props: { translate: 'pages.clients.header' },
  },
  {
    path: AdminRoutes.ClientsDetail,
    element: <ClientsDetail />,
    permissions: [Permission.pharmaCompanyRead],
    breadcrumb: BreadcrumbClientDetail,
  },
  {
    path: AdminRoutes.ClientsCreate,
    element: <ClientsCreate />,
    permissions: [Permission.pharmaCompanyCreate],
    breadcrumb: Translate,
    props: { translate: 'pages.clientsCreate.header' },
  },
  {
    path: AdminRoutes.Respondents,
    element: <Respondent />,
    breadcrumb: Translate,
    permissions: [Permission.respondentRead],
    props: { translate: 'pages.respondents.header' },
  },
  {
    path: AdminRoutes.RespondentsDetail,
    element: <RespondentDetail />,
    permissions: [Permission.respondentRead],
    breadcrumb: BreadcrumbRespondentDetail,
  },
  {
    path: AdminRoutes.RespondentsCreate,
    element: <RespondentCreate />,
    permissions: [Permission.respondentCreate],
    breadcrumb: Translate,
    props: { translate: 'pages.respondentsCreate.header' },
  },
  {
    path: AdminRoutes.Companies,
    element: <Companies />,
    permissions: [Permission.companyRead],
    breadcrumb: Translate,
    props: { translate: 'pages.companies.header' },
  },
  {
    path: AdminRoutes.CompaniesDetail,
    element: <CompaniesDetail />,
    permissions: [Permission.companyRead],
    breadcrumb: BreadcrumbCompanyDetail,
  },
  {
    path: AdminRoutes.CompaniesCreate,
    element: <CompaniesCreate variant="admin" />,
    permissions: [Permission.companyCreate],
    breadcrumb: Translate,
    props: { translate: 'pages.companiesCreate.header' },
  },
  {
    path: AdminRoutes.Cms,
    element: <Cms />,
    breadcrumb: Translate,
    props: { translate: 'pages.cms.header' },
  },
  {
    path: AdminRoutes.Settings,
    children: settingsRoutes,
    breadcrumb: null,
  },
  {
    path: 'respondents/:respondentId/address',
    breadcrumb: null,
  },
  {
    path: AdminRoutes.RespondentsAddressCreate,
    element: <AddressCreate />,
    breadcrumb: Translate,
    permissions: [Permission.respondentCreate],
    props: { translate: 'pages.addressCreate.header' },
  },
  {
    path: AdminRoutes.RespondentsAddressDetail,
    element: <AddressDetail />,
    permissions: [Permission.respondentRead],
    breadcrumb: BreadcrumbAddressDetail,
  },
  {
    path: 'respondents/:respondentId/bankAccount',
    breadcrumb: null,
  },
  {
    path: AdminRoutes.RespondentsBankAccountCreate,
    element: <BankAccountCreate />,
    permissions: [Permission.respondentCreate],
    breadcrumb: Translate,
    props: { translate: 'pages.bankAccountsCreate.header' },
  },
  {
    path: AdminRoutes.RespondentsBankAccountDetail,
    element: <BankAccountDetail />,
    permissions: [Permission.respondentRead],
    breadcrumb: BreadcrumbBankAccountDetail,
  },
  {
    path: 'respondents/:respondentId/company',
    breadcrumb: null,
  },
  {
    path: AdminRoutes.RespondentsCompanyCreate,
    element: <CompaniesCreate variant="admin" />,
    permissions: [Permission.companyCreate],
    breadcrumb: Translate,
    props: { translate: 'pages.companiesCreate.header' },
  },
  {
    path: AdminRoutes.RespondentsCompanyDetail,
    element: <CompaniesDetail />,
    permissions: [Permission.companyRead],
    breadcrumb: BreadcrumbCompanyDetail,
  },
  {
    path: 'companies/:companyId/bankAccount',
    breadcrumb: null,
  },
  {
    path: AdminRoutes.CompaniesBankAccountCreate,
    element: <BankAccountCreate />,
    permissions: [Permission.companyUpdate],
    breadcrumb: Translate,
    props: { translate: 'pages.bankAccountsCreate.header' },
  },
  {
    path: AdminRoutes.CompaniesBankAccountDetail,
    element: <BankAccountDetail />,
    permissions: [Permission.companyRead],
    breadcrumb: BreadcrumbBankAccountDetail,
  },
  {
    path: 'companies/:companyId/address',
    breadcrumb: null,
  },
  {
    path: AdminRoutes.CompaniesAddressCreate,
    element: <AddressCreate />,
    permissions: [Permission.companyUpdate],
    breadcrumb: Translate,
    props: { translate: 'pages.addressCreate.header' },
  },
  {
    path: AdminRoutes.CompaniesAddressDetail,
    element: <AddressDetail />,
    permissions: [Permission.companyRead],
    breadcrumb: BreadcrumbAddressDetail,
  },
  {
    path: 'respondents/:respondentId/company/:companyId/address',
    breadcrumb: null,
  },
  {
    path: AdminRoutes.RespondentsCompaniesAddressCreate,
    element: <AddressCreate />,
    permissions: [Permission.companyUpdate],
    breadcrumb: Translate,
    props: { translate: 'pages.addressCreate.header' },
  },
  {
    path: AdminRoutes.RespondentsCompaniesAddressDetail,
    element: <AddressDetail />,
    permissions: [Permission.companyRead],
    breadcrumb: BreadcrumbAddressDetail,
  },
  {
    path: 'respondents/:respondentId/company/:companyId/bankAccount',
    breadcrumb: null,
  },
  {
    path: AdminRoutes.RespondentsCompaniesBankAccountCreate,
    element: <BankAccountCreate />,
    permissions: [Permission.companyUpdate],
    breadcrumb: Translate,
    props: { translate: 'pages.bankAccountsCreate.header' },
  },
  {
    path: AdminRoutes.RespondentsCompaniesBankAccountDetail,
    element: <BankAccountDetail />,
    permissions: [Permission.companyRead],
    breadcrumb: BreadcrumbBankAccountDetail,
  },
  {
    path: 'clients/:clientId/address',
    breadcrumb: null,
  },
  {
    path: AdminRoutes.ClientsAddressCreate,
    element: <AddressCreate />,
    permissions: [Permission.pharmaCompanyCreate],
    breadcrumb: Translate,
    props: { translate: 'pages.addressCreate.header' },
  },
  {
    path: AdminRoutes.ClientsAddressDetail,
    element: <AddressDetail />,
    permissions: [Permission.pharmaCompanyRead],
    breadcrumb: BreadcrumbAddressDetail,
  },
  {
    path: 'clients/:clientId/bankAccount',
    breadcrumb: null,
  },
  {
    path: AdminRoutes.ClientsBankAccountCreate,
    element: <BankAccountCreate />,
    permissions: [Permission.pharmaCompanyCreate],
    breadcrumb: Translate,
    props: { translate: 'pages.bankAccountsCreate.header' },
  },
  {
    path: AdminRoutes.Contract,
    element: <Contracts />,
    permissions: [Permission.contractRead],
    breadcrumb: Translate,
    props: { translate: 'pages.adminContracts.contracts.header' },
  },
  {
    path: AdminRoutes.AcceptanceProtocol,
    element: <AcceptanceProtocol variant="admin" />,
    permissions: [Permission.acceptanceProtocolRead],
    breadcrumb: Translate,
    props: { translate: 'pages.adminContracts.acceptanceProtocol.header' },
  },
  {
    path: AdminRoutes.NonProjectAcceptanceProtocol,
    element: <NonProjectAcceptanceProtocol />,
    permissions: [Permission.nonProjectAcceptanceProtocolRead],
    breadcrumb: Translate,
    props: { translate: 'pages.adminContracts.nonProjectAcceptanceProtocol.header' },
  },
  {
    path: AdminRoutes.ClientsBankAccountDetail,
    element: <BankAccountDetail />,
    permissions: [Permission.pharmaCompanyRead],
    breadcrumb: BreadcrumbBankAccountDetail,
  },
  {
    path: AdminRoutes.Overviews,
    children: overviewRoutes,
    breadcrumb: null,
  },
  {
    path: AdminRoutes.BankTransfers,
    element: <BankTransfers />,
    breadcrumb: Translate,
    props: { translate: 'pages.bankTransfers.header' },
  },
  {
    path: AdminRoutes.RespondentImport,
    element: <ImportRespondents />,
    permissions: [],
    breadcrumb: Translate,
    props: { translate: 'pages.importRespondents.header' },
  },
  {
    path: AdminRoutes.RespondentsContactCreate,
    element: <ContactCreate />,
    permissions: [Permission.respondentUpdate],
    breadcrumb: Translate,
    props: { translate: 'pages.contactCreate.header' },
  },
  {
    path: AdminRoutes.RespondentsContactDetail,
    element: <ContactDetail />,
    permissions: [Permission.respondentUpdate],
    breadcrumb: Translate,
    props: { translate: 'pages.contactDetail.header' },
  },
  {
    path: 'respondents/:respondentId/contact',
    breadcrumb: null,
  },
];

export default adminRoutes;
