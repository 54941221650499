import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export interface ClientInvoicePayload {
  id?: number;
  pharmaCompanyId: number;
  invoiceNumber: string;
  price: number;
}

export const api = {
  getClientInvoices: ({ pagination, order, filter }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/admin/client-invoices', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  removeClientInvoice: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/admin/client-invoices/${id}`),

  getClientInvoice: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/admin/client-invoices/${id}`),

  createClientInvoice: (payload: ClientInvoicePayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/admin/client-invoices', toSnakeCase(payload)),

  updateClientInvoice: (payload: ClientInvoicePayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(`/admin/client-invoices/${payload?.id}`, toSnakeCase(payload)),

  removeClientInvoices: (payload: {
    clientInvoicesArray: number[];
  }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete('/admin/client-invoices', {
      params: {
        ...toSnakeCase(payload),
      },
    }),

  getClients: (payload: { search: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/pharma-company', {
      params: toSnakeCase({
        search: `name:like:${payload.search?.trim()}`,
        resultsPerPage: 100,
      }),
    }),
};
