import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Contract } from '../../models/Contract';
import {
  NonProjectHandoverProtocol,
  RespondentCompany,
} from '../../models/NonProjectHandoverProtocol';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { NonProjectHandoverProtocolState } from './ducks';

/* SELECTORS */
const selectNonProjectHandoverProtocol = (state: AppState): NonProjectHandoverProtocolState =>
  state.nonProjectHandoverProtocol;

export const selectNonProjectHandoverProtocolProtocols = (
  state: AppState
): NonProjectHandoverProtocol[] =>
  selectNonProjectHandoverProtocol(state).nonProjectHandoverProtocols;

export const selectNonProjectHandoverProtocolProtocolsPagination = (state: AppState): Pages =>
  selectNonProjectHandoverProtocol(state).nonProjectHandoverProtocolsPagination;

export const selectNonProjectHandoverProtocolProtocolsStatus = (state: AppState): LoadingStatus =>
  selectNonProjectHandoverProtocol(state).nonProjectHandoverProtocolsStatus;

export const selectNonProjectHandoverProtocolProtocolsOrderColumn = (
  state: AppState
): ITableOrder => selectNonProjectHandoverProtocol(state).nonProjectHandoverProtocolsOrder;

export const selectNonProjectHandoverProtocolProtocolsFilterColumn = (
  state: AppState
): Record<string, ISearch> =>
  selectNonProjectHandoverProtocol(state).nonProjectHandoverProtocolsFilter;

export const selectNonProjectHandoverProtocolGetRespondentsCompanies = (
  state: AppState
): RespondentCompany[] => selectNonProjectHandoverProtocol(state).respondentsCompanies;

export const selectNonProjectHandoverProtocolRespondentsCompaniesPagination = (
  state: AppState
): Pages => selectNonProjectHandoverProtocol(state).respondentsCompaniesPagination;

export const selectNonProjectHandoverProtocolRespondentsCompaniesStatus = (
  state: AppState
): LoadingStatus => selectNonProjectHandoverProtocol(state).respondentsCompaniesStatus;

export const selectNonProjectHandoverProtocolRespondentsCompaniesFilterColumn = (
  state: AppState
): Record<string, ISearch> => selectNonProjectHandoverProtocol(state).respondentsCompaniesFilter;

export const selectNonProjectHandoverProtocolHandoverProtocol = (
  state: AppState
): NonProjectHandoverProtocol | null =>
  selectNonProjectHandoverProtocol(state).nonProjectHandoverProtocol;

export const selectNonProjectHandoverProtocolHandoverProtocolStatus = (
  state: AppState
): LoadingStatus => selectNonProjectHandoverProtocol(state).nonProjectHandoverProtocolStatus;

export const selectNonProjectHandoverProtocolGetSignedContracts = (state: AppState): Contract[] =>
  selectNonProjectHandoverProtocol(state).signedContracts;

export const selectNonProjectHandoverProtocolGetSignedContractsStatus = (
  state: AppState
): LoadingStatus => selectNonProjectHandoverProtocol(state).signedContractsStatus;
