import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum DistrictActionTypes {
  GetDistricts = '@@District/GET_DISTRICTS',
  SetDistrictsPage = '@@District/SET_DISTRICTS_PAGE',
  IncreasePage = '@@District/INCREASE_DISTRICTS',
  SetOrderColumn = '@@District/SET_ORDER_COLUMN',
  SetFilterColumn = '@@District/SET_FILTER_COLUMNS',
  RemoveDistrict = '@@District/REMOVE_DISTRICT',
  CreateDistrict = '@@District/CREATE_DISTRICT',
  GetDistrict = '@@District/GET_DISTRICT',
  UpdateDistrict = '@@District/UPDATE_DISTRICT',
  GetRegions = '@@District/GET_REGIONS',
}

/* ACTIONS */
export const districtGetDistrictsActions = createApiActionCreators(
  DistrictActionTypes.GetDistricts
);
export const districtSetDistrictsPageActions = createActionCreator(
  DistrictActionTypes.SetDistrictsPage
);
export const districtIncreasePageActions = createActionCreator(DistrictActionTypes.IncreasePage);
export const districtSetOrderColumnActions = createActionCreator(
  DistrictActionTypes.SetOrderColumn
);
export const districtSetFilterColumnActions = createActionCreator(
  DistrictActionTypes.SetFilterColumn
);

export const districtRemoveDistrictActions = createApiActionCreators(
  DistrictActionTypes.RemoveDistrict
);

export const districtCreateDistrictActions = createApiActionCreators(
  DistrictActionTypes.CreateDistrict
);
export const districtUpdateDistrictActions = createApiActionCreators(
  DistrictActionTypes.UpdateDistrict
);
export const districtGetDistrictActions = createApiActionCreators(DistrictActionTypes.GetDistrict);
export const districtGetRegionsActions = createApiActionCreators(DistrictActionTypes.GetRegions);
