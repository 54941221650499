import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import { PersistContractForm } from '../../models/Contract';

/* ACTION TYPES */
export enum ContractActionTypes {
  GetContracts = '@@Contract/GET_CONTRACTS',
  GetAdminContracts = '@@Contract/GET_ADMIN_CONTRACTS',
  SetContractsPage = '@@Contract/SET_CONTRACTS_PAGE',
  IncreasePage = '@@Contract/INCREASE_CONTRACTS',
  SetOrderColumn = '@@Contract/SET_ORDER_COLUMN',
  SetFilterColumn = '@@Contract/SET_FILTER_COLUMNS',
  SetPrimaryContract = '@@Contract/SET_PRIMARY_CONTRACT',
  GetBankAccounts = '@@Contract/GET_BANK_ACCOUNTS',
  SetPrimaryBankAccount = '@@Contract/SET_PRIMARY_BANK_ACCOUNT',
  CreateContract = '@@Contract/CREATE_CONTRACT',
  GenerateContractDoc = '@@Contract/GENERATE_CONTRACT_DOC',
  ExportContract = '@@Contract/EXPORT_CONTRACT',
  PersistForm = '@@Contract/PERSIST_FORM',
}

/* ACTIONS */
export const contractGetContractsActions = createApiActionCreators(
  ContractActionTypes.GetContracts
);
export const contractGetAdminContractsActions = createApiActionCreators(
  ContractActionTypes.GetAdminContracts
);
export const contractSetContractsPageActions = createActionCreator(
  ContractActionTypes.SetContractsPage
);
export const contractIncreasePageActions = createActionCreator(ContractActionTypes.IncreasePage);
export const contractSetOrderColumnActions = createActionCreator(
  ContractActionTypes.SetOrderColumn
);
export const contractSetFilterColumnActions = createActionCreator(
  ContractActionTypes.SetFilterColumn
);

export const contractSetPrimaryContractActions = createApiActionCreators(
  ContractActionTypes.SetPrimaryContract
);

export const contractGetBankAccountsActions = createApiActionCreators(
  ContractActionTypes.GetBankAccounts
);

export const contractSetPrimaryBankAccountActions = createApiActionCreators(
  ContractActionTypes.SetPrimaryBankAccount
);

export const contractCreateContractActions = createApiActionCreators(
  ContractActionTypes.CreateContract
);

export const contractGenerateContractDocActions = createApiActionCreators(
  ContractActionTypes.GenerateContractDoc
);

export const contractExportContractActions = createApiActionCreators(
  ContractActionTypes.ExportContract
);

export const contractPersistForm = createActionCreator<PersistContractForm | null>(
  ContractActionTypes.PersistForm
);
