import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { customSnakeCase, toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export interface ProjectRespondentPayload {
  id?: number;
}

export const api = {
  getProjectRespondents: ({
    id,
    pagination,
    order,
    filter,
  }: ITableReq & { id?: string }): Promise<ExtendedAxiosResponse> => {
    return apiClient.get(`/admin/project/${id}/assigned-respondents`, {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: customSnakeCase(order.sort),
        search: filter,
      }),
    });
  },

  createProjectRespondent: (payload: any): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`admin/project/${payload.id}/assigned-projects`, toSnakeCase(payload)),

  removeProjectRespondent: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/admin/assigned-projects/${id}`),

  removeProjectRespondents: (payload: {
    assignedProjectsArray: number[];
  }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete('/admin/assigned-projects', {
      params: {
        ...toSnakeCase(payload),
      },
    }),

  getClients: (payload: { search: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/pharma-company', {
      params: toSnakeCase({
        search: `name:like:${payload.search?.trim()}`,
        resultsPerPage: 10000,
      }),
    }),

  getClientInvoices: (payload: {
    search: string;
    clientId: string;
  }): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/admin/client-invoices', {
      params: toSnakeCase({
        search: `invoice_number:like:${payload.search?.trim()},pharma_company_id:=:${
          payload.clientId
        }`,
        resultsPerPage: 10000,
      }),
    }),
};
