import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export interface RespondentUpdatePayload {
  id?: number;
  titleBefore: string;
  firstName: string;
  middleName: string;
  lastName: string;
  titleAfter: string;
  email: string;
  identityNumber: string;
  personalNumber: string;
  chamberId: number;
  chambers: { code: number; typeName: string }[];
}

export interface RespondentCreatePayload {
  firstName: string;
  lastName: string;
  email: string;
  chamberCode: number | null;
  chamberTypeId: number | null;
}

export const api = {
  getRespondents: ({ pagination, order, filter }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/admin/respondents', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  getRespondent: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/admin/users/${id}`),

  createRespondent: (payload: RespondentCreatePayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/admin/respondents', toSnakeCase(payload)),

  updateRespondent: (payload: RespondentUpdatePayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(`/admin/users/${payload?.id}`, toSnakeCase(payload)),

  removeRespondent: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/admin/users/${id}`),
};
