import i18n from 'i18next';
import React, { useEffect } from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { HelmetProvider } from 'react-helmet-async';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';

import cz from '../src/assets/translations/cz.json';
import sk from '../src/assets/translations/sk.json';

import { environment } from './environments/environment';
import RouteMapper from './helpers/route/components/RouteMapper/RouteMapper';
import routes from './helpers/route/routes/app-routes';
import { store, persistor } from './helpers/store/configure-store';
import { history } from './helpers/store/root-reducer';

import SEO from './views/components/SEO/SEO';
import ToastMessages from './views/components/ToastMessages/ToastMessages';
import { AppLayout } from './views/layout/AppLayout/AppLayout';

function App() {
  useEffect(() => {
    i18n.use(initReactI18next).init({
      resources: {
        sk: { translation: sk },
        cs: { translation: cz },
      },
      lng: environment.lang,
      fallbackLng: 'sk',
      interpolation: {
        escapeValue: false,
      },
      parseMissingKeyHandler: (key: string) => (environment.production ? '' : key),
      debug: environment.debug,
    });
  }, []);

  const helmetContext = {};

  return (
    <div className={'bg-gray-25 font-sans font-normal'}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <HistoryRouter history={history}>
            <ToastMessages />
            <HelmetProvider context={helmetContext}>
              <SEO title={environment.appName} />
              <AppLayout>
                <RouteMapper routes={routes} />
              </AppLayout>
            </HelmetProvider>
          </HistoryRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
