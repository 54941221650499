import React, { createContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { Disclosure, Transition } from '@headlessui/react';

import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/hamburger.svg';

import { ReactComponent as LockedIcon } from '../../../assets/icons/lock.svg';
import { Permission } from '../../../helpers/route/route';
import { menu } from '../../../mock/Menu';
import {
  selectAuthCachedUser,
  selectAuthLoggedIn,
  selectAuthUser,
} from '../../../saga/auth/selectors';
import AppLogo from '../../components/AppLogo/AppLogo';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import MenuDisclosure from '../../components/MenuDisclosure/MenuDisclosure';
import MenuDropdown from '../../components/MenuDropdown/MenuDropdown';
import MyProfileDisclosure from './components/MyProfileDisclosure/MyProfileDisclosure';
import MyProfileDropdown from './components/MyProfileDropdown/MyProfileDropdown';

interface IAppHeaderContext {
  handleCloseMenu: () => void;
}

export const AppHeaderContext = createContext<IAppHeaderContext>({
  handleCloseMenu: () => {},
});

const AppHeader = () => {
  const loggedIn = useSelector(selectAuthLoggedIn);
  const user = useSelector(selectAuthUser);
  const cachedAuthUser = useSelector(selectAuthCachedUser);

  const { t } = useTranslation();

  const [activeNavMenu, setActiveNavMenu] = useState<any>({});

  useEffect(() => {
    if (user) {
      setActiveNavMenu(!user?.tanzanitId ? menu.admin : menu.user);
    } else {
      setActiveNavMenu({});
    }
  }, [user?.tanzanitId]);

  return (
    <>
      <Disclosure as="nav" className="bg-white sticky top-0 z-10" data-testid={'header'}>
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 lg:px-4">
              <div className="relative flex h-16 items-center">
                <div className="flex shrink-0 items-center">
                  <AppLogo color={'black'} />
                </div>

                {loggedIn && (
                  <div
                    className={'flex shrink justify-between items-center w-full xl:pl-12'}
                    data-testid={user?.tanzanitId ? 'userMenu' : 'adminMenu'}
                  >
                    {user?.tanzanitId && (
                      <div
                        className={
                          'hidden lg:block absolute lg:p-3 font-sans font-normal text-sm text-secondary overflow-hidden truncate w-max'
                        }
                      >
                        {`${t(
                          `layout.appHeader.${!cachedAuthUser ? 'hello' : 'loggedInAsRespondent'}`
                        )} ${user?.firstName || ''} ${user?.lastName || ''}`}
                      </div>
                    )}
                    <div className="flex items-center justify-end lg:items-stretch w-full">
                      <div className="hidden lg:ml-6 lg:block">
                        <div className="flex md:space-x-4 lg:space-x-6 xl:space-x-8">
                          {Object.keys(activeNavMenu).map((item) => {
                            const hasPermission =
                              user?.permissions?.findIndex(
                                (perm) =>
                                  perm.name == activeNavMenu[item].permission ||
                                  perm.name === Permission.grant
                              ) !== -1 || !activeNavMenu[item]?.permission;

                            return activeNavMenu[item].children ? (
                              <div key={item}>
                                <MenuDropdown
                                  options={activeNavMenu[item].children}
                                  label={activeNavMenu[item].label}
                                />
                              </div>
                            ) : (
                              <Link
                                key={item}
                                to={activeNavMenu[item].to}
                                className={`font-normal font-sans flex items-center text-sm ${
                                  hasPermission
                                    ? 'text-secondary'
                                    : 'text-gray-75 pointer-events-none'
                                } py-2`}
                              >
                                {t(activeNavMenu[item].label)}
                                {!hasPermission && (
                                  <LockedIcon
                                    width={14}
                                    height={14}
                                    className={'stroke-gray-75 ml-1'}
                                  />
                                )}
                              </Link>
                            );
                          })}
                          <MyProfileDropdown />
                        </div>
                      </div>
                    </div>
                    {/* Mobile menu button*/}
                    <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-secondary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <CrossIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon className={'block h-6 w-6'} aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {loggedIn && (
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel
                  className={'lg:hidden block absolute w-full bg-white rounded-b shadow'}
                >
                  <div className="space-y-1 px-2 pt-2 pb-3 divide-y">
                    {Object.keys(activeNavMenu).map((item) =>
                      activeNavMenu[item].children ? (
                        <div key={item}>
                          <MenuDisclosure
                            items={activeNavMenu[item].children}
                            label={activeNavMenu[item].label}
                          />
                        </div>
                      ) : (
                        <Disclosure.Button
                          key={item}
                          as="a"
                          href={activeNavMenu[item].to}
                          className={'font-normal font-sans text-sm text-secondary block py-2 px-3'}
                        >
                          {t(activeNavMenu[item].label)}
                        </Disclosure.Button>
                      )
                    )}
                    <MyProfileDisclosure />
                  </div>
                </Disclosure.Panel>
              </Transition>
            )}
          </>
        )}
      </Disclosure>
      {loggedIn && <Breadcrumbs />}
    </>
  );
};

export default AppHeader;
