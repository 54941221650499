import React from 'react';

import { Pages } from './Pages';

export interface ITableHeadCols {
  label: string;
  accessor: string;
  sortable?: boolean;
  renderFilter?: React.ReactElement;
  classNames?: string;
  dataTestId?: string;
  renderLabel?: React.ReactElement;
}

export interface ITableHead {
  checked: boolean;
  setChecked: (e: boolean) => void;
  columns: ITableHeadCols[];
  sort: ITableOrder;
  handleOrdering: (accessor: string) => void;
  enableFiltering: boolean;
  enableSelect: boolean;
  onSelectChange?: (val: boolean) => void;
  classNames?: string;
}

export enum IOrderStatus {
  ASC = 'asc',
  DESC = 'desc',
}

export interface ITableOrder {
  sort: string;
  order: IOrderStatus;
}

export interface ISearch {
  value: string;
  prefix: string;
}

export interface ITableReq {
  pagination: Pages;
  order: ITableOrder;
  filter: string;
}
