import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export const api = {
  getInvoices: (payload: {
    invoiceNumber: string;
    pharmaCompanyId: string;
  }): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/admin/client-invoices', {
      params: toSnakeCase({
        page: 1,
        resultsPerPage: 10000,
        order: 'ASC',
        sort: 'name',
        search: `invoice_number:like:${payload.invoiceNumber},pharma_company_id:like:${payload.pharmaCompanyId}`,
      }),
    }),

  getProjectRespondents: ({
    id,
    pagination,
    order,
    filter,
  }: ITableReq & { id?: string }): Promise<ExtendedAxiosResponse> => {
    return apiClient.get(`/admin/client-invoice/${id}/assigned-projects`, {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },
  getClients: (payload: { search: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/pharma-company', {
      params: toSnakeCase({
        search: `name:like:${payload.search?.trim()}`,
        resultsPerPage: 10000,
      }),
    }),
  getProjects: (payload: { search: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/project', {
      params: toSnakeCase({
        search: `name:like:${payload.search?.trim()}`,
        resultsPerPage: 10000,
      }),
    }),
};
