import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export type TUserUpdatePayload = {
  id?: string;
  item: {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    titleBefore?: string;
    titleAfter?: string;
    email?: string;
    genderId?: number;
    birthday?: string;
    personalNumber?: string;
    chambers?: {
      typeName: string;
      code: number;
    }[];
    typeName?: string;
  };
};

export const api = {
  getUsers: ({ pagination, order, filter }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/admin/admin-users', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },

  removeUser: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/admin/users/${id}`),

  getUserProfile: (payload: { userId: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/admin/users/${payload.userId}`),

  managePermission: (payload: {
    userId: string;
    permissionId: string[];
  }): Promise<ExtendedAxiosResponse> =>
    apiClient.post(
      `/admin/users/${payload.userId}/manage-permissions`,
      toSnakeCase({
        ...payload,
      })
    ),

  updateUserProfile: (payload: TUserUpdatePayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(payload.id ? `/admin/users/${payload.id}` : '/user/update', {
      item: payload.item,
    }),

  sendChangeEmailRequest: (payload: { userId: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/user/${payload.userId}/change-email`, {}),

  changeEmail: (payload: {
    email: string;
    userId: string;
    expires: string;
    signature: string;
  }): Promise<ExtendedAxiosResponse> => {
    const formData = new FormData();

    formData.append('email', payload.email);

    return apiClient.post(`/user/${payload.userId}/confirm-email-change`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        expires: payload.expires,
        signature: payload.signature,
      },
    });
  },

  verifyEmail: (payload: {
    email: string;
    userId: string;
    expires: string;
    signature: string;
  }): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/user/${payload.userId}/verify-email-change`, undefined, {
      params: {
        email: payload.email,
        expires: payload.expires,
        signature: payload.signature,
      },
    }),

  createUser: (payload: {
    titleBefore: string;
    firstName: string;
    middleName: string;
    lastName: string;
    titleAfter: string;
    email: string;
    password: string;
    passwordConfirmation: string;
  }): Promise<ExtendedAxiosResponse> => apiClient.post('/admin/admin-users', toSnakeCase(payload)),
};
