import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum ProjectRespondentActionTypes {
  GetProjectRespondents = '@@ProjectRespondent/GET_PROJECT_RESPONDENTS',
  SetProjectRespondentsPage = '@@ProjectRespondent/SET_PROJECT_RESPONDENTS_PAGE',
  IncreasePage = '@@ProjectRespondent/INCREASE_PROJECT_RESPONDENTS',
  SetOrderColumn = '@@ProjectRespondent/SET_ORDER_COLUMN',
  SetFilterColumn = '@@ProjectRespondent/SET_FILTER_COLUMNS',
  RemoveProjectRespondent = '@@ProjectRespondent/REMOVE_PROJECT_RESPONDENTS',
  CreateProjectRespondent = '@@ProjectRespondent/CREATE_PROJECT_RESPONDENT',
  RemoveMultipleProjectRespondents = '@@ProjectRespondent/REMOVE_MULTIPLE_PROJECT_RESPONDENTS',
  SetProjectId = '@@ProjectSetProjectId/SET_PROJECT_ID',
  GetClients = '@@ProjectRespondent/GET_CLIENTS',
  GetClientInvoices = '@@ProjectRespondent/GET_CLIENT_INVOICES',
}

/* ACTIONS */
export const projectRespondentGetProjectRespondentsActions = createApiActionCreators(
  ProjectRespondentActionTypes.GetProjectRespondents
);
export const projectRespondentSetProjectRespondentsPageActions = createActionCreator(
  ProjectRespondentActionTypes.SetProjectRespondentsPage
);
export const projectRespondentIncreasePageActions = createActionCreator(
  ProjectRespondentActionTypes.IncreasePage
);
export const projectRespondentSetOrderColumnActions = createActionCreator(
  ProjectRespondentActionTypes.SetOrderColumn
);
export const projectRespondentSetFilterColumnActions = createActionCreator(
  ProjectRespondentActionTypes.SetFilterColumn
);
export const projectRespondentRemoveProjectRespondentActions = createApiActionCreators(
  ProjectRespondentActionTypes.RemoveProjectRespondent
);

export const projectRespondentCreateProjectRespondentActions = createApiActionCreators(
  ProjectRespondentActionTypes.CreateProjectRespondent
);

export const projectRespondentGetClientsActions = createApiActionCreators(
  ProjectRespondentActionTypes.GetClients
);

export const projectRespondentRemoveMultipleProjectRespondentsActions = createApiActionCreators(
  ProjectRespondentActionTypes.RemoveMultipleProjectRespondents
);

export const projectRespondentSetProjectId = createActionCreator(
  ProjectRespondentActionTypes.SetProjectId
);

export const projectRespondentGetClientInvoicesActions = createApiActionCreators(
  ProjectRespondentActionTypes.GetClientInvoices
);
