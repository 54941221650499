import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum HandoverProtocolActionTypes {
  GetHandoverProtocols = '@@HandoverProtocol/GET_HANDOVER_PROTOCOLS',
  SetHandoverProtocolsPage = '@@HandoverProtocol/SET_HANDOVER_PROTOCOLS_PAGE',
  IncreasePage = '@@HandoverProtocol/INCREASE_HANDOVER_PROTOCOLS',
  SetOrderColumn = '@@HandoverProtocol/SET_ORDER_COLUMN',
  SetFilterColumn = '@@HandoverProtocol/SET_FILTER_COLUMNS',
  DownloadPdf = '@@HandoverProtocol/DOWNLOAD_PDF',
}

/* ACTIONS */
export const handoverProtocolGetHandoverProtocolsActions = createApiActionCreators(
  HandoverProtocolActionTypes.GetHandoverProtocols
);
export const handoverProtocolSetHandoverProtocolsPageActions = createActionCreator(
  HandoverProtocolActionTypes.SetHandoverProtocolsPage
);
export const handoverProtocolIncreasePageActions = createActionCreator(
  HandoverProtocolActionTypes.IncreasePage
);
export const handoverProtocolSetOrderColumnActions = createActionCreator(
  HandoverProtocolActionTypes.SetOrderColumn
);
export const handoverProtocolSetFilterColumnActions = createActionCreator(
  HandoverProtocolActionTypes.SetFilterColumn
);

export const handoverProtocolDownloadPdfActions = createApiActionCreators(
  HandoverProtocolActionTypes.DownloadPdf
);
