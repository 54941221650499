import { Environment } from './Type';

export const environment: Environment = {
  debug: true,
  production: false,

  lang: 'sk',
  currency: '€',

  appName: 'eDotazníky',
  appDescription:
    'Webová aplikácia na vytvorenie a distribúciu rôznych prieskumov vybraným respondentom.',

  api: {
    protocol: 'http',
    host: 'localhost',
    port: 3030,
  },

  recaptchaKey: '6LfZGI0fAAAAAGdQ8kTVidq3a9mqEYKeUYC1Ri7D',

  defaultPagination: {
    perPage: 50,
  },
};
