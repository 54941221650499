import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { OverviewRoutes } from '../../helpers/route/routes/overview-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { Client } from '../../models/Client';
import { ClientInvoice } from '../../models/ClientInvoice';

import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';

import {
  ClientInvoiceActionTypes,
  clientInvoiceCreateClientInvoiceActions,
  clientInvoiceGetClientInvoiceActions,
  clientInvoiceGetClientInvoicesActions,
  clientInvoiceGetClientsActions,
  clientInvoiceRemoveClientInvoiceActions,
  clientInvoiceRemoveMultipleClientInvoicesActions,
  clientInvoiceUpdateClientInvoiceActions,
} from './actions';
import { api, ClientInvoicePayload } from './api';
import {
  selectClientInvoiceClientInvoicesFilterColumn,
  selectClientInvoiceClientInvoicesOrderColumn,
  selectClientInvoiceClientInvoicesPagination,
} from './selectors';

/* STATE */
export interface ClientInvoiceState {
  clientInvoices: ClientInvoice[];
  clientInvoicesPagination: Pages;
  clientInvoicesStatus: LoadingStatus;
  clientInvoicesOrder: ITableOrder;
  clientInvoicesFilter: Record<string, ISearch>;
  clientInvoice: ClientInvoice | null;
  clientInvoiceStatus: LoadingStatus;
  clients: { id: string; name: string }[];
  clientsStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: ClientInvoiceState = {
  clientInvoices: [],
  clientInvoicesPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  clientInvoicesStatus: LoadingStatus.initial,
  clientInvoicesOrder: {
    sort: '',
    order: IOrderStatus.DESC,
  },
  clientInvoicesFilter: {},
  clientInvoice: null,
  clientInvoiceStatus: LoadingStatus.initial,
  clients: [],
  clientsStatus: LoadingStatus.initial,
};

const clientInvoices = createReducer(initialState.clientInvoices, {
  [ClientInvoiceActionTypes.GetClientInvoices]: {
    [RequestActionTypes.SUCCESS]: (
      state: ClientInvoice[],
      payload: PaginatedResp<ClientInvoice>
    ) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.clientInvoices,
  },
  [ClientInvoiceActionTypes.UpdateClientInvoice]: {
    [RequestActionTypes.SUCCESS]: (state: ClientInvoice[], payload: ClientInvoice) => {
      const index = state.findIndex((client) => client.id === payload.id);
      if (index < 0) return state;

      const newState = [...state];
      newState[index] = payload;

      return newState;
    },
  },
  [ClientInvoiceActionTypes.RemoveClientInvoice]: {
    [RequestActionTypes.SUCCESS]: (state: ClientInvoice[], payload: { id: number }) => {
      return state.filter((clientInvoice) => clientInvoice.id !== payload.id);
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clientInvoices,
  },
});

const clientInvoicesPagination = createReducer(initialState.clientInvoicesPagination, {
  [ClientInvoiceActionTypes.GetClientInvoices]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<ClientInvoice>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.clientInvoicesPagination,
  },
  [ClientInvoiceActionTypes.SetClientInvoicesPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [ClientInvoiceActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [ClientInvoiceActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ClientInvoiceActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clientInvoicesPagination,
  },
});

const clientInvoicesStatus = createLoadingStateReducer(
  initialState.clientInvoicesStatus,
  {
    [ClientInvoiceActionTypes.GetClientInvoices]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ClientInvoiceActionTypes.SetClientInvoicesPage]: () => initialState.clientInvoicesStatus,
    [ClientInvoiceActionTypes.IncreasePage]: () => initialState.clientInvoicesStatus,
    [ClientInvoiceActionTypes.SetFilterColumn]: () => initialState.clientInvoicesStatus,
    [ClientInvoiceActionTypes.SetOrderColumn]: () => initialState.clientInvoicesStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.clientInvoicesStatus,
    },
  }
);

const clientInvoicesOrder = createReducer(initialState.clientInvoicesOrder, {
  [ClientInvoiceActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clientInvoicesOrder,
  },
});

const clientInvoicesFilter = createReducer(initialState.clientInvoicesFilter, {
  [ClientInvoiceActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clientInvoicesFilter,
  },
});

const clientInvoice = createReducer(initialState.clientInvoice, {
  [ClientInvoiceActionTypes.CreateClientInvoice]: {
    [RequestActionTypes.SUCCESS]: (state: ClientInvoice | null, payload: ClientInvoice) => payload,
  },
  [ClientInvoiceActionTypes.GetClientInvoice]: {
    [RequestActionTypes.REQUEST]: () => initialState.clientInvoice,
    [RequestActionTypes.SUCCESS]: (state: ClientInvoice | null, payload: ClientInvoice) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.clientInvoice,
  },
  [ClientInvoiceActionTypes.UpdateClientInvoice]: {
    [RequestActionTypes.SUCCESS]: (state: ClientInvoice | null, payload: ClientInvoice) => payload,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clientInvoice,
  },
});

const clients = createReducer(initialState.clients, {
  [ClientInvoiceActionTypes.GetClients]: {
    [RequestActionTypes.SUCCESS]: (state: Client[], payload: Client[]) =>
      payload?.reduce((acc: { id: string; name: string }[], i) => {
        acc.push({
          id: `${i.id}`,
          name: i.name,
        });
        return acc;
      }, []) || initialState.clients,
  },
  [ClientInvoiceActionTypes.GetClientInvoice]: {
    [RequestActionTypes.SUCCESS]: (state: Client[], payload: ClientInvoice) => {
      return state.find((client) => `${client.id}` == `${payload.pharmaCompanyId}`)
        ? state
        : [
            ...state,
            {
              id: `${payload.pharmaCompanyId}`,
              name: payload.pharmaCompanyName,
            },
          ];
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clients,
  },
});

const clientsStatus = createLoadingStateReducer(
  initialState.clientsStatus,
  {
    [ClientInvoiceActionTypes.GetClients]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.clientsStatus,
    },
  }
);

const clientInvoiceStatus = createLoadingStateReducer(
  initialState.clientInvoiceStatus,
  {
    [ClientInvoiceActionTypes.UpdateClientInvoice]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [ClientInvoiceActionTypes.CreateClientInvoice]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ClientInvoiceActionTypes.GetClientInvoice]: {
      [RequestActionTypes.REQUEST]: () => initialState.clientInvoiceStatus,
    },
    [ClientInvoiceActionTypes.GetClientInvoices]: {
      [RequestActionTypes.REQUEST]: () => initialState.clientInvoiceStatus,
    },
    [ClientInvoiceActionTypes.SetClientInvoicesPage]: () => initialState.clientInvoiceStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.clientInvoiceStatus,
    },
  }
);

export default combineReducers<ClientInvoiceState>({
  clientInvoices,
  clientInvoicesPagination,
  clientInvoicesStatus,
  clientInvoicesOrder,
  clientInvoicesFilter,
  clientInvoice,
  clients,
  clientsStatus,
  clientInvoiceStatus,
});

/* SAGAS */
function* getClientInvoices() {
  const pagination: Pages = yield select(selectClientInvoiceClientInvoicesPagination);
  const orderColumn: ITableOrder = yield select(selectClientInvoiceClientInvoicesOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(
    selectClientInvoiceClientInvoicesFilterColumn
  );

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getClientInvoices, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(clientInvoiceGetClientInvoicesActions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(clientInvoiceGetClientInvoicesActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeClientInvoice({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeClientInvoice, payload);

  if (resp.ok) {
    yield put(clientInvoiceRemoveClientInvoiceActions.success({ id: payload.id }));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(clientInvoiceRemoveClientInvoiceActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createClientInvoice({ payload }: AppAction<ClientInvoicePayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.createClientInvoice, payload);

  if (resp.ok) {
    yield put(clientInvoiceCreateClientInvoiceActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    yield put(clientInvoiceGetClientInvoicesActions.request());
  } else {
    yield put(clientInvoiceCreateClientInvoiceActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getClientInvoice({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getClientInvoice, payload);

  if (resp.ok) {
    yield put(clientInvoiceGetClientInvoiceActions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(clientInvoiceGetClientInvoiceActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
    history.push(
      buildRoute([AppRoutes.Admin, AdminRoutes.Overviews, OverviewRoutes.ClientInvoice])
    );
  }
}

function* updateClientInvoice({ payload }: AppAction<ClientInvoicePayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateClientInvoice, payload);

  if (resp.ok) {
    yield put(clientInvoiceUpdateClientInvoiceActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));

    history.push(
      buildRoute([AppRoutes.Admin, AdminRoutes.Overviews, OverviewRoutes.ClientInvoice])
    );
  } else {
    yield put(clientInvoiceUpdateClientInvoiceActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getClients({ payload }: AppAction<any>) {
  const resp: ExtendedAxiosResponse = yield call(api.getClients, payload);

  if (resp.ok) {
    yield put(clientInvoiceGetClientsActions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(clientInvoiceGetClientsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeMultipleClientInvoices({ payload }: AppAction<{ clientInvoicesArray: any[] }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeClientInvoices, payload);

  if (resp.ok) {
    yield put(clientInvoiceRemoveMultipleClientInvoicesActions.success(toCamelCase(resp.data)));
    yield put(toastCreateSuccessActions(resp.data?.message));
    yield put(clientInvoiceGetClientInvoicesActions.request());
  } else {
    yield put(clientInvoiceRemoveMultipleClientInvoicesActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* clientInvoiceSaga() {
  yield takeLatest(
    [
      createActionType(ClientInvoiceActionTypes.GetClientInvoices, RequestActionTypes.REQUEST),
      ClientInvoiceActionTypes.SetClientInvoicesPage,
      ClientInvoiceActionTypes.IncreasePage,
      ClientInvoiceActionTypes.SetOrderColumn,
      ClientInvoiceActionTypes.SetFilterColumn,
    ],
    getClientInvoices
  );
  yield takeLatest(
    [createActionType(ClientInvoiceActionTypes.RemoveClientInvoice, RequestActionTypes.REQUEST)],
    removeClientInvoice
  );
  yield takeLatest(
    createActionType(ClientInvoiceActionTypes.CreateClientInvoice, RequestActionTypes.REQUEST),
    createClientInvoice
  );
  yield takeLatest(
    createActionType(ClientInvoiceActionTypes.GetClientInvoice, RequestActionTypes.REQUEST),
    getClientInvoice
  );
  yield takeLatest(
    createActionType(ClientInvoiceActionTypes.UpdateClientInvoice, RequestActionTypes.REQUEST),
    updateClientInvoice
  );
  yield takeLatest(
    createActionType(ClientInvoiceActionTypes.GetClients, RequestActionTypes.REQUEST),
    getClients
  );
  yield takeLatest(
    createActionType(
      ClientInvoiceActionTypes.RemoveMultipleClientInvoices,
      RequestActionTypes.REQUEST
    ),
    removeMultipleClientInvoices
  );
}
