import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

export enum BankTransferActionTypes {
  GetExports = '@@BankTransfer/GET_EXPORTS',
  SetExport = '@@BankTransfer/SET_EXPORT',
  GetBankTransfers = '@@BankTransfer/GET_PROJECT_RESPONDENTS',
  SetBankTransfersPage = '@@BankTransfer/SET_PROJECT_RESPONDENTS_PAGE',
  IncreasePage = '@@BankTransfer/INCREASE_PROJECT_RESPONDENTS',
  SetOrderColumn = '@@BankTransfer/SET_ORDER_COLUMN',
  SetFilterColumn = '@@BankTransfer/SET_FILTER_COLUMNS',
  CleanData = '@@BankTransfer/CLEAN_DATA',
  UpdateBankTransfer = '@@BankTransfer/UPDATE_BANK_TRANSFER',
  GetGenerationTime = '@@BankTransfer/GET_GENERATION_TIME',
  UpdateGenerationTime = '@@BankTransfer/UPDATE_GENERATION_TIME',
  ChangeSpr = '@@BankTransfer/CHANGE_SPR',
  GetInvoiceDoc = '@@BankTransfer/GET_INVOICE_DOC',
  UpdateLink = '@@BankTransfer/UPDATE_LINK',
  RemoveLink = '@@BankTransfer/REMOVE_LINK',
  DownloadFile = '@@BankTransfer/DOWNLOAD_FILE',
  RemoveFile = '@@BankTransfer/REMOVE_FILE',
  UpdateFile = '@@BankTransfer/UPDATE_FILE',
}

/* ACTIONS */
export const bankTransferGetBankTransfersActions = createApiActionCreators(
  BankTransferActionTypes.GetBankTransfers
);
export const bankTransferSetBankTransfersPageActions = createActionCreator(
  BankTransferActionTypes.SetBankTransfersPage
);
export const bankTransferIncreasePageActions = createActionCreator(
  BankTransferActionTypes.IncreasePage
);
export const bankTransferSetOrderColumnActions = createActionCreator(
  BankTransferActionTypes.SetOrderColumn
);
export const bankTransferSetFilterColumnActions = createActionCreator(
  BankTransferActionTypes.SetFilterColumn
);

export const bankTransferGetExports = createApiActionCreators(BankTransferActionTypes.GetExports);

export const bankTransferSetExport = createActionCreator(BankTransferActionTypes.SetExport);

export const bankTransferCleanData = createActionCreator(BankTransferActionTypes.CleanData);

export const bankTransferUpdateBankTransferActions = createApiActionCreators(
  BankTransferActionTypes.UpdateBankTransfer
);

export const bankTransferGetGenerationTimeActions = createApiActionCreators(
  BankTransferActionTypes.GetGenerationTime
);

export const bankTransferUpdateGenerationTimeActions = createApiActionCreators(
  BankTransferActionTypes.UpdateGenerationTime
);

export const bankTransferChangeSprActions = createApiActionCreators(
  BankTransferActionTypes.ChangeSpr
);

export const bankTransferGetInvoiceDoc = createApiActionCreators(
  BankTransferActionTypes.GetInvoiceDoc
);

export const bankTransferUpdateLinkActions = createApiActionCreators(
  BankTransferActionTypes.UpdateLink
);
export const bankTransferRemoveLinkActions = createApiActionCreators(
  BankTransferActionTypes.RemoveLink
);
export const bankTransferUpdateFileActions = createApiActionCreators(
  BankTransferActionTypes.UpdateFile
);
export const bankTransferRemoveFileActions = createApiActionCreators(
  BankTransferActionTypes.RemoveFile
);
export const bankTransferDownloadFileActions = createApiActionCreators(
  BankTransferActionTypes.DownloadFile
);
