import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import { ITableReq } from '../../models/Table';

/* ACTION TYPES */
export enum WinnerActionTypes {
  GetWinners = '@@Winner/GET_WINNERS',
  SetWinnersPage = '@@Winner/SET_WINNERS_PAGE',
  IncreasePage = '@@Winner/INCREASE_WINNERS',
  SetOrderColumn = '@@Winner/SET_ORDER_COLUMN',
  SetFilterColumn = '@@Winner/SET_FILTER_COLUMNS',
}

/* ACTIONS */
export const winnerGetWinnersActions = createApiActionCreators<ITableReq>(
  WinnerActionTypes.GetWinners
);
export const winnerSetWinnersPageActions = createActionCreator(WinnerActionTypes.SetWinnersPage);
export const winnerIncreasePageActions = createActionCreator(WinnerActionTypes.IncreasePage);
export const winnerSetOrderColumnActions = createActionCreator(WinnerActionTypes.SetOrderColumn);
export const winnerSetFilterColumnActions = createActionCreator(WinnerActionTypes.SetFilterColumn);
