import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export interface DistrictPayload {
  id?: number;
  name: string;
  regionId: number | null;
  state: boolean;
}

export const api = {
  getDistricts: ({ pagination, order, filter }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/territory', {
      params: toSnakeCase({
        territoryType: 'District',
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: order.sort,
        search: filter,
      }),
    });
  },

  removeDistrict: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/territory/${id}`, {
      params: toSnakeCase({
        territoryType: 'District',
      }),
    }),

  getRegions: (): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/territory', {
      params: toSnakeCase({
        territoryType: 'Region',
      }),
    }),

  getDistrict: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/territory/${id}`, {
      params: toSnakeCase({
        territoryType: 'District',
      }),
    }),

  createDistrict: (payload: DistrictPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post(
      '/territory',
      toSnakeCase({
        territoryType: 'District',
        data: {
          ...payload,
        },
      })
    ),

  updateDistrict: (payload: DistrictPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(
      `/territory/${payload?.id}`,
      toSnakeCase({
        territoryType: 'District',
        data: {
          ...payload,
        },
      })
    ),
};
