import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum BankActionTypes {
  GetBanks = '@@Bank/GET_BANKS',
  SetBanksPage = '@@Bank/SET_BANKS_PAGE',
  IncreasePage = '@@Bank/INCREASE_BANKS',
  SetOrderColumn = '@@Bank/SET_ORDER_COLUMN',
  SetFilterColumn = '@@Bank/SET_FILTER_COLUMNS',
  RemoveBank = '@@Bank/REMOVE_BANK',
  CreateBank = '@@Bank/CREATE_BANK',
  GetBank = '@@Bank/GET_BANK',
  UpdateBank = '@@Bank/UPDATE_BANK',
}

/* ACTIONS */
export const bankGetBanksActions = createApiActionCreators(BankActionTypes.GetBanks);
export const bankSetBanksPageActions = createActionCreator(BankActionTypes.SetBanksPage);
export const bankIncreasePageActions = createActionCreator(BankActionTypes.IncreasePage);
export const bankSetOrderColumnActions = createActionCreator(BankActionTypes.SetOrderColumn);
export const bankSetFilterColumnActions = createActionCreator(BankActionTypes.SetFilterColumn);
export const bankRemoveBankActions = createApiActionCreators(BankActionTypes.RemoveBank);

export const bankCreateBankActions = createApiActionCreators(BankActionTypes.CreateBank);
export const bankUpdateBankActions = createApiActionCreators(BankActionTypes.UpdateBank);
export const bankGetBankActions = createApiActionCreators(BankActionTypes.GetBank);
