import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Country } from '../../models/Country';
import { Pages } from '../../models/Pages';
import { Region } from '../../models/Region';
import { ISearch, ITableOrder } from '../../models/Table';
import { RegionState } from './ducks';

/* SELECTORS */
const selectRegion = (state: AppState): RegionState => state.region;

export const selectRegionRegions = (state: AppState): Region[] => selectRegion(state).regions;

export const selectRegionRegionsPagination = (state: AppState): Pages =>
  selectRegion(state).regionsPagination;

export const selectRegionRegionsStatus = (state: AppState): LoadingStatus =>
  selectRegion(state).regionsStatus;

export const selectRegionRegionsOrderColumn = (state: AppState): ITableOrder =>
  selectRegion(state).regionsOrder;

export const selectRegionRegionsFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectRegion(state).regionsFilter;

export const selectRegionRegion = (state: AppState): Region | null => selectRegion(state).region;

export const selectRegionCountries = (state: AppState): Country[] => selectRegion(state).countries;

export const selectRegionRegionActionStatus = (state: AppState): LoadingStatus =>
  selectRegion(state).regionActionStatus;
