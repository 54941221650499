import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import { UpsertContactsPayload, GetContactsPayload, RemoveContactsPayload } from './api';

/* ACTION TYPES */
export enum ContactActionTypes {
  GetContacts = '@@Contact/GET_PROFESSIONS',
  UpsertContact = '@@Contact/UPSERT_CONTACT',
  RemoveContact = '@@Contact/REMOVE_CONTACT',
  SetContactsPage = '@@Contact/SET_PROFESSIONS_PAGE',
  IncreasePage = '@@Contact/INCREASE_PAGE',
  SetOrderColumn = '@@Contact/SET_ORDER_COLUMN',
  SetFilterColumn = '@@Contact/SET_FILTER_COLUMNS',
}

/* ACTIONS */
export const contactGetContactsActions = createApiActionCreators<GetContactsPayload>(
  ContactActionTypes.GetContacts
);
export const contactUpsertContactActions = createApiActionCreators<UpsertContactsPayload>(
  ContactActionTypes.UpsertContact
);
export const contactRemoveContactActions = createApiActionCreators<RemoveContactsPayload>(
  ContactActionTypes.RemoveContact
);
export const contactSetContactsPage = createActionCreator(ContactActionTypes.SetContactsPage);
export const contactIncreasePageActions = createActionCreator(ContactActionTypes.IncreasePage);
export const contactSetOrderColumnActions = createActionCreator(ContactActionTypes.SetOrderColumn);
export const contactSetFilterColumnActions = createActionCreator(
  ContactActionTypes.SetFilterColumn
);
