import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { ContactType } from '../../models/User';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  ContactActionTypes,
  contactGetContactsActions,
  contactRemoveContactActions,
  contactUpsertContactActions,
} from './actions';
import { api, RemoveContactsPayload, UpsertContactsPayload } from './api';
import {
  selectContactContactsFilterColumn,
  selectContactContactsOrderColumn,
  selectContactContactsPagination,
} from './selectors';

export interface ContactState {
  contacts: ContactType[];
  contactsPagination: Pages;
  contactsStatus: LoadingStatus;
  contactsOrder: ITableOrder;
  contactsFilter: Record<string, ISearch>;
  contactActionStatus: LoadingStatus;
}

const initialState: ContactState = {
  contacts: [],
  contactsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  contactsStatus: LoadingStatus.initial,
  contactsOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  contactsFilter: {},
  contactActionStatus: LoadingStatus.initial,
};

const contacts = createReducer(initialState.contacts, {
  [ContactActionTypes.GetContacts]: {
    [RequestActionTypes.SUCCESS]: (state: ContactType[], payload: PaginatedResp<ContactType>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.contacts,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.contacts,
  },
});

const contactsPagination = createReducer(initialState.contactsPagination, {
  [ContactActionTypes.GetContacts]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<ContactType>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.contactsPagination,
  },
  [ContactActionTypes.SetContactsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [ContactActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [ContactActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ContactActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.contactsPagination,
  },
});

const contactsStatus = createLoadingStateReducer(
  initialState.contactsStatus,
  {
    [ContactActionTypes.GetContacts]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ContactActionTypes.SetContactsPage]: () => initialState.contactsStatus,
    [ContactActionTypes.IncreasePage]: () => initialState.contactsStatus,
    [ContactActionTypes.SetFilterColumn]: () => initialState.contactsStatus,
    [ContactActionTypes.SetOrderColumn]: () => initialState.contactsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.contactsStatus,
    },
  }
);

const contactsOrder = createReducer(initialState.contactsOrder, {
  [ContactActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.contactsOrder,
  },
});

const contactsFilter = createReducer(initialState.contactsFilter, {
  [ContactActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.contactsFilter,
  },
});

const contactActionStatus = createLoadingStateReducer(
  initialState.contactActionStatus,
  {
    [ContactActionTypes.UpsertContact]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [ContactActionTypes.RemoveContact]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.contactActionStatus,
    },
  }
);

export default combineReducers<ContactState>({
  contacts,
  contactsPagination,
  contactsStatus,
  contactsOrder,
  contactsFilter,
  contactActionStatus,
});

function* GetContacts() {
  const pagination: Pages = yield select(selectContactContactsPagination);
  const orderColumn: ITableOrder = yield select(selectContactContactsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(selectContactContactsFilterColumn);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getContacts, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(contactGetContactsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(contactGetContactsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* upsertContacts({ payload }: AppAction<UpsertContactsPayload>) {
  const resp: ExtendedAxiosResponse = yield call(
    payload.id ? api.upsertContactsAdmin : api.upsertContacts,
    payload
  );

  if (resp.ok) {
    yield put(contactUpsertContactActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    if (payload.id) {
      history.push(
        buildRoute([AppRoutes.Admin, AdminRoutes.RespondentsDetail], {
          respondentId: `${payload.id}`,
        })
      );
    } else {
      history.push(buildRoute(AppRoutes.Profile));
    }
  } else {
    yield put(contactUpsertContactActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeContacts({ payload }: AppAction<RemoveContactsPayload>) {
  const resp: ExtendedAxiosResponse = yield call(
    payload.id ? api.removeContactsAdmin : api.removeContacts,
    payload
  );

  if (resp.ok) {
    yield put(contactRemoveContactActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(contactRemoveContactActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

export function* contactSaga() {
  yield takeLatest(
    [
      createActionType(ContactActionTypes.GetContacts, RequestActionTypes.REQUEST),
      ContactActionTypes.SetContactsPage,
      ContactActionTypes.IncreasePage,
      ContactActionTypes.SetOrderColumn,
      ContactActionTypes.SetFilterColumn,
    ],
    GetContacts
  );
  yield takeLatest(
    createActionType(ContactActionTypes.UpsertContact, RequestActionTypes.REQUEST),
    upsertContacts
  );
  yield takeLatest(
    createActionType(ContactActionTypes.RemoveContact, RequestActionTypes.REQUEST),
    removeContacts
  );
}
