import { omit } from 'lodash';
import { combineReducers } from 'redux';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IProject } from '../../models/Project';
import { ProjectsDataOverviewProjectRespondent } from '../../models/ProjectsDataOverview';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions } from '../toast/actions';
import {
  ProjectsDataOverviewActionTypes,
  projectsDataOverviewGetProjectRespondentsActions,
  projectsDataOverviewGetProjects,
} from './actions';
import { api } from './api';
import {
  selectProjectsDataOverviewProject,
  selectProjectsDataOverviewProjectRespondentsFilterColumn,
  selectProjectsDataOverviewProjectRespondentsOrderColumn,
  selectProjectsDataOverviewProjectRespondentsPagination,
} from './selectors';

/* STATE */
export interface ProjectsDataOverviewSummary {
  numberOfAssignedProjects: number;
  surveysInProgressCount: number;
}
export interface ProjectsDataOverviewState {
  projects: IProject[];
  projectsStatus: LoadingStatus;
  project: IProject | null;
  projectRespondents: ProjectsDataOverviewProjectRespondent[];
  projectRespondentsPagination: Pages;
  projectRespondentsStatus: LoadingStatus;
  projectRespondentsOrder: ITableOrder;
  projectRespondentsFilter: Record<string, ISearch>;
  summary: ProjectsDataOverviewSummary;
}

/* REDUCERS */
const initialState: ProjectsDataOverviewState = {
  projects: [],
  projectsStatus: LoadingStatus.initial,
  project: null,
  projectRespondents: [],
  projectRespondentsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  projectRespondentsStatus: LoadingStatus.initial,
  projectRespondentsOrder: {
    sort: '',
    order: IOrderStatus.DESC,
  },
  projectRespondentsFilter: {},
  summary: {
    numberOfAssignedProjects: 0,
    surveysInProgressCount: 0,
  },
};

const projects = createReducer(initialState.projects, {
  [ProjectsDataOverviewActionTypes.GetProjects]: {
    [RequestActionTypes.SUCCESS]: (state: IProject[], payload: IProject[]) => payload,
  },
  [ProjectsDataOverviewActionTypes.CleanData]: () => initialState.projects,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projects,
  },
});

const projectsStatus = createLoadingStateReducer(
  initialState.projectsStatus,
  {
    [ProjectsDataOverviewActionTypes.GetProjects]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.projectsStatus,
    },
  }
);

const project = createReducer(initialState.project, {
  [ProjectsDataOverviewActionTypes.SetProject]: (state: IProject | null, payload: IProject) =>
    payload,
  [ProjectsDataOverviewActionTypes.CleanData]: () => initialState.project,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.project,
  },
});

const projectRespondents = createReducer(initialState.projectRespondents, {
  [ProjectsDataOverviewActionTypes.GetProjectRespondents]: {
    [RequestActionTypes.SUCCESS]: (
      state: ProjectsDataOverviewProjectRespondent[],
      payload: PaginatedResp<ProjectsDataOverviewProjectRespondent>
    ) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.projectRespondents,
  },
  [ProjectsDataOverviewActionTypes.CleanData]: () => initialState.projectRespondents,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectRespondents,
  },
});

const projectRespondentsPagination = createReducer(initialState.projectRespondentsPagination, {
  [ProjectsDataOverviewActionTypes.GetProjectRespondents]: {
    [RequestActionTypes.SUCCESS]: (
      state: Pages,
      payload: PaginatedResp<ProjectsDataOverviewProjectRespondent>
    ) => omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.projectRespondentsPagination,
  },
  [ProjectsDataOverviewActionTypes.SetProjectRespondentsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [ProjectsDataOverviewActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [ProjectsDataOverviewActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ProjectsDataOverviewActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ProjectsDataOverviewActionTypes.CleanData]: () => initialState.projectRespondentsPagination,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectRespondentsPagination,
  },
});

const projectRespondentsStatus = createLoadingStateReducer(
  initialState.projectRespondentsStatus,
  {
    [ProjectsDataOverviewActionTypes.GetProjectRespondents]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ProjectsDataOverviewActionTypes.SetProjectRespondentsPage]: () =>
      initialState.projectRespondentsStatus,
    [ProjectsDataOverviewActionTypes.IncreasePage]: () => initialState.projectRespondentsStatus,
    [ProjectsDataOverviewActionTypes.SetFilterColumn]: () => initialState.projectRespondentsStatus,
    [ProjectsDataOverviewActionTypes.SetOrderColumn]: () => initialState.projectRespondentsStatus,
    [ProjectsDataOverviewActionTypes.CleanData]: () => initialState.projectRespondentsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.projectRespondentsStatus,
    },
  }
);

const projectRespondentsOrder = createReducer(initialState.projectRespondentsOrder, {
  [ProjectsDataOverviewActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [ProjectsDataOverviewActionTypes.CleanData]: () => initialState.projectRespondentsOrder,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectRespondentsOrder,
  },
});

const projectRespondentsFilter = createReducer(initialState.projectRespondentsFilter, {
  [ProjectsDataOverviewActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [ProjectsDataOverviewActionTypes.CleanData]: () => initialState.projectRespondentsFilter,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectRespondentsFilter,
  },
});

const summary = createReducer(initialState.summary, {
  [ProjectsDataOverviewActionTypes.GetProjectRespondents]: {
    [RequestActionTypes.SUCCESS]: (
      state: ProjectsDataOverviewSummary,
      payload: {
        summary: ProjectsDataOverviewSummary;
      } & PaginatedResp<ProjectsDataOverviewProjectRespondent>
    ) => {
      const numberOfAssignedProjects = parseInt(`${payload?.summary?.numberOfAssignedProjects}`);
      const surveysInProgressCount = parseInt(`${payload?.summary?.surveysInProgressCount}`);

      if (!isNaN(surveysInProgressCount) && !isNaN(numberOfAssignedProjects)) {
        if (payload?.currentPage == 1) {
          return {
            numberOfAssignedProjects: numberOfAssignedProjects,
            surveysInProgressCount: surveysInProgressCount,
          };
        } else {
          return {
            numberOfAssignedProjects: state.numberOfAssignedProjects + numberOfAssignedProjects,
            surveysInProgressCount: state.surveysInProgressCount + surveysInProgressCount,
          };
        }
      } else {
        return state;
      }
    },
  },
  [ProjectsDataOverviewActionTypes.SetProject]: () => initialState.summary,
  [ProjectsDataOverviewActionTypes.CleanData]: () => {
    return initialState.summary;
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.summary,
  },
});

export default combineReducers<ProjectsDataOverviewState>({
  projects,
  projectsStatus,
  project,
  projectRespondents,
  projectRespondentsPagination,
  projectRespondentsStatus,
  projectRespondentsOrder,
  projectRespondentsFilter,
  summary,
});

/* SAGAS */
function* getProjects({ payload }: AppAction<{ name: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getProjects, payload);

  if (resp.ok) {
    yield put(projectsDataOverviewGetProjects.success(toCamelCase(resp.data)?.data || []));
  } else {
    yield put(projectsDataOverviewGetProjects.failure());
  }
}
function* getProjectsDataOverviewsProjectRespondents() {
  const pagination: Pages = yield select(selectProjectsDataOverviewProjectRespondentsPagination);
  const orderColumn: ITableOrder = yield select(
    selectProjectsDataOverviewProjectRespondentsOrderColumn
  );
  const filterColumn: Record<string, ISearch> = yield select(
    selectProjectsDataOverviewProjectRespondentsFilterColumn
  );

  const project: IProject = yield select(selectProjectsDataOverviewProject);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getProjectRespondents, {
    id: `${project?.id}` ?? '',
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(projectsDataOverviewGetProjectRespondentsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(projectsDataOverviewGetProjectRespondentsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* projectsDataOverviewSaga() {
  yield takeLatest(
    createActionType(ProjectsDataOverviewActionTypes.GetProjects, RequestActionTypes.REQUEST),
    getProjects
  );
  yield takeLatest(
    [
      createActionType(
        ProjectsDataOverviewActionTypes.GetProjectRespondents,
        RequestActionTypes.REQUEST
      ),
      ProjectsDataOverviewActionTypes.SetProjectRespondentsPage,
      ProjectsDataOverviewActionTypes.IncreasePage,
      ProjectsDataOverviewActionTypes.SetOrderColumn,
      ProjectsDataOverviewActionTypes.SetFilterColumn,
    ],
    getProjectsDataOverviewsProjectRespondents
  );
}
