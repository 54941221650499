import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export interface CityPayload {
  id?: number;
  name: string;
  districtId: number;
}

export const api = {
  getCities: ({ pagination, order, filter }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/territory', {
      params: toSnakeCase({
        territoryType: 'City',
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: order.sort,
        search: filter,
      }),
    });
  },

  getCitiesZip: ({ pagination, order, filter }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/cities-with-zip', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: order.sort,
        search: filter,
      }),
    });
  },

  removeCity: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/territory/${id}`, {
      params: toSnakeCase({
        territoryType: 'City',
      }),
    }),

  getDistricts: (): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/territory', {
      params: toSnakeCase({
        territoryType: 'District',
        resultsPerPage: 1000,
      }),
    }),

  getCity: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/territory/${id}`, {
      params: toSnakeCase({
        territoryType: 'City',
      }),
    }),

  createCity: (payload: CityPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post(
      '/territory',
      toSnakeCase({
        territoryType: 'City',
        data: {
          ...payload,
        },
      })
    ),

  updateCity: (payload: CityPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(
      `/territory/${payload?.id}`,
      toSnakeCase({
        territoryType: 'City',
        data: {
          ...payload,
        },
      })
    ),
};
