import i18n from 'i18next';
import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { BankAccount } from '../../models/BankAccount';
import { Company } from '../../models/Company';

import { PersistContractForm } from '../../models/Contract';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AddressActionTypes } from '../address/actions';
import { AuthActionTypes } from '../auth/actions';
import { BankAccountActionTypes } from '../bankAccount/actions';
import { contractPersistForm } from '../contract/actions';
import { selectContractPersistForm } from '../contract/selectors';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  CompanyActionTypes,
  companyCreateCompanyActions,
  companyGetBankAccountsActions,
  companyGetCompaniesActions,
  companyGetCompanyActions,
  companyGetRespondents,
  companyRemoveCompanyActions,
  companyUpdateCompanyActions,
} from './actions';
import { api, CompanyPayload } from './api';
import {
  selectCompanyCompaniesFilterColumn,
  selectCompanyCompaniesOrderColumn,
  selectCompanyCompaniesPagination,
} from './selectors';

/* STATE */
export interface CompanyState {
  companies: Company[];
  companiesPagination: Pages;
  companiesStatus: LoadingStatus;
  companiesOrder: ITableOrder;
  companiesFilter: Record<string, ISearch>;
  bankAccounts: Record<string, BankAccount[]>;
  company: Company | null;
  companyStatus: LoadingStatus;
  respondents: {
    id: string;
    name: string;
  }[];
  respondentsStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: CompanyState = {
  companies: [],
  companiesPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  companiesStatus: LoadingStatus.loading,
  companiesOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  companiesFilter: {},
  bankAccounts: {},
  company: null,
  companyStatus: LoadingStatus.initial,
  respondents: [],
  respondentsStatus: LoadingStatus.initial,
};

const companies = createReducer(initialState.companies, {
  [CompanyActionTypes.GetCompanies]: {
    [RequestActionTypes.SUCCESS]: (state: Company[], payload: PaginatedResp<Company>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.companies,
  },
  [CompanyActionTypes.RemoveCompany]: {
    [RequestActionTypes.SUCCESS]: (state: Company[], payload: { id: number }) => {
      return state.filter((company) => company.id !== payload.id);
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.companies,
  },
});

const companiesPagination = createReducer(initialState.companiesPagination, {
  [CompanyActionTypes.GetCompanies]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<Company>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.companiesPagination,
  },
  [CompanyActionTypes.SetCompaniesPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [CompanyActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [CompanyActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [CompanyActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.companiesPagination,
  },
});

const companiesStatus = createLoadingStateReducer(
  initialState.companiesStatus,
  {
    [CompanyActionTypes.GetCompanies]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [CompanyActionTypes.SetCompaniesPage]: () => initialState.companiesStatus,
    [CompanyActionTypes.IncreasePage]: () => initialState.companiesStatus,
    [CompanyActionTypes.SetFilterColumn]: () => initialState.companiesStatus,
    [CompanyActionTypes.SetOrderColumn]: () => initialState.companiesStatus,
  }
);

const companiesOrder = createReducer(initialState.companiesOrder, {
  [CompanyActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.companiesOrder,
  },
});

const companiesFilter = createReducer(initialState.companiesFilter, {
  [CompanyActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.companiesFilter,
  },
});

const bankAccounts = createReducer(initialState.bankAccounts, {
  [CompanyActionTypes.GetBankAccounts]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, BankAccount[]>,
      payload: { data: PaginatedResp<BankAccount>; companyId: number }
    ) => {
      return {
        ...state,
        [payload.companyId]: [...payload?.data?.data],
      };
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.bankAccounts,
  },
});

const company = createReducer(initialState.company, {
  [CompanyActionTypes.CreateCompany]: {
    [RequestActionTypes.SUCCESS]: (state: Company | null, payload: Company) => ({
      ...state,
      ...payload,
    }),
  },
  [CompanyActionTypes.GetCompany]: {
    [RequestActionTypes.REQUEST]: () => initialState.company,
    [RequestActionTypes.SUCCESS]: (state: Company | null, payload: Company) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.company,
  },
  [CompanyActionTypes.UpdateCompany]: {
    [RequestActionTypes.SUCCESS]: (state: Company | null, payload: Company) => ({
      ...state,
      ...payload,
    }),
  },
  [AddressActionTypes.RemoveAddress]: {
    [RequestActionTypes.SUCCESS]: (state: Company | null, payload: { id: number }) => ({
      ...state,
      addresses: state?.addresses?.filter((address) => address.id !== payload.id),
    }),
  },
  [BankAccountActionTypes.RemoveBankAccount]: {
    [RequestActionTypes.SUCCESS]: (state: Company | null, payload: { id: number }) => ({
      ...state,
      bankAccounts: state?.bankAccounts?.filter((bankAccount) => bankAccount.id !== payload.id),
    }),
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.company,
  },
});

const companyStatus = createLoadingStateReducer(initialState.companyStatus, {
  [CompanyActionTypes.CreateCompany]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
  [CompanyActionTypes.UpdateCompany]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
});

const respondents = createReducer(initialState.respondents, {
  [CompanyActionTypes.GetRespondents]: {
    [RequestActionTypes.SUCCESS]: (
      state: { id: string; name: string }[],
      payload: { id: string; tanzanitId: string; respondentName: string; state: boolean }[]
    ) =>
      payload.map((item) => ({
        id: item.id,
        name: `#${item.tanzanitId} - ${item.respondentName}`,
      })),
  },
  [AuthActionTypes.Logout]: () => initialState.respondents,
});

const respondentsStatus = createLoadingStateReducer(initialState.respondentsStatus, {
  [CompanyActionTypes.GetRespondents]: [
    RequestActionTypes.REQUEST,
    RequestActionTypes.SUCCESS,
    RequestActionTypes.FAILURE,
  ],
});

export default combineReducers<CompanyState>({
  companies,
  companiesPagination,
  companiesStatus,
  companiesOrder,
  companiesFilter,
  bankAccounts,
  company,
  companyStatus,
  respondents,
  respondentsStatus,
});

/* SAGAS */
function* getCompanies() {
  const pagination: Pages = yield select(selectCompanyCompaniesPagination);
  const orderColumn: ITableOrder = yield select(selectCompanyCompaniesOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(selectCompanyCompaniesFilterColumn);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getCompanies, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(companyGetCompaniesActions.success(toCamelCase(resp.data)));
  } else {
    yield put(companyGetCompaniesActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeCompany({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeCompany, payload);

  if (resp.ok) {
    yield put(companyRemoveCompanyActions.success({ id: payload.id }));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(companyRemoveCompanyActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getBankAccounts({ payload }: AppAction<{ companyId: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getBankAccounts, payload);

  if (resp.ok) {
    yield put(
      companyGetBankAccountsActions.success({
        data: toCamelCase(resp.data),
        companyId: payload.companyId,
      })
    );
  } else {
    yield put(companyGetBankAccountsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createCompany({ payload }: AppAction<CompanyPayload>) {
  const { location, ...data } = payload;

  const resp: ExtendedAxiosResponse = yield call(api.createCompany, data);

  if (resp.ok) {
    const data = toCamelCase(resp.data)?.data;

    yield put(
      companyCreateCompanyActions.success({
        userId: payload.userId,
        data,
      })
    );
    yield put(
      toastCreateSuccessActions(
        resp.data?.message || i18n.t('components.successToast.requestPending')
      )
    );

    const locationState = location?.state as any;

    if (location?.pathname.includes(AppRoutes.Admin)) {
      if (payload?.respondentId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.RespondentsDetail], {
            respondentId: payload?.respondentId?.toString(),
          })
        );
      } else {
        history.push(buildRoute([AppRoutes.Admin, AdminRoutes.Companies]));
      }
    } else if (locationState?.from === 'contracts') {
      const persistedForm: PersistContractForm | null = yield select(selectContractPersistForm);

      if (persistedForm?.contractType === 'PO') {
        yield put(
          contractPersistForm({
            ...persistedForm,
            companyId: data?.id,
          })
        );
      }

      history.push(buildRoute(AppRoutes.ContractCreate));
    } else {
      history.push(buildRoute([AppRoutes.Profile]));
    }
  } else {
    yield put(companyCreateCompanyActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getCompany({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getCompany, payload);

  if (resp.ok) {
    yield put(companyGetCompanyActions.success(toCamelCase(resp?.data)));
  } else {
    yield put(companyGetCompanyActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateCompany({ payload }: AppAction<CompanyPayload>) {
  const { location, ...data } = payload;

  const resp: ExtendedAxiosResponse = yield call(api.updateCompany, data);

  if (resp.ok) {
    yield put(companyUpdateCompanyActions.success(toCamelCase(resp.data)?.data));
    yield put(
      toastCreateSuccessActions(
        resp.data?.message || i18n.t('components.successToast.requestPending')
      )
    );

    if (location?.pathname.includes(AppRoutes.Admin)) {
      if (payload?.respondentId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.RespondentsDetail], {
            respondentId: payload?.respondentId?.toString(),
          })
        );
      } else {
        history.push(buildRoute([AppRoutes.Admin, AdminRoutes.Companies]));
      }
    } else {
      history.push(buildRoute([AppRoutes.Profile]));
    }
  } else {
    yield put(companyUpdateCompanyActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getRespondents({ payload }: AppAction<{ search: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getRespondents, payload);

  if (resp.ok) {
    yield put(companyGetRespondents.success(toCamelCase(resp.data)?.data || []));
  } else {
    yield put(companyGetRespondents.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* companySaga() {
  yield takeLatest(
    [
      createActionType(CompanyActionTypes.GetCompanies, RequestActionTypes.REQUEST),
      CompanyActionTypes.SetCompaniesPage,
      CompanyActionTypes.IncreasePage,
      CompanyActionTypes.SetOrderColumn,
      CompanyActionTypes.SetFilterColumn,
    ],
    getCompanies
  );
  yield takeLatest(
    [createActionType(CompanyActionTypes.RemoveCompany, RequestActionTypes.REQUEST)],
    removeCompany
  );
  yield takeLatest(
    createActionType(CompanyActionTypes.CreateCompany, RequestActionTypes.REQUEST),
    createCompany
  );
  yield takeLatest(
    createActionType(CompanyActionTypes.GetCompany, RequestActionTypes.REQUEST),
    getCompany
  );
  yield takeLatest(
    createActionType(CompanyActionTypes.UpdateCompany, RequestActionTypes.REQUEST),
    updateCompany
  );
  yield takeLatest(
    [createActionType(CompanyActionTypes.GetBankAccounts, RequestActionTypes.REQUEST)],
    getBankAccounts
  );
  yield takeLatest(
    [createActionType(CompanyActionTypes.GetRespondents, RequestActionTypes.REQUEST)],
    getRespondents
  );
}
