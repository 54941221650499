import { useSelector } from 'react-redux';
import { BreadcrumbMatch } from 'use-react-router-breadcrumbs';

import { selectArchivedProjectProject } from '../../../../saga/archivedProject/selectors';
import { selectAssignedProjectProject } from '../../../../saga/assignedProject/selectors';
import { selectAuthUser } from '../../../../saga/auth/selectors';
import { selectCityCity } from '../../../../saga/city/selectors';
import { selectClientClient } from '../../../../saga/client/selectors';
import { selectCompanyCompany } from '../../../../saga/company/selectors';
import { selectDistrictDistrict } from '../../../../saga/district/selectors';
import { selectInvoiceCompanyCompany } from '../../../../saga/invoiceCompany/selectors';
import { selectRegionRegion } from '../../../../saga/region/selectors';
import { selectRespondentRespondent } from '../../../../saga/respondent/selectors';
import { selectSurveyProject } from '../../../../saga/survey/selectors';
import { selectUserProfile } from '../../../../saga/user/selectors';
import Translate from '../Translate/Translate';

interface ISubBreadcrumb {
  match: BreadcrumbMatch<any>;
}

const BreadcrumbUserDetail = ({ match }: ISubBreadcrumb) => {
  const id = match.params.userId;

  const user = useSelector(selectUserProfile);
  return (
    <>
      <Translate translate={'pages.usersDetail.header'} />
      {` ${user?.firstName || ''} ${user?.lastName || ''}`}
    </>
  );
};

const BreadcrumbMyProfile = ({ match }: ISubBreadcrumb) => {
  const user = useSelector(selectAuthUser);
  return (
    <>
      <Translate translate={'pages.profile.header'} />
      {` ${user?.firstName || ''} ${user?.lastName || ''}`}
    </>
  );
};

const BreadcrumbBankAccountDetail = ({ match }: ISubBreadcrumb) => {
  return (
    <>
      <Translate translate={'pages.bankAccountsDetail.header'} />
    </>
  );
};

const BreadcrumbCompanyCreate = ({ match }: ISubBreadcrumb) => {
  return (
    <>
      <Translate translate={'pages.companiesCreate.header'} />
    </>
  );
};

const BreadcrumbCompanyDetail = ({ match }: ISubBreadcrumb) => {
  const id = match.params.companyId;

  const company = useSelector(selectCompanyCompany);

  return (
    <>
      <Translate translate={'pages.companiesDetail.header'} />
      <span className={'ml-0.5'}>{company ? company.name : id}</span>
    </>
  );
};

const BreadcrumbClientDetail = ({ match }: ISubBreadcrumb) => {
  const id = match.params.clientId;

  const client = useSelector(selectClientClient);

  return (
    <>
      <Translate translate={'pages.clientsDetail.header'} />
      <span className={'ml-0.5'}>{client ? client.name : id}</span>
    </>
  );
};

const BreadcrumbInvoiceCompanyDetail = ({ match }: ISubBreadcrumb) => {
  const id = match.params.companyId;

  const company = useSelector(selectInvoiceCompanyCompany);

  return (
    <>
      <Translate translate={'pages.invoiceCompanyDetail.header'} />
      <span className={'ml-0.5'}>{company ? company.name : id}</span>
    </>
  );
};

const BreadcrumbRegionDetail = ({ match }: ISubBreadcrumb) => {
  const id = match.params.regionId;

  const region = useSelector(selectRegionRegion);

  return (
    <>
      <Translate translate={'pages.regionsDetail.header'} />
      <span className={'ml-0.5'}>{region ? region.name : id}</span>
    </>
  );
};

const BreadcrumbDistrictDetail = ({ match }: ISubBreadcrumb) => {
  const id = match.params.districtId;

  const district = useSelector(selectDistrictDistrict);

  return (
    <>
      <Translate translate={'pages.districtsDetail.header'} />
      <span className={'ml-0.5'}>{district ? district.name : id}</span>
    </>
  );
};

const BreadcrumbCityDetail = ({ match }: ISubBreadcrumb) => {
  const id = match.params.cityId;

  const city = useSelector(selectCityCity);

  return (
    <>
      <Translate translate={'pages.citiesDetail.header'} />
      <span className={'ml-0.5'}>{city ? city.name : id}</span>
    </>
  );
};

const BreadcrumbRespondentDetail = ({ match }: ISubBreadcrumb) => {
  const id = match.params.respondentId;

  const respondent = useSelector(selectRespondentRespondent);
  return (
    <>
      <Translate translate={'pages.respondentsDetail.header'} />
      <span className={'ml-1'}>
        {respondent?.firstName && respondent?.lastName
          ? `${respondent?.firstName || ''} ${respondent?.lastName || ''}`
          : id}
      </span>
    </>
  );
};

const BreadcrumbAddress = ({ match }: ISubBreadcrumb) => {
  return (
    <>
      <Translate translate={'pages.address.header'} />
    </>
  );
};

const BreadcrumbAddressCreate = ({ match }: ISubBreadcrumb) => {
  return (
    <>
      <Translate translate={'pages.addressCreate.header'} />
    </>
  );
};

const BreadcrumbAddressDetail = ({ match }: ISubBreadcrumb) => {
  return (
    <>
      <Translate translate={'pages.addressDetail.header'} />
    </>
  );
};

const BreadcrumbCreateProject = ({ match }: ISubBreadcrumb) => {
  const step = match.params.step;

  if (step === '1') {
    return (
      <>
        <Translate translate={'pages.createProject.stepper.first.title'} />
      </>
    );
  }
  return (
    <>
      <Translate translate={'pages.createProject.stepper.second.title'} />
    </>
  );
};

const BreadcrumbProjectDetail = ({ match }: ISubBreadcrumb) => {
  const step = match.params.step;

  if (step === '1') {
    return (
      <>
        <Translate translate={'pages.projectDetail.stepper.first.title'} />
      </>
    );
  }
  return (
    <>
      <Translate translate={'pages.projectDetail.stepper.second.title'} />
    </>
  );
};

const BreadcrumbSurvey = ({ match }: ISubBreadcrumb) => {
  const survey = useSelector(selectSurveyProject);
  return (
    <>
      <Translate translate={'components.survey.header'} />
      <span className={'ml:1'}>: {survey ? survey.name : ''}</span>
    </>
  );
};

const BreadcrumbArchivedProjectDetail = ({ match }: ISubBreadcrumb) => {
  const id = match.params.projectId;

  const project = useSelector(selectArchivedProjectProject);

  return (
    <>
      <Translate translate={'pages.archivedProjectDetail.header'} />
      <span className={'ml-0.5'}>{project ? project?.project?.name : id}</span>
    </>
  );
};

const BreadcrumbAssignedProjectDetail = ({ match }: ISubBreadcrumb) => {
  const id = match.params.projectId;

  const project = useSelector(selectAssignedProjectProject);

  return (
    <>
      <Translate translate={'pages.assignedProjectDetail.header'} />
      <span className={'ml-0.5'}>{project ? project?.project?.name : id}</span>
    </>
  );
};

export {
  BreadcrumbUserDetail,
  BreadcrumbBankAccountDetail,
  BreadcrumbMyProfile,
  BreadcrumbCompanyDetail,
  BreadcrumbInvoiceCompanyDetail,
  BreadcrumbRegionDetail,
  BreadcrumbDistrictDetail,
  BreadcrumbCityDetail,
  BreadcrumbRespondentDetail,
  BreadcrumbClientDetail,
  BreadcrumbCreateProject,
  BreadcrumbAddressCreate,
  BreadcrumbAddressDetail,
  BreadcrumbSurvey,
  BreadcrumbProjectDetail,
  BreadcrumbAssignedProjectDetail,
  BreadcrumbArchivedProjectDetail,
  BreadcrumbAddress,
  BreadcrumbCompanyCreate,
};
