import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

export enum SurveyActionTypes {
  GetBlocks = '@@Survey/GET_BLOCKS',
  GetNextBlock = '@@Survey/GET_NEXT_BLOCK',
  GetPrevBlock = '@@Survey/GET_PREV_BLOCK',
  SendAnswer = '@@Survey/SEND_ANSWER',
  FinishSurvey = '@@Survey/FINISH_SURVEY',
  SetSessionId = '@@Survey/SET_SESSION_ID',
  SetBlockId = '@@Survey/SET_BLOCK_ID',
  CleanSurveyData = '@@Survey/CLEAN_SURVEY_DATA',
  GetChildOptions = '@@Survey/GET_CHILD_OPTIONS',
  SetSurveyStatuses = '@@Survey/SET_SURVEY_STATUSES',
  CleanSurveysStatuses = '@@Survey/CLEAN_SURVEY_STATUSES',
}

/* ACTIONS */
export const surveyGetBlocks = createApiActionCreators(SurveyActionTypes.GetBlocks);

export const surveyGetNextBlock = createActionCreator(SurveyActionTypes.GetNextBlock);

export const surveyGetPrevBlock = createActionCreator(SurveyActionTypes.GetPrevBlock);

export const surveySendAnswer = createApiActionCreators(SurveyActionTypes.SendAnswer);

export const surveyFinishSurvey = createApiActionCreators(SurveyActionTypes.FinishSurvey);

export const surveySetSessionId = createActionCreator(SurveyActionTypes.SetSessionId);

export const surveySetBlockId = createActionCreator(SurveyActionTypes.SetBlockId);

export const surveyCleanSurveyData = createActionCreator(SurveyActionTypes.CleanSurveyData);

export const surveyGetChildOptions = createApiActionCreators(SurveyActionTypes.GetChildOptions);

export const surveySetSurveyStatuses = createActionCreator(SurveyActionTypes.SetSurveyStatuses);

export const surveyCleanSurveyStatuses = createActionCreator(
  SurveyActionTypes.CleanSurveysStatuses
);
