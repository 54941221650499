import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

interface AddressPayload {
  id?: number;
  street?: string;
  streetNumber?: string;
  district?: string;
  city?: string;
  zip?: string;
  userId?: number;
  companyId?: number;
}

export const api = {
  createAddress: (payload: AddressPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post(
      '/addresses',
      toSnakeCase({
        ...payload,
        typeName: 'primárna',
      })
    ),
  getAddress: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/addresses/${id}`),
  removeAddress: ({
    id,
    companyId,
    userId,
  }: {
    id: number;
    companyId?: number;
    userId?: number;
  }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/addresses/${id}`, {
      params: toSnakeCase({
        companyId: companyId,
        userId: userId,
      }),
    }),
  updateAddress: (payload: AddressPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(
      `/addresses/${payload?.id}`,
      toSnakeCase({
        ...payload,
        typeName: 'primárna',
      })
    ),
  getAddresses: ({ pagination }: ITableReq): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/addresses', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
      }),
    }),
};
