import React from 'react';

import { useTranslation } from 'react-i18next';

import { Disclosure, Transition } from '@headlessui/react';

import { ReactComponent as ArrowDown } from '../../../../../assets/icons/arrow-down.svg';
import { myProfile } from '../../../../../mock/Menu';
import Logout from '../../../../components/Logout/Logout';

const MyProfileDisclosure = () => {
  const { t } = useTranslation();

  return (
    <Disclosure
      as={'div'}
      className={'block lg:hidden font-sans font-normal text-sm bg-primary rounded text-white'}
    >
      <Disclosure.Button
        className={'w-full justify-between px-3 py-2 font-normal font-sans text-sm'}
      >
        <div className={'inline-flex w-full justify-between items-center'}>
          {t('layout.appHeader.myProfile.label')}
          <ArrowDown width={12} height={6} className={'stroke-white ml-1'} />
        </div>
      </Disclosure.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Disclosure.Panel>
          <div className={'block space-y-1 px-2'}>
            {Object.keys(myProfile).map((item) => {
              return (
                <Disclosure.Button
                  key={item}
                  as={'a'}
                  href={myProfile[item].to}
                  className={'block py-2 px-3 flex justify-between items-center cursor-pointer'}
                >
                  {t(myProfile[item].label)}
                </Disclosure.Button>
              );
            })}
            <Disclosure.Button
              as={'div'}
              className={'flex justify-between items-center cursor-pointer'}
            >
              <Logout />
            </Disclosure.Button>
          </div>
        </Disclosure.Panel>
      </Transition>
    </Disclosure>
  );
};

export default MyProfileDisclosure;
