import i18n from 'i18next';
import { TypeOptions } from 'react-toastify/dist/types';
import { combineReducers } from 'redux';

import { createReducer } from '../../helpers/redux/redux-helpers';
import { ToastActionTypes } from './actions';

/* STATE */
export interface ToastState {
  message: string | null;
  type: TypeOptions | 'default';
  closeOnClick?: boolean;
  progress: string | number | null;
  rawHTML?: boolean;
}

/* REDUCERS */
const initialState: ToastState = {
  message: null,
  type: 'default',
  closeOnClick: true,
  progress: null,
  rawHTML: false,
};

const message = createReducer(initialState.message, {
  [ToastActionTypes.CreateToast]: (state: string | null, payload: ToastState) => payload.message,
  [ToastActionTypes.CreateSuccessToast]: (state: string | null, payload?: string) =>
    payload || i18n.t('components.successToast.requestSuccess'),
  [ToastActionTypes.CreateWarnToast]: (state: string | null, payload: string) => payload,
  [ToastActionTypes.CreateErrorToast]: (state: string | null, payload?: string) =>
    payload || i18n.t('components.errorToast.requestFailed'),
  [ToastActionTypes.CreateInfoToast]: (state: string | null, payload: string) => payload,
  [ToastActionTypes.ClearToast]: () => initialState.message,
});

const type = createReducer(initialState.type, {
  [ToastActionTypes.CreateToast]: (state: ToastState | undefined, payload: ToastState) =>
    payload.type,
  [ToastActionTypes.CreateSuccessToast]: () => 'success',
  [ToastActionTypes.CreateWarnToast]: () => 'warning',
  [ToastActionTypes.CreateErrorToast]: () => 'error',
  [ToastActionTypes.CreateInfoToast]: () => 'info',
  [ToastActionTypes.ClearToast]: () => initialState.type,
});

const closeOnClick = createReducer(initialState.closeOnClick, {
  [ToastActionTypes.CreateToast]: (state: boolean | undefined, payload: ToastState) =>
    payload.closeOnClick,
  [ToastActionTypes.CreateSuccessToast]: () => false,
  [ToastActionTypes.CreateWarnToast]: () => false,
  [ToastActionTypes.CreateErrorToast]: () => false,
  [ToastActionTypes.CreateInfoToast]: () => false,
  [ToastActionTypes.ClearToast]: () => initialState.closeOnClick,
});

const progress = createReducer(initialState.progress, {
  [ToastActionTypes.CreateToast]: (state: string | number | null, payload: ToastState) =>
    payload.progress,
  [ToastActionTypes.CreateSuccessToast]: () => null,
  [ToastActionTypes.CreateWarnToast]: () => null,
  [ToastActionTypes.CreateErrorToast]: () => null,
  [ToastActionTypes.CreateInfoToast]: () => null,
  [ToastActionTypes.ClearToast]: () => initialState.progress,
});

const rawHTML = createReducer(initialState.rawHTML, {
  [ToastActionTypes.CreateToast]: (state: boolean | undefined, payload: ToastState) =>
    payload.rawHTML != null ? payload.rawHTML : false,
  [ToastActionTypes.CreateSuccessToast]: () => false,
  [ToastActionTypes.CreateWarnToast]: () => false,
  [ToastActionTypes.CreateErrorToast]: () => false,
  [ToastActionTypes.CreateInfoToast]: () => false,
  [ToastActionTypes.ClearToast]: () => initialState.rawHTML,
});

export default combineReducers<ToastState>({
  message,
  type,
  closeOnClick,
  progress,
  rawHTML,
});

/* SAGAS */

/* EXPORT */
export function* toastSaga() {}
