import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import { CreateSpecPayload, GetSpecsPayload, RemoveSpecPayload } from './api';

/* ACTION TYPES */
export enum SpecActionTypes {
  GetSpecs = '@@Spec/GET_SPECS',
  CreateSpec = '@@Spec/CREATE_SPEC',
  RemoveSpec = '@@Spec/REMOVE_SPEC',
  SetSpecsPage = '@@Spec/SET_SPECS_PAGE',
  IncreasePage = '@@Spec/INCREASE_PAGE',
  SetOrderColumn = '@@Spec/SET_ORDER_COLUMN',
  SetFilterColumn = '@@Spec/SET_FILTER_COLUMNS',
}

/* ACTIONS */
export const specGetSpecsActions = createApiActionCreators<GetSpecsPayload>(
  SpecActionTypes.GetSpecs
);
export const specCreateSpecActions = createApiActionCreators<CreateSpecPayload>(
  SpecActionTypes.CreateSpec
);
export const specRemoveSpecActions = createApiActionCreators<RemoveSpecPayload>(
  SpecActionTypes.RemoveSpec
);
export const specSetSpecsPage = createActionCreator(SpecActionTypes.SetSpecsPage);
export const specIncreasePageActions = createActionCreator(SpecActionTypes.IncreasePage);
export const specSetOrderColumnActions = createActionCreator(SpecActionTypes.SetOrderColumn);
export const specSetFilterColumnActions = createActionCreator(SpecActionTypes.SetFilterColumn);
