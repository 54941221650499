import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

export enum AddressActionTypes {
  RemoveAddress = '@@Address/REMOVE_ADDRESS',
  CreateAddress = '@@Address/CREATE_ADDRESS',
  GetAddress = '@@Address/GET_ADDRESS',
  UpdateAddress = '@@Address/UPDATE_ADDRESS',
  GetAddresses = '@@Address/GET_ADDRESSES',
  SetAddressesPage = '@@Address/SET_ADDRESS_PAGE',
  IncreasePage = '@@Address/INCREASE_ADDRESS',
  SetOrderColumn = '@@Address/SET_ORDER_COLUMN',
  SetFilterColumn = '@@Address/SET_FILTER_COLUMNS',
}

export const addressCreateAddressActions = createApiActionCreators(
  AddressActionTypes.CreateAddress
);
export const addressGetAddressActions = createApiActionCreators(AddressActionTypes.GetAddress);
export const addressRemoveAddressActions = createApiActionCreators(
  AddressActionTypes.RemoveAddress
);
export const addressUpdateAddressActions = createApiActionCreators(
  AddressActionTypes.UpdateAddress
);
export const addressGetAddressesActions = createApiActionCreators(AddressActionTypes.GetAddresses);
export const addressSetAddressesPageActions = createActionCreator(
  AddressActionTypes.SetAddressesPage
);
export const addressIncreasePageActions = createActionCreator(AddressActionTypes.IncreasePage);
export const addressSetOrderColumnActions = createActionCreator(AddressActionTypes.SetOrderColumn);
export const addressSetFilterColumnActions = createActionCreator(
  AddressActionTypes.SetFilterColumn
);
