import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Pages } from '../../models/Pages';
import { IBlock, IProject, IQuestion, IQuestionType } from '../../models/Project';
import { ISearch, ITableOrder } from '../../models/Table';
import { ProjectState } from './ducks';

/* SELECTORS */
const selectProject = (state: AppState): ProjectState => state.project;

export const selectCurrentProject = (state: AppState): IProject | null =>
  selectProject(state).project;

export const selectProjectBlocks = (state: AppState): Record<string, IBlock> =>
  selectProject(state).blocks;

export const selectProjectBlocksStatus = (state: AppState): LoadingStatus =>
  selectProject(state).blocksStatus;

export const selectProjectBlockCreateStatus = (state: AppState): LoadingStatus =>
  selectProject(state).blockCreateStatus;

export const selectProjectBlock = (state: AppState, blockId: string): IBlock =>
  selectProject(state)?.blocks?.[blockId];

export const selectProjectTypes = (state: AppState): IQuestionType[] => selectProject(state).types;

export const selectProjectTotalQuestion = (state: AppState): number => {
  const blocks = selectProject(state).blocks;

  return Object.keys(blocks)?.reduce((acc, i) => {
    return acc + Object.keys(blocks[i].questions)?.length;
  }, 0);
};

export const selectProjectTotalExistingQuestion = (state: AppState): number => {
  const blocks = selectProject(state).blocks;

  return Object.keys(blocks)?.reduce((acc, i) => {
    return (
      acc + Object.keys(blocks[i].questions).filter((id) => blocks[i].questions[id]?.id)?.length
    );
  }, 0);
};

export const selectProjectBlockOffsetQuestion = (state: AppState, position: number) => {
  const blocks = selectProject(state).blocks;

  return Object.keys(blocks)?.reduce((acc: number, i) => {
    if (blocks[i].position < position) {
      return acc + Object.keys(blocks[i].questions)?.length;
    }
    return acc;
  }, 0);
};

/* PROJECT TABLE SELECTORS */
export const selectProjectProjects = (state: AppState): IProject[] => selectProject(state).projects;

export const selectProjectProjectsPagination = (state: AppState): Pages =>
  selectProject(state).projectsPagination;

export const selectProjectProjectsStatus = (state: AppState): LoadingStatus =>
  selectProject(state).projectsStatus;

export const selectProjectProjectsOrderColumn = (state: AppState): ITableOrder =>
  selectProject(state).projectsOrder;

export const selectProjectProjectsFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectProject(state).projectsFilter;

export const selectProjectProjectActionStatus = (state: AppState): LoadingStatus =>
  selectProject(state).projectActionStatus;

export const selectProjectExportProjectsStatus = (state: AppState): LoadingStatus =>
  selectProject(state).projectExportStatus;

export const selectProjectProjectQuestions = (state: AppState): IQuestion[] =>
  selectProject(state).questions;

export const selectProjectProjectQuestionsPagination = (state: AppState): Pages =>
  selectProject(state).questionsPagination;

export const selectProjectProjectQuestionsStatus = (state: AppState): LoadingStatus =>
  selectProject(state).questionsStatus;

export const selectProjectGetQuestion = (state: AppState): IQuestion | null =>
  selectProject(state).question;
