import { lazy } from 'react';

import Translate from '../components/Translate/Translate';
import { IRoute, Permission } from '../route';

export enum OverviewRoutes {
  ClientInvoice = 'client-invoices',
  ProjectsData = 'projects-data',
  ClientsData = 'clients-data',
}

const ClientInvoices = lazy(() => import('../../../views/pages/ClientInvoices/ClientInvoices'));
const ProjectsData = lazy(
  () => import('../../../views/pages/OverviewProjectsData/OverviewProjectsData')
);
const ClientsData = lazy(
  () => import('../../../views/pages/OverviewClientsData/OverviewClientsData')
);

const overviewRoutes: IRoute[] = [
  {
    path: OverviewRoutes.ClientInvoice,
    element: <ClientInvoices />,
    permissions: [Permission.clientInvoiceRead],
    breadcrumb: Translate,
    props: { translate: 'pages.clientInvoices.header' },
  },
  {
    path: OverviewRoutes.ProjectsData,
    element: <ProjectsData />,
    permissions: [Permission.clientInvoiceRead],
    breadcrumb: Translate,
    props: { translate: 'pages.overviewProjectsData.header' },
  },
  {
    path: OverviewRoutes.ClientsData,
    element: <ClientsData />,
    permissions: [Permission.clientsDataRead],
    breadcrumb: Translate,
    props: { translate: 'pages.overviewClientsData.header' },
  },
];

export default overviewRoutes;
