import { IQuestionType } from '../models/Project';

export const transformQuestionResp = (state: any, payload: any) => {
  return Object.keys(state).reduce((acc: any, i: string) => {
    if (i == payload.blockId) {
      acc[i] = {
        ...state[i],
        questions: Object.keys(state[i].questions).reduce((qO: any, qId: string) => {
          if (qId == payload.questionId) {
            qO[qId] = {
              ...payload.data,
            };
          } else {
            qO[qId] = { ...state[i].questions[qId] };
          }
          return qO;
        }, {}),
      };
    } else {
      acc[i] = state[i];
    }
    return acc;
  }, {});
};

export const getQuestionType = (types: IQuestionType[], id: number) => {
  const tmp = types.filter((type) => {
    const tm = type?.childTypes?.filter((childType) => childType.id == id);
    return tm?.length && tm.length > 0;
  });

  if (tmp?.length) {
    return {
      validation: id,
      questionTypeId: tmp[0].id,
    };
  }
  return {
    questionTypeId: id,
  };
};
