import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import {
  AddQuestionToGroupedNumericBlock,
  CreateGroupedNumericBlockPayload,
  DuplicateProjectPayload,
  ExportAnswersPayload,
  GetGroupedNumericBlocksPayload,
  GetProjectQuestionsPayload,
  GetQuestionPayload,
  RemoveGroupedNumericBlock,
  RemoveQuestionFromGroupedNumericBlock,
  UpdateGroupedNumericBlockPayload,
} from './api';

/* ACTION TYPES */
export enum ProjectActionTypes {
  SaveProject = '@@Project/SAVE_PROJECT',
  GetTypes = '@@Project/GET_TYPES',
  ChangeBlockPosition = '@@Project/CHANGE_BLOCK_POSITION',
  AddQuestion = '@@Project/ADD_QUESTION',
  ChangeQuestionPosition = '@@Project/CHANGE_QUESTION_POSITION',
  ChangeQuestionPositionBetweenBlocks = '@@Project/CHANGE_QUESTION_POSITION_BETWEEN_BLOCKS',
  RemoveQuestion = '@@Project/REMOVE_QUESTION',
  RemoveExistingQuestion = '@@Project/REMOVE_EXISTING_QUESTION',
  SaveQuestion = '@@Project/SAVE_QUESTION',
  SaveQuestionWithBlock = '@@Project/SAVE_QUESTION_WITH_BLOCK',
  SaveQuestionWithExistingBlock = '@@Project/SAVE_QUESTION_WITH_EXISTING_BLOCK',
  UpdateQuestion = '@@Project/UPDATE_QUESTION',
  DuplicateQuestion = '@@Project/DUPLICATE_QUESTION',
  ClearProject = '@@Project/CLEAR_PROJECT',
  CreateProject = '@@Project/CREATE_PROJECT',
  UpdateProject = '@@Project/UPDATE_PROJECT',
  DuplicateProject = '@@Project/DUPLICATE_PROJECT',
  GetProjects = '@@Project/GET_PROJECTS',
  GetProject = '@@Project/GET_PROJECT',
  RemoveProject = '@@Project/REMOVE_PROJECT',
  RemoveProjectAnswers = '@@Project/REMOVE_PROJECT_ANSWERS',
  SetProjectsPage = '@@Project/SET_PROJECTS_PAGE',
  IncreasePage = '@@Project/INCREASE_PROJECTS_PAGE',
  SetOrderColumn = '@@Project/SET_ORDER_COLUMN',
  SetFilterColumn = '@@Project/SET_FILTER_COLUMNS',
  GetProjectBlocks = '@@Project/GET_PROJECT_BLOCKS',
  ExportAnswers = '@@Project/EXPORT_ANSWERS',
  ExportProjects = '@@Project/EXPORT_PROJECTS',
  CreateExistingQuestion = '@@Project/CREATE_EXISTING_QUESTION',

  GetProjectQuestions = '@@Project/GET_PROJECT_QUESTIONS',
  SetProjectQuestionsPage = '@@Project/SET_PROJECT_QUESTIONS_PAGE',
  IncreaseProjectQuestionsPage = '@@Project/INCREASE_PROJECT_QUESTIONS_PAGE',
  GetQuestion = '@@Project/GET_QUESTION',

  CreateBlock = '@@Project/CREATE_BLOCK',
  UpdateBlock = '@@Project/UPDATE_BLOCK',
  RemoveBlock = '@@Project/REMOVE_BLOCK',

  GetGroupedNumericBlocks = '@@Project/GET_GROUPED_NUMERIC_BLOCKS',
  CreateGroupedNumericBlock = '@@Project/CREATE_GROUPED_NUMERIC_BLOCK',
  UpdateGroupedNumericBlock = '@@Project/UPDATE_GROUPED_NUMERIC_BLOCK',
  RemoveGroupedNumericBlock = '@@Project/REMOVE_GROUPED_NUMERIC_BLOCK',

  AddQuestionToGroupedNumericBlock = '@@Project/ADD_QUESTION_TO_GROUPED_NUMERIC_BLOCK',
  RemoveQuestionFromGroupedNumericBlock = '@@Project/REMOVE_QUESTION_FROM_GROUPED_NUMERIC_BLOCK',
}

/* ACTIONS */
export const projectClearProject = createActionCreator(ProjectActionTypes.ClearProject);
export const projectSaveProjectActions = createActionCreator(ProjectActionTypes.SaveProject);

export const projectCreateProjectActions = createApiActionCreators(
  ProjectActionTypes.CreateProject
);

export const projectUpdateProjectActions = createApiActionCreators(
  ProjectActionTypes.UpdateProject
);

export const projectAddQuestionActions = createActionCreator(ProjectActionTypes.AddQuestion);

export const projectChangeBlockPosition = createActionCreator(
  ProjectActionTypes.ChangeBlockPosition
);

export const projectChangeQuestionPosition = createActionCreator(
  ProjectActionTypes.ChangeQuestionPosition
);

export const projectChangeQuestionPositionBetweenBlocks = createActionCreator(
  ProjectActionTypes.ChangeQuestionPositionBetweenBlocks
);

export const projectSaveQuestionWithBlock = createApiActionCreators(
  ProjectActionTypes.SaveQuestionWithBlock
);

export const projectSaveQuestionWithExistingBlock = createApiActionCreators(
  ProjectActionTypes.SaveQuestionWithExistingBlock
);

export const projectUpdateQuestion = createApiActionCreators(ProjectActionTypes.UpdateQuestion);

export const projectDuplicateQuestion = createApiActionCreators(
  ProjectActionTypes.DuplicateQuestion
);

export const projectSaveQuestion = createActionCreator(ProjectActionTypes.SaveQuestion);

export const projectRemoveQuestion = createActionCreator(ProjectActionTypes.RemoveQuestion);
export const projectRemoveExistingQuestion = createApiActionCreators(
  ProjectActionTypes.RemoveExistingQuestion
);

export const projectRemoveBlock = createApiActionCreators(ProjectActionTypes.RemoveBlock);

export const projectGetTypes = createApiActionCreators(ProjectActionTypes.GetTypes);

export const projectUpdateBlock = createApiActionCreators(ProjectActionTypes.UpdateBlock);

export const projectCreateBlock = createApiActionCreators(ProjectActionTypes.CreateBlock);

/* PROJECT TABLE */

export const projectGetProjectsActions = createApiActionCreators(ProjectActionTypes.GetProjects);
export const projectGetProjectActions = createApiActionCreators(ProjectActionTypes.GetProject);
export const projectRemoveProjectActions = createApiActionCreators(
  ProjectActionTypes.RemoveProject
);
export const projectRemoveAnswersProjectActions = createApiActionCreators(
  ProjectActionTypes.RemoveProjectAnswers
);

export const projectSetProjectsPageActions = createActionCreator(
  ProjectActionTypes.SetProjectsPage
);
export const projectIncreasePageActions = createActionCreator(ProjectActionTypes.IncreasePage);
export const projectSetOrderColumnActions = createActionCreator(ProjectActionTypes.SetOrderColumn);
export const projectSetFilterColumnActions = createActionCreator(
  ProjectActionTypes.SetFilterColumn
);

export const projectGetProjectBlocksActions = createApiActionCreators(
  ProjectActionTypes.GetProjectBlocks
);

export const projectExportAnswers = createApiActionCreators<ExportAnswersPayload>(
  ProjectActionTypes.ExportAnswers
);

export const projectExportProjects = createApiActionCreators(ProjectActionTypes.ExportProjects);

export const projectDuplicateProject = createApiActionCreators<DuplicateProjectPayload>(
  ProjectActionTypes.DuplicateProject
);

export const projectGetProjectQuestions = createApiActionCreators<GetProjectQuestionsPayload>(
  ProjectActionTypes.GetProjectQuestions
);

export const projectSetProjectQuestionsPage = createActionCreator(
  ProjectActionTypes.SetProjectQuestionsPage
);

export const projectIncreaseProjectQuestionsPage = createActionCreator(
  ProjectActionTypes.IncreaseProjectQuestionsPage
);

export const projectGetQuestion = createApiActionCreators<GetQuestionPayload>(
  ProjectActionTypes.GetQuestion
);

export const projectCreateExistingQuestion = createApiActionCreators(
  ProjectActionTypes.CreateExistingQuestion
);

export const projectGetGroupedNumericBlocks =
  createApiActionCreators<GetGroupedNumericBlocksPayload>(
    ProjectActionTypes.GetGroupedNumericBlocks
  );

export const projectCreateGroupedNumericBlock =
  createApiActionCreators<CreateGroupedNumericBlockPayload>(
    ProjectActionTypes.CreateGroupedNumericBlock
  );

export const projectUpdateGroupedNumericBlock =
  createApiActionCreators<UpdateGroupedNumericBlockPayload>(
    ProjectActionTypes.UpdateGroupedNumericBlock
  );

export const projectRemoveGroupedNumericBlock = createApiActionCreators<RemoveGroupedNumericBlock>(
  ProjectActionTypes.RemoveGroupedNumericBlock
);

export const projectAddQuestionToGroupedNumericBlock =
  createApiActionCreators<AddQuestionToGroupedNumericBlock>(
    ProjectActionTypes.AddQuestionToGroupedNumericBlock
  );

export const projectRemoveQuestionFromGroupedNumericBlock =
  createApiActionCreators<RemoveQuestionFromGroupedNumericBlock>(
    ProjectActionTypes.RemoveQuestionFromGroupedNumericBlock
  );
