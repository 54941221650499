import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { buildRoute } from '../../../helpers/route/route-builder';
import { AppRoutes } from '../../../helpers/route/routes/app-routes';
import AppLogo from '../../components/AppLogo/AppLogo';

const AppFooter = () => {
  const { t } = useTranslation();

  return (
    <footer className={''}>
      <div className={'bg-secondary flex-col md:flex-row justify-center'}>
        <div
          className={
            'container xl:max-w-screen-xl mx-auto flex flex-col lg:flex-row justify-between p-10 lg:p-0 lg:py-10'
          }
        >
          <div className={''}>
            <AppLogo color={'white'} />
          </div>
          <div className={'pt-4 lg:pt-0'}>
            <h2 className={'text-base font-sans font-semibold text-gray-25'}>
              {t('layout.appFooter.contact')}
            </h2>
            <address className={'text-gray-25 font-sans font-normal text-sm not-italic pt-5'}>
              <p>
                <a href={'mailto:support@edotazniky.sk'}>support@edotazniky.sk</a>
              </p>
              <p className={'pt-4'}>
                <a href={'tel:+421 948 634 336'}>
                  tel.: +421 948 634 336 <span className={'text-gray-75'}>(8:00 - 16:00)</span>
                </a>
              </p>
            </address>
          </div>
          <div className={'font-sans font-normal text-sm text-primary lg:mr-20 pt-4 lg:pt-0'}>
            <p>
              <Link to={buildRoute(AppRoutes.Gdpr)}>{t('layout.appFooter.gdpr')}</Link>
            </p>
            <p className={'pt-4'}>
              <Link to={buildRoute(AppRoutes.Cookies)}>{t('layout.appFooter.cookies')}</Link>
            </p>
          </div>
        </div>
      </div>
      <div className={'bg-secondary border-t border-gray-100'}>
        <div className={'container xl:max-w-screen-xl mx-auto px-10 lg:px-0 py-6'}>
          <p className={'font-normal font-sans text-sm'}>
            <span className={'text-primary'}>© 2023 Karatnet s.r.o. </span>
            <span className={'text-gray-75'}>{t('layout.appFooter.allRightsReserved')}</span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;
