import { createActionCreator } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum ToastActionTypes {
  CreateToast = '@@Toast/CREATE',
  CreateSuccessToast = '@@Toast/CREATE_SUCCESS',
  CreateWarnToast = '@@Toast/CREATE_WARN',
  CreateErrorToast = '@@Toast/CREATE_ERROR',
  CreateInfoToast = '@@Toast/CREATE_INFO',
  ClearToast = '@@Toast/CLEAR',
}

/* ACTIONS */
export const toastCreateActions = createActionCreator(ToastActionTypes.CreateToast);
export const toastCreateSuccessActions = createActionCreator(ToastActionTypes.CreateSuccessToast);
export const toastCreateWarnActions = createActionCreator(ToastActionTypes.CreateWarnToast);
export const toastCreateErrorActions = createActionCreator(ToastActionTypes.CreateErrorToast);
export const toastCreateInfoActions = createActionCreator(ToastActionTypes.CreateInfoToast);
export const toastClearActions = createActionCreator(ToastActionTypes.ClearToast);
