import { isPlainObject, isArray, forEach, camelCase, snakeCase, chain } from 'lodash';

export const customSnakeCase = (s: string) => chain(s).split(/\./).map(snakeCase).join('.').value();

export const toSnakeCase = (obj: any): any => {
  if (!isPlainObject(obj) && !isArray(obj)) {
    return obj;
  }

  const snakeCaseObject: any = {};
  forEach(obj, (value, key) => {
    if (isPlainObject(value)) {
      value = toSnakeCase(value);
    }
    if (isArray(value)) {
      value = value.map((v) => toSnakeCase(v));
    }
    snakeCaseObject[customSnakeCase(key)] = value;
  });
  return snakeCaseObject;
};

export const toCamelCase = (obj: any): any => {
  if (!isPlainObject(obj) && !isArray(obj)) {
    return obj;
  }

  const camelCaseObject: any = {};
  forEach(obj, (value, key) => {
    if (isPlainObject(value)) {
      value = toCamelCase(value);
    }
    if (isArray(value)) {
      value = value.map((v) => toCamelCase(v));
    }
    camelCaseObject[camelCase(key)] = value;
  });
  return camelCaseObject;
};

export const lowercaseObjectKeys = (obj: any) =>
  Object.keys(obj).reduce((acc: any, key) => {
    acc[key.toLocaleLowerCase()] = obj[key];
    return acc;
  }, {});
