import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { ClientInvoice } from '../../models/ClientInvoice';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { ClientInvoiceState } from './ducks';

/* SELECTORS */
const selectClientInvoice = (state: AppState): ClientInvoiceState => state.clientInvoice;

export const selectClientInvoiceClientInvoices = (state: AppState): ClientInvoice[] =>
  selectClientInvoice(state).clientInvoices;

export const selectClientInvoiceClientInvoicesPagination = (state: AppState): Pages =>
  selectClientInvoice(state).clientInvoicesPagination;

export const selectClientInvoiceClientInvoicesStatus = (state: AppState): LoadingStatus =>
  selectClientInvoice(state).clientInvoicesStatus;

export const selectClientInvoiceClientInvoicesOrderColumn = (state: AppState): ITableOrder =>
  selectClientInvoice(state).clientInvoicesOrder;

export const selectClientInvoiceClientInvoicesFilterColumn = (
  state: AppState
): Record<string, ISearch> => selectClientInvoice(state).clientInvoicesFilter;

export const selectClientInvoiceClientInvoice = (state: AppState): ClientInvoice | null =>
  selectClientInvoice(state).clientInvoice;

export const selectClientInvoiceClients = (state: AppState): { id: string; name: string }[] =>
  selectClientInvoice(state).clients;

export const selectClientInvoiceClientsStatus = (state: AppState): LoadingStatus =>
  selectClientInvoice(state).clientsStatus;

export const selectClientInvoiceStatus = (state: AppState): LoadingStatus =>
  selectClientInvoice(state).clientInvoiceStatus;
