import i18n from 'i18next';
import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IRespondent } from '../../models/Respondent';

import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AddressActionTypes } from '../address/actions';
import { AuthActionTypes } from '../auth/actions';
import { BankAccountActionTypes } from '../bankAccount/actions';
import { CompanyActionTypes } from '../company/actions';
import { ContactActionTypes } from '../contact/actions';
import { SpecActionTypes } from '../specs/actions';
import {
  toastCreateActions,
  toastCreateErrorActions,
  toastCreateSuccessActions,
} from '../toast/actions';
import {
  RespondentActionTypes,
  respondentCreateRespondentActions,
  respondentGetRespondentActions,
  respondentGetRespondentsActions,
  respondentRemoveRespondentActions,
  respondentUpdateRespondentActions,
} from './actions';
import { api, RespondentCreatePayload, RespondentUpdatePayload } from './api';
import {
  selectRespondentRespondentsFilterColumn,
  selectRespondentRespondentsOrderColumn,
  selectRespondentRespondentsPagination,
} from './selectors';

/* STATE */
export interface RespondentState {
  respondents: IRespondent[];
  respondentsPagination: Pages;
  respondentsStatus: LoadingStatus;
  respondentsOrder: ITableOrder;
  respondentsFilter: Record<string, ISearch>;
  respondent: IRespondent | null;
  respondentStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: RespondentState = {
  respondents: [],
  respondentsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  respondentsStatus: LoadingStatus.initial,
  respondentsOrder: {
    sort: 'tanzanitId',
    order: IOrderStatus.ASC,
  },
  respondentsFilter: {},
  respondent: null,
  respondentStatus: LoadingStatus.initial,
};

const respondents = createReducer(initialState.respondents, {
  [RespondentActionTypes.GetRespondents]: {
    [RequestActionTypes.SUCCESS]: (state: IRespondent[], payload: PaginatedResp<IRespondent>) => {
      if (payload.currentPage == 1 || payload?.removeOldData) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.respondents,
  },
  [RespondentActionTypes.RemoveRespondent]: {
    [RequestActionTypes.SUCCESS]: (state: IRespondent[], payload: { id: number }) => {
      return state.filter((respondent) => respondent.id !== payload.id);
    },
  },
  [RespondentActionTypes.Clean]: () => initialState.respondents,
  [RespondentActionTypes.CleanRespondent]: () => initialState.respondents,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.respondents,
  },
});

const respondentsPagination = createReducer(initialState.respondentsPagination, {
  [RespondentActionTypes.GetRespondents]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<IRespondent>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.respondentsPagination,
  },
  [RespondentActionTypes.SetRespondentsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [RespondentActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [RespondentActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [RespondentActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [RespondentActionTypes.Clean]: () => initialState.respondentsPagination,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.respondentsPagination,
  },
});

const respondentsStatus = createLoadingStateReducer(
  initialState.respondentsStatus,
  {
    [RespondentActionTypes.GetRespondents]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [RespondentActionTypes.SetRespondentsPage]: () => initialState.respondentsStatus,
    [RespondentActionTypes.IncreasePage]: () => initialState.respondentsStatus,
    [RespondentActionTypes.SetFilterColumn]: () => initialState.respondentsStatus,
    [RespondentActionTypes.SetOrderColumn]: () => initialState.respondentsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.respondentStatus,
    },
  }
);

const respondentsOrder = createReducer(initialState.respondentsOrder, {
  [RespondentActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [RespondentActionTypes.Clean]: () => initialState.respondentsOrder,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.respondentsOrder,
  },
});

const respondentsFilter = createReducer(initialState.respondentsFilter, {
  [RespondentActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [RespondentActionTypes.Clean]: () => initialState.respondentsFilter,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.respondentsFilter,
  },
});

const respondent = createReducer(initialState.respondent, {
  [RespondentActionTypes.GetRespondent]: {
    [RequestActionTypes.REQUEST]: () => initialState.respondent,
    [RequestActionTypes.SUCCESS]: (state: IRespondent | null, payload: IRespondent | null) => {
      return payload;
    },
    [RequestActionTypes.FAILURE]: () => initialState.respondent,
  },
  [SpecActionTypes.CreateSpec]: {
    [RequestActionTypes.SUCCESS]: (state: IRespondent | null, payload: IRespondent) => {
      if (state?.id === payload.id) {
        // updated respondent's specs
        return {
          ...state,
          specs: payload.specs,
        };
      }
      return state;
    },
  },
  [SpecActionTypes.RemoveSpec]: {
    [RequestActionTypes.SUCCESS]: (state: IRespondent | null, payload: IRespondent) => {
      if (state?.id === payload.id) {
        // updated respondent's specs
        return {
          ...state,
          specs: payload.specs,
        };
      }
      return state;
    },
  },
  [BankAccountActionTypes.RemoveBankAccount]: {
    [RequestActionTypes.SUCCESS]: (state: IRespondent | null, payload: { id: number }) => ({
      ...state,
      bankAccounts: state?.bankAccounts?.filter((account) => account.id !== payload.id),
    }),
  },
  [AddressActionTypes.RemoveAddress]: {
    [RequestActionTypes.SUCCESS]: (state: IRespondent | null, payload: { id: number }) => ({
      ...state,
      addresses: state?.addresses?.filter((address) => address.id !== payload.id),
    }),
  },
  [ContactActionTypes.UpsertContact]: {
    [RequestActionTypes.SUCCESS]: (state: IRespondent | null, payload: IRespondent) => {
      if (state?.id === payload.id) {
        // updated respondent's contacts
        return {
          ...state,
          contacts: payload.contacts,
        };
      }
      return state;
    },
  },
  [ContactActionTypes.RemoveContact]: {
    [RequestActionTypes.SUCCESS]: (state: IRespondent | null, payload: IRespondent) => {
      if (state?.id === payload.id) {
        // updated respondent's contacts
        return {
          ...state,
          contacts: payload.contacts,
        };
      }
      return state;
    },
  },
  [CompanyActionTypes.RemoveCompany]: {
    [RequestActionTypes.SUCCESS]: (state: IRespondent | null, payload: { id: number }) => ({
      ...state,
      companies: state?.companies?.filter((company) => company.id !== payload.id),
    }),
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.respondent,
  },
});

const respondentStatus = createReducer(initialState.respondentStatus, {
  [RespondentActionTypes.GetRespondent]: {
    [RequestActionTypes.REQUEST]: () => initialState.respondentStatus,
  },
  [RespondentActionTypes.UpdateRespondent]: {
    [RequestActionTypes.REQUEST]: () => LoadingStatus.loading,
    [RequestActionTypes.SUCCESS]: () => initialState.respondentStatus,
    [RequestActionTypes.FAILURE]: () => initialState.respondentStatus,
  },
  [RespondentActionTypes.CreateRespondent]: {
    [RequestActionTypes.REQUEST]: () => LoadingStatus.loading,
    [RequestActionTypes.SUCCESS]: () => initialState.respondentStatus,
    [RequestActionTypes.FAILURE]: () => initialState.respondentStatus,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.respondentStatus,
  },
});

export default combineReducers<RespondentState>({
  respondents,
  respondentsPagination,
  respondentsStatus,
  respondentsOrder,
  respondentsFilter,
  respondent,
  respondentStatus,
});

/* SAGAS */
function* getRespondents() {
  const pagination: Pages = yield select(selectRespondentRespondentsPagination);
  const orderColumn: ITableOrder = yield select(selectRespondentRespondentsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(
    selectRespondentRespondentsFilterColumn
  );

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getRespondents, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(
      respondentGetRespondentsActions.success(
        toCamelCase({
          ...resp.data,
          removeOldData: !!pagination?.removeOldData,
        })
      )
    );
  } else {
    yield put(respondentGetRespondentsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createRespondent({ payload }: AppAction<RespondentCreatePayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.createRespondent, payload);

  if (resp.ok) {
    yield put(respondentCreateRespondentActions.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(buildRoute([AppRoutes.Admin, AdminRoutes.Respondents]));
  } else {
    yield put(respondentCreateRespondentActions.failure());
    yield put(toastCreateErrorActions(resp?.data?.message));
  }
}
function* getRespondent({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getRespondent, payload);

  if (resp.ok) {
    yield put(respondentGetRespondentActions.success(toCamelCase(resp.data) || null));
  } else {
    yield put(respondentGetRespondentActions.failure());
    yield put(toastCreateErrorActions(resp?.data?.message));
  }
}

function* removeRespondent({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeRespondent, payload);

  if (resp.ok) {
    yield put(respondentRemoveRespondentActions.success({ id: payload.id }));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(respondentRemoveRespondentActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateRespondent({ payload }: AppAction<RespondentUpdatePayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateRespondent, payload);

  if (resp.ok) {
    yield put(respondentUpdateRespondentActions.success());
    yield put(
      toastCreateSuccessActions(
        resp.data.message || i18n.t('components.successToast.requestPending')
      )
    );

    history.push(buildRoute([AppRoutes.Admin, AdminRoutes.Respondents]));
  } else {
    yield put(respondentUpdateRespondentActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* respondentSaga() {
  yield takeLatest(
    [
      createActionType(RespondentActionTypes.GetRespondents, RequestActionTypes.REQUEST),
      RespondentActionTypes.SetRespondentsPage,
      RespondentActionTypes.IncreasePage,
      RespondentActionTypes.SetOrderColumn,
      RespondentActionTypes.SetFilterColumn,
    ],
    getRespondents
  );
  yield takeLatest(
    createActionType(RespondentActionTypes.CreateRespondent, RequestActionTypes.REQUEST),
    createRespondent
  );
  yield takeLatest(
    createActionType(RespondentActionTypes.GetRespondent, RequestActionTypes.REQUEST),
    getRespondent
  );
  yield takeLatest(
    [createActionType(RespondentActionTypes.RemoveRespondent, RequestActionTypes.REQUEST)],
    removeRespondent
  );
  yield takeLatest(
    createActionType(RespondentActionTypes.UpdateRespondent, RequestActionTypes.REQUEST),
    updateRespondent
  );
}
