import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { BankAccount } from '../../models/BankAccount';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { BankAccountState } from './ducks';

/* SELECTORS */
const selectBankAccount = (state: AppState): BankAccountState => state.bankAccount;

export const selectBankAccountBankAccounts = (state: AppState): BankAccount[] =>
  selectBankAccount(state).bankAccounts;

export const selectBankAccountBankAccountsPagination = (state: AppState): Pages =>
  selectBankAccount(state).bankAccountsPagination;

export const selectBankAccountBankAccountsStatus = (state: AppState): LoadingStatus =>
  selectBankAccount(state).bankAccountsStatus;

export const selectBankAccountBankAccountsOrderColumn = (state: AppState): ITableOrder =>
  selectBankAccount(state).bankAccountsOrder;

export const selectBankAccountBankAccountsFilterColumn = (
  state: AppState
): Record<string, ISearch> => selectBankAccount(state).bankAccountsFilter;

export const selectBankAccountBankAccount = (state: AppState) =>
  selectBankAccount(state).bankAccount;

export const selectBankAccountBankAccountStatus = (state: AppState) =>
  selectBankAccount(state).bankAccountStatus;
