import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import { LoginAsRespondentRequest, ResetPasswordSetByAdminRequest } from '../../models/Auth';

/* ACTION TYPES */
export enum AuthActionTypes {
  Login = '@@Auth/LOGIN',
  LoginAsRespondent = '@@Auth/LOGIN_AS_RESPONDENT',
  Logout = '@@Auth/LOGOUT',
  Register = '@@Auth/REGISTER',
  RequestPasswordReset = '@@Auth/REQUEST_PASSWORD_RESET',
  ResetPassword = '@@Auth/RESET_PASSWORD',
  GetProfile = '@@Auth/GET_PROFILE',
  SetCookieConsent = '@@Auth/SET_COOKIE_CONSENT',
  GetChambers = '@@Auth/GET_CHAMBERS',
  GetPermissions = '@@Auth/GET_PERMISSIONS',
  ResetPasswordAuthenticated = '@@Auth/RESET_PASSWORD_AUTHENTICATED',
  Verify = '@@Auth/VERIFY',
  SetHasSignedContract = '@@Auth/SET_HAS_SIGNED_CONTRACT',
  CacheAuthUser = '@@Auth/CACHE_AUTH_USER',
  ResetPasswordSetByAdmin = '@@Auth/RESET_PASSWORD_SET_BY_ADMIN',
}

/* ACTIONS */
export const authLoginActions = createApiActionCreators(AuthActionTypes.Login);
export const authLoginAsRespondent = createApiActionCreators<LoginAsRespondentRequest>(
  AuthActionTypes.LoginAsRespondent
);
export const authLogoutActions = createApiActionCreators(AuthActionTypes.Logout);
export const authRegisterActions = createApiActionCreators(AuthActionTypes.Register);
export const authRequestPasswordResetActions = createApiActionCreators(
  AuthActionTypes.RequestPasswordReset
);
export const authResetPasswordActions = createApiActionCreators(AuthActionTypes.ResetPassword);
export const authGetProfileActions = createApiActionCreators(AuthActionTypes.GetProfile);
export const authSetCookieConsentActions = createActionCreator(AuthActionTypes.SetCookieConsent);
export const authGetChambersActions = createApiActionCreators(AuthActionTypes.GetChambers);
export const authGetPermissionsActions = createApiActionCreators(AuthActionTypes.GetPermissions);
export const authResetPasswordAuthenticatedActions = createApiActionCreators(
  AuthActionTypes.ResetPasswordAuthenticated
);
export const authVerifyActions = createApiActionCreators(AuthActionTypes.Verify);
export const authSetHasSignedConctractActions = createActionCreator(
  AuthActionTypes.SetHasSignedContract
);
export const authCacheAuthUser = createActionCreator(AuthActionTypes.CacheAuthUser);
export const authResetPasswordSetByAdmin = createApiActionCreators<ResetPasswordSetByAdminRequest>(
  AuthActionTypes.ResetPasswordSetByAdmin
);
