import dayjs from 'dayjs';
import { omit } from 'lodash';

import { combineReducers } from 'redux';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase, toSnakeCase } from '../../helpers/transformObject';
import { AssignedProject } from '../../models/AssignedProject';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions } from '../toast/actions';
import {
  AssignedProjectActionTypes,
  assignedProjectGetAssignedProject,
  assignedProjectGetAssignedProjects,
} from './actions';
import { api } from './api';
import {
  selectAssignedProjectProjects,
  selectAssignedProjectProjectsFilterColumn,
  selectAssignedProjectProjectsOrderColumn,
  selectAssignedProjectProjectsPagination,
} from './selectors';

/* STATE */
export interface AssignedProjectState {
  projects: AssignedProject[] | null;
  projectsPagination: Pages;
  projectsStatus: LoadingStatus;
  projectsOrder: ITableOrder;
  projectsFilter: Record<string, ISearch>;
  project: AssignedProject | null;
  projectStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: AssignedProjectState = {
  projects: null,
  projectsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  projectsStatus: LoadingStatus.initial,
  projectsOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  projectsFilter: {},
  project: null,
  projectStatus: LoadingStatus.initial,
};

const projects = createReducer(initialState.projects, {
  [AssignedProjectActionTypes.GetAssignedProjects]: {
    [RequestActionTypes.SUCCESS]: (
      state: AssignedProject[],
      payload: PaginatedResp<AssignedProject>
    ) => payload.data,
    [RequestActionTypes.FAILURE]: () => initialState.projects,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projects,
  },
});

const projectsPagination = createReducer(initialState.projectsPagination, {
  [AssignedProjectActionTypes.GetAssignedProjects]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<AssignedProject>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.projectsPagination,
  },
  [AssignedProjectActionTypes.SetAssignedProjectPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [AssignedProjectActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [AssignedProjectActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AssignedProjectActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectsPagination,
  },
});

const projectsStatus = createLoadingStateReducer(
  initialState.projectsStatus,
  {
    [AssignedProjectActionTypes.GetAssignedProjects]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AssignedProjectActionTypes.SetAssignedProjectPage]: () => initialState.projectsStatus,
    [AssignedProjectActionTypes.IncreasePage]: () => initialState.projectsStatus,
    [AssignedProjectActionTypes.SetFilterColumn]: () => initialState.projectsStatus,
    [AssignedProjectActionTypes.SetOrderColumn]: () => initialState.projectsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.projectsStatus,
    },
  }
);

const projectsOrder = createReducer(initialState.projectsOrder, {
  [AssignedProjectActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectsOrder,
  },
});

const projectsFilter = createReducer(initialState.projectsFilter, {
  [AssignedProjectActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectsFilter,
  },
});

const project = createReducer(initialState.project, {
  [AssignedProjectActionTypes.GetAssignedProject]: {
    [RequestActionTypes.REQUEST]: () => initialState.project,
    [RequestActionTypes.SUCCESS]: (state: AssignedProject | null, payload: AssignedProject) =>
      payload,
    [RequestActionTypes.REQUEST]: () => initialState.project,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.project,
  },
});

const projectStatus = createLoadingStateReducer(
  initialState.projectStatus,
  {
    [AssignedProjectActionTypes.GetAssignedProject]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.projectStatus,
    },
  }
);

export default combineReducers<AssignedProjectState>({
  projects,
  projectsPagination,
  projectsStatus,
  projectsOrder,
  projectsFilter,
  project,
  projectStatus,
});

/* SAGAS */
function* getProjects() {
  const pagination: Pages = yield select(selectAssignedProjectProjectsPagination);
  const orderColumn: ITableOrder = yield select(selectAssignedProjectProjectsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(
    selectAssignedProjectProjectsFilterColumn
  );

  const stringFromFilter = `project_end:>=:${dayjs().format('YYYY-MM-DD')},${formatFilterToString(
    filterColumn
  )}`;

  const resp: ExtendedAxiosResponse = yield call(api.getAssignedProjects, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    const data = toCamelCase(resp.data);

    if (data.currentPage <= 1) {
      yield put(assignedProjectGetAssignedProjects.success(data));
    } else {
      const prevProjects: AssignedProject[] = yield select(selectAssignedProjectProjects);
      yield put(
        assignedProjectGetAssignedProjects.success({
          ...data,
          data: [...prevProjects, ...data.data],
        })
      );
    }
  } else {
    yield put(assignedProjectGetAssignedProjects.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getProject({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getAssignedProject, toSnakeCase(payload));

  if (resp.ok) {
    const data = toCamelCase(resp.data).data;

    if (data.project?.id) {
      yield put(assignedProjectGetAssignedProject.success(data));
    } else {
      yield put(toastCreateErrorActions());
    }
  } else {
    yield put(assignedProjectGetAssignedProject.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* assignedProjectSaga() {
  yield takeLatest(
    [
      createActionType(AssignedProjectActionTypes.GetAssignedProjects, RequestActionTypes.REQUEST),
      AssignedProjectActionTypes.SetAssignedProjectPage,
      AssignedProjectActionTypes.IncreasePage,
      AssignedProjectActionTypes.SetOrderColumn,
      AssignedProjectActionTypes.SetFilterColumn,
    ],
    getProjects
  );
  yield takeLatest(
    createActionType(AssignedProjectActionTypes.GetAssignedProject, RequestActionTypes.REQUEST),
    getProject
  );
}
