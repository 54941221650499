/* SELECTORS */
import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Pages } from '../../models/Pages';
import { Address } from '../../models/Respondent';
import { ISearch, ITableOrder } from '../../models/Table';
import { AddressState } from './ducks';

const selectAddress = (state: AppState): AddressState => state.address;

export const selectAddressAddress = (state: AppState): Address | null =>
  selectAddress(state).address;

export const selectAddressStatus = (state: AppState): LoadingStatus => selectAddress(state).status;

export const selectAddressAddresses = (state: AppState): Address[] =>
  selectAddress(state).addresses;

export const selectAddressAddressesPagination = (state: AppState): Pages =>
  selectAddress(state).addressesPagination;

export const selectAddressAddressesStatus = (state: AppState): LoadingStatus =>
  selectAddress(state).addressesStatus;

export const selectAddressAddressesOrderColumn = (state: AppState): ITableOrder =>
  selectAddress(state).addressesOrder;

export const selectAddressAddressesFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectAddress(state).addressesFilter;
