import { omit } from 'lodash';
import { combineReducers } from 'redux';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { Client } from '../../models/Client';
import { ClientInvoice } from '../../models/ClientInvoice';

import { ClientsDataOverviewProjectRespondent } from '../../models/ClientsDataOverview';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IProject } from '../../models/Project';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions } from '../toast/actions';
import {
  ClientsDataOverviewActionTypes,
  clientsDataOverviewGetClients,
  clientsDataOverviewGetInvoices,
  clientsDataOverviewGetProjectRespondentsActions,
  clientsDataOverviewGetProjects,
} from './actions';
import { api } from './api';
import {
  selectClientsDataOverviewClientInvoice,
  selectClientsDataOverviewProjectRespondentsFilterColumn,
  selectClientsDataOverviewProjectRespondentsOrderColumn,
  selectClientsDataOverviewProjectRespondentsPagination,
} from './selectors';

/* STATE */
export interface ClientsDataOverviewSummary {
  numberOfAssignedProjects: number;
  surveysInProgressCount: number;
}

export interface ClientsDataOverviewState {
  clientInvoices: ClientInvoice[];
  clientInvoice: ClientInvoice | null;
  clientInvoicesStatus: LoadingStatus;
  projectRespondents: ClientsDataOverviewProjectRespondent[];
  projectRespondentsPagination: Pages;
  projectRespondentsStatus: LoadingStatus;
  projectRespondentsOrder: ITableOrder;
  projectRespondentsFilter: Record<string, ISearch>;
  clients: Client[];
  clientsStatus: LoadingStatus;
  summary: ClientsDataOverviewSummary;
  projects: { id: string; name: string }[];
  projectsStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: ClientsDataOverviewState = {
  clientInvoices: [],
  clientInvoice: null,
  clientInvoicesStatus: LoadingStatus.initial,
  projectRespondents: [],
  projectRespondentsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  projectRespondentsStatus: LoadingStatus.initial,
  projectRespondentsOrder: {
    sort: '',
    order: IOrderStatus.DESC,
  },
  projectRespondentsFilter: {},
  clients: [],
  clientsStatus: LoadingStatus.initial,
  summary: {
    numberOfAssignedProjects: 0,
    surveysInProgressCount: 0,
  },
  projects: [],
  projectsStatus: LoadingStatus.initial,
};

const clientInvoices = createReducer(initialState.clientInvoices, {
  [ClientsDataOverviewActionTypes.GetInvoices]: {
    [RequestActionTypes.SUCCESS]: (state: ClientInvoice[], payload: ClientInvoice[]) => payload,
  },
  [ClientsDataOverviewActionTypes.CleanData]: () => initialState.clientInvoices,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clientInvoices,
  },
});

const clientInvoicesStatus = createLoadingStateReducer(
  initialState.clientInvoicesStatus,
  {
    [ClientsDataOverviewActionTypes.GetInvoices]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.clientInvoicesStatus,
    },
  }
);

const clientInvoice = createReducer(initialState.clientInvoice, {
  [ClientsDataOverviewActionTypes.SetInvoice]: (
    state: ClientInvoice | null,
    payload: ClientInvoice
  ) => payload,
  [ClientsDataOverviewActionTypes.CleanData]: () => initialState.clientInvoice,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clientInvoice,
  },
});

const projectRespondents = createReducer(initialState.projectRespondents, {
  [ClientsDataOverviewActionTypes.GetProjectRespondents]: {
    [RequestActionTypes.SUCCESS]: (
      state: ClientsDataOverviewProjectRespondent[],
      payload: PaginatedResp<ClientsDataOverviewProjectRespondent>
    ) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.projectRespondents,
  },
  [ClientsDataOverviewActionTypes.CleanData]: () => initialState.projectRespondents,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectRespondents,
  },
});

const projectRespondentsPagination = createReducer(initialState.projectRespondentsPagination, {
  [ClientsDataOverviewActionTypes.GetProjectRespondents]: {
    [RequestActionTypes.SUCCESS]: (
      state: Pages,
      payload: PaginatedResp<ClientsDataOverviewProjectRespondent>
    ) => omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.projectRespondentsPagination,
  },
  [ClientsDataOverviewActionTypes.SetProjectRespondentsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [ClientsDataOverviewActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [ClientsDataOverviewActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ClientsDataOverviewActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ClientsDataOverviewActionTypes.CleanData]: () => initialState.projectRespondentsPagination,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectRespondentsPagination,
  },
});

const projectRespondentsStatus = createLoadingStateReducer(
  initialState.projectRespondentsStatus,
  {
    [ClientsDataOverviewActionTypes.GetProjectRespondents]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ClientsDataOverviewActionTypes.SetProjectRespondentsPage]: () =>
      initialState.projectRespondentsStatus,
    [ClientsDataOverviewActionTypes.IncreasePage]: () => initialState.projectRespondentsStatus,
    [ClientsDataOverviewActionTypes.SetFilterColumn]: () => initialState.projectRespondentsStatus,
    [ClientsDataOverviewActionTypes.SetOrderColumn]: () => initialState.projectRespondentsStatus,
    [ClientsDataOverviewActionTypes.CleanData]: () => initialState.projectRespondentsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.projectRespondentsStatus,
    },
  }
);

const projectRespondentsOrder = createReducer(initialState.projectRespondentsOrder, {
  [ClientsDataOverviewActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [ClientsDataOverviewActionTypes.CleanData]: () => initialState.projectRespondentsOrder,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectRespondentsOrder,
  },
});

const projectRespondentsFilter = createReducer(initialState.projectRespondentsFilter, {
  [ClientsDataOverviewActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [ClientsDataOverviewActionTypes.CleanData]: () => initialState.projectRespondentsFilter,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectRespondentsFilter,
  },
});

const clients = createReducer(initialState.clients, {
  [ClientsDataOverviewActionTypes.GetClients]: {
    [RequestActionTypes.SUCCESS]: (state: Client[], payload: Client[]) => payload,
  },
  [ClientsDataOverviewActionTypes.CleanData]: () => initialState.clients,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clients,
  },
});

const clientsStatus = createLoadingStateReducer(
  initialState.clientsStatus,
  {
    [ClientsDataOverviewActionTypes.GetClients]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.clientsStatus,
    },
  }
);

const summary = createReducer(initialState.summary, {
  [ClientsDataOverviewActionTypes.GetProjectRespondents]: {
    [RequestActionTypes.SUCCESS]: (
      state: ClientsDataOverviewSummary,
      payload: {
        summary: ClientsDataOverviewSummary;
      } & PaginatedResp<ClientsDataOverviewProjectRespondent>
    ) => {
      const numberOfAssignedProjects = parseInt(`${payload?.summary?.numberOfAssignedProjects}`);
      const surveysInProgressCount = parseInt(`${payload?.summary?.surveysInProgressCount}`);

      if (!isNaN(surveysInProgressCount) && !isNaN(numberOfAssignedProjects)) {
        if (payload?.currentPage == 1) {
          return {
            numberOfAssignedProjects: numberOfAssignedProjects,
            surveysInProgressCount: surveysInProgressCount,
          };
        } else {
          return {
            numberOfAssignedProjects: state.numberOfAssignedProjects + numberOfAssignedProjects,
            surveysInProgressCount: state.surveysInProgressCount + surveysInProgressCount,
          };
        }
      } else {
        return state;
      }
    },
  },
  [ClientsDataOverviewActionTypes.CleanData]: () => initialState.summary,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.summary,
  },
});

const projects = createReducer(initialState.projects, {
  [ClientsDataOverviewActionTypes.GetProjects]: {
    [RequestActionTypes.SUCCESS]: (state: IProject[], payload: IProject[]) =>
      payload?.reduce((acc: { id: string; name: string }[], i) => {
        acc.push({
          id: `${i.id}`,
          name: i.name,
        });
        return acc;
      }, []) || initialState.projects,
  },
  [ClientsDataOverviewActionTypes.CleanData]: () => initialState.projects,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projects,
  },
});

const projectsStatus = createLoadingStateReducer(
  initialState.projectsStatus,
  {
    [ClientsDataOverviewActionTypes.GetProjects]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.projectsStatus,
    },
  }
);

export default combineReducers<ClientsDataOverviewState>({
  clientInvoices,
  clientInvoicesStatus,
  clientInvoice,
  projectRespondents,
  projectRespondentsPagination,
  projectRespondentsStatus,
  projectRespondentsOrder,
  projectRespondentsFilter,
  clients,
  clientsStatus,
  summary,
  projects,
  projectsStatus,
});

/* SAGAS */
function* getInvoices({ payload }: AppAction<{ invoiceNumber: string; pharmaCompanyId: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getInvoices, payload);

  if (resp.ok) {
    yield put(clientsDataOverviewGetInvoices.success(toCamelCase(resp.data)?.data?.data || []));
  } else {
    yield put(clientsDataOverviewGetInvoices.failure());
  }
}

function* getProjectRespondents() {
  const pagination: Pages = yield select(selectClientsDataOverviewProjectRespondentsPagination);
  const orderColumn: ITableOrder = yield select(
    selectClientsDataOverviewProjectRespondentsOrderColumn
  );
  const filterColumn: Record<string, ISearch> = yield select(
    selectClientsDataOverviewProjectRespondentsFilterColumn
  );

  const invoice: ClientInvoice = yield select(selectClientsDataOverviewClientInvoice);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getProjectRespondents, {
    id: `${invoice?.id}` ?? '',
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(clientsDataOverviewGetProjectRespondentsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(clientsDataOverviewGetProjectRespondentsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getClients({ payload }: AppAction<{ search: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getClients, payload);

  if (resp.ok) {
    yield put(clientsDataOverviewGetClients.success(toCamelCase(resp.data)?.data || []));
  } else {
    yield put(clientsDataOverviewGetClients.failure());
  }
}

function* getProjects({ payload }: AppAction<{ search: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getProjects, payload);

  if (resp.ok) {
    yield put(clientsDataOverviewGetProjects.success(toCamelCase(resp.data)?.data || []));
  } else {
    yield put(clientsDataOverviewGetProjects.failure());
  }
}

/* EXPORT */
export function* clientsDataOverviewSaga() {
  yield takeLatest(
    createActionType(ClientsDataOverviewActionTypes.GetInvoices, RequestActionTypes.REQUEST),
    getInvoices
  );
  yield takeLatest(
    [
      createActionType(
        ClientsDataOverviewActionTypes.GetProjectRespondents,
        RequestActionTypes.REQUEST
      ),
      ClientsDataOverviewActionTypes.SetProjectRespondentsPage,
      ClientsDataOverviewActionTypes.IncreasePage,
      ClientsDataOverviewActionTypes.SetOrderColumn,
      ClientsDataOverviewActionTypes.SetFilterColumn,
    ],
    getProjectRespondents
  );
  yield takeLatest(
    createActionType(ClientsDataOverviewActionTypes.GetClients, RequestActionTypes.REQUEST),
    getClients
  );
  yield takeLatest(
    createActionType(ClientsDataOverviewActionTypes.GetProjects, RequestActionTypes.REQUEST),
    getProjects
  );
}
