import React from 'react';

export enum Permission {
  grant = 'grant',
  loggedIn = 'loggedIn',
  notLoggedInOnly = 'notLoggedInOnly',
  cmsCreate = 'cms.create',
  cmsRead = 'cms.read',
  cmsUpdate = 'cms.update',
  cmsDelete = 'cms.delete',
  companyRead = 'company.read',
  companyUpdate = 'company.update',
  companyCreate = 'company.create',
  companyDelete = 'company.delete',
  clientRead = 'client.read',
  clientUpdate = 'client.update',
  clientCreate = 'client.create',
  clientDelete = 'client.delete',
  projectCreate = 'project.create',
  projectRead = 'project.read',
  projectUpdate = 'project.update',
  projectDelete = 'project.delete',
  projectExportAnswers = 'project.exportAnswers',
  projectExportProjects = 'project.exportProjects',
  respondentCreate = 'respondent.create',
  respondentRead = 'respondent.read',
  respondentUpdate = 'respondent.update',
  respondentDelete = 'respondent.delete',
  respondentLogin = 'respondent.login',
  adminCreate = 'admin.create',
  adminRead = 'admin.read',
  adminUpdate = 'admin.update',
  adminDelete = 'admin.delete',
  templateCreate = 'template.create',
  templateRead = 'template.read',
  templateUpdate = 'template.update',
  templateDelete = 'template.delete',
  contractCreate = 'contract.create',
  contractRead = 'contract.read',
  contractUpdate = 'contract.update',
  contractDelete = 'contract.delete',
  invoicingEntityCreate = 'invoicingEntity.create',
  invoicingEntityRead = 'invoicingEntity.read',
  invoicingEntityUpdate = 'invoicingEntity.update',
  invoicingEntityDelete = 'invoicingEntity.delete',
  pharmaCompanyCreate = 'pharmaCompany.create',
  pharmaCompanyRead = 'pharmaCompany.read',
  pharmaCompanyUpdate = 'pharmaCompany.update',
  pharmaCompanyDelete = 'pharmaCompany.delete',
  territoryCreate = 'territory.create',
  territoryRead = 'territory.read',
  territoryUpdate = 'territory.update',
  territoryDelete = 'territory.delete',
  assignedProjectCreate = 'assignedProject.create',
  assignedProjectRead = 'assignedProject.read',
  assignedProjectUpdate = 'assignedProject.update',
  assignedProjectDelete = 'assignedProject.delete',
  assignedProjectImport = 'assignedProject.import',
  clientsDataCreate = 'clientsData.create',
  clientsDataRead = 'clientsData.read',
  clientsDataUpdate = 'clientsData.update',
  clientsDataDelete = 'clientsData.delete',
  projectDataCreate = 'projectsData.create',
  projectDataRead = 'projectData.read',
  projectDataUpdate = 'projectData.update',
  projectDataDelete = 'projectData.delete',
  nonProjectAcceptanceProtocolCreate = 'nonProjectAcceptanceProtocol.create',
  nonProjectAcceptanceProtocolRead = 'nonProjectAcceptanceProtocol.read',
  nonProjectAcceptanceProtocolUpdate = 'nonProjectAcceptanceProtocol.update',
  nonProjectAcceptanceProtocolDelete = 'nonProjectAcceptanceProtocol.delete',
  bankTransferCreate = 'bankTransfer.create',
  bankTransferRead = 'bankTransfer.read',
  bankTransferUpdate = 'bankTransfer.update',
  bankTransferDelete = 'bankTransfer.delete',
  clientInvoiceCreate = 'clientInvoice.create',
  clientInvoiceRead = 'clientInvoice.read',
  clientInvoiceUpdate = 'clientInvoice.update',
  clientInvoiceDelete = 'clientInvoice.delete',
  acceptanceProtocolCreate = 'acceptanceProtocol.create',
  acceptanceProtocolRead = 'acceptanceProtocol.read',
  acceptanceProtocolUpdate = 'acceptanceProtocol.update',
  acceptanceProtocolDelete = 'acceptanceProtocol.delete',
  banksRead = 'banks.read',
  banksCreate = 'banks.create',
  banksUpdate = 'banks.update',
  banksDelete = 'banks.delete',
}

export interface IRoute {
  children?: IRoute[];
  element?: React.ReactElement;
  index?: boolean;
  path: string;
  permissions?: Permission[];
  breadcrumb?: any;
  props?: {
    translate: string;
  };
}
