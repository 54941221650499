import React, { createContext, useState } from 'react';

import { useLocation } from 'react-router';

import { environment } from '../../../environments/environment';
import { buildRoute } from '../../../helpers/route/route-builder';
import { AdminRoutes } from '../../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../../helpers/route/routes/app-routes';
import SEO from '../../components/SEO/SEO';

interface Props {
  children: React.ReactNode;
}

interface IHandleTitleChange {
  titleStart?: string;
  titleEnd?: string;
}

interface ITitleContext {
  handleTitlesChange: (titles: IHandleTitleChange) => void;
}

export const TitleContext = createContext<ITitleContext>({
  handleTitlesChange: (_: IHandleTitleChange) => {},
});

const AppContent = ({ children }: Props) => {
  const [titleStart, setTitleStart] = useState('');
  const [titleEnd, setTitleEnd] = useState('');

  const handleTitlesChange = (titles: IHandleTitleChange) => {
    setTitleStart(titles?.titleStart || '');
    setTitleEnd(titles?.titleEnd || '');
  };

  const location = useLocation();

  return (
    <TitleContext.Provider
      value={{
        handleTitlesChange,
      }}
    >
      <div className={'grow flex flex-col'}>
        <SEO title={`${titleStart ? `${titleStart} -` : ''} ${environment.appName}`} />
        {(titleStart || titleEnd) && (
          <div className={'bg-primary'}>
            <div
              className={
                'container xl:max-w-screen-xl mx-auto p-4 text-white font-sans font-normal text-lg md:flex md:justify-between'
              }
            >
              <div>{`${titleStart}`}</div>
              <div>{`${titleEnd}`}</div>
            </div>
          </div>
        )}
        <main
          className={`flex-1 flex flex-col p-4 ${
            location.pathname !== buildRoute([AppRoutes.Admin, AdminRoutes.BankTransfers]) &&
            'md:mx-auto xl:max-w-screen-xl md:container '
          }`}
        >
          {children}
        </main>
      </div>
    </TitleContext.Provider>
  );
};

export default AppContent;
