import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export interface RegionPayload {
  id?: number;
  name: string;
  regionId: number;
}

export const api = {
  getRegions: ({ pagination, order, filter }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/territory', {
      params: toSnakeCase({
        territoryType: 'Region',
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: order.sort,
        search: filter,
      }),
    });
  },

  removeRegion: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/territory/${id}`, {
      params: toSnakeCase({
        territoryType: 'Region',
      }),
    }),

  getCountries: (): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/territory', {
      params: toSnakeCase({
        territoryType: 'Country',
        resultsPerPage: 1000,
      }),
    }),

  getRegion: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/territory/${id}`, {
      params: toSnakeCase({
        territoryType: 'Region',
      }),
    }),

  createRegion: (payload: RegionPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post(
      '/territory',
      toSnakeCase({
        territoryType: 'Region',
        data: {
          ...payload,
        },
      })
    ),

  updateRegion: (payload: RegionPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(
      `/territory/${payload?.id}`,
      toSnakeCase({
        territoryType: 'Region',
        data: {
          ...payload,
        },
      })
    ),
};
