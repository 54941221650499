/* SELECTORS */
import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { AssignedProject } from '../../models/AssignedProject';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { AssignedProjectState } from './ducks';

const selectAssignedProject = (state: AppState): AssignedProjectState => state.assignedProject;

export const selectAssignedProjectProjects = (state: AppState): AssignedProject[] | null =>
  selectAssignedProject(state).projects;

export const selectAssignedProjectProjectsPagination = (state: AppState): Pages =>
  selectAssignedProject(state).projectsPagination;

export const selectAssignedProjectProjectsStatus = (state: AppState): LoadingStatus =>
  selectAssignedProject(state).projectsStatus;

export const selectAssignedProjectProjectsOrderColumn = (state: AppState): ITableOrder =>
  selectAssignedProject(state).projectsOrder;

export const selectAssignedProjectProjectsFilterColumn = (
  state: AppState
): Record<string, ISearch> => selectAssignedProject(state).projectsFilter;

export const selectAssignedProjectProject = (state: AppState): AssignedProject | null =>
  selectAssignedProject(state).project;

export const selectAssignedProjectStatus = (state: AppState): LoadingStatus =>
  selectAssignedProject(state).projectStatus;
