/* SELECTORS */
import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { IInvoiceCompany } from '../../models/InvoiceCompany';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { InvoiceCompanyState } from './ducks';

const selectInvoiceCompany = (state: AppState): InvoiceCompanyState => state.invoiceCompany;

export const selectInvoiceCompaniesAll = (state: AppState): IInvoiceCompany[] =>
  selectInvoiceCompany(state).invoiceCompaniesAll;

export const selectInvoiceCompanyCompanies = (state: AppState): IInvoiceCompany[] =>
  selectInvoiceCompany(state).invoiceCompanies;

export const selectInvoiceCompanyCompaniesPagination = (state: AppState): Pages =>
  selectInvoiceCompany(state).invoiceCompaniesPagination;

export const selectInvoiceCompanyCompaniesStatus = (state: AppState): LoadingStatus =>
  selectInvoiceCompany(state).invoiceCompaniesStatus;

export const selectInvoiceCompanyCompaniesOrderColumn = (state: AppState): ITableOrder =>
  selectInvoiceCompany(state).invoiceCompaniesOrder;

export const selectInvoiceCompanyCompaniesFilterColumn = (
  state: AppState
): Record<string, ISearch> => selectInvoiceCompany(state).invoiceCompaniesFilter;

export const selectInvoiceCompanyCompany = (state: AppState): IInvoiceCompany | null =>
  selectInvoiceCompany(state).invoiceCompany;

export const selectInvoiceCompanyInvoiceCompanyActionStatus = (state: AppState): LoadingStatus =>
  selectInvoiceCompany(state).invoiceCompanyActionStatus;
