import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { BankAccount } from '../../models/BankAccount';
import { AdminContract, Contract, PersistContractForm } from '../../models/Contract';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { ContractState } from './ducks';

/* SELECTORS */
const selectContract = (state: AppState): ContractState => state.contract;

export const selectContractContracts = (state: AppState): Contract[] =>
  selectContract(state).contracts;

export const selectContractAdminContracts = (state: AppState): AdminContract[] =>
  selectContract(state).adminContracts;

export const selectContractContractsPagination = (state: AppState): Pages =>
  selectContract(state).contractsPagination;

export const selectContractContractsStatus = (state: AppState): LoadingStatus =>
  selectContract(state).contractsStatus;

export const selectContractContractsOrderColumn = (state: AppState): ITableOrder =>
  selectContract(state).contractsOrder;

export const selectContractContractsFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectContract(state).contractsFilter;

export const selectContractBankAccounts = (state: AppState): Record<string, BankAccount[]> =>
  selectContract(state).bankAccounts;

export const selectContractBankAccountsStatus = (state: AppState): LoadingStatus =>
  selectContract(state).bankAccountsStatus;

export const selectContractGenerateDoc = (state: AppState): string | null =>
  selectContract(state).contractDoc;

export const selectContractPersistForm = (state: AppState): PersistContractForm | null =>
  selectContract(state).contractForm;
