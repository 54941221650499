import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export const api = {
  getProtocols: ({
    id,
    pagination,
    order,
    filter,
  }: ITableReq & { id?: string }): Promise<ExtendedAxiosResponse> => {
    return apiClient.get(`/admin/client-invoice/${id}/assigned-projects/handover-protocols`, {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    });
  },
};
