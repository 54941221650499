import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toSnakeCase } from '../../helpers/transformObject';
import { ITableReq } from '../../models/Table';

export interface BankPayload {
  id?: number;
  name: string;
  code: string;
  switfCode: string;
}

export const api = {
  getBanks: ({ pagination, order }: ITableReq): Promise<ExtendedAxiosResponse> => {
    return apiClient.get('/banks', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
      }),
    });
  },

  removeBank: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/admin/banks/${id}`),

  getBank: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/admin/banks/${id}`),

  createBank: (payload: BankPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post(
      '/admin/banks',
      toSnakeCase({
        ...payload,
      })
    ),

  updateBank: (payload: BankPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(
      `/admin/banks/${payload?.id}`,
      toSnakeCase({
        ...payload,
      })
    ),
};
