import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import { RemoveBankAccountPayload } from './api';

/* ACTION TYPES */
export enum BankAccountActionTypes {
  GetBankAccounts = '@@BankAccount/GET_BANK_ACCOUNTS',
  SetBankAccountsPage = '@@BankAccount/SET_COMPANIES_PAGE',
  IncreasePage = '@@BankAccount/INCREASE_COMPANIES',
  SetOrderColumn = '@@BankAccount/SET_ORDER_COLUMN',
  SetFilterColumn = '@@BankAccount/SET_FILTER_COLUMNS',
  SetPrimaryBankAccount = '@@BankAccount/SET_PRIMARY_BANK_ACCOUNT',
  CreateBankAccount = '@@BankAccount/CREATE_BANK_ACCOUNT',
  GetBankAccount = '@@BankAccount/GET_BANK_ACCOUNT',
  RemoveBankAccount = '@@BankAccount/REMOVE_BANK_ACCOUNT',
  UpdateBankAccount = '@@BankAccount/UPDATE_BANK_ACCOUNT',
}

/* ACTIONS */
export const bankAccountGetBankAccountsActions = createApiActionCreators(
  BankAccountActionTypes.GetBankAccounts
);
export const bankAccountSetBankAccountsPageActions = createActionCreator(
  BankAccountActionTypes.SetBankAccountsPage
);
export const bankAccountIncreasePageActions = createActionCreator(
  BankAccountActionTypes.IncreasePage
);
export const bankAccountSetOrderColumnActions = createActionCreator(
  BankAccountActionTypes.SetOrderColumn
);
export const bankAccountSetFilterColumnActions = createActionCreator(
  BankAccountActionTypes.SetFilterColumn
);

export const bankAccountSetPrimaryBankAccountActions = createApiActionCreators(
  BankAccountActionTypes.SetPrimaryBankAccount
);

export const bankAccountCreateBankAccountActions = createApiActionCreators(
  BankAccountActionTypes.CreateBankAccount
);

export const bankAccountGetBankAccountActions = createApiActionCreators(
  BankAccountActionTypes.GetBankAccount
);

export const bankAccountRemoveBankAccountActions =
  createApiActionCreators<RemoveBankAccountPayload>(BankAccountActionTypes.RemoveBankAccount);

export const bankAccountUpdateBankAccountActions = createApiActionCreators(
  BankAccountActionTypes.UpdateBankAccount
);
