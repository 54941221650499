import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { buildRoute } from '../../helpers/route/route-builder';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { lowercaseObjectKeys, toCamelCase } from '../../helpers/transformObject';
import { BankAccount } from '../../models/BankAccount';
import { AdminContract, Contract, PersistContractForm } from '../../models/Contract';

import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { User } from '../../models/User';
import { AuthActionTypes, authSetHasSignedConctractActions } from '../auth/actions';
import { selectAuthUser } from '../auth/selectors';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  ContractActionTypes,
  contractCreateContractActions,
  contractExportContractActions,
  contractGenerateContractDocActions,
  contractGetAdminContractsActions,
  contractGetBankAccountsActions,
  contractGetContractsActions,
  contractSetPrimaryBankAccountActions,
  contractSetPrimaryContractActions,
} from './actions';
import { api } from './api';
import {
  selectContractContractsFilterColumn,
  selectContractContractsOrderColumn,
  selectContractContractsPagination,
} from './selectors';

/* STATE */
export interface ContractState {
  contracts: Contract[];
  adminContracts: AdminContract[];
  contractsPagination: Pages;
  contractsStatus: LoadingStatus;
  contractsOrder: ITableOrder;
  contractsFilter: Record<string, ISearch>;
  bankAccounts: Record<string, BankAccount[]>;
  bankAccountsStatus: LoadingStatus;
  contractDoc: string | null;
  contractForm: PersistContractForm | null;
}

/* REDUCERS */
const initialState: ContractState = {
  contracts: [],
  adminContracts: [],
  contractsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  contractsStatus: LoadingStatus.initial,
  contractsOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  contractsFilter: {},
  bankAccounts: {},
  bankAccountsStatus: LoadingStatus.initial,
  contractDoc: null,
  contractForm: null,
};

const contracts = createReducer(initialState.contracts, {
  [ContractActionTypes.GetContracts]: {
    [RequestActionTypes.SUCCESS]: (state: Contract[], payload: PaginatedResp<Contract>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.contracts,
  },
  [ContractActionTypes.SetPrimaryContract]: {
    [RequestActionTypes.SUCCESS]: (state: Contract[], payload: { id: number }) =>
      state.map((contract) => ({
        ...contract,
        default: contract.id == payload.id,
      })),
    [RequestActionTypes.FAILURE]: (state: Contract[]) => [...state],
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.contracts,
  },
});

const adminContracts = createReducer(initialState.adminContracts, {
  [ContractActionTypes.GetAdminContracts]: {
    [RequestActionTypes.SUCCESS]: (
      state: AdminContract[],
      payload: PaginatedResp<AdminContract>
    ) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.adminContracts,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.adminContracts,
  },
});

const contractsPagination = createReducer(initialState.contractsPagination, {
  [ContractActionTypes.GetContracts]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<Contract>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.contractsPagination,
  },
  [ContractActionTypes.GetAdminContracts]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<AdminContract>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.contractsPagination,
  },
  [ContractActionTypes.SetContractsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [ContractActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [ContractActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ContractActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.contractsPagination,
  },
});

const contractsStatus = createLoadingStateReducer(
  initialState.contractsStatus,
  {
    [ContractActionTypes.GetContracts]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [ContractActionTypes.GetAdminContracts]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ContractActionTypes.SetContractsPage]: () => initialState.contractsStatus,
    [ContractActionTypes.IncreasePage]: () => initialState.contractsStatus,
    [ContractActionTypes.SetFilterColumn]: () => initialState.contractsStatus,
    [ContractActionTypes.SetOrderColumn]: () => initialState.contractsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.contractsStatus,
    },
  }
);

const contractsOrder = createReducer(initialState.contractsOrder, {
  [ContractActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.contractsOrder,
  },
});

const contractsFilter = createReducer(initialState.contractsFilter, {
  [ContractActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.contractsFilter,
  },
});

const bankAccounts = createReducer(initialState.bankAccounts, {
  [ContractActionTypes.GetContracts]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, BankAccount[]>,
      payload: PaginatedResp<Contract>
    ) => {
      return {
        ...state,
        ...payload?.data?.reduce((arr: Record<string, BankAccount[]>, i: Contract) => {
          arr[i.id.toString()] = [i.bankAccount];
          return arr;
        }, {}),
      };
    },
  },
  [ContractActionTypes.GetBankAccounts]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, BankAccount[]>,
      payload: { data: PaginatedResp<BankAccount>; contractId: number }
    ) => {
      return {
        ...state,
        [payload.contractId]: [...payload?.data?.data],
      };
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.bankAccounts,
  },
});

const bankAccountsStatus = createLoadingStateReducer(
  initialState.bankAccountsStatus,
  {
    [ContractActionTypes.GetBankAccounts]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.bankAccountsStatus,
    },
  }
);

const contractDoc = createReducer(initialState.contractsFilter, {
  [ContractActionTypes.GenerateContractDoc]: {
    [RequestActionTypes.SUCCESS]: (state: any, payload: any) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.contractDoc,
    [RequestActionTypes.REQUEST]: () => initialState.contractDoc,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.contractDoc,
  },
});

const contractForm = createReducer(initialState.contractForm, {
  [ContractActionTypes.PersistForm]: (
    state: PersistContractForm | null,
    payload: PersistContractForm | null
  ) => {
    return payload;
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.contractForm,
  },
});

export default combineReducers<ContractState>({
  contracts,
  adminContracts,
  contractsPagination,
  contractsStatus,
  contractsOrder,
  contractsFilter,
  bankAccounts,
  bankAccountsStatus,
  contractDoc,
  contractForm,
});

/* SAGAS */
function* getContracts() {
  const pagination: Pages = yield select(selectContractContractsPagination);
  const orderColumn: ITableOrder = yield select(selectContractContractsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(selectContractContractsFilterColumn);

  const user: User | null = yield select(selectAuthUser);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const apiRequest = user?.tanzanitId ? api.getContracts : api.getAdminContracts;
  const action = user?.tanzanitId ? contractGetContractsActions : contractGetAdminContractsActions;

  const resp: ExtendedAxiosResponse = yield call(apiRequest, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(action.success(toCamelCase(resp.data)));
  } else {
    yield put(action.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* setPrimaryContract({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.setPrimaryContract, payload);

  if (resp.ok) {
    yield put(contractSetPrimaryContractActions.success({ id: payload.id }));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(contractSetPrimaryContractActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getBankAccounts({ payload }: AppAction<{ contractId: number; companyId?: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getBankAccounts, payload);

  if (resp.ok) {
    yield put(
      contractGetBankAccountsActions.success({
        data: toCamelCase(resp.data),
        contractId: payload.contractId,
      })
    );
  } else {
    yield put(contractGetBankAccountsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* setPrimaryBankAccount({ payload }: AppAction<{ id: number; bankAccountId: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.setPrimaryBankAccount, payload);

  if (resp.ok) {
    yield put(contractSetPrimaryBankAccountActions.success(resp.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(contractSetPrimaryBankAccountActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createContract({
  payload,
}: AppAction<{
  contractType: string;
  addressId: number;
  bankAccountId: number;
  companyId?: number;
}>) {
  const resp: ExtendedAxiosResponse = yield call(api.createContract, payload);

  if (resp.ok) {
    history.push(buildRoute(`${AppRoutes.Contracts}`));
    yield put(contractCreateContractActions.success());
    yield put(authSetHasSignedConctractActions(true));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(contractCreateContractActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* generateContractDoc({
  payload,
}: AppAction<{
  contractType: string;
  addressId: number;
  bankAccountId: number;
  companyId?: number;
}>) {
  const resp: ExtendedAxiosResponse = yield call(api.generateContractDoc, payload);

  if (resp.ok) {
    yield put(contractGenerateContractDocActions.success(resp.data));
  } else {
    yield put(contractGenerateContractDocActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* exportContract({ payload }: AppAction<Pick<AdminContract, 'id'>>) {
  const user: User | null = yield select(selectAuthUser);

  const resp: ExtendedAxiosResponse = yield call(
    user?.tanzanitId ? api.exportContract : api.exportAdminContract,
    payload
  );

  if (resp.ok) {
    const href = URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));

    const filename =
      lowercaseObjectKeys(resp.headers)
        ['content-disposition']?.split('filename="')?.[1]
        ?.split('"')?.[0] || '';

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${filename}.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    yield put(contractExportContractActions.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(contractExportContractActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* contractSaga() {
  yield takeLatest(
    [
      createActionType(ContractActionTypes.GetContracts, RequestActionTypes.REQUEST),
      ContractActionTypes.SetContractsPage,
      ContractActionTypes.IncreasePage,
      ContractActionTypes.SetOrderColumn,
      ContractActionTypes.SetFilterColumn,
    ],
    getContracts
  );
  yield takeLatest(
    [createActionType(ContractActionTypes.SetPrimaryContract, RequestActionTypes.REQUEST)],
    setPrimaryContract
  );
  yield takeLatest(
    [createActionType(ContractActionTypes.GetBankAccounts, RequestActionTypes.REQUEST)],
    getBankAccounts
  );
  yield takeLatest(
    [createActionType(ContractActionTypes.CreateContract, RequestActionTypes.REQUEST)],
    createContract
  );
  yield takeLatest(
    [createActionType(ContractActionTypes.GenerateContractDoc, RequestActionTypes.REQUEST)],
    generateContractDoc
  );
  yield takeLatest(
    [createActionType(ContractActionTypes.SetPrimaryBankAccount, RequestActionTypes.REQUEST)],
    setPrimaryBankAccount
  );
  yield takeLatest(
    [createActionType(ContractActionTypes.ExportContract, RequestActionTypes.REQUEST)],
    exportContract
  );
}
