import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum ArchivedProjectActionTypes {
  GetArchivedProjects = '@@ArchivedProject/GET_ASSIGNED_PROJECTS',
  SetArchivedProjectPage = '@@ArchivedProject/SET_PROJECTS_PAGE',
  IncreasePage = '@@ArchivedProject/INCREASE_PROJECTS_PAGE',
  SetOrderColumn = '@@ArchivedProject/SET_ORDER_COLUMN',
  SetFilterColumn = '@@ArchivedProject/SET_FILTER_COLUMNS',
  GetArchivedProject = '@@ArchivedProject/GET_ASSIGNED_PROJECT',
}

/* ACTIONS */
export const archivedProjectGetArchivedProjects = createApiActionCreators(
  ArchivedProjectActionTypes.GetArchivedProjects
);
export const archivedProjectSetProjectsPageActions = createActionCreator(
  ArchivedProjectActionTypes.SetArchivedProjectPage
);
export const archivedProjectIncreasePageActions = createActionCreator(
  ArchivedProjectActionTypes.IncreasePage
);
export const archivedProjectSetOrderColumnActions = createActionCreator(
  ArchivedProjectActionTypes.SetOrderColumn
);
export const archivedProjectSetFilterColumnActions = createActionCreator(
  ArchivedProjectActionTypes.SetFilterColumn
);
export const archivedProjectGetArchivedProject = createApiActionCreators(
  ArchivedProjectActionTypes.GetArchivedProject
);
