import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { City, CityZip } from '../../models/City';
import { District } from '../../models/District';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { CityState } from './ducks';

/* SELECTORS */
const selectCity = (state: AppState): CityState => state.city;

export const selectCityCities = (state: AppState): City[] => selectCity(state).cities;

export const selectCityCitiesPagination = (state: AppState): Pages =>
  selectCity(state).citiesPagination;

export const selectCityCitiesStatus = (state: AppState): LoadingStatus =>
  selectCity(state).citiesStatus;

export const selectCityCitiesOrderColumn = (state: AppState): ITableOrder =>
  selectCity(state).citiesOrder;

export const selectCityCitiesFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectCity(state).citiesFilter;

export const selectCityCity = (state: AppState): City | null => selectCity(state).city;

export const selectCityDistricts = (state: AppState): District[] => selectCity(state).districts;

export const selectCityCityActionStatus = (state: AppState): LoadingStatus =>
  selectCity(state).cityActionStatus;

export const selectCityCitiesZip = (state: AppState): CityZip[] => selectCity(state).citiesZip;

export const selectCityCitiesZipPagination = (state: AppState): Pages =>
  selectCity(state).citiesZipPagination;

export const selectCityCitiesZipStatus = (state: AppState): LoadingStatus =>
  selectCity(state).citiesZipStatus;

export const selectCityCitiesZipOrderColumn = (state: AppState): ITableOrder =>
  selectCity(state).citiesZipOrder;

export const selectCityCitiesZipFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectCity(state).citiesZipFilter;
