/* SELECTORS */
import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { ImportRespondentState, ImportStatus, Respondent } from './ducks';

const selectImportRespondent = (state: AppState): ImportRespondentState => state.importRespondent;

export const selectImportRespondentGenerateStatus = (state: AppState): LoadingStatus =>
  selectImportRespondent(state).generateStatus;

export const selectImportRespondentErrors = (state: AppState): { row: string; message: string }[] =>
  selectImportRespondent(state).errors;

export const selectImportRespondentProjects = (state: AppState): { id: string; name: string }[] =>
  selectImportRespondent(state).projects;

export const selectImportRespondentProjectsStatus = (state: AppState): LoadingStatus =>
  selectImportRespondent(state).projectsStatus;

export const selectImportRespondentImportStatus = (state: AppState): ImportStatus | null =>
  selectImportRespondent(state).importStatus;

export const selectImportRespondentImportId = (state: AppState): string =>
  selectImportRespondent(state).importId;

export const selectImportRespondentRespondents = (state: AppState): Respondent[] =>
  selectImportRespondent(state).respondents;

export const selectImportRespondentRespondentsPagination = (state: AppState): Pages =>
  selectImportRespondent(state).respondentsPagination;
export const selectImportRespondentRespondentsStatus = (state: AppState): LoadingStatus =>
  selectImportRespondent(state).respondentsStatus;

export const selectImportRespondentRespondentsOrderColumn = (state: AppState): ITableOrder =>
  selectImportRespondent(state).respondentsOrder;

export const selectImportRespondentRespondentsFilterColumn = (
  state: AppState
): Record<string, ISearch> => selectImportRespondent(state).respondentsFilter;

export const selectImportRespondentCompanies = (state: AppState): { id: string; name: string }[] =>
  selectImportRespondent(state).companies;

export const selectImportRespondentCompaniesStatus = (state: AppState): LoadingStatus =>
  selectImportRespondent(state).companiesStatus;

export const selectImportRespondentGetImportedProjectList = (state: AppState): string[] =>
  selectImportRespondent(state).importedProjectList;
