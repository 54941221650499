import { Location } from 'history';
import i18n from 'i18next';
import { omit } from 'lodash';
import { combineReducers } from 'redux';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { PersistContractForm } from '../../models/Contract';
import { Pages, PaginatedResp } from '../../models/Pages';
import { Address } from '../../models/Respondent';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';

import { contractPersistForm } from '../contract/actions';
import { selectContractPersistForm } from '../contract/selectors';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  addressGetAddressActions,
  addressRemoveAddressActions,
  AddressActionTypes,
  addressCreateAddressActions,
  addressUpdateAddressActions,
  addressGetAddressesActions,
} from './actions';
import { api } from './api';
import {
  selectAddressAddressesFilterColumn,
  selectAddressAddressesOrderColumn,
  selectAddressAddressesPagination,
} from './selectors';

/* STATE */
export interface AddressState {
  address: Address | null;
  status: LoadingStatus;
  addresses: Address[];
  addressesPagination: Pages;
  addressesOrder: ITableOrder;
  addressesFilter: Record<string, ISearch>;
  addressesStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: AddressState = {
  address: null,
  status: LoadingStatus.initial,
  addresses: [],
  addressesPagination: {
    currentPage: 1,
    perPage: 50,
  },
  addressesStatus: LoadingStatus.initial,
  addressesOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  addressesFilter: {},
};

const address = createReducer(initialState.address, {
  [AddressActionTypes.CreateAddress]: {
    [RequestActionTypes.SUCCESS]: (state: Address | null, payload: Address) => ({
      ...state,
      payload,
    }),
  },
  [AddressActionTypes.GetAddress]: {
    [RequestActionTypes.REQUEST]: () => initialState.address,
    [RequestActionTypes.SUCCESS]: (state: Address | null, payload: Address) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.address,
  },
  [AddressActionTypes.UpdateAddress]: {
    [RequestActionTypes.SUCCESS]: (state: Address | null, payload: Address) => ({
      ...state,
      payload,
    }),
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.address,
  },
});

const addresses = createReducer(initialState.addresses, {
  [AddressActionTypes.GetAddresses]: {
    [RequestActionTypes.SUCCESS]: (state: Address[], payload: PaginatedResp<Address>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.addresses,
  },
  [AddressActionTypes.RemoveAddress]: {
    [RequestActionTypes.SUCCESS]: (state: Address[], payload: { id: number }) => {
      return state.filter((address) => address.id !== payload.id);
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.addresses,
  },
});

const addressesPagination = createReducer(initialState.addressesPagination, {
  [AddressActionTypes.GetAddresses]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<Address>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.addressesPagination,
  },
  [AddressActionTypes.SetAddressesPage]: (state: Pages, payload: Pages) => ({
    ...state,
    ...payload,
  }),
  [AddressActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [AddressActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AddressActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.addressesPagination,
  },
});

const addressesStatus = createLoadingStateReducer(
  initialState.addressesStatus,
  {
    [AddressActionTypes.GetAddresses]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AddressActionTypes.SetAddressesPage]: () => initialState.addressesStatus,
    [AddressActionTypes.IncreasePage]: () => initialState.addressesStatus,
    [AddressActionTypes.SetFilterColumn]: () => initialState.addressesStatus,
    [AddressActionTypes.SetOrderColumn]: () => initialState.addressesStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.addressesStatus,
    },
  }
);

const addressesOrder = createReducer(initialState.addressesOrder, {
  [AddressActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.addressesOrder,
  },
});

const addressesFilter = createReducer(initialState.addressesFilter, {
  [AddressActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.addressesFilter,
  },
});

const status = createLoadingStateReducer(
  initialState.status,
  {
    [AddressActionTypes.CreateAddress]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [AddressActionTypes.UpdateAddress]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.status,
    },
  }
);

export default combineReducers<AddressState>({
  address,
  addresses,
  status,
  addressesPagination,
  addressesStatus,
  addressesOrder,
  addressesFilter,
});

function* getAddresses() {
  const pagination: Pages = yield select(selectAddressAddressesPagination);
  const orderColumn: ITableOrder = yield select(selectAddressAddressesOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(selectAddressAddressesFilterColumn);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getAddresses, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(addressGetAddressesActions.success(toCamelCase(resp.data)));
  } else {
    yield put(addressGetAddressesActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* SAGAS */
function* removeAddress({
  payload,
}: AppAction<{ id: number; companyId?: number; userId: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeAddress, payload);

  if (resp.ok) {
    yield put(addressRemoveAddressActions.success({ id: payload.id }));
    yield put(
      toastCreateSuccessActions(
        resp.data?.message || i18n.t('components.successToast.requestPending')
      )
    );
  } else {
    yield put(addressRemoveAddressActions.failure());

    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createAddress({
  payload,
}: AppAction<{ id: number; userId?: number; companyId?: number; location?: Location }>) {
  const { location, ...data } = payload;

  const resp: ExtendedAxiosResponse = yield call(api.createAddress, data);

  if (resp.ok) {
    const data = toCamelCase(resp.data)?.data;
    yield put(
      addressCreateAddressActions.success({
        userId: payload.userId,
        companyId: payload.companyId,
        data,
      })
    );
    yield put(
      toastCreateSuccessActions(
        resp.data?.message || i18n.t('components.successToast.requestPending')
      )
    );

    const locationState = location?.state as any;

    /* Navigation */
    if (!location?.pathname.includes(AppRoutes.Admin)) {
      if (payload?.companyId) {
        history.push(
          buildRoute(AppRoutes.CompanyDetail, {
            companyId: payload.companyId.toString(),
          })
        );
      } else if (locationState?.from === 'contracts') {
        const persistedForm: PersistContractForm | null = yield select(selectContractPersistForm);

        if (persistedForm?.contractType === 'FO') {
          yield put(
            contractPersistForm({
              ...persistedForm,
              addressId: data?.id,
            })
          );
        }

        history.push(buildRoute(AppRoutes.ContractCreate));
      } else {
        history.push(buildRoute(AppRoutes.Addresses));
      }
    } else {
      if (payload?.companyId && payload?.userId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.RespondentsCompanyDetail], {
            companyId: payload.companyId.toString(),
            respondentId: payload.userId.toString(),
          })
        );
      } else if (payload?.companyId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.CompaniesDetail], {
            companyId: payload.companyId.toString(),
          })
        );
      } else if (payload?.userId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.RespondentsDetail], {
            respondentId: payload.userId.toString(),
          })
        );
      }
    }
  } else {
    yield put(addressCreateAddressActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getAddress({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getAddress, payload);

  if (resp.ok) {
    yield put(addressGetAddressActions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(addressGetAddressActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateAddress({
  payload,
}: AppAction<{ id: number; userId?: number; companyId?: number; location?: Location }>) {
  const { location, ...data } = payload;

  const resp: ExtendedAxiosResponse = yield call(api.updateAddress, data);

  if (resp.ok) {
    yield put(
      addressUpdateAddressActions.success({
        userId: payload.userId,
        companyId: payload.companyId,
        data: toCamelCase(resp.data)?.data,
      })
    );
    yield put(
      toastCreateSuccessActions(
        resp.data?.message || i18n.t('components.successToast.requestPending')
      )
    );

    /* Navigation */
    if (!location?.pathname.includes(AppRoutes.Admin)) {
      if (payload?.companyId) {
        history.push(
          buildRoute(AppRoutes.CompanyDetail, {
            companyId: payload.companyId.toString(),
          })
        );
      } else {
        history.push(buildRoute(AppRoutes.Addresses));
      }
    } else {
      if (payload?.companyId && payload?.userId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.RespondentsCompanyDetail], {
            companyId: payload.companyId.toString(),
            respondentId: payload.userId.toString(),
          })
        );
      } else if (payload?.companyId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.CompaniesDetail], {
            companyId: payload.companyId.toString(),
          })
        );
      } else if (payload?.userId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.RespondentsDetail], {
            respondentId: payload.userId.toString(),
          })
        );
      } else {
        history.push(buildRoute(AppRoutes.Addresses));
      }
    }
  } else {
    yield put(addressUpdateAddressActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* addressSaga() {
  yield takeLatest(
    [
      createActionType(AddressActionTypes.GetAddresses, RequestActionTypes.REQUEST),
      AddressActionTypes.SetAddressesPage,
      AddressActionTypes.IncreasePage,
      AddressActionTypes.SetOrderColumn,
      AddressActionTypes.SetFilterColumn,
    ],
    getAddresses
  );
  yield takeLatest(
    [createActionType(AddressActionTypes.RemoveAddress, RequestActionTypes.REQUEST)],
    removeAddress
  );
  yield takeLatest(
    createActionType(AddressActionTypes.CreateAddress, RequestActionTypes.REQUEST),
    createAddress
  );
  yield takeLatest(
    createActionType(AddressActionTypes.GetAddress, RequestActionTypes.REQUEST),
    getAddress
  );
  yield takeLatest(
    createActionType(AddressActionTypes.UpdateAddress, RequestActionTypes.REQUEST),
    updateAddress
  );
}
