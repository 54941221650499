import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import { RemoveCompanyPayload } from './api';

/* ACTION TYPES */
export enum CompanyActionTypes {
  GetCompanies = '@@Company/GET_COMPANIES',
  SetCompaniesPage = '@@Company/SET_COMPANIES_PAGE',
  IncreasePage = '@@Company/INCREASE_COMPANIES',
  SetOrderColumn = '@@Company/SET_ORDER_COLUMN',
  SetFilterColumn = '@@Company/SET_FILTER_COLUMNS',
  RemoveCompany = '@@Company/REMOVE_COMPANY',
  GetBankAccounts = '@@Company/GET_BANK_ACCOUNTS',
  GetCompany = '@@Company/GET_COMPANY',
  UpdateCompany = '@@Company/UPDATE_COMPANY',
  CreateCompany = '@@Company/CREATE_COMPANY',
  GetRespondents = '@@Company/GET_RESPONDENTS',
}

/* ACTIONS */
export const companyGetCompaniesActions = createApiActionCreators(CompanyActionTypes.GetCompanies);
export const companySetCompaniesPageActions = createActionCreator(
  CompanyActionTypes.SetCompaniesPage
);
export const companyIncreasePageActions = createActionCreator(CompanyActionTypes.IncreasePage);
export const companySetOrderColumnActions = createActionCreator(CompanyActionTypes.SetOrderColumn);
export const companySetFilterColumnActions = createActionCreator(
  CompanyActionTypes.SetFilterColumn
);

export const companyCreateCompanyActions = createApiActionCreators(
  CompanyActionTypes.CreateCompany
);

export const companyGetCompanyActions = createApiActionCreators(CompanyActionTypes.GetCompany);

export const companyUpdateCompanyActions = createApiActionCreators(
  CompanyActionTypes.UpdateCompany
);

export const companyRemoveCompanyActions = createApiActionCreators<RemoveCompanyPayload>(
  CompanyActionTypes.RemoveCompany
);

export const companyGetBankAccountsActions = createApiActionCreators(
  CompanyActionTypes.GetBankAccounts
);

export const companyGetRespondents = createApiActionCreators(CompanyActionTypes.GetRespondents);
