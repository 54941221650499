import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { WinningUser } from '../../models/User';

import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions } from '../toast/actions';
import { WinnerActionTypes, winnerGetWinnersActions } from './actions';
import { api } from './api';
import {
  selectWinnerWinnersPagination,
  selectWinnerWinnersOrderColumn,
  selectWinnerWinnersFilterColumn,
} from './selectors';

/* STATE */
export interface WinnerState {
  winners: WinningUser[];
  winnersPagination: Pages;
  winnersStatus: LoadingStatus;
  winnersOrder: ITableOrder;
  winnersFilter: Record<string, ISearch>;
}

/* REDUCERS */
const initialState: WinnerState = {
  winners: [],
  winnersPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  winnersStatus: LoadingStatus.initial,
  winnersOrder: {
    sort: 'tanzanit_id',
    order: IOrderStatus.ASC,
  },
  winnersFilter: {},
};

const winners = createReducer(initialState.winners, {
  [WinnerActionTypes.GetWinners]: {
    [RequestActionTypes.SUCCESS]: (state: WinningUser[], payload: PaginatedResp<WinningUser>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.winners,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.winners,
  },
});

const winnersPagination = createReducer(initialState.winnersPagination, {
  [WinnerActionTypes.GetWinners]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<WinningUser>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.winnersPagination,
  },
  [WinnerActionTypes.SetWinnersPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [WinnerActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [WinnerActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [WinnerActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.winnersPagination,
  },
});

const winnersStatus = createLoadingStateReducer(
  initialState.winnersStatus,
  {
    [WinnerActionTypes.GetWinners]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [WinnerActionTypes.SetWinnersPage]: () => initialState.winnersStatus,
    [WinnerActionTypes.IncreasePage]: () => initialState.winnersStatus,
    [WinnerActionTypes.SetFilterColumn]: () => initialState.winnersStatus,
    [WinnerActionTypes.SetOrderColumn]: () => initialState.winnersStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.winnersStatus,
    },
  }
);

const winnersOrder = createReducer(initialState.winnersOrder, {
  [WinnerActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.winnersOrder,
  },
});

const winnersFilter = createReducer(initialState.winnersFilter, {
  [WinnerActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.winnersFilter,
  },
});

export default combineReducers<WinnerState>({
  winners,
  winnersPagination,
  winnersStatus,
  winnersOrder,
  winnersFilter,
});

/* SAGAS */
function* getWinners() {
  const pagination: Pages = yield select(selectWinnerWinnersPagination);
  const orderColumn: ITableOrder = yield select(selectWinnerWinnersOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(selectWinnerWinnersFilterColumn);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getWinners, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(winnerGetWinnersActions.success(toCamelCase(resp.data)));
  } else {
    yield put(winnerGetWinnersActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* winnerSaga() {
  yield takeLatest(
    [
      createActionType(WinnerActionTypes.GetWinners, RequestActionTypes.REQUEST),
      WinnerActionTypes.SetWinnersPage,
      WinnerActionTypes.IncreasePage,
      WinnerActionTypes.SetOrderColumn,
      WinnerActionTypes.SetFilterColumn,
    ],
    getWinners
  );
}
