import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

/* ACTION TYPES */
export enum RegionActionTypes {
  GetRegions = '@@Region/GET_REGIONS',
  SetRegionsPage = '@@Region/SET_REGIONS_PAGE',
  IncreasePage = '@@Region/INCREASE_REGIONS',
  SetOrderColumn = '@@Region/SET_ORDER_COLUMN',
  SetFilterColumn = '@@Region/SET_FILTER_COLUMNS',
  RemoveRegion = '@@Region/REMOVE_REGION',
  CreateRegion = '@@Region/CREATE_REGION',
  GetRegion = '@@Region/GET_REGION',
  UpdateRegion = '@@Region/UPDATE_REGION',
  GetCountries = '@@Region/GET_COUNTRIES',
}

/* ACTIONS */
export const regionGetRegionsActions = createApiActionCreators(RegionActionTypes.GetRegions);
export const regionSetRegionsPageActions = createActionCreator(RegionActionTypes.SetRegionsPage);
export const regionIncreasePageActions = createActionCreator(RegionActionTypes.IncreasePage);
export const regionSetOrderColumnActions = createActionCreator(RegionActionTypes.SetOrderColumn);
export const regionSetFilterColumnActions = createActionCreator(RegionActionTypes.SetFilterColumn);
export const regionRemoveRegionActions = createApiActionCreators(RegionActionTypes.RemoveRegion);

export const regionCreateRegionActions = createApiActionCreators(RegionActionTypes.CreateRegion);
export const regionUpdateRegionActions = createApiActionCreators(RegionActionTypes.UpdateRegion);
export const regionGetRegionActions = createApiActionCreators(RegionActionTypes.GetRegion);
export const regionGetCountriesActions = createApiActionCreators(RegionActionTypes.GetCountries);
