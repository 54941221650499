import i18n from 'i18next';
import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { v4 as uuidv4 } from 'uuid';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import { getQuestionType, transformQuestionResp } from '../../helpers/project';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { reorder, sortByPosition } from '../../helpers/reorder';
import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { SettingsRoutes } from '../../helpers/route/routes/settings-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { lowercaseObjectKeys, toCamelCase, toSnakeCase } from '../../helpers/transformObject';
import { Pages, PaginatedResp } from '../../models/Pages';
import {
  ChangeBlockPositionPayload,
  ChangeQuestionPositionPayload,
  IBlock,
  IProject,
  IQuestion,
  IQuestionType,
  SaveQuestionWithBlockPayload,
  SaveQuestionWithExistingBlockPayload,
} from '../../models/Project';

import { Block, GroupedNumericBlock, SurveyBlockQuestion } from '../../models/Survey';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  ProjectActionTypes,
  projectAddQuestionToGroupedNumericBlock,
  projectChangeBlockPosition,
  projectChangeQuestionPosition,
  projectCreateBlock,
  projectCreateExistingQuestion,
  projectCreateGroupedNumericBlock,
  projectCreateProjectActions,
  projectDuplicateProject,
  projectDuplicateQuestion,
  projectExportAnswers,
  projectExportProjects,
  projectGetProjectActions,
  projectGetProjectBlocksActions,
  projectGetProjectQuestions,
  projectGetProjectsActions,
  projectGetQuestion,
  projectGetTypes,
  projectRemoveBlock,
  projectRemoveExistingQuestion,
  projectRemoveGroupedNumericBlock,
  projectRemoveProjectActions,
  projectSaveQuestionWithBlock,
  projectSaveQuestionWithExistingBlock,
  projectUpdateBlock,
  projectUpdateGroupedNumericBlock,
  projectUpdateProjectActions,
  projectUpdateQuestion,
} from './actions';
import {
  AddQuestionToGroupedNumericBlock,
  api,
  CreateGroupedNumericBlockPayload,
  DuplicateProjectPayload,
  ExportAnswersPayload,
  GetProjectQuestionsPayload,
  GetQuestionPayload,
  RemoveGroupedNumericBlock,
  UpdateGroupedNumericBlockPayload,
} from './api';
import {
  selectCurrentProject,
  selectProjectBlock,
  selectProjectBlocks,
  selectProjectProjectsFilterColumn,
  selectProjectProjectsOrderColumn,
  selectProjectProjectsPagination,
  selectProjectTypes,
} from './selectors';

/* STATE */
export interface ProjectState {
  project: IProject | null;
  blocks: Record<string, IBlock>;
  blocksStatus: LoadingStatus;
  blockCreateStatus: LoadingStatus;
  types: IQuestionType[];
  projects: IProject[];
  projectsPagination: Pages;
  projectsStatus: LoadingStatus;
  projectsOrder: ITableOrder;
  projectsFilter: Record<string, ISearch>;
  projectActionStatus: LoadingStatus;
  projectExportStatus: LoadingStatus;

  questions: IQuestion[];
  questionsPagination: Pages;
  questionsStatus: LoadingStatus;

  question: IQuestion | null;
}

/* REDUCERS */
const initialState: ProjectState = {
  project: null,
  blocks: {},
  blocksStatus: LoadingStatus.initial,
  blockCreateStatus: LoadingStatus.initial,
  types: [],
  projects: [],
  projectsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  projectsStatus: LoadingStatus.loading,
  projectsOrder: {
    sort: 'id',
    order: IOrderStatus.DESC,
  },
  projectsFilter: {},
  projectActionStatus: LoadingStatus.initial,
  projectExportStatus: LoadingStatus.initial,
  questions: [],
  questionsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  questionsStatus: LoadingStatus.initial,
  question: null,
};

const project = createReducer(initialState.project, {
  [ProjectActionTypes.CreateProject]: {
    [RequestActionTypes.SUCCESS]: (state: IProject | null, payload: IProject) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.project,
  },
  [ProjectActionTypes.GetProject]: {
    [RequestActionTypes.REQUEST]: () => initialState.project,
    [RequestActionTypes.SUCCESS]: (state: IProject | null, payload: IProject) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.project,
  },
  [ProjectActionTypes.UpdateProject]: {
    [RequestActionTypes.SUCCESS]: (state: IProject | null, payload: IProject) => payload,
    [RequestActionTypes.FAILURE]: (state: IProject | null) => state,
  },
  [ProjectActionTypes.ClearProject]: () => initialState.project,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.project,
  },
});

const blocks = createReducer(initialState.blocks, {
  [ProjectActionTypes.CreateBlock]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, IBlock>,
      payload: {
        questionBlock: { id: string; position: number; questions?: Record<string, IQuestion> };
      }
    ) => {
      const data = payload?.questionBlock;

      return {
        ...state,
        [uuidv4()]: {
          blockQuestions: [],
          questions: {},
          groupedNumericBlocks: [],
          ...data,
        },
      };
    },
  },
  [ProjectActionTypes.ChangeBlockPosition]: (
    state: Record<string, { id: string; position: number; questions: Record<string, IQuestion> }>,
    payload: { sourceIndex: number; destinationIndex: number }
  ) => {
    if (payload?.destinationIndex >= 0 && payload?.sourceIndex >= 0) {
      return { ...reorder(state, payload.sourceIndex, payload.destinationIndex) };
    }

    return state;
  },
  [ProjectActionTypes.AddQuestion]: (
    state: Record<
      string,
      { id?: string; position: number; questions: Record<string, { position: number }> }
    >,
    payload: any
  ) => {
    const tmpState = { ...state };
    if (tmpState?.[payload.blockId]) {
      const countOfQuestions = Object.keys(tmpState[payload.blockId]?.questions)?.length;
      tmpState[payload.blockId]['questions'][`${payload.question.id}`] = {
        position: countOfQuestions,
      };
    }
    return {
      ...tmpState,
    };
  },
  [ProjectActionTypes.ChangeQuestionPosition]: (
    state: Record<string, { id: string; position: number; questions: IQuestion }>,
    payload: { blockId: number; sourceIndex: number; destinationIndex: number }
  ) => {
    if (payload?.blockId && state?.[payload?.blockId]) {
      return {
        ...state,
        [payload.blockId]: {
          ...state[payload.blockId],
          questions: {
            ...reorder(
              state[payload.blockId]?.questions,
              payload.sourceIndex,
              payload.destinationIndex
            ),
          },
        },
      };
    }
    return { ...state };
  },
  [ProjectActionTypes.ChangeQuestionPositionBetweenBlocks]: (
    state: Record<string, { id: string; position: number; questions: Record<string, IQuestion> }>,
    payload: any
  ) => {
    const { startBlockId, finishBlockId, sourceIndex, destinationIndex } = payload;

    const startStateQuestions = Object.fromEntries(
      Object.entries({
        ...reorder(
          state[startBlockId].questions,
          sourceIndex,
          Object.keys(state[startBlockId].questions).length
        ),
      }).filter((s) => s[1].position != Object.keys(state[startBlockId].questions)?.length)
    );

    const startQuestion: Record<string, any> = Object.fromEntries(
      Object.entries(state[startBlockId].questions).filter(
        (s: Record<string, any>) => s[1].position == sourceIndex
      )
    );

    const finishStateQuestions = {
      ...reorder(
        {
          ...state[finishBlockId].questions,
          ...Object.keys(startQuestion).map((id) => ({
            [uuidv4()]: {
              ...startQuestion[id],
              position: Object.keys(state[finishBlockId].questions)?.length,
            },
          }))[0],
        },
        Object.keys(state[finishBlockId].questions)?.length,
        destinationIndex
      ),
    };

    const newState = {
      ...state,
      [startBlockId]: {
        ...state[startBlockId],
        questions: { ...startStateQuestions },
      },
      [finishBlockId]: {
        ...state[finishBlockId],
        questions: { ...finishStateQuestions },
      },
    };

    return { ...newState };
  },
  [ProjectActionTypes.SaveQuestionWithBlock]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, { id: string; position: number; questions: Record<string, IQuestion> }>,
      payload: { data: any; blockId: string; questionId: string }
    ) => {
      return Object.keys(state).reduce((acc: any, i: string) => {
        if (i == payload.blockId) {
          acc[i] = {
            ...state[i],
            ...payload.data.questionBlock,
            questions: Object.keys(state[i].questions).reduce((qO: any, qId: string) => {
              if (qId == payload.questionId) {
                qO[qId] = {
                  ...state[i].questions[qId],
                  ...payload.data.surveyQuestion,
                };
              } else {
                qO[qId] = state[i].questions[qId];
              }
              return qO;
            }, {}),
          };
        } else {
          acc[i] = state[i];
        }
        return acc;
      }, {});
    },
  },
  [ProjectActionTypes.SaveQuestionWithExistingBlock]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, { id: string; position: number; questions: Record<string, IQuestion> }>,
      payload: { data: any; blockId: string; questionId: string }
    ) => {
      return transformQuestionResp(state, payload);
    },
  },
  [ProjectActionTypes.UpdateQuestion]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, { id: string; position: number; questions: Record<string, IQuestion> }>,
      payload: { data: any; blockId: string; questionId: string }
    ) => {
      return transformQuestionResp(state, payload);
    },
  },
  [ProjectActionTypes.DuplicateQuestion]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<
        string,
        { id: string; position: number; questions: Record<string, IQuestion>; blockQuestions: any }
      >,
      payload: SurveyBlockQuestion & { blockId: string }
    ) => {
      return Object.keys(state).reduce((acc: any, i: string) => {
        if (i == payload.blockId) {
          acc[i] = {
            ...state[i],
            blockQuestions: [...state[i].blockQuestions, payload].sort(
              (a: { position: number }, b: { position: number }) =>
                a.position > b.position ? 1 : -1
            ),
            questions: sortByPosition({
              ...Object.fromEntries(
                Object.entries(state[i].questions).map(([key, question]) => {
                  if (question.position >= payload.position) {
                    question.position += 1;
                  }

                  return [key, question];
                })
              ),
              [uuidv4()]: payload,
            }),
          };
        } else {
          acc[i] = state[i];
        }

        return acc;
      }, {});
    },
  },
  [ProjectActionTypes.CreateExistingQuestion]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<
        string,
        { id: string; position: number; questions: Record<string, IQuestion>; blockQuestions: any }
      >,
      payload: SurveyBlockQuestion & { blockId: string; questionId: string }
    ) => {
      return Object.keys(state).reduce((acc: any, i: string) => {
        if (i == payload.blockId) {
          acc[i] = {
            ...state[i],
            blockQuestions: [...state[i].blockQuestions, payload].sort(
              (a: { position: number }, b: { position: number }) =>
                a.position > b.position ? 1 : -1
            ),
            questions: sortByPosition({
              ...Object.fromEntries(
                Object.entries(state[i].questions).map(([key, question]) => {
                  if (question.position >= payload.position) {
                    question.position += 1;
                  }

                  return [key, question];
                })
              ),
              [payload.questionId]: payload,
            }),
          };
        } else {
          acc[i] = state[i];
        }

        return acc;
      }, {});
    },
  },
  [ProjectActionTypes.RemoveBlock]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, { id: string; position: number; questions: Record<string, IQuestion> }>,
      payload: { blockId: string }
    ) => {
      return Object.fromEntries(
        Object.entries({
          ...reorder(state, state[payload.blockId].position, Object.keys(state)?.length),
        }).filter((s) => s[0] != payload.blockId)
      );
    },
  },
  [ProjectActionTypes.RemoveExistingQuestion]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, { id: string; position: number; questions: Record<string, IQuestion> }>,
      payload: { blockId: string; id: string; deletedBlockId?: string | null }
    ) => {
      return Object.keys(state).reduce((acc: any, i: string) => {
        if (i == payload.blockId) {
          acc[i] = {
            ...state[i],
            questions: Object.fromEntries(
              Object.entries({
                ...reorder(
                  state[i].questions,
                  state[i].questions[payload.id].position,
                  Object.keys(state[i].questions)?.length
                ),
              }).filter((s) => s[0] != payload.id)
            ),
          };
          if (state[i]?.id) {
            acc[i].id =
              payload.deletedBlockId && state[i]?.id == payload.deletedBlockId
                ? null
                : state[i]?.id;
          }
        } else {
          acc[i] = state[i];
        }

        return acc;
      }, {});
    },
  },
  [ProjectActionTypes.UpdateBlock]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, { id: string; position: number; questions: Record<string, IQuestion> }>,
      payload: any
    ) => ({
      ...state,
      [`${payload.blockId}`]: {
        ...state[`${payload.blockId}`],
        ...payload,
      },
    }),
  },
  [ProjectActionTypes.GetProjectBlocks]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, { id: string; position: number; questions: Record<string, IQuestion> }>,
      payload: { data: Block[]; groupedNumericBlocks: GroupedNumericBlock[] }
    ) => {
      /**
       * group numeric blocks by questionBlockId property
       * [
       *  {
       *    questionBlockId: [numeric blocks with questionBlockId]
       *  }
       * ]
       */
      const groupedNumericBlocks = payload.groupedNumericBlocks.reduce(
        (acc: Record<number, GroupedNumericBlock[]>, current: GroupedNumericBlock) => {
          const { questionBlockId } = current;

          (acc[questionBlockId] = acc[questionBlockId] || []).push(current);

          return acc;
        },
        {}
      );

      return {
        ...payload.data?.reduce((acc: Record<string, any>, block: Block) => {
          return {
            ...acc,
            [uuidv4()]: {
              ...block,
              questions: {
                ...block?.blockQuestions
                  ?.sort((a: { position: number }, b: { position: number }) =>
                    a.position > b.position ? 1 : -1
                  )
                  ?.reduce((qAcc: Record<string, any>, question: SurveyBlockQuestion) => {
                    return {
                      ...qAcc,
                      [uuidv4()]: {
                        ...question,
                        questionTypeId: question?.type?.questionTypeId
                          ? question.type.questionTypeId
                          : question.questionTypeId,
                        validation: question?.type?.id,
                        options: question?.options?.map((option) => {
                          return {
                            ...option,
                            nextQuestionBlockId: option?.nextQuestionBlocks?.[0]?.id || null,
                          };
                        }),
                      },
                    };
                  }, {}),
              },
              groupedNumericBlocks: groupedNumericBlocks[parseInt(block.id)] || [],
            },
          };
        }, {}),
      };
    },
  },
  [ProjectActionTypes.CreateGroupedNumericBlock]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, IBlock>,
      payload: { data: GroupedNumericBlock; blockUUID: string }
    ) => {
      /**
       * [
       *  [blockUUID, block],
       * ]
       */
      const result = Object.entries(state).map(([blockUUID, block]) => [
        blockUUID,
        {
          ...block,
          // add a new grouped numeric block to the corresponding block
          groupedNumericBlocks:
            payload.data.questionBlockId === block.id
              ? [...(block?.groupedNumericBlocks || []), payload.data]
              : block.groupedNumericBlocks,
        },
      ]);

      return Object.fromEntries(result);
    },
  },
  [ProjectActionTypes.UpdateGroupedNumericBlock]: {
    [RequestActionTypes.SUCCESS]: (
      state: Record<string, IBlock>,
      payload: { data: GroupedNumericBlock; blockUUID: string }
    ) => {
      /**
       * [
       *  [blockUUID, block],
       * ]
       */
      const result = Object.entries(state).map(([blockUUID, block]) => [
        blockUUID,
        {
          ...block,
          groupedNumericBlocks:
            payload.data.questionBlockId === block.id
              ? block.groupedNumericBlocks.map(
                  (groupedNumericBlock) =>
                    [payload.data].find((o) => o.id === groupedNumericBlock.id) ||
                    groupedNumericBlock
                )
              : block.groupedNumericBlocks,
        },
      ]);

      return Object.fromEntries(result);
    },
  },
  [ProjectActionTypes.RemoveGroupedNumericBlock]: {
    [RequestActionTypes.SUCCESS]: (state: Record<string, IBlock>, payload: number) => {
      /**
       * [
       *  [blockUUID, block with filtered grouped numeric block],
       * ]
       */
      const result = Object.entries(state).map(([blockUUID, block]) => [
        blockUUID,
        {
          ...block,
          groupedNumericBlocks: block.groupedNumericBlocks.filter(
            (groupedNumericBlock) => groupedNumericBlock.id !== payload
          ),
        },
      ]);

      return Object.fromEntries(result);
    },
  },
  [ProjectActionTypes.CreateProject]: {
    [RequestActionTypes.REQUEST]: () => initialState.blocks,
  },
  [ProjectActionTypes.ClearProject]: () => initialState.blocks,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.blocks,
  },
});

const blocksStatus = createLoadingStateReducer(
  initialState.blocksStatus,
  {
    [ProjectActionTypes.GetProjectBlocks]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.blocksStatus,
    },
  }
);

const blockCreateStatus = createLoadingStateReducer(
  initialState.blockCreateStatus,
  {
    [ProjectActionTypes.CreateBlock]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.blockCreateStatus,
    },
  }
);

const types = createReducer(initialState.types, {
  [ProjectActionTypes.GetTypes]: {
    [RequestActionTypes.SUCCESS]: (state: IQuestionType[], payload: IQuestionType[]) => {
      return [
        ...payload,
        {
          id: 0,
          name: 'existingQuestion',
          questionTypeId: null,
          rules: {
            validationRules: 'required',
          },
          childTypes: [],
        },
      ];
    },
    [RequestActionTypes.FAILURE]: () => initialState.types,
  },
  [ProjectActionTypes.ClearProject]: () => initialState.types,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.types,
  },
});

const projectActionStatus = createLoadingStateReducer(
  initialState.projectActionStatus,
  {
    [ProjectActionTypes.CreateProject]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [ProjectActionTypes.UpdateProject]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [ProjectActionTypes.DuplicateProject]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.projectActionStatus,
    },
  }
);

const projectExportStatus = createLoadingStateReducer(
  initialState.projectExportStatus,
  {
    [ProjectActionTypes.ExportProjects]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.projectExportStatus,
    },
  }
);

/* PROJECT TABLE REDUCERS */
const projects = createReducer(initialState.projects, {
  [ProjectActionTypes.GetProjects]: {
    [RequestActionTypes.SUCCESS]: (state: IProject[], payload: PaginatedResp<IProject>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.projects,
  },
  [ProjectActionTypes.RemoveProject]: {
    [RequestActionTypes.SUCCESS]: (state: IProject[], payload: { id: number }) => {
      return state.filter((project) => project.id !== payload.id);
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projects,
  },
});

const projectsPagination = createReducer(initialState.projectsPagination, {
  [ProjectActionTypes.GetProjects]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<IProject>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.projectsPagination,
  },
  [ProjectActionTypes.SetProjectsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [ProjectActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [ProjectActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ProjectActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectsPagination,
  },
});

const projectsStatus = createLoadingStateReducer(
  initialState.projectsStatus,
  {
    [ProjectActionTypes.GetProjects]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ProjectActionTypes.SetProjectsPage]: () => initialState.projectsStatus,
    [ProjectActionTypes.IncreasePage]: () => initialState.projectsStatus,
    [ProjectActionTypes.SetFilterColumn]: () => initialState.projectsStatus,
    [ProjectActionTypes.SetOrderColumn]: () => initialState.projectsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.projectsStatus,
    },
  }
);

const projectsOrder = createReducer(initialState.projectsOrder, {
  [ProjectActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectsOrder,
  },
});

const projectsFilter = createReducer(initialState.projectsFilter, {
  [ProjectActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectsFilter,
  },
});

const questions = createReducer(initialState.questions, {
  [ProjectActionTypes.GetProjectQuestions]: {
    [RequestActionTypes.SUCCESS]: (state: IQuestion[], payload: IQuestion[]) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.questions,
  },
  [ProjectActionTypes.RemoveExistingQuestion]: {
    [RequestActionTypes.SUCCESS]: (state: IQuestion[], payload: IQuestion) =>
      state.filter((question) => question.id !== payload.questionId),
  },
  [ProjectActionTypes.RemoveBlock]: {
    [RequestActionTypes.SUCCESS]: (state: any[], payload: IBlock) =>
      state.filter((question) => question.questionBlockId !== payload.id),
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.questions,
  },
});

const questionsPagination = createReducer(initialState.questionsPagination, {
  [ProjectActionTypes.GetProjectQuestions]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: IQuestion[]) => omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.questionsPagination,
  },
  [ProjectActionTypes.SetProjectQuestionsPage]: (state: Pages, payload: Pages) => ({
    ...state,
    ...payload,
  }),
  [ProjectActionTypes.IncreaseProjectQuestionsPage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.questionsPagination,
  },
});

const questionsStatus = createLoadingStateReducer(
  initialState.questionsStatus,
  {
    [ProjectActionTypes.GetProjectQuestions]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ProjectActionTypes.SetProjectQuestionsPage]: () => initialState.questionsStatus,
    [ProjectActionTypes.IncreaseProjectQuestionsPage]: () => initialState.questionsStatus,
  }
);

const question = createReducer(initialState.question, {
  [ProjectActionTypes.GetQuestion]: {
    [RequestActionTypes.SUCCESS]: (state: IQuestion | null, payload: IQuestion) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.question,
  },
  [ProjectActionTypes.GetProjectQuestions]: {
    [RequestActionTypes.SUCCESS]: () => initialState.question,
  },
  [ProjectActionTypes.RemoveExistingQuestion]: {
    [RequestActionTypes.SUCCESS]: () => initialState.question,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.question,
  },
});

export default combineReducers<ProjectState>({
  project,
  blocks,
  blocksStatus,
  blockCreateStatus,
  types,
  projects,
  projectsPagination,
  projectsStatus,
  projectsOrder,
  projectsFilter,
  projectActionStatus,
  projectExportStatus,
  questions,
  questionsPagination,
  questionsStatus,
  question,
});

/* SAGAS */
function* saveProject({ payload }: AppAction<any>) {
  const project: IProject = yield select(selectCurrentProject);

  if (project?.id) {
    yield put(projectUpdateProjectActions.request(payload));
  } else {
    yield put(projectCreateProjectActions.request(payload));
  }
}

function* createProject({ payload }: AppAction<any>) {
  const resp: ExtendedAxiosResponse = yield call(api.createProject, payload);

  if (resp.ok) {
    yield put(projectCreateProjectActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(
      buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ProjectDetail], {
        projectId: `${resp.data?.data?.id}`,
        step: '2',
      })
    );
  } else {
    yield put(projectCreateProjectActions.failure(toCamelCase(resp.data)?.errors));
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* SAGAS */
function* updateProject({ payload }: AppAction<any>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateProject, payload);

  if (resp.ok) {
    yield put(projectUpdateProjectActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(
      buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ProjectDetail], {
        projectId: `${resp.data?.data?.id}`,
        step: '2',
      })
    );
  } else {
    yield put(projectUpdateProjectActions.failure(toCamelCase(resp.data)?.errors));
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* saveQuestionWithBlock({ payload }: AppAction<any>) {
  const resp: ExtendedAxiosResponse = yield call(api.saveQuestionWithBlock, {
    ...omit(payload.payload, ['blockId', 'questionId']),
    questionBlock: {
      name: payload.currentBlock.name,
      explanation: payload.payload.explanation,
      position: payload.currentBlock.position,
      surveyId: payload.payload.surveyId,
    },
  } as SaveQuestionWithBlockPayload);

  if (resp.ok) {
    const questionTypes: IQuestionType[] = yield select(selectProjectTypes);

    yield put(
      projectSaveQuestionWithBlock.success(
        toCamelCase({
          data: {
            ...resp.data?.data,
            ...getQuestionType(
              questionTypes,
              toCamelCase(resp.data)?.data?.surveyQuestion?.questionTypeId
            ),
          },
          blockId: payload.payload.blockId,
          questionId: payload.payload.questionId,
        })
      )
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(projectSaveQuestionWithBlock.failure());
    yield put(toastCreateErrorActions(resp?.data?.message));
  }
}

function* saveQuestionWithExistingBlock({ payload }: AppAction<any>) {
  const resp: ExtendedAxiosResponse = yield call(api.saveQuestionQuestionWithExistingBlock, {
    ...omit(payload.payload, ['blockId', 'questionId']),
  } as SaveQuestionWithExistingBlockPayload);

  if (resp.ok) {
    const questionTypes: IQuestionType[] = yield select(selectProjectTypes);
    yield put(
      projectSaveQuestionWithExistingBlock.success(
        toCamelCase({
          data: {
            ...resp.data?.data,
            ...getQuestionType(questionTypes, toCamelCase(resp.data)?.data?.questionTypeId),
          },
          blockId: payload.payload.blockId,
          questionId: payload.payload.questionId,
        })
      )
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(projectSaveQuestionWithExistingBlock.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateBlock({ payload }: AppAction<any>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateBlock, {
    ...payload,
  });

  if (resp.ok) {
    yield put(
      projectUpdateBlock.success({
        name: resp.data?.data?.name,
        explanation: resp.data?.data?.explanation,
        blockId: payload.blockId,
        hasHiddenName: resp.data?.data?.has_hidden_name,
      })
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(projectUpdateBlock.failure());
    yield put(toastCreateErrorActions(resp?.data?.message));
  }
}

function* updateQuestion({ payload }: AppAction<any>) {
  const resp: ExtendedAxiosResponse = yield call(
    api.updateQuestion,
    toSnakeCase({
      ...omit(payload.payload, ['blockId', 'questionId']),
    })
  );

  if (resp.ok) {
    const questionTypes: IQuestionType[] = yield select(selectProjectTypes);

    yield put(
      projectUpdateQuestion.success(
        toCamelCase({
          data: {
            ...resp.data?.data,
            ...getQuestionType(questionTypes, toCamelCase(resp.data)?.data?.questionTypeId),
          },
          blockId: payload.payload.blockId,
          questionId: payload.payload.questionId,
        })
      )
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(projectUpdateQuestion.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* saveQuestion({ payload }: AppAction<any>) {
  const block: Record<string, IBlock> = yield select(selectProjectBlocks);
  const currentBlock = block[payload.blockId];

  if (currentBlock?.id) {
    if (currentBlock?.questions?.[payload.questionId]?.id) {
      yield put(
        projectUpdateQuestion.request({
          payload: {
            ...payload,
            questionBlockId: currentBlock.id,
            id: currentBlock?.questions?.[payload.questionId]?.id,
          },
          currentBlock: currentBlock,
        })
      );
    } else {
      yield put(
        projectSaveQuestionWithExistingBlock.request({
          payload: {
            ...payload,
            questionBlockId: currentBlock.id,
          },
          currentBlock: currentBlock,
        })
      );
    }
  } else {
    yield put(
      projectSaveQuestionWithBlock.request({
        payload: payload,
        currentBlock: currentBlock,
      })
    );
  }
}

function* removeBlock({ payload }: AppAction<{ id: string }>) {
  const block: IBlock = yield select((selector) => selectProjectBlock(selector, payload.id));

  const resp: ExtendedAxiosResponse = yield call(api.removeBlock, `${block?.id}`);

  if (resp.ok) {
    yield put(
      projectRemoveBlock.success({
        id: block?.id,
        blockId: payload.id,
      })
    );

    yield put(toastCreateSuccessActions(resp.data?.message));
    yield put(projectChangeBlockPosition());
  } else {
    yield put(projectRemoveBlock.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* duplicateQuestion({ payload }: AppAction<{ id: string; blockId: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.duplicateQuestion, payload.id);

  if (resp.ok) {
    yield put(
      projectDuplicateQuestion.success(
        toCamelCase({
          ...resp.data,
          blockId: payload.blockId,
        })
      )
    );
    yield put(
      toastCreateSuccessActions(
        resp.data?.message || i18n.t('pages.questions.toast.duplicate.success')
      )
    );
  } else {
    yield put(projectDuplicateQuestion.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeQuestion({ payload }: AppAction<any>) {
  const block: IBlock = yield select((selector) => selectProjectBlock(selector, payload.blockId));

  const question = block?.questions?.[payload.id];

  if (question?.id) {
    yield put(
      projectRemoveExistingQuestion.request({
        ...payload,
        questionId: question.id,
      })
    );
  } else {
    yield put(projectRemoveExistingQuestion.success({ ...payload }));
  }
}

function* removeExistingQuestion({ payload }: AppAction<any>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeQuestion, payload.questionId);
  if (resp.ok) {
    yield put(
      projectRemoveExistingQuestion.success({
        ...payload,
        deletedBlockId: toCamelCase(resp.data)?.deletedBlockId || null,
      })
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
    yield put(projectChangeQuestionPosition());
  } else {
    yield put(projectRemoveExistingQuestion.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getTypes() {
  const resp: ExtendedAxiosResponse = yield call(api.getTypes);

  if (resp.ok) {
    yield put(projectGetTypes.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(projectGetTypes.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* changeBlockPositions() {
  const blocks: Record<string, IBlock> = yield select(selectProjectBlocks);
  const project: IProject = yield select(selectCurrentProject);

  const existingBlocks = Object.entries(blocks)?.filter((s) => s[1]?.id);

  const payload: ChangeBlockPositionPayload = {
    surveyId: project?.survey?.id,
    data: existingBlocks.map((eb) => ({
      blockId: eb[1].id,
      position: eb[1].position,
    })),
  };
  const resp: ExtendedAxiosResponse = yield call(api.changeBlockPosition, payload);

  if (resp.ok) {
  } else {
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* changeQuestionPositions() {
  const blocks: Record<string, IBlock> = yield select(selectProjectBlocks);
  const existingBlocks = Object.entries(blocks)?.filter((s) => s[1]?.id);

  const payload: ChangeQuestionPositionPayload = {
    data: existingBlocks.map((eb) => ({
      blockId: eb[1].id,
      questions: Object.entries(eb[1].questions)
        ?.filter((s: [string, { id: number | string; position: number }]) => s[1]?.id)
        .map((question: [string, { id: number | string; position: number }]) => {
          return {
            id: question[1].id,
            position: question[1].position,
          };
        }),
    })),
  };

  const resp: ExtendedAxiosResponse = yield call(api.changeQuestionPosition, payload);

  if (resp.ok) {
  } else {
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* PROJECT TABLE SAGAS */
function* getProjects() {
  const pagination: Pages = yield select(selectProjectProjectsPagination);
  const orderColumn: ITableOrder = yield select(selectProjectProjectsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(selectProjectProjectsFilterColumn);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getProjects, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(projectGetProjectsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(projectGetProjectsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getProject({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getProject, payload);

  if (resp.ok) {
    yield put(projectGetProjectActions.success(toCamelCase(resp.data)?.data));
    const surveyId = resp.data?.data?.survey?.id;
    if (surveyId) {
      yield put(projectGetProjectBlocksActions.request({ id: surveyId }));
    }
  } else {
    yield put(projectGetProjectActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getProjectBlocks({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getProjectBlocks, payload);

  if (resp.ok) {
    const respData = toCamelCase(resp.data);

    yield put(
      projectGetProjectBlocksActions.success({
        data: respData?.data,
        groupedNumericBlocks: respData?.groupedNumericQuestions,
      })
    );
  } else {
    yield put(projectGetProjectBlocksActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeProject({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeProject, payload);

  if (resp.ok) {
    yield put(projectRemoveProjectActions.success({ id: payload.id }));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(projectRemoveProjectActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createBlock({ payload }: AppAction<{ surveyId: string; position: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.createBlock, payload);

  if (resp.ok) {
    yield put(projectCreateBlock.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(projectCreateBlock.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* duplicateProject({ payload }: AppAction<DuplicateProjectPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.duplicateProject, payload);

  if (resp.ok) {
    const project: IProject = toCamelCase(resp.data)?.data;

    yield put(projectDuplicateProject.success(project));
    yield put(
      toastCreateSuccessActions(
        resp.data?.message || i18n.t('pages.projects.toast.duplicate.success')
      )
    );

    history.push(
      buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ProjectDetail], {
        projectId: `${project.id}`,
        step: '1',
      })
    );
  } else {
    yield put(projectDuplicateProject.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* exportAnswers({ payload }: AppAction<ExportAnswersPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.exportAnswers, payload);

  if (resp.ok) {
    const href = URL.createObjectURL(resp.data);

    const filename = lowercaseObjectKeys(resp.headers)['content-disposition']?.split(
      'filename='
    )[1];

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    yield put(projectExportAnswers.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(projectExportAnswers.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* exportProjects() {
  const filterColumn: Record<string, ISearch> = yield select(selectProjectProjectsFilterColumn);
  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.exportProjects, {
    search: stringFromFilter,
  });

  if (resp.ok) {
    const href = URL.createObjectURL(resp.data);

    const filename = lowercaseObjectKeys(resp.headers)['content-disposition']?.split(
      'filename='
    )[1];

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    yield put(projectExportProjects.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(projectExportProjects.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getProjectQuestions({ payload }: AppAction<GetProjectQuestionsPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.getProjectQuestions, payload);

  if (resp.ok) {
    yield put(projectGetProjectQuestions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(projectGetProjectQuestions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getQuestion({ payload }: AppAction<GetQuestionPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.getQuestion, payload);

  if (resp.ok) {
    yield put(projectGetQuestion.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(projectGetQuestion.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createExistingQuestion({ payload }: AppAction<any>) {
  const block: Record<string, IBlock> = yield select(selectProjectBlocks);
  const currentBlock = block[payload.blockId];

  const resp: ExtendedAxiosResponse = yield call(api.createExistingQuestion, {
    id: payload.id,
    questionBlockId: currentBlock?.id as number,
    position: payload.position,
  });

  if (resp.ok) {
    yield put(
      projectCreateExistingQuestion.success(
        toCamelCase({
          ...resp.data,
          blockId: payload.blockId,
          questionId: payload.questionId,
        })
      )
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(projectCreateExistingQuestion.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createGroupedNumericBlock({ payload }: AppAction<CreateGroupedNumericBlockPayload>) {
  const block: Record<string, IBlock> = yield select(selectProjectBlocks);
  const currentBlock = block[payload?.blockUUID || ''];

  const resp: ExtendedAxiosResponse = yield call(api.createGroupedNumericBlock, {
    blockTotal: payload.blockTotal,
    questionBlockId: currentBlock.id as number,
  });

  if (resp.ok) {
    yield put(
      projectCreateGroupedNumericBlock.success({
        data: toCamelCase(resp.data)?.data,
        blockUUID: payload.blockUUID,
      })
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(projectCreateGroupedNumericBlock.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateGroupedNumericBlock({ payload }: AppAction<UpdateGroupedNumericBlockPayload>) {
  const block: Record<string, IBlock> = yield select(selectProjectBlocks);
  const currentBlock = block[payload?.blockUUID || ''];

  const resp: ExtendedAxiosResponse = yield call(api.updateGroupedNumericBlock, {
    id: payload.id,
    blockTotal: payload.blockTotal,
    questionBlockId: currentBlock.id as number,
  });

  if (resp.ok) {
    yield put(
      projectUpdateGroupedNumericBlock.success({
        data: toCamelCase(resp.data)?.data,
        blockUUID: payload.blockUUID,
      })
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(projectUpdateGroupedNumericBlock.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeGroupedNumericBlock({ payload }: AppAction<RemoveGroupedNumericBlock>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeGroupedNumericBlock, {
    id: payload.id,
  });

  if (resp.ok) {
    yield put(projectRemoveGroupedNumericBlock.success(payload.id));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(projectRemoveGroupedNumericBlock.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* addQuestionToGroupedNumericBlock({
  payload,
}: AppAction<AddQuestionToGroupedNumericBlock>) {
  const resp: ExtendedAxiosResponse = yield call(api.addQuestionToGroupedNumericBlock, payload);

  if (resp.ok) {
    yield put(projectAddQuestionToGroupedNumericBlock.success(payload.id));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(projectAddQuestionToGroupedNumericBlock.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* projectSaga() {
  yield takeLatest(ProjectActionTypes.SaveProject, saveProject);
  yield takeLatest(
    createActionType(ProjectActionTypes.CreateProject, RequestActionTypes.REQUEST),
    createProject
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.UpdateProject, RequestActionTypes.REQUEST),
    updateProject
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.SaveQuestionWithBlock, RequestActionTypes.REQUEST),
    saveQuestionWithBlock
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.SaveQuestionWithExistingBlock, RequestActionTypes.REQUEST),
    saveQuestionWithExistingBlock
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.UpdateQuestion, RequestActionTypes.REQUEST),
    updateQuestion
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.RemoveExistingQuestion, RequestActionTypes.REQUEST),
    removeExistingQuestion
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.GetTypes, RequestActionTypes.REQUEST),
    getTypes
  );
  yield takeEvery([ProjectActionTypes.ChangeBlockPosition], changeBlockPositions);
  yield takeEvery(
    [
      ProjectActionTypes.ChangeQuestionPosition,
      ProjectActionTypes.ChangeQuestionPositionBetweenBlocks,
    ],
    changeQuestionPositions
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.UpdateBlock, RequestActionTypes.REQUEST),
    updateBlock
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.RemoveBlock, RequestActionTypes.REQUEST),
    removeBlock
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.DuplicateQuestion, RequestActionTypes.REQUEST),
    duplicateQuestion
  );
  yield takeLatest(ProjectActionTypes.RemoveQuestion, removeQuestion);
  yield takeLatest(ProjectActionTypes.SaveQuestion, saveQuestion);
  yield takeLatest(
    [
      createActionType(ProjectActionTypes.GetProjects, RequestActionTypes.REQUEST),
      ProjectActionTypes.SetProjectsPage,
      ProjectActionTypes.IncreasePage,
      ProjectActionTypes.SetOrderColumn,
      ProjectActionTypes.SetFilterColumn,
    ],
    getProjects
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.GetProject, RequestActionTypes.REQUEST),
    getProject
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.RemoveProject, RequestActionTypes.REQUEST),
    removeProject
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.GetProjectBlocks, RequestActionTypes.REQUEST),
    getProjectBlocks
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.CreateBlock, RequestActionTypes.REQUEST),
    createBlock
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.DuplicateProject, RequestActionTypes.REQUEST),
    duplicateProject
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.ExportAnswers, RequestActionTypes.REQUEST),
    exportAnswers
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.ExportProjects, RequestActionTypes.REQUEST),
    exportProjects
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.GetProjectQuestions, RequestActionTypes.REQUEST),
    getProjectQuestions
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.GetQuestion, RequestActionTypes.REQUEST),
    getQuestion
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.CreateExistingQuestion, RequestActionTypes.REQUEST),
    createExistingQuestion
  );

  yield takeLatest(
    createActionType(ProjectActionTypes.CreateGroupedNumericBlock, RequestActionTypes.REQUEST),
    createGroupedNumericBlock
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.UpdateGroupedNumericBlock, RequestActionTypes.REQUEST),
    updateGroupedNumericBlock
  );
  yield takeLatest(
    createActionType(ProjectActionTypes.RemoveGroupedNumericBlock, RequestActionTypes.REQUEST),
    removeGroupedNumericBlock
  );
  yield takeLatest(
    createActionType(
      ProjectActionTypes.AddQuestionToGroupedNumericBlock,
      RequestActionTypes.REQUEST
    ),
    addQuestionToGroupedNumericBlock
  );
}
