import _ from 'lodash';

import { apiClient, ExtendedAxiosResponse } from '../../helpers/api-client';
import { toCamelCase, toSnakeCase } from '../../helpers/transformObject';
import {
  ChangeBlockPositionPayload,
  ChangeQuestionPositionPayload,
  IProject,
  SaveQuestionWithBlockPayload,
  SaveQuestionWithExistingBlockPayload,
  UpdateQuestionPayload,
} from '../../models/Project';
import { ITableReq } from '../../models/Table';

export interface CreateExistingQuestionPayload {
  id: number;
  questionBlockId: number;
  position: number;
}

export interface DuplicateProjectPayload {
  id: string;
}

export interface ExportAnswersPayload {
  id: number;
}

export interface ExportProjectsPayload {
  search: string;
}

export interface GetProjectQuestionsPayload {
  id: number;
}

export interface GetQuestionPayload {
  id: number;
}

export interface GetGroupedNumericBlocksPayload {
  id: number;
}

export interface CreateGroupedNumericBlockPayload {
  questionBlockId?: number;
  blockUUID?: string;
  blockTotal: number;
}

export interface UpdateGroupedNumericBlockPayload extends CreateGroupedNumericBlockPayload {
  id: number;
}

export interface RemoveGroupedNumericBlock {
  id: number;
}

export interface AddQuestionToGroupedNumericBlock {
  id: number;
  questionId: number;
}

export interface RemoveQuestionFromGroupedNumericBlock {
  id: number;
  questionId: number;
}

export const api = {
  createProject: (payload: IProject): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/project', toSnakeCase(payload)),

  updateProject: (payload: IProject): Promise<ExtendedAxiosResponse> =>
    apiClient.put(`/project/${payload.id}`, toSnakeCase(payload)),

  getProject: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/project/${id}`),

  getProjectBlocks: ({ id }: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/survey/${id}/question-blocks`),

  getProjects: ({ pagination, order, filter }: ITableReq): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/project', {
      params: toSnakeCase({
        page: pagination.currentPage,
        resultsPerPage: pagination.perPage,
        order: order.order,
        sort: _.snakeCase(order.sort),
        search: filter,
      }),
    }),

  removeProject: (payload: { id: number }): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/project/${payload.id}`),

  saveQuestionWithBlock: (payload: SaveQuestionWithBlockPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/survey/question-blocks', toSnakeCase(payload)),

  saveQuestionQuestionWithExistingBlock: (
    payload: SaveQuestionWithExistingBlockPayload
  ): Promise<ExtendedAxiosResponse> => apiClient.post('/survey/questions', toSnakeCase(payload)),

  updateBlock: (payload: {
    name?: string;
    explanation?: string;
    id: number;
  }): Promise<ExtendedAxiosResponse> =>
    apiClient.put(`/survey/question-blocks/${payload.id}`, toSnakeCase(payload)),

  updateQuestion: (data: UpdateQuestionPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.put(`/survey/questions/${data.id}`, data),

  duplicateQuestion: (id: string): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/survey/questions/${id}/duplicate`),

  removeBlock: (id: string): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/survey/question-blocks/${id}`),

  removeQuestion: (id: number): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/survey/questions/${id}`),

  getTypes: (): Promise<ExtendedAxiosResponse> => apiClient.get('/survey/question-types'),

  changeBlockPosition: (payload: ChangeBlockPositionPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/survey/question-blocks/change-position', toSnakeCase(payload)),

  changeQuestionPosition: (
    payload: ChangeQuestionPositionPayload
  ): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/survey/questions/change-position', toSnakeCase(payload)),

  createBlock: (payload: { position: string; surveyId: string }): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/survey/question-blocks', toSnakeCase(payload)),

  duplicateProject: (payload: DuplicateProjectPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/project/${payload.id}/duplicate`),

  exportAnswers: (payload: ExportAnswersPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/admin/project/${payload.id}/export-answers`, {
      responseType: 'blob',
    }),

  exportProjects: (payload: ExportProjectsPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.get('/admin/project/export-projects', {
      params: toSnakeCase(payload),
      responseType: 'blob',
    }),

  getProjectQuestions: (payload: GetProjectQuestionsPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/survey/${payload.id}/questions`),

  getQuestion: (payload: GetQuestionPayload): Promise<ExtendedAxiosResponse> =>
    apiClient.get(`/survey/questions/${payload.id}`),

  createExistingQuestion: (
    payload: CreateExistingQuestionPayload
  ): Promise<ExtendedAxiosResponse> => {
    const { id, ...data } = payload;
    return apiClient.post(`/survey/questions/${payload.id}/duplicate`, toSnakeCase(data));
  },

  getGroupedNumericBlocks: (
    payload: GetGroupedNumericBlocksPayload
  ): Promise<ExtendedAxiosResponse> => apiClient.get(`/admin/grouped-numeric-blocks/${payload.id}`),

  createGroupedNumericBlock: (
    payload: CreateGroupedNumericBlockPayload
  ): Promise<ExtendedAxiosResponse> =>
    apiClient.post('/admin/grouped-numeric-blocks', toSnakeCase(payload)),

  updateGroupedNumericBlock: (
    payload: UpdateGroupedNumericBlockPayload
  ): Promise<ExtendedAxiosResponse> => {
    const { id, ...data } = payload;

    return apiClient.put(`/admin/grouped-numeric-blocks/${id}`, toSnakeCase(data));
  },

  removeGroupedNumericBlock: (payload: RemoveGroupedNumericBlock): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/admin/grouped-numeric-blocks/${payload.id}`),

  addQuestionToGroupedNumericBlock: (
    payload: AddQuestionToGroupedNumericBlock
  ): Promise<ExtendedAxiosResponse> =>
    apiClient.post(`/admin/grouped-numeric-blocks/${payload.id}/questions/${payload.questionId}`),

  removeQuestionFromGroupedNumericBlock: (
    payload: RemoveQuestionFromGroupedNumericBlock
  ): Promise<ExtendedAxiosResponse> =>
    apiClient.delete(`/admin/grouped-numeric-blocks/${payload.id}/questions/${payload.questionId}`),
};
