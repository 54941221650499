import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { history } from '../../helpers/store/root-reducer';

import { formatFilterToString } from '../../helpers/table';
import { lowercaseObjectKeys, toCamelCase } from '../../helpers/transformObject';
import { Contract } from '../../models/Contract';

import {
  NonProjectHandoverProtocol,
  RespondentCompany,
} from '../../models/NonProjectHandoverProtocol';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IRespondent } from '../../models/Respondent';
import { ISearch, IOrderStatus, ITableOrder } from '../../models/Table';
import { Company } from '../../models/User';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  NonProjectHandoverProtocolActionTypes,
  nonProjectHandoverProtocolCreateHandoverProtocolActions,
  nonProjectHandoverProtocolDownloadZipActions,
  nonProjectHandoverProtocolGetNonProjectHandoverProtocolActions,
  nonProjectHandoverProtocolGetNonProjectHandoverProtocolsActions,
  nonProjectHandoverProtocolGetRespondentCompanyDataActions,
  nonProjectHandoverProtocolGetRespondentsCompaniesActions,
  nonProjectHandoverProtocolGetSignedContractsActions,
  nonProjectHandoverProtocolRemoveFile,
  nonProjectHandoverProtocolRemoveHandoverProtocolsActions,
  nonProjectHandoverProtocolUpdateHandoverProtocolActions,
  nonProjectHandoverProtocolUploadFiles,
} from './actions';
import {
  api,
  CreateNPPPayload,
  GetRespondentCompanyDataPayload,
  GetRespondentsCompaniesPayload,
  GetSignedContractsPayload,
  RemoveFilePayload,
  RemoveNPPsPayload,
  UpdateNPPPayload,
  UploadFilesPayload,
} from './api';
import {
  selectNonProjectHandoverProtocolProtocolsFilterColumn,
  selectNonProjectHandoverProtocolProtocolsOrderColumn,
  selectNonProjectHandoverProtocolProtocolsPagination,
} from './selectors';

/* STATE */
export interface NonProjectHandoverProtocolState {
  nonProjectHandoverProtocols: NonProjectHandoverProtocol[];
  nonProjectHandoverProtocolsPagination: Pages;
  nonProjectHandoverProtocolsStatus: LoadingStatus;
  nonProjectHandoverProtocolsOrder: ITableOrder;
  nonProjectHandoverProtocolsFilter: Record<string, ISearch>;

  respondentsCompanies: RespondentCompany[];
  respondentsCompaniesPagination: Pages;
  respondentsCompaniesStatus: LoadingStatus;
  respondentsCompaniesFilter: Record<string, ISearch>;

  nonProjectHandoverProtocol: NonProjectHandoverProtocol | null;
  nonProjectHandoverProtocolStatus: LoadingStatus;

  signedContracts: Contract[];
  signedContractsStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: NonProjectHandoverProtocolState = {
  nonProjectHandoverProtocols: [],
  nonProjectHandoverProtocolsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  nonProjectHandoverProtocolsStatus: LoadingStatus.initial,
  nonProjectHandoverProtocolsOrder: {
    sort: 'id',
    order: IOrderStatus.DESC,
  },
  nonProjectHandoverProtocolsFilter: {},

  respondentsCompanies: [],
  respondentsCompaniesPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  respondentsCompaniesStatus: LoadingStatus.initial,
  respondentsCompaniesFilter: {},

  nonProjectHandoverProtocol: null,
  nonProjectHandoverProtocolStatus: LoadingStatus.initial,

  signedContracts: [],
  signedContractsStatus: LoadingStatus.initial,
};

const nonProjectHandoverProtocols = createReducer(initialState.nonProjectHandoverProtocols, {
  [NonProjectHandoverProtocolActionTypes.GetNonProjectHandoverProtocols]: {
    [RequestActionTypes.SUCCESS]: (
      state: NonProjectHandoverProtocol[],
      payload: PaginatedResp<NonProjectHandoverProtocol>
    ) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.nonProjectHandoverProtocols,
  },
  [NonProjectHandoverProtocolActionTypes.RemoveNonProjectHandoverProtocols]: {
    [RequestActionTypes.SUCCESS]: (
      state: NonProjectHandoverProtocol[],
      payload: { id: number[] }
    ) => {
      return state.filter((protocol) => payload.id.indexOf(protocol.id) < 0);
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.nonProjectHandoverProtocols,
  },
});

const nonProjectHandoverProtocolsPagination = createReducer(
  initialState.nonProjectHandoverProtocolsPagination,
  {
    [NonProjectHandoverProtocolActionTypes.GetNonProjectHandoverProtocols]: {
      [RequestActionTypes.SUCCESS]: (
        state: Pages,
        payload: PaginatedResp<NonProjectHandoverProtocol>
      ) => omit(payload, 'data'),
      [RequestActionTypes.FAILURE]: () => initialState.nonProjectHandoverProtocolsPagination,
    },
    [NonProjectHandoverProtocolActionTypes.SetNonProjectHandoverProtocolsPage]: (
      state: Pages,
      payload: Pages
    ) => {
      return {
        ...state,
        ...payload,
      };
    },
    [NonProjectHandoverProtocolActionTypes.IncreasePage]: (state: Pages) => ({
      ...state,
      currentPage: state.currentPage + 1,
    }),
    [NonProjectHandoverProtocolActionTypes.SetOrderColumn]: (state: Pages) => ({
      ...state,
      currentPage: 1,
    }),
    [NonProjectHandoverProtocolActionTypes.SetFilterColumn]: (state: Pages) => ({
      ...state,
      currentPage: 1,
    }),
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.nonProjectHandoverProtocolsPagination,
    },
  }
);

const nonProjectHandoverProtocolsStatus = createLoadingStateReducer(
  initialState.nonProjectHandoverProtocolsStatus,
  {
    [NonProjectHandoverProtocolActionTypes.GetNonProjectHandoverProtocols]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [NonProjectHandoverProtocolActionTypes.SetNonProjectHandoverProtocolsPage]: () =>
      initialState.nonProjectHandoverProtocolsStatus,
    [NonProjectHandoverProtocolActionTypes.IncreasePage]: () =>
      initialState.nonProjectHandoverProtocolsStatus,
    [NonProjectHandoverProtocolActionTypes.SetFilterColumn]: () =>
      initialState.nonProjectHandoverProtocolsStatus,
    [NonProjectHandoverProtocolActionTypes.SetOrderColumn]: () =>
      initialState.nonProjectHandoverProtocolsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.nonProjectHandoverProtocolsStatus,
    },
  }
);

const nonProjectHandoverProtocolsOrder = createReducer(
  initialState.nonProjectHandoverProtocolsOrder,
  {
    [NonProjectHandoverProtocolActionTypes.SetOrderColumn]: (
      state: ITableOrder,
      payload: ITableOrder
    ) => ({
      ...payload,
    }),
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.nonProjectHandoverProtocolsOrder,
    },
  }
);

const nonProjectHandoverProtocolsFilter = createReducer(
  initialState.nonProjectHandoverProtocolsFilter,
  {
    [NonProjectHandoverProtocolActionTypes.SetFilterColumn]: (
      state: Record<string, string>,
      payload: Record<string, string>
    ) => ({
      ...state,
      ...payload,
    }),
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.nonProjectHandoverProtocolsFilter,
    },
  }
);

const respondentsCompanies = createReducer(initialState.respondentsCompanies, {
  [NonProjectHandoverProtocolActionTypes.GetRespondentsCompanies]: {
    [RequestActionTypes.SUCCESS]: (
      state: RespondentCompany[],
      payload: PaginatedResp<RespondentCompany>
    ) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      const difference = payload.data.filter(
        (entity) => state.findIndex((target) => entity.id === target.id) >= 0
      );
      return [...state, ...difference];
    },
    [RequestActionTypes.FAILURE]: () => initialState.respondentsCompanies,
  },
  [NonProjectHandoverProtocolActionTypes.GetRespondentCompanyData]: {
    [RequestActionTypes.SUCCESS]: (state: RespondentCompany[], payload: RespondentCompany) => {
      const result = [...state];

      if (state.findIndex((entity) => entity.id === payload.id) < 0) {
        result.push(payload);
      }

      return result;
    },
    [RequestActionTypes.FAILURE]: () => initialState.respondentsCompanies,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.respondentsCompanies,
  },
});

const respondentsCompaniesPagination = createReducer(initialState.respondentsCompaniesPagination, {
  [NonProjectHandoverProtocolActionTypes.GetRespondentsCompanies]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<RespondentCompany>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.respondentsCompaniesPagination,
  },
  [NonProjectHandoverProtocolActionTypes.SetRespondentsCompaniesPage]: (
    state: Pages,
    payload: Pages
  ) => {
    return {
      ...state,
      ...payload,
    };
  },
  [NonProjectHandoverProtocolActionTypes.IncreaseRespondentsCompaniesPage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [NonProjectHandoverProtocolActionTypes.SetRespondentsCompaniesFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.respondentsCompaniesPagination,
  },
});

const respondentsCompaniesStatus = createLoadingStateReducer(
  initialState.respondentsCompaniesStatus,
  {
    [NonProjectHandoverProtocolActionTypes.GetRespondentsCompanies]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [NonProjectHandoverProtocolActionTypes.SetRespondentsCompaniesPage]: () =>
      initialState.respondentsCompaniesStatus,
    [NonProjectHandoverProtocolActionTypes.IncreaseRespondentsCompaniesPage]: () =>
      initialState.respondentsCompaniesStatus,
    [NonProjectHandoverProtocolActionTypes.SetRespondentsCompaniesFilterColumn]: () =>
      initialState.respondentsCompaniesStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.respondentsCompaniesStatus,
    },
  }
);

const respondentsCompaniesFilter = createReducer(initialState.respondentsCompaniesFilter, {
  [NonProjectHandoverProtocolActionTypes.SetRespondentsCompaniesFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.respondentsCompaniesFilter,
  },
});

const nonProjectHandoverProtocol = createReducer(initialState.nonProjectHandoverProtocol, {
  [NonProjectHandoverProtocolActionTypes.CreateNonProjectHandoverProtocol]: {
    [RequestActionTypes.SUCCESS]: (
      state: NonProjectHandoverProtocol | null,
      payload: NonProjectHandoverProtocol
    ) => payload,
  },
  [NonProjectHandoverProtocolActionTypes.GetNonProjectHandoverProtocol]: {
    [RequestActionTypes.REQUEST]: () => initialState.nonProjectHandoverProtocol,
    [RequestActionTypes.SUCCESS]: (
      state: NonProjectHandoverProtocol | null,
      payload: NonProjectHandoverProtocol
    ) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.nonProjectHandoverProtocol,
  },
  [NonProjectHandoverProtocolActionTypes.UpdateNonProjectHandoverProtocol]: {
    [RequestActionTypes.SUCCESS]: (
      state: NonProjectHandoverProtocol | null,
      payload: NonProjectHandoverProtocol
    ) => payload,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.nonProjectHandoverProtocol,
  },
});

const nonProjectHandoverProtocolStatus = createLoadingStateReducer(
  initialState.nonProjectHandoverProtocolStatus,
  {
    [NonProjectHandoverProtocolActionTypes.CreateNonProjectHandoverProtocol]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [NonProjectHandoverProtocolActionTypes.UpdateNonProjectHandoverProtocol]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.nonProjectHandoverProtocolStatus,
    },
  }
);

const signedContracts = createReducer(initialState.signedContracts, {
  [NonProjectHandoverProtocolActionTypes.GetSignedContracts]: {
    [RequestActionTypes.SUCCESS]: (state: Contract[], payload: PaginatedResp<Contract[]>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.signedContracts,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.signedContracts,
  },
});

const signedContractsStatus = createLoadingStateReducer(
  initialState.signedContractsStatus,
  {
    [NonProjectHandoverProtocolActionTypes.GetSignedContracts]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.signedContractsStatus,
    },
  }
);

export default combineReducers<NonProjectHandoverProtocolState>({
  nonProjectHandoverProtocols,
  nonProjectHandoverProtocolsPagination,
  nonProjectHandoverProtocolsStatus,
  nonProjectHandoverProtocolsOrder,
  nonProjectHandoverProtocolsFilter,
  respondentsCompanies,
  respondentsCompaniesPagination,
  respondentsCompaniesStatus,
  respondentsCompaniesFilter,
  nonProjectHandoverProtocol,
  nonProjectHandoverProtocolStatus,
  signedContracts,
  signedContractsStatus,
});

/* SAGAS */
function* getNonProjectHandoverProtocols() {
  const pagination: Pages = yield select(selectNonProjectHandoverProtocolProtocolsPagination);
  const orderColumn: ITableOrder = yield select(
    selectNonProjectHandoverProtocolProtocolsOrderColumn
  );
  const filterColumn: Record<string, ISearch> = yield select(
    selectNonProjectHandoverProtocolProtocolsFilterColumn
  );

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getNonProjectHandoverProtocols, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(
      nonProjectHandoverProtocolGetNonProjectHandoverProtocolsActions.success(
        toCamelCase(resp.data)
      )
    );
  } else {
    yield put(nonProjectHandoverProtocolGetNonProjectHandoverProtocolsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* downloadZip({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.downloadZip, payload);

  if (resp.ok) {
    const href = URL.createObjectURL(resp.data);

    const filename =
      lowercaseObjectKeys(resp.headers)
        ['content-disposition']?.split('filename="')?.[1]
        ?.split('"')?.[0] || '';

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${filename}.zip`); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    yield put(nonProjectHandoverProtocolDownloadZipActions.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(nonProjectHandoverProtocolDownloadZipActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getRespondentsCompanies({ payload }: AppAction<GetRespondentsCompaniesPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.getRespondentsCompanies, payload);

  if (resp.ok) {
    yield put(
      nonProjectHandoverProtocolGetRespondentsCompaniesActions.success(toCamelCase(resp.data))
    );
  } else {
    yield put(nonProjectHandoverProtocolGetRespondentsCompaniesActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getRespondentCompanyData({ payload }: AppAction<GetRespondentCompanyDataPayload>) {
  const endpoint = payload.isCompany ? api.getCompanydata : api.getRespondentData;
  const resp: ExtendedAxiosResponse = yield call(endpoint, payload);

  if (resp.ok) {
    const data = toCamelCase(resp.data) as Company | IRespondent;

    yield put(
      nonProjectHandoverProtocolGetRespondentCompanyDataActions.success(
        toCamelCase({
          id: data.id,
          isCompany: payload.isCompany,
          name: payload.isCompany
            ? (data as Company).name
            : `${(data as IRespondent).firstName} ${(data as IRespondent).lastName}`,
          state: !!data.state,
        } as RespondentCompany)
      )
    );
  } else {
    yield put(nonProjectHandoverProtocolGetRespondentCompanyDataActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createNonProjectHandoverProtocol({ payload }: AppAction<CreateNPPPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.createNonProjectHandoverProtocol, payload);

  if (resp.ok) {
    yield put(
      nonProjectHandoverProtocolCreateHandoverProtocolActions.success(toCamelCase(resp.data)?.data)
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
    yield put(nonProjectHandoverProtocolGetNonProjectHandoverProtocolsActions.request());
  } else {
    yield put(nonProjectHandoverProtocolCreateHandoverProtocolActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getNonProjectHandoverProtocol({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getNonProjectHandoverProtocol, payload);

  if (resp.ok) {
    yield put(
      nonProjectHandoverProtocolGetNonProjectHandoverProtocolActions.success(
        toCamelCase(resp.data)?.data
      )
    );
  } else {
    yield put(nonProjectHandoverProtocolGetNonProjectHandoverProtocolActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeNonProjectHandoverProtocols({ payload }: AppAction<RemoveNPPsPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeNonProjectHandoverProtocols, payload);

  if (resp.ok) {
    yield put(
      nonProjectHandoverProtocolRemoveHandoverProtocolsActions.success({
        id: payload.nonProjectHandoverProtocolsArray,
      })
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(nonProjectHandoverProtocolRemoveHandoverProtocolsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateNonProjectHandoverProtocol({ payload }: AppAction<UpdateNPPPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateNonProjectHandoverProtocol, payload);

  if (resp.ok) {
    yield put(
      nonProjectHandoverProtocolUpdateHandoverProtocolActions.success(toCamelCase(resp.data)?.data)
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
    yield put(nonProjectHandoverProtocolGetNonProjectHandoverProtocolsActions.request());

    history.push(buildRoute([AppRoutes.Admin, AdminRoutes.NonProjectAcceptanceProtocol]));
  } else {
    yield put(nonProjectHandoverProtocolUpdateHandoverProtocolActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getSignedContracts({ payload }: AppAction<GetSignedContractsPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.getSignedContracts, payload);

  if (resp.ok) {
    yield put(nonProjectHandoverProtocolGetSignedContractsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(nonProjectHandoverProtocolGetSignedContractsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* uploadFiles({ payload }: AppAction<UploadFilesPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.uploadFiles, payload);

  if (resp.ok) {
    yield put(nonProjectHandoverProtocolUploadFiles.success());
  } else {
    yield put(nonProjectHandoverProtocolUploadFiles.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeFile({ payload }: AppAction<RemoveFilePayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeFile, payload);

  if (resp.ok) {
    yield put(nonProjectHandoverProtocolRemoveFile.success());
  } else {
    yield put(nonProjectHandoverProtocolRemoveFile.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* nonProjectHandoverProtocolSaga() {
  yield takeLatest(
    [
      createActionType(
        NonProjectHandoverProtocolActionTypes.GetNonProjectHandoverProtocols,
        RequestActionTypes.REQUEST
      ),
      NonProjectHandoverProtocolActionTypes.SetNonProjectHandoverProtocolsPage,
      NonProjectHandoverProtocolActionTypes.IncreasePage,
      NonProjectHandoverProtocolActionTypes.SetOrderColumn,
      NonProjectHandoverProtocolActionTypes.SetFilterColumn,
    ],
    getNonProjectHandoverProtocols
  );
  yield takeLatest(
    createActionType(NonProjectHandoverProtocolActionTypes.DownloadZip, RequestActionTypes.REQUEST),
    downloadZip
  );
  yield takeLatest(
    [
      createActionType(
        NonProjectHandoverProtocolActionTypes.GetRespondentsCompanies,
        RequestActionTypes.REQUEST
      ),
      NonProjectHandoverProtocolActionTypes.SetRespondentsCompaniesPage,
      NonProjectHandoverProtocolActionTypes.IncreaseRespondentsCompaniesPage,
      NonProjectHandoverProtocolActionTypes.SetRespondentsCompaniesFilterColumn,
    ],
    getRespondentsCompanies
  );
  yield takeLatest(
    createActionType(
      NonProjectHandoverProtocolActionTypes.GetNonProjectHandoverProtocol,
      RequestActionTypes.REQUEST
    ),
    getNonProjectHandoverProtocol
  );
  yield takeLatest(
    createActionType(
      NonProjectHandoverProtocolActionTypes.CreateNonProjectHandoverProtocol,
      RequestActionTypes.REQUEST
    ),
    createNonProjectHandoverProtocol
  );
  yield takeLatest(
    createActionType(
      NonProjectHandoverProtocolActionTypes.RemoveNonProjectHandoverProtocols,
      RequestActionTypes.REQUEST
    ),
    removeNonProjectHandoverProtocols
  );
  yield takeLatest(
    createActionType(
      NonProjectHandoverProtocolActionTypes.UpdateNonProjectHandoverProtocol,
      RequestActionTypes.REQUEST
    ),
    updateNonProjectHandoverProtocol
  );
  yield takeLatest(
    createActionType(
      NonProjectHandoverProtocolActionTypes.GetRespondentCompanyData,
      RequestActionTypes.REQUEST
    ),
    getRespondentCompanyData
  );
  yield takeLatest(
    createActionType(
      NonProjectHandoverProtocolActionTypes.GetSignedContracts,
      RequestActionTypes.REQUEST
    ),
    getSignedContracts
  );
  yield takeLatest(
    createActionType(NonProjectHandoverProtocolActionTypes.UploadFiles, RequestActionTypes.REQUEST),
    uploadFiles
  );
  yield takeEvery(
    createActionType(NonProjectHandoverProtocolActionTypes.RemoveFile, RequestActionTypes.REQUEST),
    removeFile
  );
}
