import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { Spec, SpecState } from './ducks';

/* SELECTORS */
const selectSpec = (state: AppState): SpecState => state.spec;

export const selectSpecGetSpecs = (state: AppState): Spec[] => selectSpec(state).specs;

export const selectSpecSpecsPagination = (state: AppState): Pages =>
  selectSpec(state).specsPagination;

export const selectSpecSpecsStatus = (state: AppState): LoadingStatus =>
  selectSpec(state).specsStatus;

export const selectSpecSpecsOrderColumn = (state: AppState): ITableOrder =>
  selectSpec(state).specsOrder;

export const selectSpecSpecsFilterColumn = (state: AppState): Record<string, ISearch> =>
  selectSpec(state).specsFilter;

export const selectSpecSpecActionStatus = (state: AppState): LoadingStatus =>
  selectSpec(state).specActionStatus;
