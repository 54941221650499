/* SELECTORS */

import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Client } from '../../models/Client';
import { ClientInvoice } from '../../models/ClientInvoice';
import { ClientsDataOverviewProjectRespondent } from '../../models/ClientsDataOverview';
import { Pages } from '../../models/Pages';
import { ISearch, ITableOrder } from '../../models/Table';
import { ClientsDataOverviewSummary } from './ducks';

const selectClientsDataOverview = (state: AppState) => state.clientsDataOverview;

export const selectClientsDataOverviewClientInvoices = (state: AppState): ClientInvoice[] =>
  selectClientsDataOverview(state).clientInvoices;

export const selectClientsDataOverviewClientInvoicesStatus = (state: AppState): LoadingStatus =>
  selectClientsDataOverview(state).clientInvoicesStatus;

export const selectClientsDataOverviewClientInvoice = (state: AppState): ClientInvoice | null =>
  selectClientsDataOverview(state).clientInvoice;

export const selectClientsDataOverviewProjectRespondents = (
  state: AppState
): ClientsDataOverviewProjectRespondent[] => selectClientsDataOverview(state).projectRespondents;

export const selectClientsDataOverviewProjectRespondentsPagination = (state: AppState): Pages =>
  selectClientsDataOverview(state).projectRespondentsPagination;

export const selectClientsDataOverviewProjectRespondentsStatus = (state: AppState): LoadingStatus =>
  selectClientsDataOverview(state).projectRespondentsStatus;

export const selectClientsDataOverviewProjectRespondentsOrderColumn = (
  state: AppState
): ITableOrder => selectClientsDataOverview(state).projectRespondentsOrder;

export const selectClientsDataOverviewProjectRespondentsFilterColumn = (
  state: AppState
): Record<string, ISearch> => selectClientsDataOverview(state).projectRespondentsFilter;

export const selectClientsDataOverviewClients = (state: AppState): Client[] =>
  selectClientsDataOverview(state).clients;

export const selectClientsDataOverviewClientsStatus = (state: AppState): LoadingStatus =>
  selectClientsDataOverview(state).clientsStatus;

export const selectClientsDataOverviewSummary = (state: AppState): ClientsDataOverviewSummary =>
  selectClientsDataOverview(state).summary;

export const selectClientsDataOverviewProjects = (
  state: AppState
): { id: string; name: string }[] => selectClientsDataOverview(state).projects;

export const selectClientsDataOverviewProjectsStatus = (state: AppState): LoadingStatus =>
  selectClientsDataOverview(state).projectsStatus;
