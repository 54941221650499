import DOMPurify from 'dompurify';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';

import { toastClearActions } from '../../../saga/toast/actions';
import { selectToastState } from '../../../saga/toast/selectors';

const ToastMessages = () => {
  const selectedToast = useSelector(selectToastState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedToast.type && selectedToast.message) {
      const message = !selectedToast.rawHTML ? (
        selectedToast.message
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(selectedToast.message, {
              USE_PROFILES: { html: true },
              FORBID_TAGS: ['img'],
            }),
          }}
        />
      );

      toast(message, {
        type: selectedToast.type,
        closeOnClick: selectedToast.closeOnClick,
        progress: selectedToast.progress || undefined,
      });
    }
    dispatch(toastClearActions());
  }, [selectedToast.message, selectedToast.type]);

  interface IContextClass {
    success: string;
    error: string;
    info: string;
    warning: string;
    default: string;
    dark: string;
  }

  const contextClass: IContextClass = {
    success: 'bg-success-25 text-success-100',
    error: 'bg-danger-25 text-danger-100',
    info: 'bg-gray-600',
    warning: 'bg-warning-25 text-warning-100',
    default: 'bg-indigo-600',
    dark: 'bg-white-600 font-gray-300',
  };

  return (
    <>
      <ToastContainer
        toastClassName={({ type }: any) =>
          `${
            contextClass[(type as keyof IContextClass) || 'default']
          } text-sm relative flex p-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer font-normal font-sans text-sm mb-3`
        }
      />
    </>
  );
};
//
export default ToastMessages;
