import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { SettingsRoutes } from '../../helpers/route/routes/settings-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';

import { IInvoiceCompany } from '../../models/InvoiceCompany';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';

import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  InvoiceCompanyActionTypes,
  invoiceCompanyCreateCompanyActions,
  invoiceCompanyGetAllCompaniesActions,
  invoiceCompanyGetCompaniesActions,
  invoiceCompanyGetCompanyActions,
  invoiceCompanyRemoveCompanyActions,
  invoiceCompanyUpdateCompanyActions,
} from './actions';
import { api, InvoiceCompanyPayload } from './api';
import {
  selectInvoiceCompanyCompaniesFilterColumn,
  selectInvoiceCompanyCompaniesOrderColumn,
  selectInvoiceCompanyCompaniesPagination,
} from './selectors';

/* STATE */
export interface InvoiceCompanyState {
  invoiceCompaniesAll: IInvoiceCompany[];
  invoiceCompanies: IInvoiceCompany[];
  invoiceCompaniesPagination: Pages;
  invoiceCompaniesStatus: LoadingStatus;
  invoiceCompaniesOrder: ITableOrder;
  invoiceCompaniesFilter: Record<string, ISearch>;
  invoiceCompany: IInvoiceCompany | null;
  invoiceCompanyActionStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: InvoiceCompanyState = {
  invoiceCompaniesAll: [],
  invoiceCompanies: [],
  invoiceCompaniesPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  invoiceCompaniesStatus: LoadingStatus.initial,
  invoiceCompaniesOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  invoiceCompaniesFilter: {},
  invoiceCompany: null,
  invoiceCompanyActionStatus: LoadingStatus.initial,
};

const invoiceCompaniesAll = createReducer(initialState.invoiceCompaniesAll, {
  [InvoiceCompanyActionTypes.GetAllInvoiceCompanies]: {
    [RequestActionTypes.SUCCESS]: (state: IInvoiceCompany[], payload: IInvoiceCompany[]) => {
      return payload;
    },
    [RequestActionTypes.FAILURE]: () => initialState.invoiceCompaniesAll,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.invoiceCompaniesAll,
  },
});

const invoiceCompanies = createReducer(initialState.invoiceCompanies, {
  [InvoiceCompanyActionTypes.GetInvoiceCompanies]: {
    [RequestActionTypes.SUCCESS]: (
      state: IInvoiceCompany[],
      payload: PaginatedResp<IInvoiceCompany>
    ) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }

      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.invoiceCompanies,
  },
  [InvoiceCompanyActionTypes.RemoveInvoiceCompany]: {
    [RequestActionTypes.SUCCESS]: (state: IInvoiceCompany[], payload: { id: number }) => {
      return state.filter((company) => company.id !== payload.id);
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.invoiceCompanies,
  },
});

const invoiceCompaniesPagination = createReducer(initialState.invoiceCompaniesPagination, {
  [InvoiceCompanyActionTypes.GetInvoiceCompanies]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<IInvoiceCompany>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.invoiceCompaniesPagination,
  },
  [InvoiceCompanyActionTypes.SetInvoiceCompaniesPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [InvoiceCompanyActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [InvoiceCompanyActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [InvoiceCompanyActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.invoiceCompaniesPagination,
  },
});

const invoiceCompaniesStatus = createLoadingStateReducer(
  initialState.invoiceCompaniesStatus,
  {
    [InvoiceCompanyActionTypes.GetInvoiceCompanies]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [InvoiceCompanyActionTypes.SetInvoiceCompaniesPage]: () => initialState.invoiceCompaniesStatus,
    [InvoiceCompanyActionTypes.IncreasePage]: () => initialState.invoiceCompaniesStatus,
    [InvoiceCompanyActionTypes.SetFilterColumn]: () => initialState.invoiceCompaniesStatus,
    [InvoiceCompanyActionTypes.SetOrderColumn]: () => initialState.invoiceCompaniesStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.invoiceCompaniesStatus,
    },
  }
);

const invoiceCompaniesOrder = createReducer(initialState.invoiceCompaniesOrder, {
  [InvoiceCompanyActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.invoiceCompaniesOrder,
  },
});

const invoiceCompaniesFilter = createReducer(initialState.invoiceCompaniesFilter, {
  [InvoiceCompanyActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.invoiceCompaniesFilter,
  },
});

const invoiceCompany = createReducer(initialState.invoiceCompany, {
  [InvoiceCompanyActionTypes.CreateInvoiceCompany]: {
    [RequestActionTypes.SUCCESS]: (state: IInvoiceCompany | null, payload: IInvoiceCompany) =>
      payload,
  },
  [InvoiceCompanyActionTypes.GetInvoiceCompany]: {
    [RequestActionTypes.REQUEST]: () => initialState.invoiceCompany,
    [RequestActionTypes.SUCCESS]: (state: IInvoiceCompany | null, payload: IInvoiceCompany) =>
      payload,
    [RequestActionTypes.FAILURE]: () => initialState.invoiceCompany,
  },
  [InvoiceCompanyActionTypes.UpdateInvoiceCompany]: {
    [RequestActionTypes.SUCCESS]: (state: IInvoiceCompany | null, payload: IInvoiceCompany) =>
      payload,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.invoiceCompany,
  },
});

const invoiceCompanyActionStatus = createLoadingStateReducer(
  initialState.invoiceCompanyActionStatus,
  {
    [InvoiceCompanyActionTypes.CreateInvoiceCompany]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [InvoiceCompanyActionTypes.UpdateInvoiceCompany]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.invoiceCompanyActionStatus,
    },
  }
);

export default combineReducers<InvoiceCompanyState>({
  invoiceCompaniesAll,
  invoiceCompanies,
  invoiceCompaniesPagination,
  invoiceCompaniesStatus,
  invoiceCompaniesOrder,
  invoiceCompaniesFilter,
  invoiceCompany,
  invoiceCompanyActionStatus,
});

/* SAGAS */
function* getInvoiceCompaniesAll() {
  const resp: ExtendedAxiosResponse = yield call(api.getInvoiceCompaniesAll);

  if (resp.ok) {
    yield put(invoiceCompanyGetAllCompaniesActions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(invoiceCompanyGetAllCompaniesActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getInvoiceCompanies() {
  const pagination: Pages = yield select(selectInvoiceCompanyCompaniesPagination);
  const orderColumn: ITableOrder = yield select(selectInvoiceCompanyCompaniesOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(
    selectInvoiceCompanyCompaniesFilterColumn
  );

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getInvoiceCompanies, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(invoiceCompanyGetCompaniesActions.success(toCamelCase(resp.data)));
  } else {
    yield put(invoiceCompanyGetCompaniesActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeInvoiceCompany({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeInvoiceCompany, payload);

  if (resp.ok) {
    yield put(invoiceCompanyRemoveCompanyActions.success({ id: payload.id }));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(invoiceCompanyRemoveCompanyActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createInvoiceCompany({ payload }: AppAction<InvoiceCompanyPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.createInvoiceCompany, payload);

  if (resp.ok) {
    yield put(invoiceCompanyCreateCompanyActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(
      buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageInvoiceCompanies])
    );
  } else {
    yield put(invoiceCompanyCreateCompanyActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getInvoiceCompany({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getInvoiceCompany, payload);

  if (resp.ok) {
    yield put(invoiceCompanyGetCompanyActions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(invoiceCompanyGetCompanyActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateInvoiceCompany({ payload }: AppAction<InvoiceCompanyPayload>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateInvoiceCompany, payload);

  if (resp.ok) {
    yield put(invoiceCompanyUpdateCompanyActions.success(toCamelCase(resp.data)?.data));
    yield put(toastCreateSuccessActions(resp.data?.message));
    history.push(
      buildRoute([AppRoutes.Admin, AdminRoutes.Settings, SettingsRoutes.ManageInvoiceCompanies])
    );
  } else {
    yield put(invoiceCompanyUpdateCompanyActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* invoiceCompanySaga() {
  yield takeLatest(
    createActionType(InvoiceCompanyActionTypes.GetAllInvoiceCompanies, RequestActionTypes.REQUEST),
    getInvoiceCompaniesAll
  );
  yield takeLatest(
    [
      createActionType(InvoiceCompanyActionTypes.GetInvoiceCompanies, RequestActionTypes.REQUEST),
      InvoiceCompanyActionTypes.SetInvoiceCompaniesPage,
      InvoiceCompanyActionTypes.IncreasePage,
      InvoiceCompanyActionTypes.SetOrderColumn,
      InvoiceCompanyActionTypes.SetFilterColumn,
    ],
    getInvoiceCompanies
  );
  yield takeLatest(
    [createActionType(InvoiceCompanyActionTypes.RemoveInvoiceCompany, RequestActionTypes.REQUEST)],
    removeInvoiceCompany
  );
  yield takeLatest(
    createActionType(InvoiceCompanyActionTypes.CreateInvoiceCompany, RequestActionTypes.REQUEST),
    createInvoiceCompany
  );
  yield takeLatest(
    createActionType(InvoiceCompanyActionTypes.GetInvoiceCompany, RequestActionTypes.REQUEST),
    getInvoiceCompany
  );
  yield takeLatest(
    createActionType(InvoiceCompanyActionTypes.UpdateInvoiceCompany, RequestActionTypes.REQUEST),
    updateInvoiceCompany
  );
}
