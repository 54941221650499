import { omit } from 'lodash';
import { combineReducers } from 'redux';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { formatFilterToString } from '../../helpers/table';
import { lowercaseObjectKeys, toCamelCase } from '../../helpers/transformObject';
import { BankTransfer, ExportDate } from '../../models/BankTransfers';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { User } from '../../models/User';
import { AuthActionTypes } from '../auth/actions';
import { selectAuthUser } from '../auth/selectors';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  BankTransferActionTypes,
  bankTransferChangeSprActions,
  bankTransferDownloadFileActions,
  bankTransferGetBankTransfersActions,
  bankTransferGetExports,
  bankTransferGetGenerationTimeActions,
  bankTransferGetInvoiceDoc,
  bankTransferRemoveFileActions,
  bankTransferRemoveLinkActions,
  bankTransferUpdateBankTransferActions,
  bankTransferUpdateFileActions,
  bankTransferUpdateGenerationTimeActions,
  bankTransferUpdateLinkActions,
} from './actions';
import { api } from './api';
import {
  selectBankTransferBankTransfersFilterColumn,
  selectBankTransferBankTransfersOrderColumn,
  selectBankTransferBankTransfersPagination,
  selectBankTransferExport,
} from './selectors';

/* STATE */
export interface BankTransferState {
  exports: ExportDate[];
  exportsStatus: LoadingStatus;
  exportDate: ExportDate | null;
  bankTransfers: BankTransfer[];
  bankTransfersPagination: Pages;
  bankTransfersStatus: LoadingStatus;
  bankTransfersOrder: ITableOrder;
  bankTransfersFilter: Record<string, ISearch>;
  generationTime: { id: string; generationTime: string } | null;
}

/* REDUCERS */
const initialState: BankTransferState = {
  exports: [],
  exportsStatus: LoadingStatus.initial,
  exportDate: null,
  bankTransfers: [],
  bankTransfersPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  bankTransfersStatus: LoadingStatus.initial,
  bankTransfersOrder: {
    sort: '',
    order: IOrderStatus.DESC,
  },
  bankTransfersFilter: {},
  generationTime: null,
};

const exports = createReducer(initialState.exports, {
  [BankTransferActionTypes.GetExports]: {
    [RequestActionTypes.SUCCESS]: (state: ExportDate[], payload: ExportDate[]) => payload,
  },
  [BankTransferActionTypes.CleanData]: () => initialState.exports,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.exports,
  },
});

const exportsStatus = createLoadingStateReducer(
  initialState.exportsStatus,
  {
    [BankTransferActionTypes.GetExports]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.exportsStatus,
    },
  }
);

const exportDate = createReducer(initialState.exportDate, {
  [BankTransferActionTypes.SetExport]: (state: ExportDate | null, payload: ExportDate) => payload,
  [BankTransferActionTypes.CleanData]: () => initialState.exportDate,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.exportDate,
  },
});

const bankTransfers = createReducer(initialState.bankTransfers, {
  [BankTransferActionTypes.GetBankTransfers]: {
    [RequestActionTypes.SUCCESS]: (state: BankTransfer[], payload: PaginatedResp<BankTransfer>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.bankTransfers,
  },
  [BankTransferActionTypes.UpdateBankTransfer]: {
    [RequestActionTypes.REQUEST]: (state: BankTransfer[], payload: any) => {
      return state.map((transfer) => {
        if (transfer.id === payload.id) {
          return {
            ...transfer,
            taxableSupplyDate: payload?.taxableSupplyDate ?? transfer.taxableSupplyDate,
            variableSymbol: payload?.variableSymbol ?? transfer.variableSymbol,
          };
        }
        return transfer;
      });
    },
    [RequestActionTypes.FAILURE]: (state: BankTransfer[], payload: any) => {
      return state.map((transfer) => {
        if (transfer.id === payload.id) {
          return {
            ...transfer,
          };
        }
        return transfer;
      });
    },
  },
  [BankTransferActionTypes.ChangeSpr]: {
    [RequestActionTypes.REQUEST]: (
      state: BankTransfer[],
      payload: { id: string; spr: boolean }[]
    ) => {
      const oldArray = [...state];
      payload.map((spr) => {
        const idx = state.findIndex((f) => f.id === spr.id);
        if (idx > -1) {
          oldArray[idx] = {
            ...state[idx],
            spr: spr.spr,
          };
        }
      });
      return oldArray;
    },
    [RequestActionTypes.FAILURE]: (
      state: BankTransfer[],
      payload: { id: string; spr: boolean }[]
    ) => {
      const oldArray = [...state];
      payload.map((spr) => {
        const idx = state.findIndex((f) => f.id === spr.id);
        if (idx > -1) {
          oldArray[idx] = {
            ...state[idx],
            spr: !spr.spr,
          };
        }
      });
      return oldArray;
    },
  },
  [BankTransferActionTypes.UpdateLink]: {
    [RequestActionTypes.SUCCESS]: (state: BankTransfer[], payload: Partial<BankTransfer>) => {
      return state.map((transfer) => {
        if (transfer.id === payload.id) {
          return {
            ...transfer,
            httpsLink: payload.httpsLink,
          };
        }
        return transfer;
      });
    },
  },
  [BankTransferActionTypes.RemoveLink]: {
    [RequestActionTypes.SUCCESS]: (state: BankTransfer[], payload: { id: string }) => {
      return state.map((transfer) => {
        if (transfer.id === payload.id) {
          return {
            ...transfer,
            httpsLink: null,
          };
        }
        return transfer;
      });
    },
  },
  [BankTransferActionTypes.UpdateFile]: {
    [RequestActionTypes.SUCCESS]: (state: BankTransfer[], payload: Partial<BankTransfer>) => {
      return state.map((transfer) => {
        if (transfer.id === payload.id) {
          return {
            ...payload,
          };
        }
        return transfer;
      });
    },
  },
  [BankTransferActionTypes.RemoveFile]: {
    [RequestActionTypes.SUCCESS]: (state: BankTransfer[], payload: { id: string }) => {
      return state.map((transfer) => {
        if (transfer.id === payload.id) {
          return {
            ...transfer,
            filePath: null,
          };
        }
        return transfer;
      });
    },
  },
  [BankTransferActionTypes.CleanData]: () => initialState.bankTransfers,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.bankTransfers,
  },
});

const bankTransfersPagination = createReducer(initialState.bankTransfersPagination, {
  [BankTransferActionTypes.GetBankTransfers]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<BankTransfer>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.bankTransfersPagination,
  },
  [BankTransferActionTypes.SetBankTransfersPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [BankTransferActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [BankTransferActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [BankTransferActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [BankTransferActionTypes.CleanData]: () => initialState.bankTransfersPagination,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.bankTransfersPagination,
  },
});

const bankTransfersStatus = createLoadingStateReducer(
  initialState.bankTransfersStatus,
  {
    [BankTransferActionTypes.GetBankTransfers]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [BankTransferActionTypes.SetBankTransfersPage]: () => initialState.bankTransfersStatus,
    [BankTransferActionTypes.IncreasePage]: () => initialState.bankTransfersStatus,
    [BankTransferActionTypes.SetFilterColumn]: () => initialState.bankTransfersStatus,
    [BankTransferActionTypes.SetOrderColumn]: () => initialState.bankTransfersStatus,
    [BankTransferActionTypes.CleanData]: () => initialState.bankTransfersStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.bankTransfersStatus,
    },
  }
);

const bankTransfersOrder = createReducer(initialState.bankTransfersOrder, {
  [BankTransferActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [BankTransferActionTypes.CleanData]: () => initialState.bankTransfersOrder,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.bankTransfersOrder,
  },
});

const bankTransfersFilter = createReducer(initialState.bankTransfersFilter, {
  [BankTransferActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [BankTransferActionTypes.CleanData]: () => initialState.bankTransfersFilter,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.bankTransfersFilter,
  },
});

const generationTime = createReducer(initialState.generationTime, {
  [BankTransferActionTypes.GetGenerationTime]: {
    [RequestActionTypes.SUCCESS]: (
      state: { id: string; generationTime: string } | null,
      payload: { id: string; generationTime: string }
    ) => payload,
  },
  [BankTransferActionTypes.UpdateGenerationTime]: {
    [RequestActionTypes.SUCCESS]: (
      state: { id: string; generationTime: string } | null,
      payload: { id: string; generationTime: string }
    ) => payload,
  },
  [BankTransferActionTypes.CleanData]: () => initialState.generationTime,
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.generationTime,
  },
});

export default combineReducers<BankTransferState>({
  exports,
  exportsStatus,
  exportDate,
  bankTransfers,
  bankTransfersPagination,
  bankTransfersStatus,
  bankTransfersOrder,
  bankTransfersFilter,
  generationTime,
});

/* SAGAS */
function* getExports() {
  const resp: ExtendedAxiosResponse = yield call(api.getExports);

  if (resp.ok) {
    yield put(bankTransferGetExports.success(toCamelCase(resp.data)?.data || []));
  } else {
    yield put(bankTransferGetExports.failure());
  }
}

function* getBankTransfers() {
  const pagination: Pages = yield select(selectBankTransferBankTransfersPagination);
  const orderColumn: ITableOrder = yield select(selectBankTransferBankTransfersOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(
    selectBankTransferBankTransfersFilterColumn
  );

  const exportDate: ExportDate = yield select(selectBankTransferExport);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const apiRequest = exportDate?.all ? api.getUnpaidBankTransfers : api.getBankTransfers;

  const resp: ExtendedAxiosResponse = yield call(apiRequest, {
    id: `${exportDate?.id}` ?? '',
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(bankTransferGetBankTransfersActions.success(toCamelCase(resp.data)));
  } else {
    yield put(bankTransferGetBankTransfersActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateBankTransfer({ payload }: AppAction<Partial<BankTransfer>>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateBankTransfer, payload);

  if (resp.ok) {
    yield put(
      bankTransferUpdateBankTransferActions.success({
        id: payload.id,
        ...toCamelCase(resp.data)?.data,
      })
    );
  } else {
    yield put(toastCreateErrorActions());
    yield put(bankTransferUpdateBankTransferActions.failure(payload));
  }
}

function* changeSpr({ payload }: AppAction<{ id: string; spr: boolean }[]>) {
  const resp: ExtendedAxiosResponse = yield call(api.changeSpr, payload);

  if (resp.ok) {
    yield put(bankTransferChangeSprActions.success(payload));
  } else {
    yield put(toastCreateErrorActions());
    yield put(bankTransferChangeSprActions.failure(payload));
  }
}

function* getGenerationTime() {
  const resp: ExtendedAxiosResponse = yield call(api.getGenerationTime);

  if (resp.ok) {
    yield put(bankTransferGetGenerationTimeActions.success(toCamelCase(resp.data)));
  } else {
    yield put(bankTransferGetGenerationTimeActions.failure());
    yield put(toastCreateErrorActions());
  }
}

function* updateGenerationTime({
  payload,
}: AppAction<{
  id: string;
  generationTime: string;
}>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateGenerationTime, payload);

  if (resp.ok) {
    yield put(bankTransferUpdateBankTransferActions.success(toCamelCase(resp.data)));
    yield put(toastCreateSuccessActions());
  } else {
    yield put(bankTransferUpdateGenerationTimeActions.failure());
    yield put(toastCreateErrorActions());
  }
}

function* getInvoiceDoc({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getInvoiceDoc, payload);

  if (resp.ok) {
    const href = URL.createObjectURL(resp.data);

    const filename =
      lowercaseObjectKeys(resp.headers)
        ['content-disposition']?.split('filename="')?.[1]
        ?.split('"')?.[0] || '';

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${filename}`); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    yield put(bankTransferGetInvoiceDoc.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(bankTransferGetInvoiceDoc.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* downloadFile({ payload }: AppAction<{ id: string }>) {
  const user: User | null = yield select(selectAuthUser);

  const resp: ExtendedAxiosResponse = yield call(
    user?.tanzanitId ? api.downloadFile : api.adminDownloadFile,
    payload
  );

  if (resp.ok) {
    const href = URL.createObjectURL(resp.data);

    const filename =
      lowercaseObjectKeys(resp.headers)
        ['content-disposition']?.split('filename="')?.[1]
        ?.split('"')?.[0] || '';

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${filename}`); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    yield put(bankTransferDownloadFileActions.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(bankTransferDownloadFileActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateFile({ payload }: AppAction<any>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateFile, payload);

  if (resp.ok) {
    yield put(
      bankTransferUpdateFileActions.success({
        id: payload.id,
        ...toCamelCase(resp.data)?.data,
      })
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(toastCreateErrorActions(resp.data?.message));
    yield put(bankTransferUpdateFileActions.failure(payload));
  }
}

function* removeFile({ payload }: AppAction<{ id: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeFile, payload);

  if (resp.ok) {
    yield put(bankTransferRemoveFileActions.success(payload));
  } else {
    yield put(bankTransferRemoveFileActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateLink({ payload }: AppAction<any>) {
  const resp: ExtendedAxiosResponse = yield call(api.updateLink, payload);

  if (resp.ok) {
    yield put(
      bankTransferUpdateLinkActions.success({
        ...toCamelCase(resp.data).data,
      })
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(toastCreateErrorActions(resp.data?.message));
    yield put(bankTransferUpdateLinkActions.failure());
  }
}

function* removeLink({ payload }: AppAction<{ id: string }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeLink, payload);

  if (resp.ok) {
    yield put(
      bankTransferRemoveLinkActions.success({
        id: payload.id,
      })
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(bankTransferRemoveLinkActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* bankTransferSaga() {
  yield takeLatest(
    createActionType(BankTransferActionTypes.GetExports, RequestActionTypes.REQUEST),
    getExports
  );
  yield takeLatest(
    [
      createActionType(BankTransferActionTypes.GetBankTransfers, RequestActionTypes.REQUEST),
      BankTransferActionTypes.SetBankTransfersPage,
      BankTransferActionTypes.IncreasePage,
      BankTransferActionTypes.SetOrderColumn,
      BankTransferActionTypes.SetFilterColumn,
    ],
    getBankTransfers
  );
  yield takeLatest(
    createActionType(BankTransferActionTypes.UpdateBankTransfer, RequestActionTypes.REQUEST),
    updateBankTransfer
  );
  yield takeLatest(
    createActionType(BankTransferActionTypes.GetGenerationTime, RequestActionTypes.REQUEST),
    getGenerationTime
  );
  yield takeLatest(
    createActionType(BankTransferActionTypes.UpdateGenerationTime, RequestActionTypes.REQUEST),
    updateGenerationTime
  );
  yield takeLatest(
    createActionType(BankTransferActionTypes.ChangeSpr, RequestActionTypes.REQUEST),
    changeSpr
  );
  yield takeLatest(
    createActionType(BankTransferActionTypes.GetInvoiceDoc, RequestActionTypes.REQUEST),
    getInvoiceDoc
  );
  yield takeLatest(
    createActionType(BankTransferActionTypes.DownloadFile, RequestActionTypes.REQUEST),
    downloadFile
  );
  yield takeLatest(
    createActionType(BankTransferActionTypes.UpdateFile, RequestActionTypes.REQUEST),
    updateFile
  );
  yield takeLatest(
    createActionType(BankTransferActionTypes.RemoveFile, RequestActionTypes.REQUEST),
    removeFile
  );
  yield takeLatest(
    createActionType(BankTransferActionTypes.UpdateLink, RequestActionTypes.REQUEST),
    updateLink
  );
  yield takeLatest(
    createActionType(BankTransferActionTypes.RemoveLink, RequestActionTypes.REQUEST),
    removeLink
  );
}
