const buildRoutePart = (route: string, params: Record<string, string>) =>
  route
    .split('/')
    .map((part) => {
      if (part.includes(':')) {
        return params[part.replace(':', '')] || part;
      }

      return part;
    })
    .join('/');

export const buildRoute = (routes: string | string[], params?: Record<string, string>): string => {
  const res = (typeof routes === 'string' ? [routes] : routes)
    .map((route) => {
      if (route.includes(':') && params) {
        return buildRoutePart(route, params);
      }

      return route;
    })
    .join('/');

  return res.startsWith('/') ? res : `/${res}`;
};
