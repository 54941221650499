import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

import { environment } from '../environments/environment';
import { authLogoutActions } from '../saga/auth/actions';
import { buildRoute } from './route/route-builder';
import { AppRoutes } from './route/routes/app-routes';
import { AuthRoutes } from './route/routes/auth-routes';
import { store } from './store/configure-store';
import { history } from './store/root-reducer';

export interface ExtendedAxiosResponse<T = any> {
  ok: boolean;
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: AxiosRequestConfig;
  request?: any;
  error?: any;
}

const { protocol, host, port, basicAuthToken } = environment.api;

export const apiClient = axios.create({
  responseType: 'json',
  baseURL: `${protocol}://${host}${port ? `:${port}` : ''}`,
  withCredentials: true,
  paramsSerializer: (params: any) => {
    return qs.stringify(params);
  },
});

apiClient.interceptors.request.use((config) => {
  config.headers = {
    ...(config.headers || {}),
    'Cache-Control': 'no-cache',
    'Access-Control-Max-Age': 600,
    'Accept-Language': environment.lang,
  };

  if (basicAuthToken) {
    config.headers['BasicAuthorization'] = `Basic ${basicAuthToken}`;
  }

  const token = localStorage.getItem('authToken');

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

apiClient.interceptors.response.use(
  (response) => normalizeSuccessResponse(response),
  (error) => normalizeErrorResponse(error)
);

const normalizeSuccessResponse = (response: AxiosResponse): ExtendedAxiosResponse => {
  return {
    ...response,
    ok: true,
  };
};

const formatAxiosError = (error: any): ExtendedAxiosResponse => {
  return {
    ok: false,
    data: error.response?.data,
    status: error.response?.status,
    statusText: error.response?.statusText,
    headers: error.response?.headers,
    config: error.config,
    request: error.request,
    error,
  };
};

const normalizeErrorResponse = (error: any): ExtendedAxiosResponse => {
  if (!error.response) {
    // Show general error
    return formatAxiosError(error);
  }

  const { data, status, config } = error.response;

  if (status === 500) {
    // Show general error
  }

  // if (status === 401) {
  //   history.push(buildRoute([AppRoutes.Auth, AuthRoutes.Login]));
  //   localStorage.removeItem('authToken');
  //   store.dispatch(authLogoutActions.success());
  // }

  if (environment.debug) {
    console.error(
      `Request failed with status code %c${status}\n`,
      'font-weight: bold;',
      `${config.method?.toUpperCase() || '-'} ${config.url}\n\n`,
      'Request:\n',
      config.data,
      '\nResponse:\n',
      data
    );
  }

  return formatAxiosError(error);
};
