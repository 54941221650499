import { Location } from 'history';
import i18n from 'i18next';
import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';

import { buildRoute } from '../../helpers/route/route-builder';
import { AdminRoutes } from '../../helpers/route/routes/admin-routes';
import { AppRoutes } from '../../helpers/route/routes/app-routes';
import { history } from '../../helpers/store/root-reducer';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { BankAccount } from '../../models/BankAccount';

import { PersistContractForm } from '../../models/Contract';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { contractPersistForm } from '../contract/actions';
import { selectContractPersistForm } from '../contract/selectors';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';
import {
  BankAccountActionTypes,
  bankAccountCreateBankAccountActions,
  bankAccountGetBankAccountActions,
  bankAccountGetBankAccountsActions,
  bankAccountRemoveBankAccountActions,
  bankAccountSetPrimaryBankAccountActions,
  bankAccountUpdateBankAccountActions,
} from './actions';
import { api } from './api';
import {
  selectBankAccountBankAccountsFilterColumn,
  selectBankAccountBankAccountsOrderColumn,
  selectBankAccountBankAccountsPagination,
} from './selectors';

/* STATE */
export interface BankAccountState {
  bankAccounts: BankAccount[];
  bankAccountsPagination: Pages;
  bankAccountsStatus: LoadingStatus;
  bankAccountsOrder: ITableOrder;
  bankAccountsFilter: Record<string, ISearch>;
  bankAccount: BankAccount | null;
  bankAccountStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: BankAccountState = {
  bankAccounts: [],
  bankAccountsPagination: {
    currentPage: 1,
    perPage: 50,
  },
  bankAccountsStatus: LoadingStatus.initial,
  bankAccountsOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  bankAccountsFilter: {},
  bankAccount: null,
  bankAccountStatus: LoadingStatus.initial,
};

const bankAccounts = createReducer(initialState.bankAccounts, {
  [BankAccountActionTypes.GetBankAccounts]: {
    [RequestActionTypes.SUCCESS]: (state: BankAccount[], payload: PaginatedResp<BankAccount>) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.bankAccounts,
  },
  [BankAccountActionTypes.RemoveBankAccount]: {
    [RequestActionTypes.SUCCESS]: (state: BankAccount[], payload: { id: number }) => {
      return state.filter((bankAccount) => bankAccount.id !== payload.id);
    },
  },
  [BankAccountActionTypes.SetPrimaryBankAccount]: {
    [RequestActionTypes.REQUEST]: (state: BankAccount[]) => [...state],
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.bankAccounts,
  },
});

const bankAccountsPagination = createReducer(initialState.bankAccountsPagination, {
  [BankAccountActionTypes.GetBankAccounts]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<BankAccount>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.bankAccountsPagination,
  },
  [BankAccountActionTypes.SetBankAccountsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [BankAccountActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [BankAccountActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [BankAccountActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.bankAccountsPagination,
  },
});

const bankAccountsStatus = createLoadingStateReducer(
  initialState.bankAccountsStatus,
  {
    [BankAccountActionTypes.GetBankAccounts]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [BankAccountActionTypes.SetBankAccountsPage]: () => initialState.bankAccountsStatus,
    [BankAccountActionTypes.IncreasePage]: () => initialState.bankAccountsStatus,
    [BankAccountActionTypes.SetFilterColumn]: () => initialState.bankAccountsStatus,
    [BankAccountActionTypes.SetOrderColumn]: () => initialState.bankAccountsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.bankAccountStatus,
    },
  }
);

const bankAccountsOrder = createReducer(initialState.bankAccountsOrder, {
  [BankAccountActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.bankAccountsOrder,
  },
});

const bankAccountsFilter = createReducer(initialState.bankAccountsFilter, {
  [BankAccountActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.bankAccountsFilter,
  },
});

const bankAccount = createReducer(initialState.bankAccount, {
  [BankAccountActionTypes.CreateBankAccount]: {
    [RequestActionTypes.SUCCESS]: (
      state: BankAccount | null,
      payload: { userId?: number; data: BankAccount }
    ) => ({
      ...state,
      ...payload.data,
    }),
  },
  [BankAccountActionTypes.GetBankAccount]: {
    [RequestActionTypes.REQUEST]: () => initialState.bankAccount,
    [RequestActionTypes.SUCCESS]: (state: BankAccount | null, payload: BankAccount) => payload,
    [RequestActionTypes.FAILURE]: () => initialState.bankAccount,
  },
  [BankAccountActionTypes.UpdateBankAccount]: {
    [RequestActionTypes.SUCCESS]: (state: BankAccount | null, payload: BankAccount) => ({
      ...state,
      ...payload,
    }),
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.bankAccount,
  },
});

const bankAccountStatus = createLoadingStateReducer(
  initialState.bankAccountStatus,
  {
    [BankAccountActionTypes.CreateBankAccount]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
    [BankAccountActionTypes.UpdateBankAccount]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.bankAccountStatus,
    },
  }
);

export default combineReducers<BankAccountState>({
  bankAccounts,
  bankAccountsPagination,
  bankAccountsStatus,
  bankAccountsOrder,
  bankAccountsFilter,
  bankAccount,
  bankAccountStatus,
});

/* SAGAS */
function* getBankAccounts() {
  const pagination: Pages = yield select(selectBankAccountBankAccountsPagination);
  const orderColumn: ITableOrder = yield select(selectBankAccountBankAccountsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(
    selectBankAccountBankAccountsFilterColumn
  );

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getBankAccounts, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(bankAccountGetBankAccountsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(bankAccountGetBankAccountsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createBankAccount({
  payload,
}: AppAction<{
  id: number;
  userId?: number;
  companyId?: number;
  clientId?: number;
  location: Location;
}>) {
  const { location, ...data } = payload;
  const resp: ExtendedAxiosResponse = yield call(api.createBankAccount, data);

  if (resp.ok) {
    const data = toCamelCase(resp.data)?.data;
    yield put(
      bankAccountCreateBankAccountActions.success({
        userId: payload.userId,
        companyId: payload.companyId,
        data,
      })
    );
    yield put(
      toastCreateSuccessActions(
        resp.data?.message || i18n.t('components.successToast.requestSuccess')
      )
    );

    const locationState = location?.state as any;

    if (!location?.pathname.includes(AppRoutes.Admin)) {
      if (locationState?.from === 'contracts') {
        const persistedForm: PersistContractForm | null = yield select(selectContractPersistForm);

        if (persistedForm?.contractType === 'FO' || persistedForm?.contractType === 'PO') {
          yield put(
            contractPersistForm({
              ...persistedForm,
              bankAccountId: data?.id,
            })
          );
        }

        history.push(buildRoute(AppRoutes.ContractCreate));
      } else if (payload?.companyId) {
        history.push(
          buildRoute(AppRoutes.CompanyDetail, {
            companyId: payload.companyId.toString(),
          })
        );
      } else {
        history.push(buildRoute([AppRoutes.BankAccounts]));
      }
    } else {
      if (payload?.companyId && payload?.userId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.RespondentsCompanyDetail], {
            companyId: payload.companyId.toString(),
            respondentId: payload.userId.toString(),
          })
        );
      } else if (payload?.companyId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.CompaniesDetail], {
            companyId: payload.companyId.toString(),
          })
        );
      } else if (payload?.userId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.RespondentsDetail], {
            respondentId: payload.userId.toString(),
          })
        );
      } else if (payload?.clientId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.ClientsDetail], {
            clientId: payload.clientId?.toString(),
          })
        );
      } else {
        history.push(buildRoute([AppRoutes.BankAccounts]));
      }
    }
  } else {
    yield put(bankAccountCreateBankAccountActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getBankAccount({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getBankAccount, payload);

  if (resp.ok) {
    yield put(bankAccountGetBankAccountActions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(bankAccountGetBankAccountActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* updateBankAccount({
  payload,
}: AppAction<{
  id: number;
  userId?: number;
  companyId?: number;
  clientId?: number;
  location?: Location;
}>) {
  const { location, ...data } = payload;
  const resp: ExtendedAxiosResponse = yield call(api.updateBankAccount, data);

  if (resp.ok) {
    yield put(bankAccountUpdateBankAccountActions.success(toCamelCase(resp.data)?.data));
    yield put(
      toastCreateSuccessActions(
        resp.data?.message || i18n.t('components.successToast.requestSuccess')
      )
    );

    if (!location?.pathname.includes(AppRoutes.Admin)) {
      if (payload?.companyId) {
        history.push(
          buildRoute(AppRoutes.CompanyDetail, {
            companyId: payload.companyId.toString(),
          })
        );
      } else {
        history.push(buildRoute([AppRoutes.BankAccounts]));
      }
    } else {
      if (payload?.companyId && payload?.userId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.RespondentsCompanyDetail], {
            companyId: payload.companyId.toString(),
            respondentId: payload.userId.toString(),
          })
        );
      } else if (payload?.companyId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.CompaniesDetail], {
            companyId: payload.companyId.toString(),
          })
        );
      } else if (payload?.userId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.RespondentsDetail], {
            respondentId: payload.userId.toString(),
          })
        );
      } else if (payload?.clientId) {
        history.push(
          buildRoute([AppRoutes.Admin, AdminRoutes.ClientsDetail], {
            clientId: payload.clientId?.toString(),
          })
        );
      } else {
        history.push(buildRoute([AppRoutes.BankAccounts]));
      }
    }
  } else {
    yield put(bankAccountUpdateBankAccountActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeBankAccount({
  payload,
}: AppAction<{ id: number; userId?: number; companyId?: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeBankAccount, payload);

  if (resp.ok) {
    yield put(bankAccountRemoveBankAccountActions.success({ id: payload.id }));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(bankAccountRemoveBankAccountActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* setPrimaryBankAccount({
  payload,
}: AppAction<{
  bankAccountId: number;
  typeName: string;
  bank: string;
  iban: string;
  companyId?: number;
}>) {
  const resp: ExtendedAxiosResponse = yield call(api.setPrimaryBankAccount, payload);

  if (resp.ok) {
    yield put(bankAccountSetPrimaryBankAccountActions.success());
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(bankAccountSetPrimaryBankAccountActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* bankAccountSaga() {
  yield takeLatest(
    [
      createActionType(BankAccountActionTypes.GetBankAccounts, RequestActionTypes.REQUEST),
      BankAccountActionTypes.SetBankAccountsPage,
      BankAccountActionTypes.IncreasePage,
      BankAccountActionTypes.SetOrderColumn,
      BankAccountActionTypes.SetFilterColumn,
    ],
    getBankAccounts
  );
  yield takeLatest(
    [createActionType(BankAccountActionTypes.RemoveBankAccount, RequestActionTypes.REQUEST)],
    removeBankAccount
  );
  yield takeLatest(
    createActionType(BankAccountActionTypes.CreateBankAccount, RequestActionTypes.REQUEST),
    createBankAccount
  );
  yield takeLatest(
    createActionType(BankAccountActionTypes.GetBankAccount, RequestActionTypes.REQUEST),
    getBankAccount
  );
  yield takeLatest(
    createActionType(BankAccountActionTypes.UpdateBankAccount, RequestActionTypes.REQUEST),
    updateBankAccount
  );
  yield takeLatest(
    [createActionType(BankAccountActionTypes.SetPrimaryBankAccount, RequestActionTypes.REQUEST)],
    setPrimaryBankAccount
  );
}
