import { LoadingStatus } from '../../helpers/redux/redux-helpers';
import { AppState } from '../../helpers/store/AppState';
import { Pages } from '../../models/Pages';
import { ProjectRespondent } from '../../models/ProjectRespondent';
import { ISearch, ITableOrder } from '../../models/Table';
import { ProjectRespondentState } from './ducks';

/* SELECTORS */
const selectProjectRespondent = (state: AppState): ProjectRespondentState =>
  state.projectRespondent;

export const selectProjectRespondentProjectRespondents = (state: AppState): ProjectRespondent[] =>
  selectProjectRespondent(state).projectRespondents;

export const selectProjectRespondentProjectRespondentsPagination = (state: AppState): Pages =>
  selectProjectRespondent(state).projectRespondentsPagination;

export const selectProjectRespondentProjectRespondentsStatus = (state: AppState): LoadingStatus =>
  selectProjectRespondent(state).projectRespondentsStatus;

export const selectProjectRespondentProjectRespondentsOrderColumn = (
  state: AppState
): ITableOrder => selectProjectRespondent(state).projectRespondentsOrder;

export const selectProjectRespondentProjectRespondentsFilterColumn = (
  state: AppState
): Record<string, ISearch> => selectProjectRespondent(state).projectRespondentsFilter;

export const selectProjectRespondentClients = (state: AppState): { id: string; name: string }[] =>
  selectProjectRespondent(state).clients;

export const selectProjectRespondentClientsStatus = (state: AppState): LoadingStatus =>
  selectProjectRespondent(state).clientsStatus;

export const selectProjectRespondentClientInvoices = (
  state: AppState
): { id: string; name: string }[] => selectProjectRespondent(state).clientInvoices;

export const selectProjectRespondentClientInvoicesStatus = (state: AppState): LoadingStatus =>
  selectProjectRespondent(state).clientInvoicesStatus;

export const selectProjectRespondentProjectId = (state: AppState): string =>
  selectProjectRespondent(state).currentProjectId;

export const selectProjectRespondentAssignRespondentStatus = (state: AppState): LoadingStatus =>
  selectProjectRespondent(state).assignRespondentStatus;
