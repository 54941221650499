import React from 'react';

const Loading = ({
  classNames = '',
  color = 'bg-gray-75',
}: {
  classNames?: string;
  color?: string;
}) => {
  return (
    <div
      className={`flex items-center justify-center space-x-2 animate-pulse w-full py-4 ${classNames}`}
    >
      <div className={`w-4 h-4 rounded-full ${color}`}></div>
      <div className={`w-4 h-4 rounded-full ${color}`}></div>
      <div className={`w-4 h-4 rounded-full ${color}`}></div>
    </div>
  );
};

export default Loading;
