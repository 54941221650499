import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

export enum ProjectProtocolActionTypes {
  GetProtocols = '@@ProjectProtocol/GET_PROTOCOLS',
  SetProtocolsPage = '@@ProjectProtocol/SET_PROTOCOLS_PAGE',
  IncreasePage = '@@ProjectProtocol/INCREASE_PAGE',
  SetOrderColumn = '@@ProjectProtocol/SET_ORDER_COLUMN',
  SetFilterColumn = '@@ProjectProtocol/SET_FILTER_COLUMNS',
  CleanData = '@@ProjectProtocol/CLEAN_DATA',
}

/* ACTIONS */
export const projectProtocolGetProtocolsActions = createApiActionCreators(
  ProjectProtocolActionTypes.GetProtocols
);
export const projectProtocolSetProtocolsPageActions = createActionCreator(
  ProjectProtocolActionTypes.SetProtocolsPage
);
export const projectProtocolIncreasePageActions = createActionCreator(
  ProjectProtocolActionTypes.IncreasePage
);
export const projectProtocolSetOrderColumnActions = createActionCreator(
  ProjectProtocolActionTypes.SetOrderColumn
);
export const projectProtocolSetFilterColumnActions = createActionCreator(
  ProjectProtocolActionTypes.SetFilterColumn
);

export const projectProtocolCleanData = createActionCreator(ProjectProtocolActionTypes.CleanData);
