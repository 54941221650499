import dayjs from 'dayjs';
import { omit } from 'lodash';

import { combineReducers } from 'redux';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase, toSnakeCase } from '../../helpers/transformObject';
import { ArchivedProject } from '../../models/ArchivedProject';
import { Pages, PaginatedResp } from '../../models/Pages';
import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions } from '../toast/actions';
import {
  ArchivedProjectActionTypes,
  archivedProjectGetArchivedProject,
  archivedProjectGetArchivedProjects,
} from './actions';
import { api } from './api';
import {
  selectArchivedProjectProjects,
  selectArchivedProjectProjectsFilterColumn,
  selectArchivedProjectProjectsOrderColumn,
  selectArchivedProjectProjectsPagination,
} from './selectors';

/* STATE */
export interface ArchivedProjectState {
  projects: ArchivedProject[] | null;
  projectsPagination: Pages;
  projectsStatus: LoadingStatus;
  projectsOrder: ITableOrder;
  projectsFilter: Record<string, ISearch>;
  project: ArchivedProject | null;
  projectStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: ArchivedProjectState = {
  projects: null,
  projectsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  projectsStatus: LoadingStatus.initial,
  projectsOrder: {
    sort: 'id',
    order: IOrderStatus.ASC,
  },
  projectsFilter: {},
  project: null,
  projectStatus: LoadingStatus.initial,
};

const projects = createReducer(initialState.projects, {
  [ArchivedProjectActionTypes.GetArchivedProjects]: {
    [RequestActionTypes.SUCCESS]: (
      state: ArchivedProject[],
      payload: PaginatedResp<ArchivedProject>
    ) => payload.data,
    [RequestActionTypes.FAILURE]: () => initialState.projects,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projects,
  },
});

const projectsPagination = createReducer(initialState.projectsPagination, {
  [ArchivedProjectActionTypes.GetArchivedProjects]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<ArchivedProject>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.projectsPagination,
  },
  [ArchivedProjectActionTypes.SetArchivedProjectPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [ArchivedProjectActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [ArchivedProjectActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ArchivedProjectActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectsPagination,
  },
});

const projectsStatus = createLoadingStateReducer(
  initialState.projectsStatus,
  {
    [ArchivedProjectActionTypes.GetArchivedProjects]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ArchivedProjectActionTypes.SetArchivedProjectPage]: () => initialState.projectsStatus,
    [ArchivedProjectActionTypes.IncreasePage]: () => initialState.projectsStatus,
    [ArchivedProjectActionTypes.SetFilterColumn]: () => initialState.projectsStatus,
    [ArchivedProjectActionTypes.SetOrderColumn]: () => initialState.projectsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.projectsStatus,
    },
  }
);

const projectsOrder = createReducer(initialState.projectsOrder, {
  [ArchivedProjectActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectsOrder,
  },
});

const projectsFilter = createReducer(initialState.projectsFilter, {
  [ArchivedProjectActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectsFilter,
  },
});

const project = createReducer(initialState.project, {
  [ArchivedProjectActionTypes.GetArchivedProject]: {
    [RequestActionTypes.REQUEST]: () => initialState.project,
    [RequestActionTypes.SUCCESS]: (state: ArchivedProject | null, payload: ArchivedProject) =>
      payload,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.project,
  },
});

const projectStatus = createLoadingStateReducer(
  initialState.projectStatus,
  {
    [ArchivedProjectActionTypes.GetArchivedProject]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState,
    },
  }
);

export default combineReducers<ArchivedProjectState>({
  projects,
  projectsPagination,
  projectsStatus,
  projectsOrder,
  projectsFilter,
  project,
  projectStatus,
});

/* SAGAS */
function* getProjects() {
  const pagination: Pages = yield select(selectArchivedProjectProjectsPagination);
  const orderColumn: ITableOrder = yield select(selectArchivedProjectProjectsOrderColumn);
  const filterColumn: Record<string, ISearch> = yield select(
    selectArchivedProjectProjectsFilterColumn
  );

  const stringFromFilter = `project_end:<:${dayjs().format('YYYY-MM-DD')},${formatFilterToString(
    filterColumn
  )}`;

  const resp: ExtendedAxiosResponse = yield call(api.getArchivedProjects, {
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    const data = toCamelCase(resp.data);

    if (data.currentPage <= 1) {
      yield put(archivedProjectGetArchivedProjects.success(data));
    } else {
      const prevProjects: ArchivedProject[] = yield select(selectArchivedProjectProjects);
      yield put(
        archivedProjectGetArchivedProjects.success({
          ...data,
          data: [...prevProjects, ...data.data],
        })
      );
    }
  } else {
    yield put(archivedProjectGetArchivedProjects.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getProject({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.getArchivedProject, toSnakeCase(payload));

  if (resp.ok) {
    yield put(archivedProjectGetArchivedProject.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(archivedProjectGetArchivedProject.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

/* EXPORT */
export function* archivedProjectSaga() {
  yield takeLatest(
    [
      createActionType(ArchivedProjectActionTypes.GetArchivedProjects, RequestActionTypes.REQUEST),
      ArchivedProjectActionTypes.SetArchivedProjectPage,
      ArchivedProjectActionTypes.IncreasePage,
      ArchivedProjectActionTypes.SetOrderColumn,
      ArchivedProjectActionTypes.SetFilterColumn,
    ],
    getProjects
  );
  yield takeLatest(
    createActionType(ArchivedProjectActionTypes.GetArchivedProject, RequestActionTypes.REQUEST),
    getProject
  );
}
