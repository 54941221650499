import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Menu, Transition } from '@headlessui/react';

import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as LockedIcon } from '../../../assets/icons/lock.svg';
import { Permission } from '../../../helpers/route/route';
import { selectAuthUser } from '../../../saga/auth/selectors';

const MenuDropdown = ({ options, label }: any) => {
  const user = useSelector(selectAuthUser);

  const { t } = useTranslation();

  return (
    <Menu as={'div'} className={'relative'}>
      <>
        <Menu.Button
          className={
            'inline-flex w-full rounded-md items-center ' +
            'bg-white py-2 font-normal font-sans text-sm text-secondary ' +
            'hover:bg-opacity-30 ' +
            'focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
          }
        >
          <>
            {t(label)}
            <ArrowDown width={12} height={6} className={'stroke-secondary ml-1'} />
          </>
        </Menu.Button>
      </>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={
            'absolute right-0 mt-2 w-max origin-top-right divide-y divide-gray-100 rounded-md ' +
            'bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          }
        >
          <div className="px-1 py-1 ">
            {Object.keys(options).map((item) => {
              const hasPermission =
                user?.permissions?.findIndex(
                  (perm) => perm.name == options[item].permission || perm.name === Permission.grant
                ) !== -1 || !options[item]?.permission;

              return (
                <Menu.Item key={item} disabled={!hasPermission}>
                  {({ active }) => (
                    <Link
                      to={options[item].to}
                      className={`${
                        hasPermission
                          ? active
                            ? 'bg-primary text-white'
                            : 'text-secondary'
                          : 'pointer-events-none text-gray-75'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm justify-between`}
                    >
                      <>
                        {t(options[item].label)}
                        {!hasPermission && (
                          <LockedIcon
                            width={14}
                            height={14}
                            className={`ml-2 ${active ? 'stroke-white' : 'stroke-gray-75'}`}
                          />
                        )}
                      </>
                    </Link>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default MenuDropdown;
