import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import { GetProfessionsPayload } from './api';

/* ACTION TYPES */
export enum ProfessionActionTypes {
  GetProfessions = '@@Profession/GET_PROFESSIONS',
  SetProfessionsPage = '@@Profession/SET_PROFESSIONS_PAGE',
  IncreasePage = '@@Profession/INCREASE_PAGE',
  SetOrderColumn = '@@Profession/SET_ORDER_COLUMN',
  SetFilterColumn = '@@Profession/SET_FILTER_COLUMNS',
}

/* ACTIONS */
export const professionGetProfessionsActions = createApiActionCreators<GetProfessionsPayload>(
  ProfessionActionTypes.GetProfessions
);
export const professionSetProfessionsPage = createActionCreator(
  ProfessionActionTypes.SetProfessionsPage
);
export const professionIncreasePageActions = createActionCreator(
  ProfessionActionTypes.IncreasePage
);
export const professionSetOrderColumnActions = createActionCreator(
  ProfessionActionTypes.SetOrderColumn
);
export const professionSetFilterColumnActions = createActionCreator(
  ProfessionActionTypes.SetFilterColumn
);
