import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';

export enum ClientsDataOverviewActionTypes {
  GetInvoices = '@@ClientsDataOverview/GET_INVOICES',
  SetInvoice = '@@ClientsDataOverview/SET_INVOICE',
  GetProjectRespondents = '@@ClientsDataOverview/GET_PROJECT_RESPONDENTS',
  SetProjectRespondentsPage = '@@ClientsDataOverview/SET_PROJECT_RESPONDENTS_PAGE',
  IncreasePage = '@@ClientsDataOverview/INCREASE_PROJECT_RESPONDENTS',
  SetOrderColumn = '@@ClientsDataOverview/SET_ORDER_COLUMN',
  SetFilterColumn = '@@ClientsDataOverview/SET_FILTER_COLUMNS',
  CleanData = '@@ClientsDataOverview/CLEAN_DATA',
  GetClients = '@@ClientsDataOverview/GET_CLIENTS',
  GetProjects = '@@ClientsDataOverview/GET_PROJECTS',
}

/* ACTIONS */
export const clientsDataOverviewGetProjectRespondentsActions = createApiActionCreators(
  ClientsDataOverviewActionTypes.GetProjectRespondents
);
export const clientsDataOverviewSetProjectRespondentsPageActions = createActionCreator(
  ClientsDataOverviewActionTypes.SetProjectRespondentsPage
);
export const clientsDataOverviewIncreasePageActions = createActionCreator(
  ClientsDataOverviewActionTypes.IncreasePage
);
export const clientsDataOverviewSetOrderColumnActions = createActionCreator(
  ClientsDataOverviewActionTypes.SetOrderColumn
);
export const clientsDataOverviewSetFilterColumnActions = createActionCreator(
  ClientsDataOverviewActionTypes.SetFilterColumn
);

export const clientsDataOverviewGetInvoices = createApiActionCreators(
  ClientsDataOverviewActionTypes.GetInvoices
);

export const clientsDataOverviewSetInvoice = createActionCreator(
  ClientsDataOverviewActionTypes.SetInvoice
);

export const clientsDataOverviewCleanData = createActionCreator(
  ClientsDataOverviewActionTypes.CleanData
);

export const clientsDataOverviewGetClients = createApiActionCreators(
  ClientsDataOverviewActionTypes.GetClients
);

export const clientsDataOverviewGetProjects = createApiActionCreators(
  ClientsDataOverviewActionTypes.GetProjects
);
