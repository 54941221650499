import { createActionCreator, createApiActionCreators } from '../../helpers/redux/redux-helpers';
import { TUserUpdatePayload } from './api';

/* ACTION TYPES */
export enum UserActionTypes {
  GetUsers = '@@User/GET_USERS',
  SetUsersPage = '@@User/SET_USERS_PAGE',
  IncreasePage = '@@User/INCREASE_USERS',
  SetOrderColumn = '@@User/SET_ORDER_COLUMN',
  SetFilterColumn = '@@User/SET_FILTER_COLUMNS',
  RemoveUser = '@@User/REMOVE_USER',
  GetUserProfile = '@@User/GET_USER_PROFILE',
  ManagePermissions = '@@User/MANAGE_PERMISSIONS',
  UpdateUserProfile = '@@User/UPDATE_USER_PROFILE',
  SendChangeEmailRequest = '@@User/SEND_CHANGE_EMAIL_REQUEST',
  ChangeEmail = '@@User/CHANGE_EMAIL',
  VerifyEmail = '@@User/VERIFY_EMAIL',
  CreateUser = '@@User/CREATE_USER',
  ClearUser = '@@User/CLEAR_USER',
}

/* ACTIONS */
export const userGetUsersActions = createApiActionCreators(UserActionTypes.GetUsers);
export const userSetUsersPageActions = createActionCreator(UserActionTypes.SetUsersPage);
export const userIncreasePageActions = createActionCreator(UserActionTypes.IncreasePage);
export const userSetOrderColumnActions = createActionCreator(UserActionTypes.SetOrderColumn);
export const userSetFilterColumnActions = createActionCreator(UserActionTypes.SetFilterColumn);

export const userRemoveUserActions = createApiActionCreators(UserActionTypes.RemoveUser);

export const userGetUserProfileActions = createApiActionCreators(UserActionTypes.GetUserProfile);
export const userManagePermissionsActions = createApiActionCreators(
  UserActionTypes.ManagePermissions
);
export const userUpdateUserProfileActions = createApiActionCreators<TUserUpdatePayload>(
  UserActionTypes.UpdateUserProfile
);

export const userSendChangeEmailRequestActions = createApiActionCreators(
  UserActionTypes.SendChangeEmailRequest
);
export const userChangeEmailActions = createApiActionCreators(UserActionTypes.ChangeEmail);
export const userVerifyEmailActions = createApiActionCreators(UserActionTypes.VerifyEmail);
export const userCreateUserActions = createApiActionCreators(UserActionTypes.CreateUser);

export const userClearUser = createActionCreator(UserActionTypes.ClearUser);
