import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Menu, Transition } from '@headlessui/react';

import { ReactComponent as ArrowDown } from '../../../../../assets/icons/arrow-down.svg';
import { myProfile } from '../../../../../mock/Menu';

import Logout from '../../../../components/Logout/Logout';

const MyProfileDropdown = () => {
  const { t } = useTranslation();

  return (
    <Menu as={'div'} className={'relative'} data-testid={'myProfile'}>
      <>
        <Menu.Button
          className={
            'inline-flex w-full rounded-md items-center ' +
            'bg-white py-2 font-normal font-sans text-sm text-secondary border border-gray-50 px-3 ' +
            'hover:bg-opacity-30 ' +
            'focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
          }
        >
          <div className={'flex w-full items-center'}>
            {t('layout.appHeader.myProfile.label')}
            <ArrowDown width={12} height={6} className={'stroke-secondary ml-1'} />
          </div>
        </Menu.Button>
      </>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={
            'absolute right-0 mt-2 w-max origin-top-right divide-y divide-gray-100 rounded-md ' +
            'bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none min-w-full'
          }
        >
          <div className="px-1 py-1 ">
            {Object.keys(myProfile).map((item) => {
              return (
                <Menu.Item key={item}>
                  {({ active }) => (
                    <Link
                      to={myProfile[item].to}
                      className={`${
                        active ? 'bg-primary text-white' : 'text-secondary'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm justify-between`}
                    >
                      {t(myProfile[item].label)}
                    </Link>
                  )}
                </Menu.Item>
              );
            })}
            <Menu.Item>
              {({ active }) => (
                <div
                  className={`${
                    active ? 'bg-primary text-white' : 'text-secondary'
                  } group flex w-full items-center rounded-md text-sm justify-between cursor-pointer`}
                >
                  <Logout />
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default MyProfileDropdown;
