import { all, fork } from 'redux-saga/effects';

import { addressSaga } from '../../saga/address/ducks';
import { archivedProjectSaga } from '../../saga/archivedProject/ducks';
import { assignedProjectSaga } from '../../saga/assignedProject/ducks';
import { authSaga } from '../../saga/auth/ducks';
import { bankSaga } from '../../saga/bank/ducks';
import { bankAccountSaga } from '../../saga/bankAccount/ducks';
import { bankTransferSaga } from '../../saga/bankTransfer/ducks';
import { citySaga } from '../../saga/city/ducks';
import { clientSaga } from '../../saga/client/ducks';
import { clientInvoiceSaga } from '../../saga/clientInvoice/ducks';
import { clientProtocolSaga } from '../../saga/clientProtocol/ducks';
import { clientsDataOverviewSaga } from '../../saga/clientsDataOverview/ducks';
import { companySaga } from '../../saga/company/ducks';
import { contactSaga } from '../../saga/contact/ducks';
import { contractSaga } from '../../saga/contract/ducks';
import { districtSaga } from '../../saga/district/ducks';
import { errorSaga } from '../../saga/error/ducks';
import { handoverProtocolSaga } from '../../saga/handoverProtocol/ducks';
import { importRespondentSaga } from '../../saga/importRespondent/ducks';
import { invoiceCompanySaga } from '../../saga/invoiceCompany/ducks';
import { nonProjectHandoverProtocolSaga } from '../../saga/nonProjectHandoverProtocol/ducks';
import { professionSaga } from '../../saga/professions/ducks';
import { projectSaga } from '../../saga/project/ducks';
import { projectProtocolSaga } from '../../saga/projectProtocol/ducks';
import { projectRespondentSaga } from '../../saga/projectRespondent/ducks';
import { projectsDataOverviewSaga } from '../../saga/projectsDataOverview/ducks';
import { regionSaga } from '../../saga/region/ducks';
import { respondentSaga } from '../../saga/respondent/ducks';
import { specSaga } from '../../saga/specs/ducks';
import { surveySaga } from '../../saga/survey/ducks';
import { toastSaga } from '../../saga/toast/ducks';
import { userSaga } from '../../saga/user/ducks';
import { winnerSaga } from '../../saga/winner/ducks';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(errorSaga),
    fork(toastSaga),
    fork(companySaga),
    fork(invoiceCompanySaga),
    fork(projectSaga),
    fork(districtSaga),
    fork(regionSaga),
    fork(citySaga),
    fork(contractSaga),
    fork(bankAccountSaga),
    fork(respondentSaga),
    fork(clientSaga),
    fork(surveySaga),
    fork(addressSaga),
    fork(assignedProjectSaga),
    fork(archivedProjectSaga),
    fork(clientInvoiceSaga),
    fork(projectRespondentSaga),
    fork(projectsDataOverviewSaga),
    fork(bankTransferSaga),
    fork(clientsDataOverviewSaga),
    fork(projectProtocolSaga),
    fork(clientProtocolSaga),
    fork(bankSaga),
    fork(importRespondentSaga),
    fork(handoverProtocolSaga),
    fork(nonProjectHandoverProtocolSaga),
    fork(specSaga),
    fork(professionSaga),
    fork(contactSaga),
    fork(winnerSaga),
  ]);
}
