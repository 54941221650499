import i18n from 'i18next';
import { omit } from 'lodash';
import { combineReducers } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { environment } from '../../environments/environment';
import { ExtendedAxiosResponse } from '../../helpers/api-client';
import {
  AppAction,
  createActionType,
  createLoadingStateReducer,
  createReducer,
  LoadingStatus,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { formatFilterToString } from '../../helpers/table';
import { toCamelCase } from '../../helpers/transformObject';
import { Client } from '../../models/Client';
import { ClientInvoice } from '../../models/ClientInvoice';
import { Pages, PaginatedResp } from '../../models/Pages';
import { ProjectRespondent } from '../../models/ProjectRespondent';

import { IOrderStatus, ISearch, ITableOrder } from '../../models/Table';
import { AuthActionTypes } from '../auth/actions';
import { toastCreateErrorActions, toastCreateSuccessActions } from '../toast/actions';

import {
  ProjectRespondentActionTypes,
  projectRespondentCreateProjectRespondentActions,
  projectRespondentGetClientInvoicesActions,
  projectRespondentGetClientsActions,
  projectRespondentGetProjectRespondentsActions,
  projectRespondentRemoveMultipleProjectRespondentsActions,
  projectRespondentRemoveProjectRespondentActions,
} from './actions';
import { api, ProjectRespondentPayload } from './api';
import {
  selectProjectRespondentProjectId,
  selectProjectRespondentProjectRespondentsFilterColumn,
  selectProjectRespondentProjectRespondentsOrderColumn,
  selectProjectRespondentProjectRespondentsPagination,
} from './selectors';

/* STATE */
export interface ProjectRespondentState {
  projectRespondents: ProjectRespondent[];
  projectRespondentsPagination: Pages;
  projectRespondentsStatus: LoadingStatus;
  projectRespondentsOrder: ITableOrder;
  projectRespondentsFilter: Record<string, ISearch>;
  clients: { id: string; name: string }[];
  clientsStatus: LoadingStatus;
  currentProjectId: string;
  clientInvoices: { id: string; name: string }[];
  clientInvoicesStatus: LoadingStatus;
  assignRespondentStatus: LoadingStatus;
}

/* REDUCERS */
const initialState: ProjectRespondentState = {
  projectRespondents: [],
  projectRespondentsPagination: {
    currentPage: 1,
    perPage: environment.defaultPagination.perPage,
  },
  projectRespondentsStatus: LoadingStatus.initial,
  projectRespondentsOrder: {
    sort: '',
    order: IOrderStatus.DESC,
  },
  projectRespondentsFilter: {},
  clients: [],
  clientsStatus: LoadingStatus.initial,
  currentProjectId: '',
  clientInvoices: [],
  clientInvoicesStatus: LoadingStatus.initial,
  assignRespondentStatus: LoadingStatus.initial,
};

const projectRespondents = createReducer(initialState.projectRespondents, {
  [ProjectRespondentActionTypes.GetProjectRespondents]: {
    [RequestActionTypes.SUCCESS]: (
      state: ProjectRespondent[],
      payload: PaginatedResp<ProjectRespondent>
    ) => {
      if (payload.currentPage == 1) {
        return payload.data;
      }
      return [...state, ...payload?.data];
    },
    [RequestActionTypes.FAILURE]: () => initialState.projectRespondents,
  },
  [ProjectRespondentActionTypes.RemoveProjectRespondent]: {
    [RequestActionTypes.SUCCESS]: (state: ProjectRespondent[], payload: { id: number }) => {
      return state.filter((projectRespondent) => projectRespondent.id !== payload.id);
    },
  },
  [ProjectRespondentActionTypes.RemoveMultipleProjectRespondents]: {
    [RequestActionTypes.SUCCESS]: (state: ProjectRespondent[], payload: { id: number[] }) => {
      return state.filter((projectRespondent) => payload.id.indexOf(projectRespondent.id) < 0);
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectRespondents,
  },
});

const projectRespondentsPagination = createReducer(initialState.projectRespondentsPagination, {
  [ProjectRespondentActionTypes.GetProjectRespondents]: {
    [RequestActionTypes.SUCCESS]: (state: Pages, payload: PaginatedResp<ProjectRespondent>) =>
      omit(payload, 'data'),
    [RequestActionTypes.FAILURE]: () => initialState.projectRespondentsPagination,
  },
  [ProjectRespondentActionTypes.SetProjectRespondentsPage]: (state: Pages, payload: Pages) => {
    return {
      ...state,
      ...payload,
    };
  },
  [ProjectRespondentActionTypes.IncreasePage]: (state: Pages) => ({
    ...state,
    currentPage: state.currentPage + 1,
  }),
  [ProjectRespondentActionTypes.SetOrderColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [ProjectRespondentActionTypes.SetFilterColumn]: (state: Pages) => ({
    ...state,
    currentPage: 1,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectRespondentsPagination,
  },
});

const projectRespondentsStatus = createLoadingStateReducer(
  initialState.projectRespondentsStatus,
  {
    [ProjectRespondentActionTypes.GetProjectRespondents]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [ProjectRespondentActionTypes.SetProjectRespondentsPage]: () =>
      initialState.projectRespondentsStatus,
    [ProjectRespondentActionTypes.IncreasePage]: () => initialState.projectRespondentsStatus,
    [ProjectRespondentActionTypes.SetFilterColumn]: () => initialState.projectRespondentsStatus,
    [ProjectRespondentActionTypes.SetOrderColumn]: () => initialState.projectRespondentsStatus,
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.projectRespondentsStatus,
    },
  }
);

const projectRespondentsOrder = createReducer(initialState.projectRespondentsOrder, {
  [ProjectRespondentActionTypes.SetOrderColumn]: (state: ITableOrder, payload: ITableOrder) => ({
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectRespondentsOrder,
  },
});

const projectRespondentsFilter = createReducer(initialState.projectRespondentsFilter, {
  [ProjectRespondentActionTypes.SetFilterColumn]: (
    state: Record<string, string>,
    payload: Record<string, string>
  ) => ({
    ...state,
    ...payload,
  }),
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.projectRespondentsFilter,
  },
});

const clients = createReducer(initialState.clients, {
  [ProjectRespondentActionTypes.GetClients]: {
    [RequestActionTypes.SUCCESS]: (state: Client[], payload: Client[]) =>
      payload?.reduce((acc: { id: string; name: string }[], i) => {
        acc.push({
          id: `${i.id}`,
          name: i.name,
        });
        return acc;
      }, []) || initialState.clients,
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clients,
  },
});

const clientsStatus = createLoadingStateReducer(
  initialState.clientsStatus,
  {
    [ProjectRespondentActionTypes.GetClients]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.clientsStatus,
    },
  }
);

const currentProjectId = createReducer(initialState.currentProjectId, {
  [ProjectRespondentActionTypes.SetProjectId]: (state: string, payload: { id: string }) => {
    return payload?.id || '';
  },
});

const clientInvoices = createReducer(initialState.clientInvoices, {
  [ProjectRespondentActionTypes.GetClientInvoices]: {
    [RequestActionTypes.SUCCESS]: (state: ClientInvoice[], payload: { data: ClientInvoice[] }) => {
      return (
        payload?.data?.reduce((acc: { id: string; name: string }[], i) => {
          acc.push({
            id: `${i.id}`,
            name: i.invoiceNumber,
          });
          return acc;
        }, []) || initialState.clientInvoices
      );
    },
  },
  [AuthActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: () => initialState.clientInvoices,
  },
});

const clientInvoicesStatus = createLoadingStateReducer(
  initialState.clientInvoicesStatus,
  {
    [ProjectRespondentActionTypes.GetClientInvoices]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.clientInvoicesStatus,
    },
  }
);

const assignRespondentStatus = createLoadingStateReducer(
  initialState.assignRespondentStatus,
  {
    [ProjectRespondentActionTypes.CreateProjectRespondent]: [
      RequestActionTypes.REQUEST,
      RequestActionTypes.SUCCESS,
      RequestActionTypes.FAILURE,
    ],
  },
  {
    [AuthActionTypes.Logout]: {
      [RequestActionTypes.SUCCESS]: () => initialState.assignRespondentStatus,
    },
  }
);

export default combineReducers<ProjectRespondentState>({
  projectRespondents,
  projectRespondentsPagination,
  projectRespondentsStatus,
  projectRespondentsOrder,
  projectRespondentsFilter,
  clients,
  clientsStatus,
  currentProjectId,
  clientInvoices,
  clientInvoicesStatus,
  assignRespondentStatus,
});

/* SAGAS */
function* getProjectRespondents() {
  const pagination: Pages = yield select(selectProjectRespondentProjectRespondentsPagination);
  const orderColumn: ITableOrder = yield select(
    selectProjectRespondentProjectRespondentsOrderColumn
  );
  const filterColumn: Record<string, ISearch> = yield select(
    selectProjectRespondentProjectRespondentsFilterColumn
  );

  const projectId: string = yield select(selectProjectRespondentProjectId);

  const stringFromFilter = `${formatFilterToString(filterColumn)}`;

  const resp: ExtendedAxiosResponse = yield call(api.getProjectRespondents, {
    id: projectId,
    pagination: pagination,
    order: orderColumn,
    filter: stringFromFilter,
  });

  if (resp.ok) {
    yield put(projectRespondentGetProjectRespondentsActions.success(toCamelCase(resp.data)));
  } else {
    yield put(projectRespondentGetProjectRespondentsActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* removeProjectRespondent({ payload }: AppAction<{ id: number }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeProjectRespondent, payload);

  if (resp.ok) {
    yield put(projectRespondentRemoveProjectRespondentActions.success({ id: payload.id }));
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(projectRespondentRemoveProjectRespondentActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* createProjectRespondent({ payload }: AppAction<ProjectRespondentPayload>) {
  const projectId: string = yield select(selectProjectRespondentProjectId);
  const resp: ExtendedAxiosResponse = yield call(api.createProjectRespondent, {
    id: projectId,
    ...payload,
  });

  if (resp.ok) {
    yield put(projectRespondentCreateProjectRespondentActions.success());
    yield put(
      toastCreateSuccessActions(
        resp.data?.message || i18n.t('components.projectRespondentAssignRespondent.toasts.success')
      )
    );
    yield put(projectRespondentGetProjectRespondentsActions.request());
  } else {
    yield put(projectRespondentCreateProjectRespondentActions.failure());
    yield put(toastCreateErrorActions(resp.data?.message));
  }
}

function* getClients({ payload }: AppAction<any>) {
  const resp: ExtendedAxiosResponse = yield call(api.getClients, payload);

  if (resp.ok) {
    yield put(projectRespondentGetClientsActions.success(toCamelCase(resp?.data)?.data));
  } else {
    yield put(projectRespondentGetClientsActions.failure());
    yield put(toastCreateErrorActions(resp?.data?.message));
  }
}

function* getClientInvoices({ payload }: AppAction<any>) {
  const resp: ExtendedAxiosResponse = yield call(api.getClientInvoices, payload);

  if (resp.ok) {
    yield put(projectRespondentGetClientInvoicesActions.success(toCamelCase(resp.data)?.data));
  } else {
    yield put(projectRespondentGetClientInvoicesActions.failure());
    yield put(toastCreateErrorActions(resp?.data?.message));
  }
}

function* removeMultipleProjectRespondents({
  payload,
}: AppAction<{ assignedProjectsArray: any[] }>) {
  const resp: ExtendedAxiosResponse = yield call(api.removeProjectRespondents, payload);

  if (resp.ok) {
    yield put(
      projectRespondentRemoveMultipleProjectRespondentsActions.success({
        id: payload.assignedProjectsArray,
      })
    );
    yield put(toastCreateSuccessActions(resp.data?.message));
  } else {
    yield put(projectRespondentRemoveMultipleProjectRespondentsActions.failure());
    yield put(toastCreateErrorActions(resp?.data?.message));
  }
}

/* EXPORT */
export function* projectRespondentSaga() {
  yield takeLatest(
    [
      createActionType(
        ProjectRespondentActionTypes.GetProjectRespondents,
        RequestActionTypes.REQUEST
      ),
      ProjectRespondentActionTypes.SetProjectRespondentsPage,
      ProjectRespondentActionTypes.IncreasePage,
      ProjectRespondentActionTypes.SetOrderColumn,
      ProjectRespondentActionTypes.SetFilterColumn,
    ],
    getProjectRespondents
  );
  yield takeLatest(
    [
      createActionType(
        ProjectRespondentActionTypes.RemoveProjectRespondent,
        RequestActionTypes.REQUEST
      ),
    ],
    removeProjectRespondent
  );
  yield takeLatest(
    createActionType(
      ProjectRespondentActionTypes.CreateProjectRespondent,
      RequestActionTypes.REQUEST
    ),
    createProjectRespondent
  );
  yield takeLatest(
    createActionType(ProjectRespondentActionTypes.GetClients, RequestActionTypes.REQUEST),
    getClients
  );
  yield takeLatest(
    createActionType(
      ProjectRespondentActionTypes.RemoveMultipleProjectRespondents,
      RequestActionTypes.REQUEST
    ),
    removeMultipleProjectRespondents
  );
  yield takeLatest(
    createActionType(ProjectRespondentActionTypes.GetClientInvoices, RequestActionTypes.REQUEST),
    getClientInvoices
  );
}
